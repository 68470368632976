import React, { FC } from 'react'
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { DisplayTypesEnum } from './types'


interface DisplayTypeSelectorProps {
  label: string
  displayTypeValue: DisplayTypesEnum
  onDisplayTypeChange: (displayTypeValue: DisplayTypesEnum) => void
}

export const DisplayTypeSelector: FC<DisplayTypeSelectorProps> = ({ label, displayTypeValue, onDisplayTypeChange }) => {
  const labelInLowerCase = label.toLowerCase()
  return (
    <Box sx={{ display: 'flex'}}>
      <FormControl sx={{ maxWidth: '600px', width: { xs: '100%', md: '75%' } }}>
        <InputLabel id={`${labelInLowerCase}-display-label`}>{label}</InputLabel>
        <Select
          size="small"
          margin="dense"
          labelId={`${labelInLowerCase}-display-label`}
          id={`${labelInLowerCase}-display`}
          value={displayTypeValue}
          label={label}
          onChange={(event) => onDisplayTypeChange(event.target.value as DisplayTypesEnum)}
        >
          {Object.values(DisplayTypesEnum).map((display) => (
            <MenuItem key={display} value={display}>
              {display.charAt(0).toUpperCase() + display.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

import { useState, useEffect } from 'react'

// useDebouncedState implements debouncing logic on a react state value.
// the second param specifies the debounce delay in milliseconds
function useDebouncedState(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  // set the debounced state value if we don't call the hook again for 'delay' ms
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebouncedState

import React, { FC, useState, useEffect } from 'react'
import { getSessionToken } from '@shopify/app-bridge-utils'

import ShopifyEmbeddedApp from 'src/views/ShopifyEmbeddedApp'
import { ProviderConfig, asyncWithLDProvider  } from 'launchdarkly-react-client-sdk'
import '@shopify/polaris/build/esm/styles.css'
import { QueryProvider } from './contexts/QueryProvider'
import { ShopifyProvider } from './contexts/ShopifyContext'
import useGenericContext from './hooks/useGenericContext'

import { ProductFruits } from 'react-product-fruits'
import SdxLoading from './components/SdxLoading/SdxLoading'

interface ShopifyAppProps {
  host: string
  shop: string
  ldExtraConfig: ProviderConfig
}

const ShopifyApp: FC<ShopifyAppProps> = ({ host, shop, ldExtraConfig}) => {
  const [LDProvider, setLDProvider] = useState<any>(null)
  const [userId, setUserId] = useState<string>('')
  const PFWorkspaceCode = process.env.PRODUCT_FRUITS_WORKSPACE_CODE || 'HlwYZqDgdZ9aVeOV'
  const { merchantDetails } = useGenericContext()

  useEffect(() => {
    async function initLd() {
      try {
        const LDProviderWrapper = await asyncWithLDProvider({
          ...ldExtraConfig,
          context: {
            key: shop,
            name: shop,
            tier: merchantDetails?.plan,
            kind: 'shop',
            platform: merchantDetails?.platform,
          },
        })
        setLDProvider(() => LDProviderWrapper)
      } catch (err) {
        console.log('Error initializing LDProvider', err)
      }
    }
    async function getUserDetails() {
      try {
        const sessionToken = await getSessionToken(window.__app)
        const decodedToken = JSON.parse(atob(sessionToken.split('.')[1]))
        setUserId(decodedToken.sub)
      } catch (err) {
        console.log('Error getting user details from Shopify', err)
      }
    }
    initLd()
    getUserDetails()

  }, [ldExtraConfig, merchantDetails?.plan, shop])

  if (!LDProvider || !userId) {
    return (<SdxLoading />)
  } else {
    return (
      <LDProvider>
        <ProductFruits workspaceCode={PFWorkspaceCode} language="en" user={{
          username: userId,
          props: {
            shop: shop,
          },
        }} />
        <QueryProvider>
          <ShopifyProvider host={host} shop={shop}>
            <ShopifyEmbeddedApp />
          </ShopifyProvider>
        </QueryProvider>
      </LDProvider>
    )}
}

export default ShopifyApp

import React, { FC } from 'react'
import { ReactComponent as HeartIcon } from 'src/assets/icons/heart.svg'
import { ReactComponent as CommentIcon } from 'src/assets/icons/comment.svg'
import { Box, Typography } from '@mui/material'

type PostStatsProps = {
  comments: number
  likes: number
}

export const PostStats: FC<PostStatsProps> = ({
  comments = 0, likes = 0,
}) => {
  return (
    <Box style={{
      backgroundColor: '#F2F4F7',
      width: '100%',
    }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div style={{margin: '5px auto'}}>
          <HeartIcon />
          <div style={{color: 'black'}}>{likes}</div>
        </div>
        <div style={{margin: '5px auto'}}>
          <CommentIcon />
          <div style={{color: 'black'}}>{comments}</div>
        </div>
      </div>
    </Box>
  )
}

type PostDateProps = {
  timestamp: number
}
export const PostDate: FC<PostDateProps> = ({
  timestamp,
}) => {
  return (
    <Typography
      style={{
        color: '#607D8B',
      }}
    >
      Posted on {new Date(timestamp).toLocaleDateString('en-us')}
    </Typography>
  )
}

import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material'


import type { FC } from 'react'
import TableImage from 'src/components/TableImage'
import Icon from '../Icon/Icon'
import { brand } from 'src/theme/tokens'

const PREFIX = 'StandardTable'

const classes = {
  tablePaper: `${PREFIX}-tablePaper`,
  topAlign: `${PREFIX}-topAlign`,
  headerText: `${PREFIX}-headerText`,
  positiveText: `${PREFIX}-positiveText`,
  negativeText: `${PREFIX}-negativeText`,
  centerAlign: `${PREFIX}-centerAlign`,
}

const StyledPaper = styled(Paper)((
  {
    theme,
  },
) => ({
  [`&.${classes.tablePaper}`]: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.topAlign}`]: {
    verticalAlign: 'top',
  },

  [`& .${classes.headerText}`]: {
    color: theme.palette.system.grey4,
  },

  [`& .${classes.positiveText}`]: {
    color: theme.palette.text.positive,
  },

  [`& .${classes.negativeText}`]: {
    color: theme.palette.text.negative,
  },

  [`& .${classes.centerAlign}`]: {
    textAlign: 'center',
  },
}))

export interface StandardTableProps {
  headers: { id: string, text: string, format: 'IMAGE_WITH_LABEL' | 'ICON' | 'PERCENTAGE' | 'CURRENCY' | 'NONE' }[]
  body: Record<string, { value: number, change: 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL'} | { imgSrc: string, text: string, link: string} | { icon: string, color: 'RED' | 'GREY' | 'GREEN' | '', link? : string}>[]
}

const iconColorMap = { RED: brand.urPink, GREY: brand.blueGrey, GREEN: brand.urGreen}

const StandardTable: FC<StandardTableProps> = ({ headers, body }) => {


  return (
    <StyledPaper className={classes.tablePaper}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow classes={{
              root: classes.topAlign,
            }}>
              {headers.map((header) => <TableCell className={classes.headerText}>{header.text}</TableCell> )}
            </TableRow>
          </TableHead>
          <TableBody>
            {body.map((row, i) => {
              return <TableRow key={row[headers[0].id][0]}>
                {headers.map((header) => {
                  const value = row[header.id]
                  return (
                    <TableCell>
                      {header.format === 'IMAGE_WITH_LABEL' && 'imgSrc' in value && <TableImage imgSrc={value.imgSrc} link={value.link} text={value.text}/>}
                      {header.format === 'PERCENTAGE' && 'value' in value && <div className={clsx({[classes.negativeText]: value.change === 'NEGATIVE'}, {[classes.positiveText]: value.change === 'POSITIVE'})}>{value.value}%</div>}
                      {header.format === 'CURRENCY' && 'value' in value && <div className={clsx({[classes.negativeText]: value.change === 'NEGATIVE'}, {[classes.positiveText]: value.change === 'POSITIVE'})}>${value.value}</div>}
                      {header.format === 'ICON' && 'icon' in value &&
                      <Icon
                        className={classes.centerAlign}
                        color={iconColorMap[value.color] || ''}
                        name={value.icon}
                        root={true}
                        size={value.color ? 16 : 36}
                      />}
                      {header.format === 'NONE' && 'value' in value && <div className={clsx({[classes.negativeText]: value.change === 'NEGATIVE'}, {[classes.positiveText]: value.change === 'POSITIVE'})}>{value.value}</div>}
                    </TableCell>
                  )
                })}
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

export default StandardTable

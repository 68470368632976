import React, { FC } from 'react'
import { Box, IconButton, MenuItem, Select, TextField } from '@mui/material'
import SdxColorPicker from '../Components/SdxColorPicker'
import { deepCloneJson } from 'src/utils/helpers'
import {arrayMove, List} from 'react-movable'
import { LineItemFieldEnum } from '../ComponentSettingsPage/types'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import useGenericContext from 'src/hooks/useGenericContext'
import { ComponentTypeResponse } from '../Components/types'

export const defaultConfig: ComponentTypeResponse = {
  componentId: '',
  status: 'PUBLISHED',
  containerType: 'GRID',
  internalName: 'Cart',
  title: '',
  contentType: 'CART',
  componentType: 'CART',
  createdAt: 1,
  updatedAt: 1,
  visibleInLibrary: true,
  internalDescription: 'Cart component',
  class: 'HOOK',
  contentConfiguration: {
    type: 'LINE_ITEM',
    value: {
      uiSettings: {
        fields: [
        ],
        icon: {
          url: '',

          // TODO: rename on backend and frontend to separate badge colors
          textColor: '#000000', // badgeTextColor
          textHoverColor: '#000000', // badgeTextHoverColor

          badgingColor: '#000000', // badgeBackgroundColor
          badgingHoverColor: '#000000', // badgeBackgroundHoverColor

          backgroundColor: '#ffffff',
          backgroundHoverColor: '#ffffff',
        },
        header: {
          text: '',
          html: '',
        },
        footer: {
          text: '',
          html: '',
        },
        checkoutButton: {
          text: '',
          backgroundColor: '#ffffff',
          backgroundHoverColor: '#ffffff',
          textColor: '#ffffff',
          textHoverColor: '#ffffff',
          outlineColor: '#ffffff',
          outlineHoverColor: '#ffffff',
          outlineWidthPixels: 0,
          fontUrl: '',
        },
      },
      apiSettings: {
      },
    },
  },
}

export type CartSettingsType = {
  containerType: string
  internalName: string
  title: string
  contentType: string
  componentType: string
  createdAt: number
  updatedAt: number
  shop: string
  visibleInLibrary: boolean
  internalDescription: string
  class: string
  contentConfiguration: {
    type: string
    value: {
      uiSettings: any
    }
  }
}

export const CartSettingsUI: FC<{
  config: CartSettingsType
  setConfig: (config: CartSettingsType) => void
}> = ({config, setConfig}) => {
  const {uploadStorefrontImage} = useGenericContext()


  if(!config) return (
    <Box></Box>
  )

  const handleCartLineItemFieldOrderChange = ((oldIndex: number, newIndex: number) =>{
    const newConfig = Object.assign({}, config)
    const newFields = [...config.contentConfiguration.value.uiSettings.fields]
    newConfig.contentConfiguration.value.uiSettings.fields = arrayMove(newFields, oldIndex, newIndex)
    setConfig({ ...newConfig })
  })

  const handleCartLineItemFieldRemove = ((index: number) => {
    const newConfig = Object.assign({}, config)
    const newFields = [...config.contentConfiguration.value.uiSettings.fields]
    newFields.splice(index, 1)
    newConfig.contentConfiguration.value.uiSettings.fields = newFields
    setConfig({ ...newConfig })
  })

  const handleCartLineItemFieldAdd = ((field: LineItemFieldEnum) => {
    const newConfig = Object.assign({}, config)
    newConfig.contentConfiguration.value.uiSettings.fields = [...newConfig.contentConfiguration.value.uiSettings.fields, field]
    setConfig({ ...newConfig })
  })

  const availableCartLineItemFields = Object.values(LineItemFieldEnum).filter(field => !config.contentConfiguration.value.uiSettings.fields.includes(field))

  const handleUiSetttingsChange = (value, field: string) => {
    const keys = (field).split('.')
    // TODO: create new refs for only mutated parts
    const newConfig = deepCloneJson(config)
    let current = newConfig.contentConfiguration.value.uiSettings
    for (let i = 0; i < keys.length - 1; ++i)
      current = current[keys[i]]
    current[keys[keys.length - 1]] = value
    setConfig(newConfig)
  }
  const uiSettings = config?.contentConfiguration.value.uiSettings as {[key: string]: any} | undefined

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target
    if (files) {
      if ((files[0].size > 300000)) {
        window.alert('File is too big!')
      } else {
        const response: any = await uploadStorefrontImage(files[0])
        if(response?.data?.url) {
          handleUiSetttingsChange(response.data.url, 'icon.url')
        }
      }
    }
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            mb: 2,
          }}
        >
          Cart Settings
        </Box>

        <Box>
          <Box sx={{ marginTop: '16px' }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
            }}>
              <Box sx={{
                fontWeight: 700,
                pb: 2,
              }}> Checkout Button </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
              >

                <TextField
                  variant='outlined' size='small'
                  label='Button Text'
                  sx={{flex: 2, mb: 1}}
                  fullWidth

                  value={uiSettings?.checkoutButton.text}
                  onChange={e => handleUiSetttingsChange(e.target.value, 'checkoutButton.text')}
                />

                <TextField
                  size='small' variant='outlined'
                  sx={{flex: 1, mb: 2}}
                  fullWidth
                  name='outlineWidthPixels'

                  label='Outline width'
                  type='Number' // NOTE: Number instead of number... otherwise it leaves leading zeros
                  // NOTE: important to send non undefined value or it will overlap the label
                  value={uiSettings?.checkoutButton.outlineWidthPixels || 0}
                  onChange={e => handleUiSetttingsChange(parseInt(e.target.value), 'checkoutButton.outlineWidthPixels')}
                  InputProps={{endAdornment: <Box>px</Box>}}
                />
              </Box>

              {/* label='Font URL (Accepted Formats: .woff, .woff2, .ttf, .otf)' */}
              <TextField
                id='mainFontUrl' name='mainFontUrl' type='text'
                variant='outlined'
                size='small'
                label='Main font URL (Accepted Formats: .woff, .woff2, .ttf, .otf)'
                sx={{flex: 1, mb: 1}}
                fullWidth

                value={uiSettings?.checkoutButton.fontUrl}
                onChange={e => handleUiSetttingsChange(e.target.value, 'checkoutButton.fontUrl')}
              />

              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  gap: '6px',
                }}
              >
                <SdxColorPicker
                  label='Text Color'
                  sx={{flex: 1, mb: 2}}
                  size='small'

                  value={uiSettings?.checkoutButton.textColor}
                  onChange={v => handleUiSetttingsChange(v, 'checkoutButton.textColor')}
                />

                <SdxColorPicker
                  label='Text Hover Color'
                  sx={{flex: 1, mb: 2}}
                  size='small'

                  value={uiSettings?.checkoutButton.textHoverColor}
                  onChange={v => handleUiSetttingsChange(v, 'checkoutButton.textHoverColor')}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '6px',
              }}
            >
              <SdxColorPicker
                label='Outline Color'
                sx={{flex: 1, mb: 2}}
                size='small'

                value={uiSettings?.checkoutButton.outlineColor}
                onChange={v => handleUiSetttingsChange(v, 'checkoutButton.outlineColor')}
              />

              <SdxColorPicker
                label='Outline Hover Color'
                sx={{flex: 1, mb: 2}}
                size='small'

                value={uiSettings?.checkoutButton.outlineHoverColor}
                onChange={v => handleUiSetttingsChange(v, 'checkoutButton.outlineHoverColor')}
              />
            </Box>

          </Box>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '6px',
            }}
          >
            <SdxColorPicker
              label='Background Color'
              sx={{flex: 1, mb: 1}}
              size='small'

              value={uiSettings?.checkoutButton.backgroundColor}
              onChange={v => handleUiSetttingsChange(v, 'checkoutButton.backgroundColor')}
            />

            <SdxColorPicker
              label='Background Hover Color'
              sx={{flex: 1, mb: 1}}
              size='small'

              value={uiSettings?.checkoutButton.backgroundHoverColor}
              onChange={v => handleUiSetttingsChange(v, 'checkoutButton.backgroundHoverColor')}
            />
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              fontWeight: 700,
              py: 2,
            }}
          >
          Header
          </Box>

          <Box>
            <TextField
              variant='outlined' size='small'
              label='Header Text'
              sx={{flex: 2, my: 1}}
              fullWidth

              value={uiSettings?.header.text}
              onChange={e => handleUiSetttingsChange(e.target.value, 'header.text')}
            />

            <TextField
              variant='outlined' size='small'
              label='Header Html'

              multiline
              minRows={1}
              maxRows={5}

              sx={{flex: 2, my: 1}}
              fullWidth

              value={uiSettings?.header.html}
              onChange={e => handleUiSetttingsChange(e.target.value, 'header.html')}
            />
          </Box>

        </Box>

        <Box>
          <Box
            sx={{
              fontWeight: 700,
              py: 2,
            }}
          >
            Footer
          </Box>

          <Box>
            <TextField
              variant='outlined' size='small'
              label='Footer Text'
              sx={{flex: 2, my: 1}}
              fullWidth

              value={uiSettings?.footer.text}
              onChange={e => handleUiSetttingsChange(e.target.value, 'footer.text')}
            />

            <TextField
              variant='outlined' size='small'
              label='Footer Html'
              sx={{flex: 2, my: 1}}
              fullWidth

              multiline
              minRows={1}
              maxRows={5}

              value={uiSettings?.footer.html}
              onChange={e => handleUiSetttingsChange(e.target.value, 'footer.html')}
            />
          </Box>

          <Box
            sx={{
              fontWeight: 700,
              py: 2,
            }}
          >
            Line Item Fields
          </Box>
          <Box sx={{
            maxHeight: '210px',
            border: '1px solid lightgray',
            borderRadius: '5px',
            overflow: 'auto',
            pt: 2, mb: 2, pr: 1,
          }}>
            <List
              lockVertically
              values={uiSettings?.fields}
              onChange={({oldIndex, newIndex}) => {
                handleCartLineItemFieldOrderChange(oldIndex, newIndex)
              }}
              renderList={(list) => <Box {...list.props}>{list.children}</Box>}
              renderItem={(listItem) => {
                const value = listItem.value
                const index = listItem.index ? listItem.index : uiSettings?.fields.findIndex((val) => val === value)
                return (
                  <Box
                    {...listItem.props}
                    key={value}
                  >
                    <Box sx={{
                      ml: 1,
                    }}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '6px',
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            mb: 2,
                          }}
                        >
                          <TextField
                            variant='outlined'
                            size='small'
                            fullWidth
                            label='Line Item Field'
                            value={value}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>

                        <Box sx={{display: 'flex', mt: 1, alignItems: 'baseline'}}>

                          <Box
                            data-movable-handle
                            sx={{
                              mr: 1,
                              ml: 1,
                              cursor: listItem.isDragged ? 'grabbing' : 'grab',
                            }}
                          >
                            <DragIcon />
                          </Box>

                          <IconButton
                            sx={{
                              transform: 'translateY(-5px)',
                            }}

                            onClick={() => {
                              handleCartLineItemFieldRemove(index)
                            }}
                            disabled={value === LineItemFieldEnum.PRODUCT_NAME}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )
              }}
            />
          </Box>

          <Box display='flex' justifyContent='space-between' sx={{ pb: 1}}>
            <Select
              value=''
              size='small'
              displayEmpty
              onChange={(event) => {
                handleCartLineItemFieldAdd(event.target.value as LineItemFieldEnum)
              }}
            >
              <MenuItem value='' disabled>
                Add Line Item Field
              </MenuItem>
              {availableCartLineItemFields.map((field) => (
                <MenuItem key={field} value={field}>
                  {field}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>

      <Box>
          Cart icon (PNG only. Maximum size of 300kb.)
      </Box>
      <Box sx={{display: 'flex', py: 2, gap: 3}}>
        <img
          src={uiSettings?.icon.url ? uiSettings?.icon.url : ''}
          style={{width: 24, height: 24}}
          alt='Preview not available'
        />

        <input
          onChange={handleImageUpload}
          id='cart-icon'
          name='cart-icon'
          aria-describedby='cart-icon'
          type='file'
          accept='image/png'
        />
      </Box>

      <Box sx={{display: 'flex', gap: 3}}>
        <SdxColorPicker
          label='Badge Color'
          sx={{flex: 1, mb: 1}}
          size='small'

          value={uiSettings?.icon.badgingColor}
          onChange={v => handleUiSetttingsChange(v, 'icon.badgingColor')}
        />

        <SdxColorPicker
          label='Badge Hover Color'
          sx={{flex: 1, mb: 1}}
          size='small'

          value={uiSettings?.icon.badgingHoverColor}
          onChange={v => handleUiSetttingsChange(v, 'icon.badgingHoverColor')}
        />
      </Box>

      <Box sx={{display: 'flex', gap: 3}}>
        <SdxColorPicker
          label='Badge Text Color'
          sx={{flex: 1, mb: 1}}
          size='small'

          value={uiSettings?.icon.textColor}
          onChange={v => handleUiSetttingsChange(v, 'icon.textColor')}
        />

        <SdxColorPicker
          label='Badge Text Hover Color'
          sx={{flex: 1, mb: 1}}
          size='small'

          value={uiSettings?.icon.textHoverColor}
          onChange={v => handleUiSetttingsChange(v, 'icon.textHoverColor')}
        />
      </Box>

      <Box sx={{display: 'flex', gap: 3}}>
        <SdxColorPicker
          label='Background Color'
          sx={{flex: 1, mb: 1}}
          size='small'

          value={uiSettings?.icon.backgroundColor}
          onChange={v => handleUiSetttingsChange(v, 'icon.backgroundColor')}
        />

        <SdxColorPicker
          label='Background Hover Color'
          sx={{flex: 1, mb: 1}}
          size='small'

          value={uiSettings?.icon.backgroundHoverColor}
          onChange={v => handleUiSetttingsChange(v, 'icon.backgroundHoverColor')}
        />
      </Box>

    </Box>
  )
}

import React, { FC } from 'react'
import { Box } from '@mui/material'


const Spacer: FC<{space: number}> = (props): JSX.Element => {

  return <Box sx={{ mt: props.space}}/>
}

export default Spacer

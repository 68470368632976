import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2 } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { SocialSource } from 'src/components/SocialChannelPicker'
import { PrependShopCurrency } from 'src/contexts/types'
import { extendCampaignImages } from './extendCampaignImages'
import { SdxValue } from './getCampaignSummaryMetrics'

export type CampaignImage = {
  originalUrl: string
  largeUrl: string
  mediumUrl: string
  smallUrl: string
  objId: string
}

export type TopCampaign = {
  campaignId: string
  campaignName: string
  campaignDescription: string
  campaignImages: CampaignImage[]
  campaignSources: SocialSource[]
  startDate: number
  metrics: {value: SdxValue, label: string}[]
}
export type TopCampaignSortDir = 'ASC' | 'DESC'
export type TopCampaignSortBy = 'startDate' | 'revenue' | 'bounce' | 'ROAS' | 'name' | 'CAC'

export type GetTopCampaigns2 = (
  isBrand:boolean, startTimestamp: number, endTimestamp: number, sortBy: TopCampaignSortBy, sortDir: TopCampaignSortDir,
) => Promise<AxiosResponse<TopCampaign[]> | undefined>

export const getTopCampaigns2Api: PrependShopCurrency<GetTopCampaigns2> = async (
  activeShop, currencyCode,
  isBrand, startTimestamp, endTimestamp, sortBy, sortDir,
) => {
  try {
    const baseUrl = '/v1/analytics/top-campaigns'
    const params = new URLSearchParams()
    params.append('sortBy', sortBy)
    params.append('sortDir', sortDir)
    params.append('currency', currencyCode)
    params.append('startTimestamp', startTimestamp.toString())
    params.append('endTimestamp', endTimestamp.toString())
    if(isBrand) params.append('isBrand', '1')
    const url = `${baseUrl}?${params.toString()}`

    const cfg = {
      baseURL: analyticsServiceBaseUrl2,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<TopCampaign[]>(url, cfg)

    await extendCampaignImages(activeShop, response.data)

    return response
  }

  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

/* eslint-disable jsx-a11y/alt-text */
import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { stripHtml } from 'src/utils/helpers'
import { ImageType } from 'src/types/interpretor'
import { currencySymbolMap } from 'src/utils/currencySymbolMap'
import useGenericContext from 'src/hooks/useGenericContext'
import { MultiSourceImage } from 'storefront-interpreter/src/config'

export type ProductItemFull = {
  objId: string
  productId: string
  images: ImageType[]
  title: string
  description: string
  price: number
  comparePrice?: number
  currency: string
  variantExternalId: string
  totalInventory: number
}
type ProductImage = {
  originalUrl: string
  url: string
  altText: string
}

export type Variants = {
  id: string
  productId: string
  externalId: string
  sku: string
  title: string
  productTitle: string
  price: number
  comparePrice: number | null
  currencyCode: string
  optionValues:
  {
    name: string
    value: string
  }[]
  featuredImage: ProductImage
  images: ProductImage[]
  available: boolean
  allowOosOrder: boolean
  quantity: number
}

export type RawImage = MultiSourceImage & {
  url: string
  altText?: string
}

export type ProductPrimitive = {
  id: string
  externalId: string
  title?: string
  description?: string
  pdpUrl?: string
  handle?: string
  featuredImage?: RawImage
  price?: number
  comparePrice?: number
  currencyCode?: string
  totalInventory: number
}

type ProductSelectorLargeProps = {
  openWhenNotSelected?: boolean
  onSelect: (item: ProductItemFull) => void
  selected: ProductItemFull | null | undefined
  error?: boolean
  inputRef?: React.MutableRefObject<HTMLElement | null>
  label?: string
  previouslySelected?: string[]
}

const ProductSelectorLarge: FC<ProductSelectorLargeProps> = ({
  openWhenNotSelected,
  onSelect,
  selected,
  error,
  inputRef,
  label,
  previouslySelected,
}) => {

  const { searchProducts } = useGenericContext()
  const [searchedProducts, setSearchedProducts] = React.useState<ProductItemFull[]>([])
  const [searchString, setSearchString] = React.useState<string>('')
  const [searching, setSearching] = React.useState<boolean>(false)
  const filterStringRef = useRef<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)
  const [currentCategoryIds, setCurrentCategoryIds] = useState<string>('')
  const [offset, setOffset] = useState(0)
  const limit = 10

  useEffect(() => {
    if ((searchedProducts.length > 0) && !selected) {
      // NOTE: delay needed because there is no batching of hook dependencies so
      //       'items' come in while 'selected' is undefined, then 'selected' comes in
      let timerId
      if (openWhenNotSelected)
        timerId = setTimeout(() => setOpen(true), 200)

      return () => {
        clearTimeout(timerId)
      }
    }
  }, [searchedProducts, selected, openWhenNotSelected])

  const handleLoadMore = async (reset?: boolean) => {
    const newOffset = reset ? 0 : offset

    if (newOffset === -1) return
    if (searching) return

    setSearching(true)
    try {
      const chunk = (await searchProducts(searchString, limit, newOffset)) || []
      setSearchedProducts(newOffset === 0 ? chunk : [...searchedProducts, ...chunk])
      setOffset(chunk.length < limit ? -1 : newOffset + limit)
    }
    catch (e) {
      setSearchedProducts([])
    }
    setSearching(false)
  }

  useEffect(() => {
    const timeout = setTimeout(() => handleLoadMore(true), 200)
    return () => clearTimeout(timeout)
  }, [searchString])

  useEffect(() => {
    if (open) setTimeout(() => filterStringRef?.current?.focus(), 0)
  }, [open, filterStringRef])

  const close = () => {
    filterStringRef.current?.blur()
    setOpen(false)
  }

  const thumbnailUrl = selected?.images[0]?.smallUrl || ''

  return (
    <Autocomplete
      open={open}
      loading={searching}
      onClose={() => {
        // NOTE: setTimeout otherwise the blur doesn't work...
        setTimeout(() => {
          close()
        }, 0)
      }}
      clearIcon={searchString ? undefined : <span />}
      isOptionEqualToValue={(option, value) => option.productId === value?.productId} // NOTE: this type is wrong, value can be undefined when removing items??
      getOptionLabel={product => searchString || ''}
      getOptionDisabled={product => product?.totalInventory <= 0}
      options={searchedProducts.filter((product) => !previouslySelected?.find(selectedProductId => selectedProductId === product.productId) || product.productId === selected?.productId)}
      inputValue={searchString}
      onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e) return
        const value = e?.target?.value || ''
        setSearchString(value)
        if (offset < 0)
        {
          setOffset(0)
          handleLoadMore()
        }
      }}
      value={selected}
      onChange={(e, value) => {
        if (value && typeof value === 'object') {
          onSelect(value)
          close()
        }
      }}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget
          if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 180)
            handleLoadMore()
        },
        style: {
          textAlign: 'center',
          display: 'grid',
          gridGap: '24px',
          justifyContent: 'center',
          gridTemplateColumns: 'repeat(auto-fill, 150px)',
          padding: '0px !important',
          paddingBottom: '18px !important',
        },
      }}

      renderOption={(props, product) => {
        // TODO: send this from backend
        const currency = currencySymbolMap[product.currency] || product.currency

        return (
          <li {...props}
            key={product.objId}
            style={{
              display: 'unset',
              width: '150px',
              borderBottom: '1px solid lightgrey',
              paddingLeft: '0px !important',
              paddingRight: '0px !important',
            }}
          >
            <span style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pointerEvents: 'none',
              height: '100%',
              padding: '3px',
            }}
            >
              <img
                style={{
                  objectFit: 'cover',
                  width: '150px',
                  height: '150px',
                }}
                loading='lazy'
                src={product?.images?.[0]?.mediumUrl}
              />
              <Box
                style={{
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  display: '-webkit-box',
                }}
              >
                <Typography variant='h5'
                  style={{
                    margin: '6px 0px',
                  }}
                >
                  {product.title}
                </Typography>
              </Box>
              <Box
                style={{
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  marginTop: 'auto',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  maxWidth: 150,
                }}
              >
                {product?.description && stripHtml(product.description)}
              </Box>
              <Typography
                style={{
                  fontWeight: 'bold',
                  paddingTop: '6px',
                }}
              >
                {currency}
                {product?.price && product.price.toFixed(2)}
                &nbsp;
                {
                  product?.comparePrice &&
                  <span style={{
                    textDecoration: 'line-through',
                  }}>
                    {currency}
                    {product?.comparePrice && product.comparePrice.toFixed(2)}
                  </span>
                }
              </Typography>
            </span>
          </li>
        )
      }}

      renderInput={(params) => {
        return (
          <TextField
            fullWidth={true}
            onBlur={() => close()}
            onFocus={() => setOpen(true)}

            // NOTE: not necessary because it's handled by the Autocomplete but
            //       react still throws a warning about an uncontrolled component
            value={searchString}

            inputRef={(element) => {
              filterStringRef.current = element
              if (inputRef)
                inputRef.current = element
            }}

            label={label}
            error={error}

            size='small' margin='dense' type='string' variant='outlined'
            inputProps={{
              ...params.inputProps,
              value: filterStringRef.current === document.activeElement ? searchString : selected?.title || searchString,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: thumbnailUrl ? <img
                style={{
                  marginLeft: '5px',
                  marginRight: '5px',
                  opacity: selected && !open ? 1 : 0,
                }}
                loading='lazy'
                width={20}
                height={20}
                src={thumbnailUrl}
              /> : null,
              endAdornment: (
                <React.Fragment>
                  {searching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
    />

  )
}

export default ProductSelectorLarge

import React, { useState, useEffect, Fragment } from 'react'
import type { FC, ReactNode } from 'react'
import {   Box, useTheme } from '@mui/material'

import NavBar, { navbarIconSize } from './NavBar'
import useAuth from 'src/hooks/useAuth'
import { useTranslation } from 'react-i18next'
import Backdrop from '@mui/material/Backdrop'
import { getDeviceType } from 'src/utils/helpers'
import { Alert } from '@mui/material'
import { ReactComponent as Question } from 'src/assets/icons/Question.svg'

interface DashboardLayoutProps {
  children?: ReactNode
}

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const theme = useTheme()

  const {t} = useTranslation()
  const [deviceErrorOpen, setDeviceErrorOpen] = useState(false)
  const [device, setDevice] = useState('')
  const { stores } = useAuth()
  const projectErrorOpen = stores.length < 1

  useEffect(() => {
    const deviceType = getDeviceType()
    if (deviceType !== 'Desktop') {
      setDeviceErrorOpen(true)
      setDevice(deviceType)
    }
  }, [])

  const DeviceErrorBanner = () => <Fragment>
    {!projectErrorOpen && <NavBar />}
    <Backdrop style={{ zIndex: 100, background: '#FFF' }}
      open={deviceErrorOpen}>
      <Box style={{ display: 'grid', placeItems: 'center' }}>
        <Box>
          <Question />
        </Box>

        <Box style={{ width: '70%' }}>
          <Alert
            style={{ color: '#00A5D9', background: 'transparent' }}
            severity="info"
          >
            {t('general.device error', { device: device })}
          </Alert>
        </Box>
      </Box>
    </Backdrop>
  </Fragment>

  const ProjectErrorBanner = () => <Backdrop style={{ zIndex: 100, background: '#FFF' }}
    open={projectErrorOpen}>
    <Box style={{ display: 'grid', placeItems: 'center' }}>
      <Box>
        <Question />
      </Box>

      <Box style={{ width: '70%' }}>
        <Alert
          style={{ color: '#00A5D9', background: 'transparent' }}
          severity="info"
        >
          {t('general.no access')}
        </Alert>
      </Box>
    </Box>
  </Backdrop>

  return (
    <Fragment>
      <div style={{
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      }}>
        { projectErrorOpen && ProjectErrorBanner() }
        { deviceErrorOpen && DeviceErrorBanner() }
        {
          <Fragment>
            <Box sx={{
              zIndex: 10000999,
              position: 'absolute',
            }}>
              <NavBar />
            </Box>

            <div style={{
              display: 'flex',
              flex: '1 1 auto',
              overflow: 'hidden',
              paddingTop: 0,
              paddingLeft: navbarIconSize,
            }}>
              <div style={{
                display: 'flex',
                flex: '1 1 auto',
                overflow: 'hidden',
              }}>
                <div id="scroll-container" style={{
                  flex: '1 1 auto',
                  height: '100%',
                  overflow: 'auto',
                }}>{children}</div>
              </div>
            </div>
          </Fragment>
        }
      </div>
    </Fragment>
  )
}

export default DashboardLayout

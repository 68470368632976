import { getSessionToken } from '@shopify/app-bridge-utils'
import { fetchAuthSession } from 'aws-amplify/auth'
import axios, { AxiosRequestConfig } from 'axios'
import { logging } from 'src/utils/logging'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  async (config) => await authInjection(config),
  error => {
    Promise.reject(error)
  })

const authInjection = async (config: AxiosRequestConfig) => {
  try {
    if (window.__app) {
      const sessionToken = await getSessionToken(window.__app)
      config.headers = {
        ...config.headers,
        'X-Shopify-Session-Token': sessionToken,
      }
    } else {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {}
      const token = accessToken?.toString() || `No_token`
      config.headers = {
        ...config.headers,
        Authorization: `${token}`,
      }
      return config
    }
    return config
  } catch (error) {
    logging(error, { tags: { section: 'token error' } })
    logging(error, { tags: { section: 'axios'} })
    return config
  }
}

export { axiosInstance }

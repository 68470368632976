import { useState, useRef, useEffect } from 'react'
import { Grid, Typography, Paper, useTheme, Box } from '@mui/material'
import type { FC } from 'react'
import useGenericContext from 'src/hooks/useGenericContext'
import { StorefrontConfig } from 'src/types/interpretor'
import { formatPercent, safeParseJSON } from 'src/utils/helpers'
import { PageMetrics } from 'src/contexts/api/getPageMetrics'
import { formatDataValue, formatNumber } from 'src/utils/analyticsHelpers'
import { getPlatformInfo } from 'src/utils/getPlatformSymbol'
import { SdxMetric } from 'src/contexts/api/getOptimalPage'
import { currencySymbolMap } from 'src/utils/currencySymbolMap'

const MetricComponent = ({title, value}: {title: string, value: string}) => {
  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography component='span'
          color="white"
          fontWeight={500}
          fontSize={14}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography component='span'
          color="white"
          fontWeight={600}
          fontSize={42}
          lineHeight={1}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

export type ComponentInfoDict = {[componentId: string]: {
  color: string
  componentMetric: SdxMetric[]
  isFixed: boolean
}}
export type ComponentRectDict = {[componentId: string]: {
  componentId: number
  rect: DOMRect
}}

interface PageMlPerformanceProps {
  orientation: 'left' | 'right'
  title: string
  pageConfig: StorefrontConfig
  componentInfoDict: ComponentInfoDict
  pageMetrics: PageMetrics | undefined
  isLocalhost: boolean
}
const PageMlPerformance: FC<PageMlPerformanceProps> = ({ orientation, title, pageConfig, componentInfoDict, pageMetrics, isLocalhost}) => {

  const { shop } = useGenericContext()
  const theme = useTheme()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [containerHeight, setContainerHeight] = useState<number>(2000)

  const [componentRectDict, setComponentRectDict] = useState<ComponentRectDict>({})
  const indicators = !Object.keys(componentRectDict).length ? [] : Object.keys(componentInfoDict).map(componentId => {
    return {
      componentId,
      color: componentInfoDict[componentId].color,
      height: componentRectDict[componentId]?.rect.height,
      top: componentRectDict[componentId]?.rect.top,
      metric: componentInfoDict[componentId].componentMetric,
      isFixed: componentInfoDict[componentId]?.isFixed,
    }
  })

  const {interpreterDomain, selectedStore, platformSymbol} = getPlatformInfo(shop)
  const iframeSrc = isLocalhost ?
    `http://localhost:3001?hostname=${interpreterDomain}&s=${selectedStore}&p=${platformSymbol}`
    : `https://${interpreterDomain}?s=${selectedStore}&p=${platformSymbol}`
  useEffect(() => {
    if (!iframeLoaded) return

    const payload = {
      type: 'setPageConfig',
      value: pageConfig,
    }

    const iframeWindow = iframeRef.current?.contentWindow
    iframeWindow?.postMessage(JSON.stringify({type: 'setForceRenderUnpublished', value: true}), '*')
    iframeWindow?.postMessage(JSON.stringify(payload), '*')
  }, [pageConfig, iframeLoaded])

  useEffect(() => {
    const iframeWindow = iframeRef.current?.contentWindow
    if (!iframeWindow) return

    const cb = async (e: MessageEvent<any>) => {
      if (e.data === 'unchanged') return // some cognito spam
      if (e.source !== iframeWindow) return

      const data = safeParseJSON(e.data)
      if (data?.type === 'containersRects') {
        if(data.scrollHeight && typeof data.scrollHeight === 'number' && data.scrollHeight > containerHeight) {
          setContainerHeight(data.scrollHeight + 10)
        }
        const dict = data.value.reduce((acc: ComponentRectDict, curr: any) => {
          acc[curr.componentId] = curr
          return acc
        }, {})
        setComponentRectDict(dict)
      }
    }
    window.addEventListener('message', cb)
    return () => {
      window.removeEventListener('message', cb)
    }
  }, [iframeRef])

  return (
    <Box>
      <Grid container direction={orientation === 'left' ? 'row' : 'row-reverse' } spacing={5}>
        {/* <Grid item>
          <Grid container direction='column' spacing={0} sx={{mt: 0}}>
            {[
              {title: 'Bounce rate', value: formatPercent(pageMetrics?.bounceRate)},
              {title: 'Average page views', value: formatNumber(pageMetrics?.avgPageViews, 2)},
            ].map((item, i) => (
              <Grid item key={i}>
                <MetricComponent
                  title={item.title}
                  value={item.value}
                />
              </Grid>
            ))}
          </Grid>
        </Grid> */}
        <Grid item>
          <Grid container alignItems='center' direction='column' spacing={2} mb={2}>
            <Grid item>
              <Typography component='span'
                color="white"
                fontWeight={700}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Paper>
                <Box
                  className="box"
                  sx={{
                    margin: 'auto',
                    width: `${375}px`,
                    height: `${containerHeight}px`,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >

                  <Box sx={{
                    position: 'relative',
                  }}>
                    {indicators.map((indicator, i) => (
                      <Box key={i} sx={{
                        position: 'absolute',
                        left: orientation === 'left' ? '0px' : 'auto',
                        right: orientation === 'left' ? 'auto' : '0px',
                        transform: `translateX(calc(${orientation === 'left' ? -1: 1} * (100% + 15px)))`,
                        top: `${indicator.top}px`,
                        // transform: 'translate(-20px, 0px)',
                        height: `${indicator.height - 20}px`,
                        width: '15px',
                        borderRadius: '10px',
                        backgroundColor: indicator.isFixed ? 'transparent' : indicator.color,
                        border: indicator.isFixed ? '1px solid lightgrey' : '',
                      }}>
                        {orientation === 'right' &&
                          <Box sx={{
                            transform: 'translate(25px, 30px)',
                            color: 'white',
                          }}>
                            <Box
                              sx={{
                                width: '150px',
                              }}
                            >
                              {indicator.isFixed && <Box
                                sx={{
                                  marginBottom: 1,
                                }}
                              >
                                  AI Disabled
                              </Box>}
                              {indicator.metric.map(metric => <Box
                                sx ={{
                                }}
                              >
                                <Box
                                  sx={{
                                    marginBottom: 1,
                                  }}
                                >
                                  {`${metric.label}`}
                                </Box>

                                <Box
                                  sx={{
                                    fontSize: '36px',
                                    fontWeight: 'bold',
                                    mb: 2,
                                  }}
                                >
                                  {metric.value.metricType === 'PERCENT' && formatPercent(metric.value.value)}
                                  {metric.value.metricType === 'CURRENCY' && metric.value.currency &&  formatDataValue(metric.value.value, 2, currencySymbolMap[metric.value.currency])}
                                  {metric.value.metricType === 'COUNT' && formatDataValue(metric.value.value)}
                                </Box>

                              </Box>)}
                            </Box>
                          </Box>
                        }
                      </Box>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      flex: 3,
                      pointerEvents: 'none',
                      overflow: 'hidden',
                      height: '100%',
                      width: '100%',
                      contain: 'layout',
                      position: 'relative' as any,
                    }}
                  >
                    <iframe
                      onLoad={() => setIframeLoaded(true)}
                      ref={iframeRef}
                      title="Live preview"
                      style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        border: 0,
                        position: 'absolute' as any,
                      }}
                      src={iframeSrc}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}


export default PageMlPerformance

import { byRole, byTestId, byText, byPlaceholderText } from 'testing-library-selector'

export const ui = {
  addBtn: byRole('button', { name: /add filter/i }),
  deleteBtn: byTestId('delete-segment'),
  filter: byTestId('filter'),
  inputs: byPlaceholderText(/search/i),
  isBetweenBtn: byRole('button', { name: /is between/i }),
  listItems: byTestId('list-item'),
  listButtons: byTestId('list-btn'),
  orBtn: byRole('button', { name: /^or/i }),
  progressBar: byTestId('progress-bar'),
  operatorBtn: byRole('button', { name: /operator/i }),
  searchBar: byRole('textbox', { name: /search/i }),
  selectFilter: byRole('button', { name: /select filter/i }),
  seeMore: byText(/see \d more/i),
  section: byTestId('segment-section'),
  sessionReplayRow: byTestId('session-replay-row'),
  search: byRole('textbox'),
  segmentBtn: byTestId('segment-btn'),
  showAllBtn: byTestId('show-all'),
  showLessBtn: byTestId('show-less'),
  showFetchMoreBtn: byTestId('show-fetch-more'),
  showHiddenMoreBtn: byTestId('show-hidden-more'),
}

export const datePickerUI = {
  datePicker: byTestId('datePickerButton'),
  lastHour: byRole('menuitem', { name: /last hours/i }),
  last4Hour: byRole('menuitem', { name: /last 4 hours/i }),
  today: byRole('menuitem', { name: /today/i }),
  last7Days: byRole('menuitem', { name: /last 7 days/i }),
  last14Days: byRole('menuitem', { name: /last 14 days/i }),
  last30Days: byRole('menuitem', { name: /last 30 days/i }),
  custom: byRole('menuitem', { name: /custom/i }),
  menuItems: byRole('menuitem'),
}

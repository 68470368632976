import React, { useCallback, useEffect, useState, FC, useRef } from 'react'
import { Box, Button, Tabs, TextField } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { ReactComponent as UploadIcon } from 'src/assets/icons/Upload.svg'
import { ReactComponent as InstagramIcon } from 'src/assets/icons/Instagram.svg'
import { ReactComponent as AddIcon } from 'src/assets/icons/Add.svg'

import PostSelector from 'src/components/PostSelector'
import { Ad, Post, PostsResponse, AdsResponse } from 'src/contexts/types'
import { UploadComponent } from './UploadComponent'
import AdSelector from 'src/components/AdSelector'
import { IntegrationLocal, IntegrationsPayload } from 'src/views/StoreIntegrations/types'
import useGenericContext from 'src/hooks/useGenericContext'
import { useParams } from 'react-router-dom'
import { PageConfig } from 'src/types/interpretor'
import useDebouncedState from 'src/hooks/useDebouncedState'


const getInitialPostsResponse = () => ({
  offset: 0, posts: [], isEnd: false,
})

const getInitialAdsResponse = () => ({
  offset: 0, ads: [], isEnd: false,
})

export type SocialPickerProps = {
  dispatchPageConfig: (arg: {value: {headline?: string, subheadline?: string, campaignMedias?: PageConfig['campaignMedias']}}) => void

  section?: {
    type: string
    title: string
    collapsed: boolean
    hidden: boolean
  }
  headline: string
  subheadline: string
  campaignMedias: PageConfig['campaignMedias']
  nameErrorString?: string
  setNameErrorString?: (val: string) => void
  defaultOpen?: boolean
}

export const SocialPicker: FC<SocialPickerProps> = ({
  dispatchPageConfig,
  section,
  headline,
  subheadline,
  campaignMedias,
  nameErrorString,
  setNameErrorString,
  defaultOpen = true,
}) => {
  const { getPosts, getAds, getIntegrations, getAd, getPageConfig, getPost } = useGenericContext()
  const routeParams = useParams()
  const pageConfigId: string = routeParams['id'] || ''

  const [integrations, setIntegrations] = useState<IntegrationLocal[]>([])
  const [integrationIndex, setIntegrationIndex] = useState(0)

  const [adsResponse, setAdsResponse] = useState<AdsResponse & { offset: number, isEnd: boolean }>(getInitialAdsResponse())
  const [postsResponse, setPostsResponse] = useState<PostsResponse & { offset: number, isEnd: boolean }>(getInitialPostsResponse())
  const [selectedPost, setSelectedPost] = useState<Post | null>(null)
  const [selectedAd, setSelectedAd] = useState<Ad | null>(null)
  const [postAccountId, setPostAccountId] = useState('')
  const [adAccountId, setAdAccountId] = useState('')
  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebouncedState(searchString, 200)
  const nameRef: React.MutableRefObject<HTMLElement | null> = useRef<HTMLElement>(null)

  useEffect(() => {
    const integration = integrations[integrationIndex]
    if (!integration) return
    if (!selectedPost) return

    dispatchPageConfig({
      value: {
        campaignMedias: [{
          objId: uuidv4(),

          altText: '',
          originalUrl: selectedPost.thumbnail,
          largeUrl: selectedPost.thumbnail,
          mediumUrl: selectedPost.thumbnail,
          smallUrl: selectedPost.thumbnail,

          postId: selectedPost.externalId,
          postType: 'instagram',
          postAccountId: integration.externalId,
        }],
        subheadline: selectedPost.caption,
      },
    })
  }, [integrations, selectedPost])

  useEffect(() => {
    const integration = integrations[integrationIndex]
    if (!integration) return
    if (!selectedAd) return

    dispatchPageConfig({
      value: {
        campaignMedias: [{
          objId: uuidv4(),

          altText: '',
          originalUrl: integration.icon,
          largeUrl: integration.icon,
          mediumUrl: integration.icon,
          smallUrl: integration.icon,

          postId: selectedAd.externalId,
          postType: 'ad',
          postAccountId: integration.externalId,
        }],
        subheadline: selectedAd.message,
      },
    })
  }, [integrations, selectedAd])


  useEffect(() => {
    if (campaignMedias[0] && !campaignMedias[0].postType) {
      setIntegrationIndex(integrations.length-1)
    }
  }, [campaignMedias, setIntegrations])

  useEffect(() => {
    if (nameErrorString && headline)
      setNameErrorString && setNameErrorString('')
  }, [nameErrorString, headline])


  // set initial integration index if selected postAccountId exists
  useEffect(() => {
    if (!integrations.length) return

    if (postAccountId) {
      const index = integrations.findIndex(x => x.externalId === postAccountId)
      if (index > -1) setIntegrationIndex(index)
    }
    else if (adAccountId) {
      const index = integrations.findIndex(x => x.externalId === adAccountId)
      if (index > -1) setIntegrationIndex(index)
    } else {
      setIntegrationIndex(integrations.length - 1)}
  },[integrations, postAccountId, adAccountId])

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const response: IntegrationsPayload | undefined = (await getIntegrations())?.data
        if (response) {
          let ints: IntegrationLocal[] = []

          response.forEach(x => {
            x.igAccounts.forEach((integration) => {
              ints.push({
                label: integration.name,
                externalId: integration.externalId + '',
                icon: InstagramIcon,
                type: 'instagram',
              })
            })

            x.adAccounts.forEach((integration) => {
              ints.push({
                label: integration.name,
                externalId: integration.externalId + '',
                icon: AddIcon,
                type: 'meta-ads',
              })
            })
          })
          ints = ints.filter((x: any) => (x.type !== 'meta-ad') && (x.type !== 'meta_ads'))
          ints.push({ externalId: '', label: 'Manual', icon: UploadIcon, type: 'manual' })
          setIntegrations(ints)
        } else {
          setIntegrations([{ externalId: '', label: 'Manual', icon: UploadIcon, type: 'manual' }])
        }
      } catch(e) {
        setIntegrations([{ externalId: '', label: 'Manual', icon: UploadIcon, type: 'manual' }])
      }
    }
    fetchIntegrations()
  }, [])

  const campaignMediasIdsString = campaignMedias.map(x => x.postId).join(',')

  useEffect(() => {
    if (!pageConfigId) return

    const doAsync = async () => {
      const initialPost = campaignMedias?.find(x => x.postType === 'instagram')
      if (initialPost) {
        const asyncGetPost = async () => {
          setPostAccountId(initialPost.postAccountId!)
          const response = await getPost(initialPost.postAccountId!, initialPost.postId!)
          response?.data && setSelectedPost(response.data)
        }
        asyncGetPost()
      }

      const initialAd = campaignMedias?.find(x => x.postType === 'ad')
      if (initialAd) {
        const asyncGetAd = async () => {
          setAdAccountId(initialAd.postAccountId!)
          const response = await getAd(initialAd.postAccountId!, initialAd.postId!)
          response?.data && setSelectedAd(response.data)
        }
        asyncGetAd()
      }
    }
    doAsync()
  }, [getAd, getPageConfig, getPost, pageConfigId, campaignMediasIdsString])

  const getNextAds = useCallback(async () => {
    const integration = integrations[integrationIndex]
    if (!integration) return
    if (adsResponse.isEnd) return

    if (integration.type === 'meta-ads') {
      const response = await getAds(integration.externalId, 10, adsResponse.offset, debouncedSearchString)
      response?.data && setAdsResponse((prevAdsResponse) => {
        return {
          isEnd: response.data.ads.length < 10,
          offset: adsResponse.offset + response.data.ads.length,
          ads:  (debouncedSearchString && adsResponse.offset === 0) ? [...response.data.ads] : [
            ...prevAdsResponse.ads,
            ...response.data.ads,
          ],
        }
      })
    }
  }, [integrations, integrationIndex, adsResponse.isEnd, adsResponse.offset, getAds, debouncedSearchString])

  const getNextPosts = useCallback(async () => {
    const integration = integrations[integrationIndex]
    if (!integration) return
    if (postsResponse.isEnd) return

    if (integration.type === 'instagram') {
      const response = await getPosts(integration.externalId, 10, postsResponse.offset, debouncedSearchString)
      response?.data && setPostsResponse((prevPostsResponse) => {
        return {
          isEnd: response.data.posts.length < 10,
          offset: postsResponse.offset + response.data.posts.length,
          posts: (debouncedSearchString && postsResponse.offset === 0) ? [...response.data.posts] : [
            ...prevPostsResponse.posts,
            ...response.data.posts,
          ],
        }
      })
    }

  }, [getPosts, integrationIndex, integrations, postsResponse.isEnd, postsResponse.offset, debouncedSearchString])

  useEffect(() => {
    getNextPosts()
    getNextAds()
    // Changing offset will trigger a re-fetch
  }, [integrations, integrationIndex, debouncedSearchString])


  const onRenderNext = (() => {
    getNextPosts()
    getNextAds()
  })

  const handleSearchChange = React.useCallback((val: string) => {
    setSearchString(val)
    if (integrationIndex === integrations.length - 1) return
    if (integrations[integrationIndex]?.type === 'instagram') {
      setPostsResponse(getInitialPostsResponse())
    }
    if (integrations[integrationIndex]?.type === 'meta-ads') {
      setAdsResponse(getInitialAdsResponse())
    }
  }, [integrationIndex, integrations])


  const setIntegrationIndexFn = ((integration, index) => {
    if (index === integrationIndex) return
    if (integration.type === 'instagram') {
      setPostAccountId(integration.externalId)
      setSelectedPost(null)
      setPostsResponse(getInitialPostsResponse())
      setAdAccountId('')
    }

    if (integration.type ==='meta-ads') {
      setAdAccountId(integration.externalId)
      setSelectedAd(null)
      setAdsResponse(getInitialAdsResponse())
      setPostAccountId('')
    }

    if (campaignMedias[0]?.postType) {
      dispatchPageConfig({
        value: {
          campaignMedias: [],
          subheadline: '',
        },
      })
    }
    setIntegrationIndex(index)
  })

  const onSelect = ((val) => {
    if (integrations[integrationIndex]?.type === 'instagram') setSelectedPost(val)
    if (integrations[integrationIndex]?.type === 'meta-ads') setSelectedAd(val)
  })

  const onCampaignImageChange = ((val) => {
    dispatchPageConfig({
      value: {
        campaignMedias: val ? [{
          objId: uuidv4(),
          altText: '',
          originalUrl: val,
          largeUrl: val,
          mediumUrl: val,
          smallUrl: val,
        }] : [],
      },
    })
  })

  const onRemovePreview = (() => {
    dispatchPageConfig({
      value: {
        campaignMedias: [{
          objId: uuidv4(),
          altText: '',
          originalUrl: '',
          largeUrl: '',
          mediumUrl: '',
          smallUrl: '',
          video: {
            url: '',
            thumbnail: '',
          },
        }],
      },
    })
  })

  const onCampaignVideoChange = (async (val: {
    url: string
    thumbnail: string
  }) => {

    if (val) {
      dispatchPageConfig({
        value: {
          campaignMedias: val ? [{
            altText: '',
            objId: uuidv4(),
            videoThumbnail: val.thumbnail,
            isVideo: true,
            originalUrl: val.thumbnail,
            largeUrl: val.thumbnail,
            mediumUrl: val.thumbnail,
            smallUrl: val.thumbnail,
            video: val,
          }] : [],
        },
      })
    }
  })

  const onCampaignDescriptionChange = ((val) => {
    dispatchPageConfig({ value: { subheadline: val } })
  })

  const onCampaignNameChange = ((val) => {
    dispatchPageConfig({ value: { headline: val } })
  })

  const campaignImage = campaignMedias[0]?.video?.thumbnail || campaignMedias[0]?.mediumUrl  || ''
  const posts = postsResponse.posts
  const ads = adsResponse.ads
  const campaignName = headline
  const campaignDescription = subheadline

  if (!selectedPost && section?.collapsed) return null
  return (
    <div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Tabs
          style={{
            flex: 2,
            position: 'relative',
          }}
          scrollButtons='auto'
          ScrollButtonComponent={({ direction, className, onClick, disabled }) => {
            return (
              <div
                onClick={onClick}
                className={className}
              >
                <Button
                  size='small'
                  style={{
                    minWidth: '40px',
                    color: disabled ? 'lightgrey' : 'inherit',
                  }}
                >
                  {direction === 'right' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                </Button>
              </div>
            )
          }}
          variant='scrollable'
          TabIndicatorProps={{ children: <span /> }}
          classes={{
            indicator: 'indicator',
            // flexContainer: {{ display: unset}},
          }}
          value={integrationIndex}
          indicatorColor='primary'
        >
          {
            integrations.map((integration, i) => {
              return (
                <Button
                  key={i}
                  onClick={() => setIntegrationIndexFn(integration, i)}
                  color='inherit'
                  sx={{
                    minWidth: 'fit-content',
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                  }}>
                    <Box style={{
                      paddingRight: '6px',
                      paddingTop: '3px',
                    }}>
                      {typeof integration.icon !== 'undefined' &&
                        <integration.icon style={{
                          width: 20,
                          height: 20,
                        }} />
                      }
                    </Box>
                    <Box>{integration.label}</Box>
                  </Box>
                </Button>
              )
            })
          }
        </Tabs>
      </div>
      {
        integrationIndex === integrations.length - 1 ?
          <div
            style={{
              display: 'flex',
            }}
          >
            <div style={{
              margin: '6px 0px',
              marginRight: '12px',
            }}>
              <UploadComponent
                onRemovePreview={onRemovePreview}
                selectedImage={campaignImage}
                onImageUrlChange={onCampaignImageChange}
                onCampaignVideoChange={onCampaignVideoChange}
              />
            </div>

            <div style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}>
              <TextField
                style={{
                  flex: 1,
                }}
                label="Headline"
                margin="dense" type="string" variant="outlined"

                inputRef={(element) => {
                  if (nameRef?.current) nameRef.current = element
                }}
                error={!!nameErrorString}
                helperText={nameErrorString}

                value={campaignName}
                onChange={(e) => {
                  onCampaignNameChange(e.target.value)
                }}
              />
              {/* <TextField
                style={{
                  flex: 1,
                }}
                margin="dense" type="string" variant="outlined"
                label="Campaign Image/Video Upload URL (.mp4)"

                value={campaignImage}
                onChange={(e) => {
                  const fileName = e.target.value?.trim()
                  if (fileName?.endsWith('.mp4')) {
                    onCampaignVideoChange({ url: e.target.value?.trim(), thumbnail: '' })
                  } else {
                    onCampaignImageChange(fileName)
                  }
                }}
              /> */}
              <TextField
                multiline minRows={7} maxRows={10}
                margin='dense' type='string' variant='outlined'
                sx={{flex: 1}}

                label='Optional Subtitle'
                value={campaignDescription}
                onChange={(e) => onCampaignDescriptionChange(e.target.value)}
              />
            </div>
          </div>
          : integrations[integrationIndex]?.type === 'instagram' ? <Box>
            <PostSelector
              onRenderNext={onRenderNext}
              onSelect={onSelect}
              items={posts}
              selected={selectedPost}
              nameRef={nameRef}
              nameErrorString={nameErrorString}
              campaignName={campaignName}
              onCampaignNameChange={onCampaignNameChange}
              campaignDescription={campaignDescription}
              onCampaignDescriptionChange={onCampaignDescriptionChange}
              defaultOpen={defaultOpen}
              handleSearchChange={handleSearchChange}
              searchString={searchString}
            /></Box>
            : integrations[integrationIndex]?.type === 'meta-ads' ? <AdSelector
              onRenderNext={onRenderNext}
              onSelect={onSelect}
              items={ads}
              selected={selectedAd}
              nameRef={nameRef}
              nameErrorString={nameErrorString}
              campaignName={campaignName}
              onCampaignNameChange={onCampaignNameChange}
              campaignDescription={campaignDescription}
              onCampaignDescriptionChange={onCampaignDescriptionChange}
              defaultOpen={defaultOpen}
              handleSearchChange={handleSearchChange}
              searchString={searchString}
            />
              : ''
      }
    </div>
  )
}

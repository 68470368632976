import extractFirstTwoLettersOfUsername from './extractFirstTwoLettersOfUsername'

test('Extracts from full name', () => {
  expect(extractFirstTwoLettersOfUsername('James wood')).toBe('JW')
})

test('Extract from single name', () => {
  expect(extractFirstTwoLettersOfUsername('James')).toBe('J')
})

test('Return empty string', () => {
  expect(extractFirstTwoLettersOfUsername('')).toBe('')
})

// Check missing styling
import React, { useContext } from 'react'
import type { FC } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
// import {
//   BorderLinearProgress,
// } from 'src/styles/ClickCardStyles'
import { useTranslation } from 'react-i18next'

import { CardContext } from '../CardRowsWrapper'
import Dot from '../Dot'
import { formatDataValue } from 'src/utils/analyticsHelpers'
import { currencySymbolMap } from 'src/utils/currencySymbolMap'

export interface UrLineBarProps {
  title: string
  views: number
  order_value: number
  views_pct: number
  order_value_pct: number
  currencyCode: string
}

const UrLineBar: FC<UrLineBarProps> = ({
  title,
  views,
  order_value,
  views_pct,
  order_value_pct,
  currencyCode,
}) => {
  const { view } = useContext(CardContext)
  const { t } = useTranslation()
  const theme = useTheme()

  const displayPctValue = view === 'order_value' ? order_value_pct : views_pct
  const displayValue = view === 'order_value' ? formatDataValue(order_value, 2, currencySymbolMap[currencyCode]) : formatDataValue(views)

  return (
    <div
      data-testid="progress-bar"
    >
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          style={{width: 'calc(100% - 150px)'}}
        >
          <Box
            p={1}
            style={{
              position: 'relative',
              width: '100%',
              height: 36,
            }}
          >
            <Typography
              data-testid="var-title"
              noWrap
              variant="body2"
              style={{
                position: 'absolute',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                top: 0,
                left: 8,
                lineHeight: '36px',
              }}
            >
              {title ? title : t('Other')}
            </Typography>
          </Box>
        </Box>

        <Box p={1}>
          <Typography
            data-testid="var-total"
            variant="body2"
            style={{color: theme.palette.system.grey4}}
          >
            <span>{displayValue}</span><Dot />  <span>{(displayPctValue * 100).toFixed(0)}%</span>
          </Typography>
        </Box>
      </Box>
      {/* <BorderLinearProgress variant="determinate" value={displayPctValue*100} /> */}
    </div>
  )
}

export default UrLineBar

import React from 'react'
import type { FC, ReactNode } from 'react'
import useAuth from 'src/hooks/useAuth'
import SdxLoading from '../SdxLoading/SdxLoading'

interface AuthGuardProps {
  children?: ReactNode
}

// catch changes in auth status while app is in use
const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  // when user is unauthenticated, we just show the loading screen until we redirect to login page
  if (!isAuthenticated) {
    return <SdxLoading />
  }

  return <>{children}</>
}

export default AuthGuard

import React from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Grid, Typography,   Link } from '@mui/material'

import type { FC } from 'react'


import { Variant } from '@mui/material/styles/createTypography'
import clsx from 'clsx'
import Icon from 'src/components/Icon/Icon'

const PREFIX = 'ProductHeader'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  img: `${PREFIX}-img`,
  linkBox: `${PREFIX}-linkBox`,
  flexStyle: `${PREFIX}-flexStyle`,
  textColor: `${PREFIX}-textColor`,
  pointerCursor: `${PREFIX}-pointerCursor`,
  padRight: `${PREFIX}-padRight`,
}

const StyledGrid = styled(Grid)((
  {
    theme,
  },
) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.header}`]: {
    fontSize: 18,
    marginBottom: 14,
  },

  [`& .${classes.img}`]: {
    height: theme.spacing(30),
    width: theme.spacing(30),
    borderRadius: theme.spacing(2),
  },

  [`& .${classes.linkBox}`]: {
    height: theme.spacing(5),
    background: '#E4E7EC',
    border: '1px solid #D0D5DD',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
  },

  [`& .${classes.flexStyle}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  [`& .${classes.textColor}`]: {
    color: theme.palette.brand.urIndigo,
  },

  [`& .${classes.pointerCursor}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.padRight}`]: {
    paddingRight: theme.spacing(1),
  },
}))

interface HeaderProps {
  className?: string
  imgSrc: string
  mainHeaderFirstLine: string
  mainHeaderSecondLine?: string
  subHeaderFirstLine?: string
  subHeaderSecondLine?: string
  linkDescription?: string
  link: string
}

const LinkBox: FC<{children: string, variant: Variant}> = ({ variant, children }) => {

  const { t } = useTranslation()
  return <div className={(clsx(classes.linkBox, classes.flexStyle))}>
    <Typography variant={variant}>
      {children}
    </Typography>
    <Link color='primary' className={(clsx(classes.flexStyle, classes.textColor, classes.pointerCursor))} href={children} target="_blank" rel="noreferrer">
      <Typography variant='body1'className={classes.padRight}>
        {t('Visit')}
      </Typography>
      <Icon name='OpenInNewTab' size={12} root />
    </Link>
  </div>
}

const ProductHeader: FC<HeaderProps> = ({
  className,
  imgSrc,
  mainHeaderFirstLine,
  mainHeaderSecondLine,
  subHeaderFirstLine,
  subHeaderSecondLine,
  linkDescription,
  link,
}) => {

  return (
    <StyledGrid
      container
      spacing={2}
    >
      <Grid xs item>
        <Grid spacing={subHeaderFirstLine ? 2 : 4} container direction='column'>
          <Grid item>
            <Typography variant='h1' gutterBottom color='textPrimary'>
              {mainHeaderFirstLine}
            </Typography>
            {mainHeaderSecondLine ?
              <Typography variant='h1' color='textPrimary'>
                {mainHeaderSecondLine}
              </Typography> : null
            }
          </Grid>

          {subHeaderFirstLine ? <Grid item>
            <Typography variant='body1' gutterBottom>
              {subHeaderFirstLine}
            </Typography>
            {subHeaderSecondLine ?
              <Typography variant='body1'>
                {subHeaderSecondLine}
              </Typography> : null
            }
          </Grid> : null}

          <Grid item>
            {linkDescription ? <Typography variant='h6' gutterBottom color='textPrimary'>
              {linkDescription}
            </Typography> : null}
            <LinkBox variant='body1'>
              {link}
            </LinkBox>
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  )
}


export default ProductHeader

import React from 'react'
import { Modal, TextContainer} from '@shopify/polaris'

function AccountDisconnectionModal({ handleChange, active, handleSetConnection }) {

  return (
    <div style={{height: '500px'}}>
      <Modal
        open={active}
        onClose={handleChange}
        title={<div><img src="/static/images/simplicity_circular.png" alt='SDX Icon' width='30px' height='30px' style={{ verticalAlign: 'bottom'}}/> Disconnect SimplicityDX account?</div>}
        primaryAction={{
          content: 'Disconnect account',
          onAction: handleSetConnection,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Disconnecting your account will remove your products from SimplicityDX. You can reconnect your account anytime to continue selling on SimplicityDX.
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  )
}

export default AccountDisconnectionModal

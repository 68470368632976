import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import React, { FC } from 'react'
import { ComponentTypeResponse, getExtendedFields, getMinifiedFields } from 'src/views/Components/types'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  label?: string | undefined
}

export const AltsInCategoryInput: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  label,
}) => {
  if (componentConfig.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return <span />
  return (
    <div>
      <Box style={{
        display: 'flex',
      }}>
        <FormControl sx={{ marginTop: '8px', marginRight: '8px', width: '100%' }}>
          <InputLabel id="image-fit-label">Image fit</InputLabel>
          <Select
            size='small' margin='dense'
            labelId="image-fit-label"
            id="image-fit"
            value={componentConfig.contentConfiguration.value.uiSettings.imageFit || 'contain'} // 'cover' | 'contain' | 'fill'
            label="Image fit"
            onChange={(event: SelectChangeEvent) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return

              newComponent.contentConfiguration.value.uiSettings.imageFit = event.target.value as 'contain' | 'cover' | 'fill'
              setComponentConfig(newComponent)
            }}
          >
            <MenuItem value='contain'>Contain</MenuItem>
            <MenuItem value='cover'>Cover</MenuItem>
            <MenuItem value='fill'>Fill</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id='alt_products_type'
          style={{
            width: '100%',
            marginRight: '8px',
            marginBottom: '8px',
          }}
          select
          value={componentConfig.contentConfiguration.value.uiSettings.fields.length > 1 ? 'EXTENDED' : 'MINIFIED'}
          size='small' margin='dense' type='string' variant='outlined'
          label="Display Type"
          onChange={(e) => {
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
            newComponent.contentConfiguration.value.uiSettings.fields =
              e.target.value === 'MINIFIED' ? getMinifiedFields() : getExtendedFields()
            setComponentConfig(newComponent)
          }}
        >
          <MenuItem key={'MINIFIED'} value={'MINIFIED'}>Minified</MenuItem>
          <MenuItem key={'EXTENDED'} value={'EXTENDED'}>Extended</MenuItem>
        </TextField>

        <TextField
          id='alt_product_view'
          style={{
            width: '100%',
            marginBottom: '8px',
          }}
          select
          value={componentConfig.containerType}
          size='small' margin='dense' type='string' variant='outlined'
          label="Container Type"
          onChange={(e) => {
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
            newComponent.containerType = e.target.value as any
            setComponentConfig(newComponent)
          }}
        >
          <MenuItem key={'GRID'} value={'GRID'}>Grid</MenuItem>
          <MenuItem key={'CAROUSEL'} value={'CAROUSEL'}>Carousel</MenuItem>
        </TextField>
      </Box>
    </div>
  )
}

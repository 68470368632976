import { render, screen } from '@testing-library/react'

import wrapComponent from 'src/tests/wrapComponent'
import StoreIntegrations from './index'

const setup = () => render(wrapComponent(StoreIntegrations, {}))

describe('StoreIntegrations list page', () => {
  beforeEach(setup)

  it('renders table of StoreIntegrations', async () => {
    await screen.findByText('Integrations')
    await screen.findByText('Manage which external services you have connected to your SimplicityDX account.')
    screen.findByText('All integrations')

    // expect(screen.getAllByText('Fashion Icon - SDX').length).toEqual(1)
    // expect(screen.getAllByText('Add Integration').length).toEqual(1)
    // expect(screen.getAllByText('Update Integration').length).toEqual(1)

    // screen.getByText('Meta')
    // screen.getByText('Meta business accounts')
    // screen.getByText('Ad accounts')
    // screen.getByText('SimplicityDX Fashion Icon Ad Account')
    // screen.getByText('Instagram accounts')
    // screen.getByText('Fashion Icon')

    // screen.getByText('Klaviyo')
    // screen.getByText('Marketing automation platform')
    // screen.getByText('abc')
  })
})

import { storefrontDomain } from 'src/config'

export const getPlatformInfo = (shop: string) => {
  if (shop.indexOf('.myshopify.com') > -1) return {
    selectedStore: shop.split('.')[0],
    interpreterDomain: shop.replace('.myshopify.com', storefrontDomain),
    platformSymbol: 's',
  }

  return {
    selectedStore: shop,
    interpreterDomain: shop.substring(0, shop.indexOf('.')) + storefrontDomain,
    platformSymbol: 'f',
  }
}

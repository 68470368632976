import { ContainerConfig } from 'storefront-interpreter/src/api/components'

export enum LineItemFieldEnum {
  PRODUCT_NAME = 'PRODUCT_NAME',
  VARIANT_NAME = 'VARIANT_NAME',
  ITEMIZED_OPTIONS = 'ITEMIZED_OPTIONS',
  LOW_INVENTORY = 'LOW_INVENTORY'
}

export enum ImageFitEnum {
  CONTAIN = 'contain',
  COVER = 'cover',
  FILL = 'fill'
}

export enum DisplayTypesEnum {
  MINIFIED = 'MINIFIED',
  EXTENDED = 'EXTENDED'
}

export enum ContainerTypeEnum {
  CAROUSEL = 'CAROUSEL',
  GRID = 'GRID'
}

type DeepRequired<T> = {
  [K in keyof T]-?: DeepRequired<T[K]>;
};

export type DeepRequiredStorePrimitiveSettings = DeepRequired<StorePrimitiveSettings>

export type StorePrimitiveSettings = {
  product: {
    containerType?: ContainerConfig['containerType']
    displayType?: DisplayTypesEnum
    uiSettings: {
      imageFit: ImageFitEnum
      showImageSearch: boolean
    }
  }
  category: {
    containerType?: ContainerConfig['containerType']
    displayType?: DisplayTypesEnum
    uiSettings: {
      imageFit: ImageFitEnum
    }
  }
  storefront: {
    containerType?: ContainerConfig['containerType']
    displayType?: DisplayTypesEnum
    uiSettings: {
      imageFit: ImageFitEnum
    }
  }
}

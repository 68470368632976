import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import type { FC, ReactNode } from 'react'
import { useHistory } from 'react-router'
import { getRoutePath } from 'src/routes'

interface GuardProps {
  children?: ReactNode
}

const CampaignStoreGuard: FC<GuardProps> = ({ children }) => {
  const { campaign_stores } = useFlags()
  const history = useHistory()

  if (!campaign_stores) history.push(getRoutePath('home'))

  return <>{children}</>
}

export default CampaignStoreGuard

import {Card, Page,
  Layout,
  Banner,
  FooterHelp,
} from '@shopify/polaris'
import React from 'react'
import {Link, AccountConnection} from '@shopify/polaris'
import {useState, useCallback} from 'react'
import AccountDisconnectionModal from './AccountDisconnectionModal'
import useShopifyContext from 'src/hooks/useShopifyContext'


function ShopifySettings() {

  const { disconnectApp, appConnected, publishedProductsCount, shop } = useShopifyContext()

  const [showDisconnectModal, setShowDisconnectModal] = useState(false)

  const handleCloseDisconnectModal = useCallback(() => {
    setShowDisconnectModal(false)
  }, [])

  const handleOpenDisconnectModal = useCallback(() => {
    setShowDisconnectModal(true)
  }, [])

  const handleSetDisconnect = useCallback(() => {
    disconnectApp()
    setShowDisconnectModal(false)
  }, [])

  const accountName = ''
  const buttonText = appConnected ? 'Disconnect' : 'Connect'
  const details = appConnected ? <span>Account connected</span> : 'No account connected'
  const terms = appConnected ? null : (
    <p>
      By clicking <strong>Connect</strong>, you agree to accept SimplicityDX’s{' '}
      <Link url="https://www.simplicitydx.com/terms-conditions">terms and conditions</Link>. You will not be charged a commission on sales made through SimplicityDX.
    </p>
  )

  return (
    <Page>
      {/* <div style={{background: 'white', display: 'flex', alignItems: 'center', marginBottom: '10px'}}><img src="/static/images/simplicity_circular.png" alt='SDX Icon' width='30px' height='30px' style={{ verticalAlign: 'bottom', margin: '10px'}}/> <div></div>SimplicityDX</div><div/> */}
      <div style={{ marginBottom: '30px' }}>
        <h1 style={{ fontSize: '20px', marginBottom: '5px' }}>Welcome to  SimplicityDX</h1>
        <p>Let's get you setup so you can sell using SimplicityDX</p>
      </div>
      <Layout>
        <Layout.Section>
          <Banner title="Store connected" status="success" onDismiss={() => {}}>
            <p>Congratulations! Your store has been approved to sell using SimplicityDX</p>
          </Banner>
        </Layout.Section>


        <Layout.AnnotatedSection
          id="commissions"
          title="Commissions and fees"
          description="Here are the commission rates and fees you’ll pay for sales made through SimplicityDX."
        >
          <Card>
            <Card.Section>
              <p>No commission charged on sales made through SimplicityDX</p>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          id="publishing"
          title="Publishing"
          description="Products that are being synced to your catalog, or have errors preventing their sync, will be shown here."
        >
          <Card sectioned title="Product status" actions={[{content: 'Manage availability', url: `https://${shop}/admin/products`, external: true}]}>
            <Banner status="info">
              <p>
            Products publishing to SimplicityDX can take up to 10 minutes to update. Once
            your products are successfully published they will be visible on SimplicityDX.
              </p>
            </Banner>
            <Card.Section>
              <p>{`${publishedProductsCount} products are available at SimplicityDX`}</p>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          id="connectAccount"
          title="SimiplicityDX Account"
          description="Connect your account so you can manage and sync with SimplicityDX"
        >
          <AccountConnection
            accountName={accountName}
            connected={appConnected}
            title="SimplicityDX"
            action={{
              content: buttonText,
              onAction: handleOpenDisconnectModal,
            }}
            details={details}
            termsOfService={terms}
          />
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          id="termsAndConditions"
          title="Terms and conditions"
          description="Familiarise your self with SimplicityDX's terms and conditions"
        >
          <Card sectioned>
            <p>You can view our terms and consitions listed here <Link external url="https://www.simplicitydx.com/terms-conditions">Terms and Conditions</Link></p>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      <FooterHelp><p style={{textAlign: 'center', margin: '20px' }}>For more details and support on your store settings, <Link external url="https://support.simplicitydx.com/en/articles/6561816-how-to-connect-simplicitydx-to-your-shopify-account">click here</Link></p></FooterHelp>
      {showDisconnectModal && <AccountDisconnectionModal handleChange={handleCloseDisconnectModal} active={showDisconnectModal} handleSetConnection={handleSetDisconnect}/>}
    </Page>
  )
}

export default ShopifySettings


import React, { FC } from 'react'
import { IconButton } from '@mui/material'
import { List, arrayMove } from 'react-movable'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import ProductSelectorLarge, { ProductItemFull } from 'src/components/ProductSelectorLarge'

type Props = {
  productIds: string[]
  setProductIds: React.Dispatch<string[]>
  currentSelectedProductMap: Record<string, ProductItemFull>
  backgroundGradient: string
  openWhenNotSelected: boolean | undefined
  label: string
}

export const ProductSelectionInput: FC<Props> = ({
  productIds,
  setProductIds,
  currentSelectedProductMap,
  backgroundGradient,
  openWhenNotSelected,
  label,
}) => {
  const lastIndexId = 'lastIndexId'
  return (
    <List
      lockVertically
      values={productIds.concat([lastIndexId])}
      onChange={({ oldIndex, newIndex }) => {
        productIds = arrayMove(productIds, oldIndex, newIndex)
        setProductIds(productIds)
      }}
      renderList={(list) => <div {...list.props}>{list.children}</div>}
      renderItem={(listItem) => {
        const { isDragged } = listItem
        const prodId = listItem.value
        const j = listItem.index
        const isLast = listItem.value === lastIndexId
        return (
          <div
            {...listItem.props}
            key={isLast ? j : prodId}
          >
            <div style={{
              paddingLeft: '20px',
              background: backgroundGradient,
            }}>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    marginRight: '12px',
                  }}
                >
                  <ProductSelectorLarge
                    openWhenNotSelected={openWhenNotSelected}
                    onSelect={(item) => {
                      if (!item) return

                      if (isLast) {
                        productIds = [...productIds, item.productId]
                      }
                      else {
                        productIds = productIds.map(productId => productId === prodId ? item.productId : productId)
                      }
                      setProductIds(productIds)
                    }}
                    selected={currentSelectedProductMap[prodId]}
                    previouslySelected={productIds}
                    label={label}
                  />
                </div>

                <div
                  data-movable-handle
                  style={{
                    margin: '16px 6px 10px 0px',
                    cursor: isDragged ? 'grabbing' : 'grab',

                    opacity: isLast ? 0 : 1,
                    pointerEvents: isLast ? 'none' : 'auto',
                  }}
                >
                  <DragIcon />
                </div>

                <IconButton
                  style={{
                    opacity: isLast ? 0 : 1,
                  }}
                  disabled={isLast}

                  onClick={() => {
                    productIds = productIds.filter(productId => productId !== prodId)
                    setProductIds(productIds)
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

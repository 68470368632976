import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, List, ListItem, Typography } from '@mui/material'
import { TopCampaigns } from 'src/components/TopCampaigns'
import HeaderHome from 'src/components/headerHome'
import CampaignTickers from 'src/components/CampaignTickers'
import useGenericContext from 'src/hooks/useGenericContext'
import { CampaignInsights } from 'src/components/CampaignInsights'
import { SocialCharts } from 'src/components/SocialCharts'
import { AnalyticsCharts } from './AnalyticsCharts'
import { LatestOrders } from 'src/components/LatestOrders'

const GeneralAnalytics: FC = () => {
  const { homeCustomDate, isBrand, getSurveyExists } = useGenericContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [animation, setAnimation] = useState(localStorage.getItem('dashboardAnimation') === 'true' || !localStorage.getItem('dashboardAnimation'))
  const [notification, setNotification] = useState(false)
  useEffect(() => {
    localStorage.setItem('dashboardAnimation', animation ? 'true' : 'false')
  }, [animation, setAnimation])

  useEffect(() => {
    async function checkSurveyExists() {
      const response = await getSurveyExists()
      if (!response?.data?.surveyExists) setDialogOpen(true)
    }
    checkSurveyExists()
  }, [getSurveyExists])


  const handleDialogClose = useCallback(() => setDialogOpen(false), [])


  return (
    <>
      <Box>
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'relative',
          }}
        >
          <Grid container alignItems="flex-start" sx={{ width: '100%', padding: '30px 30px' }} spacing={2}>

            <Grid item sm={12} md={12} lg={12}>
              <HeaderHome notification={notification} setNotification={setNotification} setAnimation={setAnimation} animation={animation} text='Social Campaign Performance Dashboard' />
            </Grid>
            <Grid item container sm={12} md={12} lg={8} spacing={2}>
              {/* Live campaigns */}
              <Grid item sm={12}>
                <Box sx={{
                  backgroundColor: 'white',
                  borderRadius: '12px',
                }}>
                  <CampaignTickers animation={animation} />
                </Box>
              </Grid>

              <Grid item sm={12}>
                <AnalyticsCharts />
              </Grid>

              <Grid item sm={12}>
                <SocialCharts />
              </Grid>

              <Grid item sm={12}>
                <Box sx={{
                  backgroundColor: 'white',
                  p: '16px',
                  pt: 2,
                  borderRadius: '12px',
                }}>
                  <TopCampaigns />
                </Box>
              </Grid>
            </Grid>

            <Grid item sm={12} md={12} lg={4} spacing={2}>
              <Grid container spacing={2}
              >
                <Grid item sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '5px',
                    }}
                  >
                    <CampaignInsights />
                  </Box>
                </Grid>

                <Grid item sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '5px',
                    }}
                  >
                    <LatestOrders notification={notification} animation={animation} />
                  </Box>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

        </Box>
      </Box>
      <Dialog maxWidth="lg" open={dialogOpen}>
        <DialogTitle><Typography variant="h4">No data? Please check that Exit Attribution is correctly setup</Typography></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              In Shopify, follow the two steps below:
            </Typography>
            <List>
              <ListItem>
                <Grid direction='column' container spacing={1}>
                  <Grid item>
                    <Typography>1. Enable the SDX Javascript tag:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography pl={2}>In Shopify navigate to Sales Channels &gt; Online Store &gt; Theme &gt; Customize &gt; App Embeds icon &gt; Toggle SDX Tag to ON.</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid direction='column' container spacing={1}>
                  <Grid item>
                    <Typography>2. Tracking script configuration:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography pl={2}>
                      Go to SimplicityDX Settings and navigate to the exit Attribution section. Switch the toggle to 'ON'. Then press the Update button.
                      If you're on Shopify Plus, there is nothing further to do.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography pl={2}>
                      For regular Shopify users there's one additional step: Click on the Embed Code button and copy the code, then navigate to Shopify Settings &gt; Checkout &gt; Order Status Page section and paste in the code into the box called 'Additional Scripts'.
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
            <Typography style={{ marginTop: '10px' }}>
              Watch a quick video showing you how to do this here: <Link target="_blank" rel="noopener" href="https://youtu.be/uclacOOs0L8">https://youtu.be/uclacOOs0L8</Link>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right'}}>
          <Button onClick={handleDialogClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GeneralAnalytics

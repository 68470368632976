import { authenticatedFetch } from '@shopify/app-bridge-utils'
import { useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'

// Code samples
// Another way to handle this: https://github.com/Shopify/shopify-app-template-node/blob/60325990d6e6ceeadd701b3e9a60b003e5d1f098/src/App.jsx#L51
// https://github.com/Shopify/shopify-app-template-node/issues/846
// https://github.com/Shopify/shopify-frontend-template-react


/**
 * A hook that returns an auth-aware fetch function.
 * @desc The returned fetch function that matches the browser's fetch API
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 * It will provide the following functionality:
 *
 * 1. Add a `X-Shopify-Access-Token` header to the request.
 * 2. Check response for `X-Shopify-API-Request-Failure-Reauthorize` header.
 * 3. Redirect the user to the reauthorization URL if the header is present.
 *
 * @returns {Function} fetch function
 */
export function useAuthenticatedFetch() {
  const app = useAppBridge()
  const fetchFunction = authenticatedFetch(app)

  return async (uri, options) => {
    const response = await fetchFunction(uri, options)
    checkHeadersForReauthorization(response.headers, app)
    return response
  }
}

function checkHeadersForReauthorization(headers, app) {
  if (headers.get('X-Shopify-API-Request-Failure-Reauthorize') === '1') {
    const authUrlHeader =
      headers.get('X-Shopify-API-Request-Failure-Reauthorize-Url') ||
      `/api/auth`

    const redirect = Redirect.create(app)
    redirect.dispatch(
      Redirect.Action.REMOTE,
      authUrlHeader.startsWith('/')
        ? `https://${window.location.host}${authUrlHeader}`
        : authUrlHeader
    )
  }
}


// Another way to handle this: https://github.com/Shopify/shopify-app-template-node/blob/60325990d6e6ceeadd701b3e9a60b003e5d1f098/src/App.jsx#L51
// https://github.com/Shopify/shopify-app-template-node/issues/846
// https://github.com/Shopify/shopify-frontend-template-react

// We need to test a situation where auth is revoked
// if (
//   response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') === '1'
// ) {
//   const authUrlHeader = response.headers.get(
//     'X-Shopify-API-Request-Failure-Reauthorize-Url',
//   )

//   const redirect = Redirect.create(app)
//   redirect.dispatch(Redirect.Action.APP, authUrlHeader || `/auth`)
//   return null
// }

import React from 'react'
import { render, screen } from '@testing-library/react'
import '@testing-library/dom'
import Checkmark from './Checkmark'

const setup = (visible: boolean) => render(<Checkmark visible={visible} />)


describe('Checkmark', () => {
  test('Renders checkmark', async () => {
    setup(true)
    expect(screen.getByTestId('Checkmark')).toBeInTheDocument()
  })

  test('Does not render checkmark', async () => {
    setup(false)
    expect(screen.queryByTestId('Checkmark')).not.toBeInTheDocument()
  })
})

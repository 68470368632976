import React, {useEffect} from 'react'
import {MuiColorInput, MuiColorInputProps} from 'mui-color-input'

const SdxColorPicker: React.FC<
  MuiColorInputProps & {
    value: string
    onChange: (value: string) => void
    debounce?: number | null
  }
> = ({value, onChange, debounce = 500, ...props}) => {
  const [prevColor, setPrevColor] = React.useState(value)
  const [color, setColor] = React.useState<string>(value)

  // react to outside changes
  useEffect(() => {
    if (prevColor === value) return
    setPrevColor(value)
    setColor(value)
  }, [prevColor, value])

  // debounce onChange callback
  useEffect(() => {
    if (debounce === null) return onChange?.(color)

    const timer = setTimeout(() => onChange?.(color), debounce)
    return () => clearTimeout(timer)
  }, [color])

  return (
    <MuiColorInput
      {...props}

      value={color}
      onChange={setColor}

      isAlphaHidden
      format='hex'
    />
  )
}

export default SdxColorPicker

import React from 'react'
import { Button, Grid, Tooltip, Typography } from '@mui/material'

import type { FC } from 'react'
import DatePicker from 'src/components/datePicker'
import { ReactComponent as SoundsOnIcon } from 'src/assets/icons/SoundsOn.svg'
import { ReactComponent as SoundsOffIcon } from 'src/assets/icons/SoundsOff.svg'
import { useTranslation } from 'react-i18next'
import useGenericContext from 'src/hooks/useGenericContext'
import { useTheme } from '@mui/material'
interface HeaderHomeProps {
  className?: string
  text: string
  setAnimation: any
  setNotification: any
  animation: boolean
  notification: boolean
}

const HeaderHome: FC<HeaderHomeProps> = ({ className, text, setAnimation, setNotification, animation, notification, ...rest }) => {
  const { t } = useTranslation()
  const { setHomeCustomDate, setIsBrand, merchantDetails, isBrand } = useGenericContext()
  const theme = useTheme()
  return (
    <Grid
      container
      sx={{
        flexWrap: 'nowrap',
        height: 60,
        backgroundColor: '#E9EDF0',
        borderRadius: '6px',
        alignItems: 'center',
        marginBottom: '8px',
      }}
    >
      <Grid sx={{
        marginRight: 'auto',
      }} item>
        <Typography component='span' style={{ fontWeight: 700, fontSize: '16px', marginLeft: '12px' }}
          color="#5B575E">
          {text}
        </Typography>
      </Grid>

      {/* <Grid sx={{
        alignContent: 'center',
        display: 'flex',
        marginRight: '8px',
        cursor: 'pointer',
      }} item>
        <Button
          variant="contained"
          color="secondary"
          disabled={merchantDetails?.plan === 'BASE'}
          onClick={() => {
            if(merchantDetails?.plan === 'ENTERPRISE') {
              setIsBrand(!isBrand)
            }
          }}
          sx={{
            backgroundColor: '#F0F5FE',
            color: '#344563',
            fontWeight: '500',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#E9EDF0',
            },
            height: '40px',
          }}>
          {!isBrand ? 'SDX Data' : 'Brand Data'}
        </Button>
      </Grid> */}

      <Grid sx={{
        alignContent: 'center',
        display: 'flex',
        marginRight: '8px',
        cursor: 'pointer',
      }} item>
        <Tooltip title={t(`Notifications ${!notification ? 'off' : 'on'}`)}>
          {notification ?  <SoundsOnIcon onClick={() => setNotification(!notification)} style={{
            display: notification ? 'block' : 'none',
          }} /> : <SoundsOffIcon onClick={() => setNotification(!notification)} style={{
            display: !notification ? 'block' : 'none',
          }} />}
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title={t(`Dashboard animations`)}>
          <Button
            variant="contained"
            color="secondary"
            aria-haspopup="true"
            onClick={() => setAnimation(!animation)}
            sx={{
              backgroundColor: '#F0F5FE',
              color: '#344563',
              fontWeight: '500',
              cursor: 'pointer',
              width: '142px',
              marginRight: '8px',
              '&:hover': {
                backgroundColor: '#E9EDF0',
              },
              height: '40px',
            }}>
            {animation && 'Stop Animations'}
            {!animation && 'Play Animations'}
          </Button>
        </Tooltip>
      </Grid >

      {/*<Grid item>*/}
      {/*  <Filter*/}
      {/*    sxStyle={{*/}
      {/*      backgroundColor: '#F0F5FE',*/}
      {/*      color: '#344563',*/}
      {/*      marginRight: '8px',*/}
      {/*      width: '122px',*/}
      {/*      '&:hover': {*/}
      {/*        backgroundColor: '#E9EDF0',*/}
      {/*      },*/}
      {/*    }} />*/}
      {/*</Grid>*/}

      <Grid item>
        <DatePicker
          getFullDays
          isDayMinimum={true}
          onCustomDateChange={setHomeCustomDate}
          sxStyle={{
            backgroundColor: theme.palette.brand.frenchBlue,
            color: 'white',
            marginRight: '12px',
            '& svg path': {
              fill: 'white',
            },
          }}
        />
      </Grid>
    </Grid >
  )
}


export default HeaderHome

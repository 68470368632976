import type { FC }from 'react'
import { Page } from '@shopify/polaris'
import { Paper, Typography } from '@mui/material'

const NoPlansPage: FC = () => {

  return (
    <Page>
      <Paper sx={{
        padding: '16px',
      }}>
        <Typography variant='h4'>No Active Plan</Typography>
      </Paper>
    </Page>
  )
}

export default NoPlansPage

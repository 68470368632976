import { Pagination, PrependShop, StorefrontStatus } from 'src/contexts/types'
import { axiosInstance } from 'src/utils/axios'
import { componentServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { PageConfig } from 'storefront-interpreter/src/config'
import { attachImageUrlToPageConfigs } from './attachImageUrlToPageConfigs'

const STOREFRONT_PAGE_LIMIT = 25

export type GetPageConfigs = (status?: StorefrontStatus, pagination?: Pagination) => Promise<PageConfig[]>
export type GetPageConfigsApi = PrependShop<GetPageConfigs>

export const getPageConfigsApi: GetPageConfigsApi = async (activeShop: string, status?: StorefrontStatus, pagination?: Pagination) => {
  // first, fetch our new format configs from the component service.
  let pageConfigs: PageConfig[] = []
  try {
    let url = '/v1/pages'
    if (status) {
      url += `?status=${status}`
      url += `&applyMlOrdering=false`
    }

    if (pagination?.limit) url += `&limit=${pagination.limit}`
    if (pagination?.offset) url += `&offset=${pagination.offset}`

    const response = await axiosInstance.get<PageConfig[]>(url, {
      baseURL: componentServiceBaseUrl,
      headers:{
        'Shop': activeShop,
      },
    })

    if (response?.data) {
      pageConfigs = response.data
    }

    pageConfigs = await attachImageUrlToPageConfigs(activeShop, pageConfigs)

    return pageConfigs

  } catch(error) {
    console.error('get storefronts api', error)
    logging(error, { tags: { section: 'get storefronts api' } })
    return pageConfigs
  }
}

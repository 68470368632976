import React, { useCallback, useEffect, useState, FC } from 'react'
import {Box, Button, IconButton, Switch, Tabs, TextField, useTheme } from '@mui/material'



import useGenericContext from 'src/hooks/useGenericContext'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import { downloadURI } from './Header'

export type MetaDownloadModalProps = {
  startTimestamp: number
  endTimestamp: number
  isOpen: boolean
  toggleModal: () => void
}


const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  minHeight: '400px', // This makes the modal longer vertically
  maxHeight: '90vh',  // Ensure it doesn't overflow the viewport
  overflowY: 'auto',  // Adds scrolling if content exceeds the max height
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  opacity: 1,
  zIndex: 1300,  // Ensure the modal is above the backdrop
}

export const MetaDownloadModal: FC<MetaDownloadModalProps> = ({
  startTimestamp,
  endTimestamp,
  isOpen = false,
  toggleModal,
}) => {
  const theme = useTheme()
  const { getMetaDownloadUrlV2 } = useGenericContext()
  const [accountId, setAccountId] = useState('')
  const [adIdsStr, setAdIdsStr] = useState('')
  const [shouldSplitByDay, setShouldSplitByDay] = useState(false)
  const [displayError, setDisplayError] = useState(false)

  const getAndDownloadAdInsights = (async () => {
    try {
      const metaDownloadUrl = await getMetaDownloadUrlV2(startTimestamp, endTimestamp, adIdsStr, accountId, shouldSplitByDay)
      metaDownloadUrl?.data.downloadUrl && downloadURI(metaDownloadUrl?.data.downloadUrl, 'meta.json')
      setAccountId('')
      setAdIdsStr('')
    } catch(err) {
      console.error('Error in real time meta download:', err)
      window.alert('Error in meta download')
    }
  })

  const accountIdValid = () => {
    // account id should exist.
    return !!accountId
  }

  const adIdsStrValid = () => {
    // adIdsStr should be a comma delimited list of numbers
    const regex = /^(\d+)(,\d+)*$/
    return regex.test(adIdsStr)
  }


  const formIsValid = () => {
    return accountIdValid() && adIdsStrValid()
  }

  if (!isOpen) return null
  return (
    <Box
      sx={style}
    >
      <Box sx={{fontWeight: 500, mb: 2}}>
        Real Time Meta Download Report
      </Box>
      Ad Account ID To Pull Data For
      <Box>
        <TextField
          sx={{mb: 3, mr: 1}}
          onChange={(ev) => setAccountId(ev.target.value)}
          size='small' type='string' variant='outlined' fullWidth
          value={accountId}
          helperText={displayError && !accountIdValid() ? 'This field is required.' : ''}
          error={displayError && !accountIdValid()}
        />
      </Box>
      Comma Separated List Of Ad IDs
      <Box>
        <TextField
          sx={{mb: 3, mr: 1}}
          onChange={(ev) => setAdIdsStr(ev.target.value)
          }
          size='small' type='string' variant='outlined' fullWidth
          value={adIdsStr}
          helperText={displayError && !adIdsStrValid() ? 'This field is required and must be a comma separated list of numbers.' : ''}
          error={displayError && !adIdsStrValid()}
        />
      </Box>
      <Box>
        <Switch
          style={{ color: shouldSplitByDay ? theme.palette.brand.urIndigo : '' }}
          checked={shouldSplitByDay}
          onChange={() => {
            setShouldSplitByDay(!shouldSplitByDay)
          }}
          color='primary'
        />
        Split By Day?
      </Box>

      <Button
        style={{
          top: '4px',
        }}
        variant='outlined'
        onClick={() => {
          const handleOnClick = async () => {
            if (!formIsValid()) {
              setDisplayError(true)
              return
            }
            setDisplayError(false)
            await getAndDownloadAdInsights()
            toggleModal()
          }
          handleOnClick()
        }}
      >
          Submit Insights Request
      </Button>

    </Box>
  )
}

import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl, productServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { CampaignRevenueGraph, SocialCampaigns, SocialChannel, PrependShopCurrency, PrependShop } from '../types'
import { LatestOrdersData } from 'src/views/GeneralAnalytics/types'
import { Variants } from 'src/components/ProductSelectorLarge'
import { AxiosResponse } from 'src/types/axios'

export type GetCampaignTopChannels = (isBrand:boolean, startTimestamp: number, endTimestamp: number) => Promise<AxiosResponse<SocialChannel> | undefined>
export type GetCampaignTopChannelsApi = PrependShopCurrency<GetCampaignTopChannels>

export const getCampaignTopChannelsApi: GetCampaignTopChannelsApi = async (activeShop: string, currencyCode: string, isBrand:boolean, startTimestamp: number, endTimestamp: number) => {
  try {

    let url = '/v1/analytics'
    url += `?shop=${activeShop}`
    url += `&start_timestamp=${startTimestamp}`
    url += `&end_timestamp=${endTimestamp}`
    url += `&currency_code=${currencyCode}&exclude_base=true&metrics=revenue,previous_revenue,conversion_rate&group_by=utm_source`
    if(isBrand) url += `&isBrand=1`

    const response = await axiosInstance.get<SocialChannel>(url, { baseURL: analyticsServiceBaseUrl })

    return response
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

export type GetTopCampaigns = (isBrand:boolean, startTimestamp: number, endTimestamp: number) => Promise<AxiosResponse<SocialCampaigns> | undefined>
export type GetTopCampaignsApi = PrependShopCurrency<GetTopCampaigns>

export const getTopCampaignsApi: GetTopCampaignsApi = async (activeShop: string, currencyCode: string, isBrand:boolean, startTimestamp: number, endTimestamp: number) => {
  try {
    let url = '/v1/analytics'
    url += `?shop=${activeShop}`
    url += `&start_timestamp=${startTimestamp}`
    url += `&end_timestamp=${endTimestamp}`
    url += `&currency_code=${currencyCode}&exclude_base=true&metrics=revenue,previous_revenue,conversion_rate,best_source&group_by=utm_campaign`
    if(isBrand) url += `&isBrand=1`
    const response = await axiosInstance.get<SocialCampaigns>(url,
      { baseURL:  analyticsServiceBaseUrl})

    return response
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

export type GetLatestOrders = (limit: number) => Promise<LatestOrdersData[]| undefined>
export type GetLatestOrdersApi = PrependShopCurrency<GetLatestOrders>

export const getLatestOrdersApi: GetLatestOrdersApi = async (activeShop: string, currencyCode: string, limit: number) => {
  try {
    let url = '/v1/checkouts'
    url += `?shop=${activeShop}`
    url += `&limit=${limit}`
    url += `&currency_code=${currencyCode}`

    const response = await axiosInstance.get<LatestOrdersData[]>(url, { baseURL: analyticsServiceBaseUrl })

    const variantIds = response?.data?.map((order) => order?.variants?.map((product) => product.external_id)).flat().join(',')
    const variantDetails = await axiosInstance.get<Variants[]>(`/v1/variants?variantExternalIds=${variantIds}`, { baseURL: productServiceBaseUrl, headers: {
      shop: activeShop,
    } })
    const variantDetailsIdMap: Record<string, Variants> = variantDetails?.data?.reduce((acc, curr) => {
      acc[curr.externalId] = curr
      return acc
    }, {})

    const modifiedResponse = response.data.map((order) => {
      order?.variants?.map( (product) => {
        product.title = variantDetailsIdMap[product.external_id]?.title || ''
        product.image = variantDetailsIdMap[product.external_id]?.featuredImage.url || variantDetailsIdMap[product.external_id]?.featuredImage.originalUrl || ''
        return product
      })
      return order
    })
    return modifiedResponse
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

export type GetTotalRevenue = (isBrand:boolean, startTimestamp: number, endTimestamp: number) => Promise<AxiosResponse<{ revenue: number, previous_revenue: number }> | undefined>
export type GetTotalRevenueApi = PrependShopCurrency<GetTotalRevenue>

export const getTotalRevenueApi: GetTotalRevenueApi = async (activeShop: string,  currencyCode: string, isBrand:boolean, startTimestamp: number, endTimestamp: number) => {
  try {
    let url = '/v1/analytics'
    url += `?shop=${activeShop}`
    url += `&start_timestamp=${startTimestamp}`
    url += `&end_timestamp=${endTimestamp}`
    url += `&currency_code=${currencyCode}&exclude_base=true&metrics=revenue,previous_revenue`
    if(isBrand) url += `&isBrand=1`
    const response = await axiosInstance.get<{
    revenue: number
    previous_revenue: number
}>(url, { baseURL: analyticsServiceBaseUrl })

    return response
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}


export type GetCampaignRevenueGraph = (isBrand:boolean, startTimestamp: number, endTimestamp: number) => Promise<AxiosResponse<CampaignRevenueGraph[]> | undefined>
export type GetCampaignRevenueGraphApi = PrependShopCurrency<GetCampaignRevenueGraph>

export const getCampaignRevenueGraphApi: GetCampaignRevenueGraphApi  = async (activeShop: string, currencyCode: string, isBrand:boolean, startTimestamp: number, endTimestamp: number) => {
  try {

    let url = '/v1/graph'
    url += `?shop=${activeShop}`
    url += `&start_timestamp=${startTimestamp}`
    url += `&end_timestamp=${endTimestamp}`
    url += `&currency_code=${currencyCode}&graph=experience_base_revenue_pct`

    const response = await axiosInstance.get<CampaignRevenueGraph[]>(url, { baseURL: analyticsServiceBaseUrl })

    return response
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

export type GetCurrencyCode = () => Promise<AxiosResponse<{ defaultCurrencyCode: string }> | undefined>
export type GetCurrencyCodeApi = PrependShop<GetCurrencyCode>

export const getCurrencyCodeApi: GetCurrencyCodeApi = async (activeShop: string) => {
  try {
    const url = '/v1/shop'

    const response = await axiosInstance.get<{defaultCurrencyCode: string}>(url, { baseURL: productServiceBaseUrl, headers: {
      'shop': activeShop,
    } })

    return response
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get currency code api' } })
  }
}

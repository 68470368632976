import React from 'react'
import { styled } from '@mui/material/styles'
import { TextField, MenuItem, Box, FormControl } from '@mui/material'
import { FC } from 'react'
import Icon from 'src/components/Icon/Icon'


const PREFIX = 'SocialChannelPicker'

const classes = {
  linkSelect: `${PREFIX}-linkSelect`,
  linkSelectIcon: `${PREFIX}-linkSelectIcon`,
}

const StyledFormControl = styled(FormControl)((
  {
    theme,
  },
) => ({
  [`& .${classes.linkSelect}`]: {
    '& #linkSocialChannel': {
      display: 'flex',
      alignItems: 'center',

    },
  },

  [`& .${classes.linkSelectIcon}`]: {
    position: 'relative',
    height: 16,
    overflow: 'hidden',
    marginRight: '8px',
    '&  svg': {
      width: '16px',
      height: '16px',
    },
  },
}))

// NOTE: even thought these are values and not labels they are visible to both client and users in the shared link so keep them pretty
// NOTE: once added NEVER change these because analytics expect them
export type SocialSource =
  'Instagram' |
  'Facebook' |
  'Twitter' |
  'Tiktok' |
  'Youtube' |
  'Snapchat' |
  'Content Creator' |
  'Email' |
  'Direct' |
  'Meta'

export type UtmMedium = 'Social' | 'Email' | 'Direct'

export const socialSources = [
  { value: 'Meta', icon: 'Meta', label: 'Meta'},
  { value: 'Instagram', icon: 'Instagram', label: 'Instagram' },
  { value: 'Facebook', icon: 'Facebook', label: 'Facebook' },
  { value: 'Twitter', icon: 'Twitter', label: 'Twitter' },
  { value: 'Tiktok', icon: 'TikTok', label: 'TikTok' },
  { value: 'Youtube', icon: 'Youtube', label: 'Youtube' },
  { value: 'Snapchat', icon: 'Snapchat', label: 'Snapchat' },
  { value: 'Content Creator', icon: 'contentCreator', label: 'Content Creator' },

  { value: 'Email', icon: 'Email', label: 'Email' },
  { value: 'Direct', icon: 'PreviewLinkIcon', label: 'Direct' },
]

export const getUtmMediumFromSource = (socialSource: SocialSource): UtmMedium => {
  if (
    socialSource === 'Instagram' ||
    socialSource === 'Facebook' ||
    socialSource === 'Twitter' ||
    socialSource === 'Tiktok' ||
    socialSource === 'Youtube' ||
    socialSource === 'Snapchat' ||
    socialSource === 'Content Creator' ||
    socialSource === 'Meta'
  ) {
    return 'Social'
  }
  else if (socialSource === 'Email') {
    return 'Email'
  }
  else if (socialSource === 'Direct') {
    return 'Direct'
  }

  throw new Error(`Unknown social source: ${socialSource}`)
}

type Props = {
  value: string
  onChange: (value: string) => void
  inputRef: any
  error?: boolean
  helperText?: string
  onFocus?: any
}

export const SocialChannelPicker: FC<Props> = ({
  value, onChange, inputRef, error, helperText, onFocus,
}) => {
  return (
    <StyledFormControl fullWidth>
      <TextField
        onFocus={() => {onFocus?.()}}
        size='small'
        inputRef={inputRef}
        error={error}
        helperText={helperText}
        select
        id='linkSocialChannel'
        value={value}
        required
        label='Social Channel'
        variant='outlined'
        className={classes.linkSelect}
        onChange={(e) => onChange(e.target.value)}
      >
        {socialSources.map((x, i) => {
          return (
            <MenuItem
              key={x.label + + x.value}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              value={x.value}
            >
              <Box sx={{
                position: 'relative',
                height: 16,
                overflow: 'hidden',
                marginRight: '8px',
                '&  svg': {
                  width: '16px',
                  height: '16px',
                },
              }}>
                <Icon root={true} name={x.icon} />
              </Box>
              {x.label}
            </MenuItem>
          )
        })}
      </TextField>
    </StyledFormControl>
  )
}

import React, { FC } from 'react'
import { StorefrontModal } from './StorefrontModal'
import { Box, Button, TextField, useTheme } from '@mui/material'
import { PageConfig } from 'src/types/interpretor'
import { ContainerConfig } from 'storefront-interpreter/src/api/components'


export type AffectedPagesModalProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isLoadingAffectedPages: boolean
  affectedPages: PageConfig[]
  editedComponentConfig: ContainerConfig
  setReadyForUpdate: (val: boolean) => void
  pageConfig: PageConfig | undefined
}

export const AffectedPagesModal: FC<AffectedPagesModalProps> = ({
  isOpen,
  setIsOpen,
  isLoadingAffectedPages,
  affectedPages,
  editedComponentConfig,
  setReadyForUpdate,
  pageConfig,
}) => {
  const theme = useTheme()
  const closeModal = () => setIsOpen(false)

  return (
    <StorefrontModal
      open={isOpen}
      onClose={closeModal}
    >
      <Box>
        <Box
          sx={{
            fontWeight: 'bold',
            marginBottom: '12px',
          }}
        >
          Edit Saved Component
        </Box>

        <Box>
          Updating a saved component will change it for all pages that use that component.
        </Box>
        <Box>
          If you want to create a new component instead, click cancel and then uncheck "Save to Library".
        </Box>
        <br/>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >

          <TextField
            multiline
            maxRows={4}
            size='small' margin='dense' type='string' variant='outlined'
            label='Component Label'
            value={editedComponentConfig.title || ''}
          />

          <TextField
            size='small' margin='dense' type='string' variant='outlined'
            label='Internal Name'
            value={editedComponentConfig.internalName}
          />

          <TextField
            size='small' margin='dense' type='string' variant='outlined'
            label='Description'
            value={editedComponentConfig.internalDescription}
          />

        </Box>
        <br/>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              fontWeight: 'bold',
            }}
          >
            Other Campaigns Affected By This Edit {isLoadingAffectedPages ? '(Loading)': `(${affectedPages.length})`}
          </Box>

          <Box
            sx={{
              maxHeight: '200px',
              overflow: 'scroll',
            }}
          >
            {affectedPages.map((page) => {
              return (
                <Box
                  key={page.id}
                >
                  Name: {page.internalName}
                  {/* PageId: {page.id} */}
                </Box>
              )
            })}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box />

            <Box>
              <Button
                onClick={closeModal}
                sx={{
                  color: `${theme.palette.brand.urIndigo} !important`,
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => setReadyForUpdate(true)}
                variant='contained'
                sx={{
                  backgroundColor: `${theme.palette.brand.urIndigo} !important`,
                  borderColor: `${theme.palette.brand.urIndigo} !important`,
                }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </StorefrontModal>
  )
}

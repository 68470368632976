import axios from 'axios'
import { socialServiceBaseUrl } from 'src/config'
import { MultiSourceImage } from 'storefront-interpreter/src/config'

export const getMultiSourceImageWithDefaults = (s?: Partial<MultiSourceImage>, fallback = ''): MultiSourceImage => {
  fallback = fallback || (s as any)?.['url'] || ''
  return {
    ...s,
    smallUrl: s?.smallUrl || fallback,
    mediumUrl: s?.mediumUrl || s?.smallUrl || fallback,
    largeUrl: s?.largeUrl || s?.mediumUrl || s?.smallUrl || fallback,
    originalUrl: s?.originalUrl || s?.largeUrl || s?.mediumUrl || s?.smallUrl || fallback,
    altText: s?.altText || fallback,
  }
}

type ChannelType = 'instagram' | 'ad'
type PostsRequest = {postId: string, accountId: string, channelType: ChannelType}
type PostsResponse = {postId: string, accountId: string, thumbnail: string}

export const extendCampaignImages = async (shop: string, configs: any[]) => {
  const posts: PostsRequest[] = []

  for (const config of configs) {
    const firstImage = config.campaignImages[0]
    if (!firstImage) continue

    const channelType = firstImage.postType
    if (channelType !== 'instagram' && channelType !== 'ad') continue

    const postAccountId = firstImage.postAccountId
    const postId = firstImage.postId
    if (!postAccountId || !postId) continue

    if (!posts.find(p => p.accountId === postAccountId && p.postId === postId))
      posts.push({channelType, accountId: postAccountId, postId: postId})
  }

  const postsPromise: Promise<PostsResponse[]> = new Promise(async (resolve, reject) => {
    try {
      const instagramPosts = posts.filter(p => p.channelType === 'instagram')
      if (!instagramPosts.length) return resolve([])

      const url = `${socialServiceBaseUrl}/v1/instagram/posts`
      const cfg = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          shop,
        },
        body: JSON.stringify(instagramPosts.map (x => ({
          postId: x.postId,
          accountId: x.accountId,
        }))),
      }

      const response = await axios.post<PostsResponse[]>(url, cfg.body, {
        headers: cfg.headers,
      })

      if (!response) {
        console.warn ('Instagram fetch failed')
        // const errBody = await response.json()
        // throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        resolve([])
      }

      return resolve(response.data)
    } catch (error) {
      console.warn(`fetch request failed: ${error}`)
      resolve([])
    }
  })

  const adsPromise: Promise<PostsResponse[]> = new Promise(async (resolve, reject) => {
    try {
      const instagramPosts = posts.filter(p => p.channelType === 'ad')
      if (!instagramPosts.length) return resolve([])

      const url = `${socialServiceBaseUrl}/v2/meta-ads/ads`
      const cfg = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          shop,
        },
        body: JSON.stringify(instagramPosts.map (x => ({
          adId: x.postId,
          accountId: x.accountId,
        }))),
      }


      const response = await axios.post<PostsResponse[]>(url, cfg.body, {
        headers: cfg.headers,
      })

      if (!response) {
        console.warn ('Ads fetch failed')
        // const errBody = await response.json()
        // throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        resolve([])
      }

      return resolve(response.data)
    }
    catch (error) {
      console.warn(`fetch request failed: ${error}`)
      resolve([])
    }
  })

  const promises = [postsPromise, adsPromise]

  const responses = (await Promise.all(promises))
    .filter(x => x)
    .flat()
    .map (x => {
      // NOTE: this is because we hack the postId with postType === 'id'
      x.postId = x.postId || (x as any).adId
      return x
    })

  configs.forEach(config => {
    const firstImage = config.campaignImages[0]
    if (!firstImage) return

    const postAccountId = firstImage.postAccountId
    const postId = firstImage.postId
    if (!postAccountId || !postId) return

    if (firstImage.mediumUrl) return
    const post = responses.find(x => /*x.accountId === postAccountId &&*/ x.postId === postId)

    config.campaignImages[0] = {
      ...firstImage,
      ...getMultiSourceImageWithDefaults(firstImage, post?.thumbnail),
    }
  })

  return configs
}

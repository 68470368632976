import React from 'react'
import { styled } from '@mui/material/styles'

const PREFIX = 'Dot'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('span')(() => ({
  [`&.${classes.root}`]: {
    fontWeight: 'bold',
  },
}))

const Dot = (): JSX.Element => {

  return <Root className={classes.root}>&#8226;</Root>
}

export default Dot

import React from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography,   Link } from '@mui/material'

import type { FC } from 'react'

const PREFIX = 'TableImage'

const classes = {
  root: `${PREFIX}-root`,
  img: `${PREFIX}-img`,
}

const StyledGrid = styled(Grid)((
  {
    theme,
  },
) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.img}`]: {
    height: '48px',
    width: '48px',
    borderRadius: '4px',
  },
}))

export interface TableImageProps {
  imgSrc: string
  text: string
  link: string
}

const TableImage: FC<TableImageProps> = ({ imgSrc, text, link }) => {


  return (
    <StyledGrid
      container
      direction='row'
      spacing={1}
    >
      <Grid xs={3} item>
        <img src={imgSrc} className={classes.img} alt="Table"/>
      </Grid>

      <Grid item xs={8}>
        <Typography component='span'>
          <Link>
            {text}
          </Link>
        </Typography>
      </Grid>
    </StyledGrid>
  )
}


export default TableImage

import type { FC } from 'react'
import Box from '@mui/material/Box'
import { SdxValue } from 'src/contexts/api/getCampaignSummaryMetrics'
import { formatDataValue } from 'src/utils/analyticsHelpers'
import { formatPercent } from 'src/utils/helpers'

const MLPerformanceMetric: FC<{
  title: string
  hardBounce: SdxValue | undefined
  averagePageViews: SdxValue | undefined
}> = ({
  title,
  hardBounce,
  averagePageViews,
}) => {
  return (
    <Box sx={{
      fontSize: '1.2em',
      fontWeight: 'bold',
      flex: 1,
      border: '1px solid black',
      borderRadius: '5px',
      p: 1,
    }}>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <Box sx={{flex: 1, textAlign: 'center', mb: 1}}>
          {title}
        </Box>
      </Box>
      <Box sx={{display: 'flex', flex: 1, gap: 1}}>
        <Box sx={{display: 'flex', flex: 1, p: 1, backgroundColor: 'white', borderRadius: '5px', border: '1px solid lightgrey'}}>
          <Box sx={{flex: 1}}>
            Hard Bounce
          </Box>
          <Box sx={{flex: 1, fontSize: '1.8em', p: 1, pr: 0, textAlign: 'right'}}>
            {formatPercent(hardBounce?.value)}
          </Box>
        </Box>
        <Box sx={{display: 'flex', flex: 1, p: 1, backgroundColor: 'white', borderRadius: '5px', border: '1px solid lightgrey'}}>
          <Box sx={{flex: 1}}>
            Average Page Views
          </Box>
          <Box sx={{flex: 1, ml: -2, fontSize: '1.8em', p: 1, pr: 0, textAlign: 'right'}}>
            {formatDataValue(averagePageViews?.value, 2)}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MLPerformanceMetric

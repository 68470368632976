import { colors } from '@mui/material'

export interface TypeBrand {
  liftPositive: string
  liftNegative: string
  darkTurquoise: string
  blueGrey: string
  bedazzledBlue: string
  frenchBlue: string
  mediumBlue: string
  darkCornflowerBlue: string
  royalBlueDark: string
  trueBlack: string
  urPink: string
  urLightPink: string
  urYellow: string
  urIndigo: string
  urGreen: string
  urOrange: string
}

//shopify green '#007B61
export const brand: TypeBrand = {
  liftPositive: '#7DC6BC',
  liftNegative: '#E57373',
  darkTurquoise: '#15D4E0',
  blueGrey: '#6E93C6',
  bedazzledBlue: '#34598A',
  frenchBlue: '#4086CD', // '#61A4E4', // '#0088d9', //'#0472B3',
  mediumBlue: '#1101CB',
  darkCornflowerBlue: '#174486',
  royalBlueDark: '#0E2266',
  trueBlack: '#000000',
  urPink: '#F60067',
  urLightPink: '#FFE2EE',
  urYellow: '#EDB21C',
  urIndigo: '#3542B1', // '#5347D6',
  urGreen: '#066E75', // colors.green[600], // '#43a047',
  urOrange: '#A94E31',
}

export interface TypeSystem {
  white: string
  // grey1: string
  grey2: string
  grey3: string
  grey4: string
  grey5: string
  grey6: string
  grey8: string
  grey9: string
  system_red: string
  // system_yellow: string
  // system_green: string
}

export const system: TypeSystem = {
  white: '#FFFFFF',
  // grey1: '#F4F6F8',
  grey2: '#E9ECEF',
  grey3: '#D0D8E0',
  grey4: '#86A0AC',
  grey5: '#546E7A',
  grey6: '#263238',
  grey8: '#37474F',
  grey9: '#263238',
  system_red: '#EB2121',
  // system_yellow: '#EDB21C',
  // system_green: '#1BEDBA',
}

export interface TypeText {
  primary: string
  secondary: string
  disabled: string
  hint: string
  white: string
  link: string
  positive: string
  negative: string
}

export const text: TypeText = {
  primary: system.grey5,
  secondary: colors.blueGrey[800],
  disabled: system.grey2,
  hint: system.grey3,
  white: 'white',
  link: brand.urIndigo,
  positive: colors.green[600],
  negative: brand.urIndigo,
}

import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2 } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShopCurrency } from 'src/contexts/types'
import { CurrencyCode } from 'src/utils/currencySymbolMap'
import { SocialSource } from 'src/components/SocialChannelPicker'

export type TopSocialChannel = {
  socialChannel: SocialSource
  currentPeriodRevenue: number
  previousPeriodRevenue: number
  currency: CurrencyCode

  graph: {
    timestamp: number
    data: number
  }[]
}

export type GetTopSocialChannels2 = (
  isBrand:boolean, startTimestamp: number, endTimestamp: number
) => Promise<AxiosResponse<TopSocialChannel[]> | undefined>

export const getTopSocialChannels2Api: PrependShopCurrency<GetTopSocialChannels2> = async (
  activeShop, currencyCode,
  isBrand, startTimestamp, endTimestamp,
) => {
  try {
    const baseUrl = '/v1/analytics/top-social-channels'
    const params = new URLSearchParams()
    params.append('currency', currencyCode)
    params.append('startTimestamp', startTimestamp.toString())
    params.append('endTimestamp', endTimestamp.toString())
    if(isBrand) params.append('isBrand', '1')
    const url = `${baseUrl}?${params.toString()}`

    const cfg = {
      baseURL: analyticsServiceBaseUrl2,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<TopSocialChannel[]>(url, cfg)

    return response
  }

  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

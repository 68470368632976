import React, { FC } from 'react'
import { IconButton } from '@mui/material'
import { List, arrayMove } from 'react-movable'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import AsyncSelectCategories from './AsyncSelectCategories'
import { Category } from 'src/contexts/types'

type Props = {
  categoryIds: string[]
  setCategoryIds: React.Dispatch<string[]>
  currentSelectedCategoryMap: Record<string, Category>
  backgroundGradient: string
  openWhenNotSelected: boolean | undefined
  label: string
}

export const CategoryMultiSelect: FC<Props> = ({
  categoryIds,
  setCategoryIds,
  currentSelectedCategoryMap,
  backgroundGradient,
  openWhenNotSelected,
  label,
}) => {
  const lastIndexId = 'lastIndexId'
  return (
    <List
      lockVertically
      values={categoryIds.concat([lastIndexId])}
      onChange={({ oldIndex, newIndex }) => {
        categoryIds = arrayMove(categoryIds, oldIndex, newIndex)
        setCategoryIds(categoryIds)
      }}
      renderList={(list) => <div {...list.props}>{list.children}</div>}
      renderItem={(listItem) => {
        const { isDragged } = listItem
        const catId = listItem.value
        const j = listItem.index
        const isLast = listItem.value === lastIndexId
        return (
          <div
            {...listItem.props}
            key={isLast ? j : catId}
          >
            <div style={{
              paddingLeft: '20px',
              background: backgroundGradient,
            }}>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    marginRight: '12px',
                  }}
                >
                  <AsyncSelectCategories
                    selected={currentSelectedCategoryMap[catId]}
                    onChange={(e,item) => {
                      if (item && typeof item === 'object') {
                        if (!item) return

                        if (isLast) {
                          categoryIds = [...categoryIds, item.id]
                        }
                        else {
                          categoryIds = categoryIds.map(categoryId => categoryId === catId ? item.id : categoryId)
                        }
                        setCategoryIds(categoryIds)
                      }
                    }}
                    previouslySelected={categoryIds}
                    label={label}
                  />
                </div>

                <div
                  data-movable-handle
                  style={{
                    margin: '16px 6px 10px 0px',
                    cursor: isDragged ? 'grabbing' : 'grab',

                    opacity: isLast ? 0 : 1,
                    pointerEvents: isLast ? 'none' : 'auto',
                  }}
                >
                  <DragIcon />
                </div>

                <IconButton
                  style={{
                    opacity: isLast ? 0 : 1,
                  }}
                  disabled={isLast}

                  onClick={() => {
                    categoryIds = categoryIds.filter(categoryId => categoryId !== catId)
                    setCategoryIds(categoryIds)
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

import { PrependShop } from '../types'
import { AxiosResponse } from 'axios'
import { logging } from 'src/utils/logging'
import { axiosInstance } from 'src/utils/axios'
import { componentServiceBaseUrl } from 'src/config'

export interface DownloadUrl {
  downloadUrl: string
}

export type GetPageComponentMlMetrics = (storefrontId: string) => Promise<AxiosResponse<DownloadUrl> | undefined>
export type GetPageComponentMlMetricsApi = PrependShop<GetPageComponentMlMetrics>

export const getPageComponentMlMetricsApi: GetPageComponentMlMetricsApi = async (activeShop: string, storefrontId: string) => {
  try {
    let url = '/v1/pages/ml-metrics'
    url += `?id=${storefrontId}`

    const response = await axiosInstance.get<DownloadUrl>(url, { baseURL: componentServiceBaseUrl, headers: {
      shop: activeShop,
    } })
    return response

  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get page component ml metrics api' } })
  }
}

import React, { FC } from 'react'
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { ImageFitEnum } from './types'
import ImagePlaceholder from 'src/assets/icons/ImagePlaceholder.png'


interface ImageFitSelectorProps {
  label: string
  imageFitValue: ImageFitEnum
  onImageFitChange: (imageFitValue: ImageFitEnum) => void
  imageFitDisplay: ImageFitEnum
}

export const ImageFitSelector: FC<ImageFitSelectorProps> = ({ label, imageFitValue, onImageFitChange, imageFitDisplay }) => {
  return (
    <Box sx={{ display: 'flex'}}>
      <FormControl sx={{ maxWidth: '600px', width: { xs: '100%', md: '75%' } }}>
        <InputLabel id={`${label.toLowerCase()}-image-fit-label`}>{label}</InputLabel>
        <Select
          size="small"
          margin="dense"
          labelId={`${label.toLowerCase()}-image-fit-label`}
          id={`${label.toLowerCase()}-image-fit`}
          value={imageFitValue}
          label={label}
          onChange={(event) => onImageFitChange(event.target.value as ImageFitEnum)}
        >
          {Object.values(ImageFitEnum).map((fit) => (
            <MenuItem key={fit} value={fit}>
              {fit.charAt(0).toUpperCase() + fit.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ position: 'relative', width: '36px', minWidth: '36px', paddingBottom: '36px', marginLeft: '6px' }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '4px',
            overflow: 'hidden',
            border: '1px solid #eee',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <img
            src={ImagePlaceholder}
            style={{ width: '100%', height: '100%', objectFit: imageFitDisplay }}
            alt={`${label} Preview`}
          />
        </Box>
      </Box>
    </Box>
  )
}

import { TimeRange } from 'src/types/redux'

export const loadSegments = () => {
  try {
    const serializedSegments = sessionStorage.getItem('segments')
    if (serializedSegments === null) {
      return []
    }
    return JSON.parse(serializedSegments)
  } catch (error) {
    console.warn(error)
    return []
  }
}

export const saveSegments = (segments): boolean => {
  try {
    const serializedSegments = JSON.stringify(segments)
    sessionStorage.setItem('segments', serializedSegments)
    return true
  } catch (error) {
    console.warn(error)
    return false
  }
}

export const loadTimeRange = (): Required<TimeRange> | undefined=> {
  try {
    const serializedDate = sessionStorage.getItem('date')
    if (serializedDate === null) {
      return undefined
    }
    return JSON.parse(serializedDate)
  } catch (error) {
    console.warn(error)
    return undefined
  }
}

export const saveTimeRange = (range: Required<TimeRange>): boolean => {
  try {
    const serializedDate = JSON.stringify(range)
    sessionStorage.setItem('date', serializedDate)
    return true
  } catch (error) {
    console.warn(error)
    return false
  }
}

export const loadStoreSelection = (): string => {
  try {
    const serializedStore = localStorage.getItem('store')
    if (serializedStore === null) {
      return ''
    }
    return JSON.parse(serializedStore)
  } catch (error) {
    console.warn(error)
    return ''
  }
}

export const saveStoreSelection = (project: string): boolean => {
  try {
    const serializedStore = JSON.stringify(project)
    localStorage.setItem('store', serializedStore)
    return true
  } catch (error) {
    console.warn(error)
    return false
  }
}

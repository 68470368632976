import { axiosInstance } from 'src/utils/axios'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { configAPIBaseURL, ecommerceServiceBaseUrl } from 'src/config'
import axios from 'axios'

interface ShopifyAuthParamType {
  queryParams: string
}

interface RedirectParamType {
  queryParams: string
}


export const getRedirectEndpoint = async (payload: RedirectParamType ): Promise<AxiosResponse<{ registrationUrl: string} > | undefined> => {
  try {
    // Note that we are using axios instead of axiosInstance and that is because we don't want any authorization headers to be sent.
    const response =  await axios.get(`/v1/shopify/app/registration?${payload.queryParams}`, { baseURL: ecommerceServiceBaseUrl })
    if (response.data.registrationUrl) {
      return response
    }
  }
  catch (error) {
    logging(error, { tags: { section: 'Shopify registration'}})
    return undefined
  }
}

export const authorizationEndpoint = async (payload: ShopifyAuthParamType): Promise<AxiosResponse<Record<string, never>>| undefined> => {
  try {
    // Note that we are using axios instead of axiosInstance and that is because we don't want any authorization headers to be sent.
    const response =  await axios.get(`/v1/shopify/app/installation?${payload.queryParams}`, { baseURL: ecommerceServiceBaseUrl })
    if (response) {
      return response
    }
  } catch (error) {
    logging(error, { tags: { section: 'Shopify registration'}})
    return undefined
  }
}

export const registerStore = async (payload: {shop: string}): Promise<AxiosResponse<unknown> | undefined> => {
  try {
    return await axiosInstance.post(`/registerstore`, null, {baseURL: configAPIBaseURL})
  } catch (error) {
    logging(error, { tags: { section: 'Register Store'}})
  }
}

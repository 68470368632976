import { createSlice } from '@reduxjs/toolkit'

import { getDate } from 'src/utils/time'
import type { DateState } from 'src/types/redux'

const initialState: DateState = {
  timeRange: getDate(),
}

const slice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    setDate(state: DateState, action) {
      state.timeRange = action.payload
    },
  },
})

export const reducer = slice.reducer

export default slice

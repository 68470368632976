import { Button, Box, useTheme, IconButton, TextField, FormControlLabel, Checkbox, Tooltip } from '@mui/material'
import React, { FC } from 'react'
import { ReactComponent as EditIcon } from 'src/assets/icons/Edit.svg'
import { ReactComponent as LibraryOutlineIcon } from 'src/assets/icons/LibraryOutline.svg'
import { ReactComponent as LibraryFilledIcon } from 'src/assets/icons/LibraryFilled.svg'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import { List, arrayMove } from 'react-movable'
import { ContainerConfig } from 'storefront-interpreter/src/api/components'
import { componentTypeMap } from '../../Components'
import { isComponentEditable } from 'src/utils/classifyComponents'
import { getComponentIcon } from 'src/components/componentIconMap'
import { useTranslation } from 'react-i18next'

type Item = ContainerConfig

type Props = {
  items: Item[]
  allItems: Item[]
  editedId?: string
  buttonColor?: string
  onRemoveClick?: (item: Item) => void
  getTitle: (item: Item) => string
  onTitleChange?: (item: Item, title: string) => void
  onAddClick?: (item: Item) => void
  onEditClick?: (item: Item) => void
  onLibraryClick?: (item: Item) => void
  onSort?: (items: Item[]) => void
  onAiOrderingClick?: (event: React.ChangeEvent<HTMLInputElement>, item: Item) => void
  isAiOrderingChecked?: (itemId: string) => boolean
}

export const ComponentSelectorList: FC<Props> = ({
  allItems, items, editedId, buttonColor, onRemoveClick, onTitleChange, getTitle, onAddClick, onEditClick, onLibraryClick, onSort, onAiOrderingClick,
  isAiOrderingChecked,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <List
      lockVertically

      values={items}
      onChange={({ oldIndex, newIndex }) => {
        onSort?.(arrayMove(items, oldIndex, newIndex))
      }}
      renderList={(list) => <Box {...list.props}>{list.children}</Box>}
      renderItem={(listItem) => {
        const item = listItem.value
        const { isDragged } = listItem

        const isDisabled = !!allItems.find(a => a.componentId === item.componentId)
        const color = isDisabled ? '' : buttonColor
        const myIcon = getComponentIcon(item.componentType, theme)
        return (
          <Box
            {...listItem.props}
            key={item.componentId}
            sx={{
              my: theme.spacing(1),
              display: 'flex',
              alignItems: 'space-between',
              borderBottom: onTitleChange ? '' : '1px solid #E0E0E0',
              height: '50px',
            }}
          >
            <Box sx={{
              flex: 1,
              display: 'flex',

              overflow: 'hidden',
              textOverflow: 'ellipsis',

              position: 'relative',
            }}>
              <Box sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  width: 40,
                },
              }}>
                {myIcon}
              </Box>

              {onTitleChange &&
                <TextField
                  fullWidth
                  label={ `${componentTypeMap[item.componentType]} Label`}
                  size='small' margin='dense' variant='outlined'
                  value={getTitle(item)}
                  onChange={(e) => {onTitleChange?.(item, e.target.value)}}
                />
              }

              {!onTitleChange &&
                <Box sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',

                  // border: '1px solid blue',
                }}>
                  <Box sx={{
                    flex: 1,
                    px: theme.spacing(2),

                    // border: '1px solid red',
                    position: 'absolute',
                    textOverflow: 'ellipsis', // TODO: why is this not working??

                  }}>
                    <Box sx={{
                      flex: 1,

                      overflow: 'hidden',

                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                    }}>
                      {item.internalName}
                    </Box>
                    <Box sx={{
                      flex: 1,
                      whiteSpace: 'nowrap',
                    }}>
                      {item.internalDescription}
                    </Box>

                  </Box>

                </Box>
              }

            </Box>

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
              {onEditClick && isComponentEditable(item) &&
                <Box
                  sx={{
                    mx: theme.spacing(1),
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => onEditClick(item)}
                >
                  <IconButton
                    sx={{
                      border: `1px solid ${item.componentId === editedId ? theme.palette.brand.urIndigo : 'transparent'}`,
                      color: theme.palette.brand.urIndigo,
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              }

              {onLibraryClick && isComponentEditable(item) &&
                <Box
                  sx={{
                  }}
                  onClick={() => onLibraryClick(item)}
                >
                  <IconButton style={{color: theme.palette.brand.urIndigo}}>
                    {item.visibleInLibrary ? <LibraryFilledIcon /> : <LibraryOutlineIcon />}
                  </IconButton>
                </Box>
              }

              {onAddClick &&
                <Button
                  disabled={isDisabled}
                  sx={{
                    ml: theme.spacing(1),
                    color: color ? `${color} !important` : '',
                    borderColor: color ? `${color} !important` : '',
                  }}
                  variant='outlined'
                  onClick={() => onAddClick(item)}
                >
                  + Add
                </Button>
              }

              {
                onSort ?
                  <Box
                    data-movable-handle
                    sx={{
                      mx: theme.spacing(1),
                      cursor: isDragged ? 'grabbing' : 'grab',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <DragIcon />
                  </Box>
                  : <span data-movable-handle />
              }
              {onAiOrderingClick &&
              <Tooltip title="Use AI ordering on component" placement="top">
                <FormControlLabel
                  control={<Checkbox
                    checked={isAiOrderingChecked?.(item.componentId)}
                    onChange={(event) => onAiOrderingClick(event, item)}
                    inputProps={{ 'aria-label': 'uncontrolled' }}
                  />} label={t('user settings.AI on')} />
              </Tooltip>
              }

              {onRemoveClick &&
                <Box
                  sx={{
                    mx: theme.spacing(1),
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => onRemoveClick(item)}
                >
                  <IconButton>
                    <CloseIcon />
                  </IconButton>
                </Box>
              }
            </Box>
          </Box>
        )
      }}
    />
  )
}

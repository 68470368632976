import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'

export type TabbedHeaderProps = {
  title?: string
  rightSide?: React.ReactNode
  children?: React.ReactNode
}

const TabbedHeader: FC<TabbedHeaderProps> = ({title, rightSide, children}) => {
  const height = 50

  return (
    <Typography component='span'>
      <Box
        style={{
          padding: '12px',
          width: 'calc(100% - 55px)',
          fontWeight: 500,
          height,
          position: 'fixed',
          background: 'white',
          borderBottom: '1px solid lightgrey',
          paddingRight: '50px',
        }}>

        <Box style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Box style={{
            display: 'flex',
            placeItems: 'center',
          }}>
            {!title ? '' : <Box style={{
              color: 'grey',
              textTransform: 'uppercase',
              marginRight: '20px',
            }}>{title}</Box>}


            {children}
          </Box>
          <Box>
            {rightSide}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          height,
        }}
      ></Box>
    </Typography>
  )
}
export default TabbedHeader

import React, { Fragment } from 'react'
import type { FC } from 'react'
import Icon from 'src/components/Icon/Icon'

interface CheckmarkProps {
  visible: boolean
}

const Checkmark: FC<CheckmarkProps> = ({ visible }) => {
  if (visible) {
    return (
      <Icon name={'Checkmark'} root/>
    )
  } else {
    return <Fragment></Fragment>
  }
}

export default Checkmark

import React, { useState, useEffect, createContext, Fragment } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import type { FC } from 'react'
import {Grid, Typography, Button, Paper, Divider, FormControl, InputLabel, Select, MenuItem} from '@mui/material'
import Page from 'src/components/Page'
import { AlertType } from 'src/types/components'
import { metaAdsAccountConfigId } from 'src/config'

import useGenericContext from 'src/hooks/useGenericContext'
import Icon from 'src/components/Icon/Icon'
import { Integration, MetaPixel } from './types'
import ThirdPartyIntegrationRows from './ThirdPartyIntegrations/ThirdPartyIntegrationRows'
import { logging } from 'src/utils/logging'
import Spacer from 'src/components/Spacer'
import { useFlags } from 'launchdarkly-react-client-sdk'

const PREFIX = 'StoreIntegrations'

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.paper}`]: {
    // marginRight: theme.spacing(10),
    // marginLeft: theme.spacing(20),
    padding: theme.spacing(2),
    textAlign: 'center',
    flexGrow: 1,
  },
}))

declare global {
  interface Window {
    FB: any
  }
}

interface MetaState {
  status: 'connected' | 'not_authorized' | 'unknown'
  authResponse: {
    accessToken: string
    data_access_expiration_time: number
    expiresIn: string
    signedRequest: any
    userID: string
  }
}

export const UsersSettingsContext = createContext<{
  refetchUsers: () => Promise<void>
  showAlert: (alertData: { message: string, alertType: AlertType }) => void}>({ refetchUsers: async () => undefined, showAlert: () => null })


export interface TrackingScriptsDataPayload {
  name: string
  content: string
}

export interface TrackingScriptsPayload {
  configName: string
  client: string
  tenant: string
  data: TrackingScriptsDataPayload[]
}

const StoreIntegrations: FC<{
  noMargins?: boolean
}> = ({noMargins}) => {
  const theme = useTheme()
  const {shop, getIntegrations, saveCodeGetIntegrations, getMetaPixels, setMetaPixel} = useGenericContext()

  const [integrations, setIntegrations] = useState<Integration[]>([])
  const [integrationsLoading, setIntegrationsLoading] = useState(false)
  const [selectedPixel, setSelectedPixel] = useState<string>('none')
  const [metaPixels, setMetaPixels] = useState<MetaPixel[]>([])
  const {klaviyo} = useFlags()

  useEffect(() => {
    async function fetchIntegrations() {
      setIntegrationsLoading(true)
      try {
        const existingIntegrations = (await getIntegrations())?.data || []
        setIntegrations(existingIntegrations)
      }
      catch (error) {
        logging(error, {tags: {section: 'fetch meta integrations'}})
        console.log(error)
      }
      finally {
        setIntegrationsLoading(false)
      }
    }

    fetchIntegrations()
  }, [getIntegrations, setIntegrations, setIntegrationsLoading, shop])

  useEffect(() => {
    const fetchMetaPixels = (async () => {
      try {
        const response = await getMetaPixels()
        if(response) {
          setMetaPixels(response.data)
          response.data.map((item) => {
            if(item.isSelected) setSelectedPixel(item.id)
          })
        }
      } catch(error) {

      }
    })

    fetchMetaPixels()
  }, [integrations])

  const handleMetaPixelSelection = ((event) => {
    const targetValue = event.target.value
    if(targetValue && targetValue !== 'none') {
      metaPixels.map((item) => {
        if(targetValue === item.id) {
          setSelectedPixel(item.id)
          setMetaPixel({pixelId: item.id})
        }
      })
    } else {
      setSelectedPixel('none')
      setMetaPixel({pixelId: ''})
    }
  })

  const IntegrationsBusinessRow = ({imageName, name, subText}) => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Grid
            container
            spacing={3}
            alignItems="center"
            direction="row"
          >
            <Grid item
              style={{
                width: '120px',
              }}>
              <Icon
                name={imageName}
                root={true}
              />
            </Grid>

            <Grid item>
              <Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    {name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                  >
                    {subText}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'right',
        }}>
          <Grid item>
            <Button onClick={() => initiateConnectMetaAds()}>
              <Grid
                direction='row'
                container
                spacing={1}
              >
                <Grid item>
                  <Icon
                    name='Plus'
                    root={true}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                  >
                    {`${integrations.length > 0 ? 'Update' : 'Add'} Integration`}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item>
            {integrationsLoading ? 'Loading...' : (integrations.length > 0 &&
              integrations
                .map((integration) => {
                  return <div key={integration.name}>
                    <div style={{
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                    }}>{integration.name}</div>
                    {
                      integration.adAccounts.length > 0 && (
                        <>
                          <div style={{
                            fontWeight: 'bold',
                          }}>Ad accounts</div>
                          <div>
                            {integration.adAccounts.map((child) => {
                              return <div key={child.externalId}>{child.name}</div>
                            })}
                          </div>
                        </>
                      )
                    }
                    <Spacer space={2} />
                    {
                      integration.igAccounts.length > 0 && (
                        <>
                          <div style={{
                            fontWeight: 'bold',
                          }}>Instagram accounts</div>
                          <div>
                            {integration.igAccounts.map((child) => {
                              return <div key={child.externalId}>{child.name}</div>
                            })}
                          </div>
                        </>
                      )
                    }
                  </div>
                },
                ))}
          </Grid>
          <Grid sx={{
            marginTop: '15px',
          }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Pixel</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPixel}
                label="Pixel"
                onChange={handleMetaPixelSelection}
              >
                <MenuItem value={'none'}>None</MenuItem>
                {metaPixels.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const initiateConnectMetaAds = async () => window.FB.login(async response => {
    const code = response?.authResponse?.code

    if (code) {
      // Since the form has been submitted, we now know that the user has connected their account and that we have a valid token
      // First call the API to set the Meta integration using this access token, and then set the state integrations as well.
      setIntegrationsLoading(true)
      const {err, data} = await saveCodeGetIntegrations({code: code})
      if (err) {
        setIntegrationsLoading(false)
        setIntegrations([])
        return window.alert(`${err}`)
      }
      setIntegrationsLoading(false)
      setIntegrations(data || [])
    }
    else {
      logging('Unable to log in user from facebook/or we are unable to tell', {tags: {section: 'store integration'}})
      console.error('Unable to log in user from facebook/or we are unable to tell')
      window.alert('An error has occured, please reach out to support')
      setIntegrationsLoading(false)
      setIntegrations([])
    }
  }, {
    config_id: metaAdsAccountConfigId,
    response_type: 'code',
    override_default_response_type: true,
    // auth_type: 'reauthorize'
  })

  return (
    <Root>
      <Page className={classes.root} title={'Store integrations'}>
        <Paper className={classes.paper} sx={{
          marginRight: noMargins ? '' : theme.spacing(20),
          marginLeft: noMargins ? '' : theme.spacing(20),
        }}>
          <Typography
            style={{
              textAlign: 'left',
              fontWeight: 500,
              fontSize: 'large',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            Integrations
          </Typography>
          <Divider
            light
            style={{
              marginTop: '5px',
              marginBottom: '15px',
            }}
          />
          <Typography
            variant="body2"
            style={{
              textAlign: 'left',
              marginBottom: '20px',
            }}
          >
            Manage which external services you have connected to your SimplicityDX account.
          </Typography>
          <Typography
            variant="body2"
            style={{
              textAlign: 'left',
              fontWeight: 'bold',
            }}
          >
            All integrations
          </Typography>

          <Divider
            light
            style={{
              marginTop: '20px',
              marginBottom: '20px',
            }}
          />

          <IntegrationsBusinessRow imageName='Integrations' name='Meta' subText='Meta business accounts' />

          <Divider
            light
            style={{
              marginTop: '20px',
              marginBottom: '20px',
            }}
          />

          {klaviyo && <ThirdPartyIntegrationRows/>}
        </Paper>
      </Page>
    </Root>
  )
}

export default StoreIntegrations

import { Box, Stack } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import useGenericContext from 'src/hooks/useGenericContext'
import SocialChart from './SocialChart'
import { TopSocialChannel } from 'src/contexts/api/getTopSocialChannels'
import { analyticsHomeRefreshMillis } from 'src/config'
import { logging } from 'src/utils/logging'
import { SocialSource } from 'src/components/SocialChannelPicker'
import { CurrencyCode } from 'src/utils/currencySymbolMap'

const getPlaceholderData = (): TopSocialChannel => {
  return ({
    socialChannel: '' as SocialSource,
    currentPeriodRevenue: 0,
    previousPeriodRevenue: 0,
    currency: '' as CurrencyCode,
    graph: [],
  })
}

const placeholderDatas = Array.from({length: 5}, () => getPlaceholderData())

export const SocialCharts: FC = () => {
  const { getTopSocialChannels2, homeCustomDate, isBrand } = useGenericContext()
  const [socialChannels, setSocialChannels] = useState<(TopSocialChannel)[]>(placeholderDatas)
  const [isLoading, setIsLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(false)
  const [lastFetchedCustomDate, setLastFetchedCustomDate] = useState<number[]>([])

  useEffect(() => {
    const callback = async() => {
      try {
        if (!homeCustomDate[0] && !homeCustomDate[1]) return

        if (
          lastFetchedCustomDate[0] !== homeCustomDate[0] ||
        lastFetchedCustomDate[1] !== homeCustomDate[1]
        ) {

          setSocialChannels(placeholderDatas)
          setIsLoading(true)
          setLoadingError(false)
          setLastFetchedCustomDate(homeCustomDate)
        }

        const result = await getTopSocialChannels2(isBrand, homeCustomDate[0], homeCustomDate[1])
        setLoadingError(false)
        setIsLoading(false)
        if (result && result.data) {setSocialChannels(result.data)} else throw new Error('No data')
      } catch (error) {
        logging(error, {tags: {section: 'getTopSocialChannels2'}})
        setLoadingError(true)
      }
    }
    callback()
    const interval = setInterval(callback, analyticsHomeRefreshMillis)
    return () => clearInterval(interval)

  }, [homeCustomDate, getTopSocialChannels2, lastFetchedCustomDate, isBrand])

  return (
    <Stack sx={{overflowX: 'auto', overflowY: 'clip'}}direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row'}} spacing={1}>
      {socialChannels.map((item, i) => {
        return (
          <Box
            key={i+item.socialChannel+item.currentPeriodRevenue}
            sx={{
              backgroundColor: 'white',
              borderRadius: '12px',
              minWidth: '33.33%',
            }}
          >
            <SocialChart
              error={loadingError}
              isLoading={isLoading}
              title={item.socialChannel}
              revenue={item.currentPeriodRevenue}
              lift={item.previousPeriodRevenue > 0 ? ((item.currentPeriodRevenue - item.previousPeriodRevenue) / item.previousPeriodRevenue) * 100 : undefined}
              currency={item.currency}
              data={item.graph.map(x => ({timestamp: x.timestamp, value: x.data}))}
            />
          </Box>
        )
      })}
    </Stack>
  )
}

import { Box } from '@mui/material'
import { FC } from 'react'
import { SocialSource } from 'src/components/SocialChannelPicker'

import { ReactComponent as InstagramIcon } from 'src/assets/icons/Instagram.svg'
import { ReactComponent as FacebookIcon } from 'src/assets/icons/Facebook.svg'
import { ReactComponent as MetaIcon } from 'src/assets/icons/Meta.svg'
import { ReactComponent as TiktokIcon } from 'src/assets/icons/TikTok.svg'
import { ReactComponent as YoutubeIcon } from 'src/assets/icons/Youtube.svg'
import { ReactComponent as TwitterIcon } from 'src/assets/icons/Twitter.svg'
import { ReactComponent as SnapchatIcon } from 'src/assets/icons/Snapchat.svg'
import { ReactComponent as ContentCreatorIcon } from 'src/assets/icons/contentCreator.svg'
import { ReactComponent as EmailIcon } from 'src/assets/icons/Email.svg'
import { ReactComponent as PreviewLinkIcon } from 'src/assets/icons/PreviewLinkIcon.svg'
import { ReactComponent as OtherIcon } from 'src/assets/icons/CustomIcon.svg'


export const ListIcons: FC<{
  icons: SocialSource[]
  size: number
}> = ({
  icons, size,
}) => {
  const style = {
    width: size,
    height: size,
    margin: 2,
  }
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
    }}
    >
      {icons.map((icon, i) => {
        const iconLowerCase = icon.toLowerCase()
        if (iconLowerCase === 'instagram') return <InstagramIcon key={i} style={style} />
        if (iconLowerCase === 'tiktok') return <TiktokIcon key={i} style={style} />
        if (iconLowerCase === 'facebook') return <FacebookIcon key={i} style={style} />
        if (iconLowerCase === 'youtube') return <YoutubeIcon key={i} style={style} />
        if (iconLowerCase === 'meta') return <MetaIcon key={i} style={style} />
        if (iconLowerCase === 'twitter') return <TwitterIcon key={i} style={style} />
        if (iconLowerCase === 'snapchat') return <SnapchatIcon key={i} style={style} />
        if (iconLowerCase === 'content creator') return <ContentCreatorIcon key={i} style={style} />
        if (iconLowerCase === 'email') return <EmailIcon key={i} style={style} />
        if (iconLowerCase === 'direct') return <PreviewLinkIcon key={i} style={style} />
        return <OtherIcon key={i} style={style} />
      })}
    </Box>
  )
}

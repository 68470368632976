import React, { FC, useState } from 'react'
import { SocialPicker } from 'src/views/CreateStorefront/components/SocialPicker'
import { ComponentTypeResponse, getFullFields, getMinifiedFields } from 'src/views/Components/types'
import { PageConfig } from 'src/types/interpretor'
import { ManualProductSelectionInput } from './ManualProductSelection'
import { ProductItemFull } from 'src/components/ProductSelectorLarge'
import { DisplayTypesEnum, StorePrimitiveSettings } from 'src/views/ComponentSettingsPage/types'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  currentSelectedProductMap: Record<string, ProductItemFull>
  storePrimitiveSettings: StorePrimitiveSettings | undefined
}

export const Banner: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  currentSelectedProductMap,
  storePrimitiveSettings,
}) => {
  const bannerProductSelectionContainer: ComponentTypeResponse = {
    componentId: '',
    createdAt: 0,
    updatedAt: 0,
    componentType: 'MANUAL_PRODUCT_SELECTION',
    containerType: storePrimitiveSettings?.product.containerType || 'CAROUSEL',
    internalName: '',
    title: '',
    contentType: 'PRODUCT',
    contentConfiguration: {
      type: 'PRODUCT_PRIMITIVE',
      value: {
        uiSettings: {
          fields: storePrimitiveSettings?.product.displayType === DisplayTypesEnum.MINIFIED ? getMinifiedFields() : getFullFields(),
          imageFit: storePrimitiveSettings?.product.uiSettings.imageFit,
        },
        apiSettings: {
          productIds: componentConfig.contentConfiguration.type === 'MEDIA_PRIMITIVE' ? componentConfig.contentConfiguration.value.apiSettings.productIds || [] : [],
        },
      },
    },
    visibleInLibrary: true,
    internalDescription: '',
    status: 'PUBLISHED',
    class:'SAVED',
  }
  const [productsComponentConfig, setProductsComponentConfig] = useState(bannerProductSelectionContainer)

  const dispatchPageConfig = (arg: { value: { headline?: string, subheadline?: string, campaignMedias?: PageConfig['campaignMedias']}}) => {
    const newComponent: ComponentTypeResponse = { ...componentConfig }
    if (newComponent.contentConfiguration.type !== 'MEDIA_PRIMITIVE') return
    if (arg.value.headline || arg.value.headline === '') newComponent.contentConfiguration.value.uiSettings.headline = arg.value.headline
    if (arg.value.subheadline || arg.value.subheadline === '') newComponent.contentConfiguration.value.uiSettings.subtitle = arg.value.subheadline
    if (arg.value.campaignMedias) newComponent.contentConfiguration.value.apiSettings.sources = arg.value.campaignMedias
    setComponentConfig(newComponent)
  }

  const setManualProductSelectionConfig = ((manualProductSelectionConfig: ComponentTypeResponse) => {
    const newComponent: ComponentTypeResponse = { ...componentConfig }
    if (manualProductSelectionConfig.contentConfiguration.type !== 'PRODUCT_PRIMITIVE' || newComponent.contentConfiguration.type !== 'MEDIA_PRIMITIVE') return
    newComponent.contentConfiguration.value.apiSettings.productIds = manualProductSelectionConfig.contentConfiguration.value.apiSettings.productIds
    newComponent.contentConfiguration.value.uiSettings.imageFit = manualProductSelectionConfig.contentConfiguration.value.uiSettings.imageFit
    newComponent.contentConfiguration.value.uiSettings.productFields= manualProductSelectionConfig.contentConfiguration.value.uiSettings.fields
    newComponent.contentConfiguration.value.uiSettings.productContainerType= manualProductSelectionConfig.containerType
    setComponentConfig(newComponent)
    setProductsComponentConfig(manualProductSelectionConfig)
  })

  return (
    componentConfig.contentConfiguration.type === 'MEDIA_PRIMITIVE' ?
      <div>
        <SocialPicker
          headline={componentConfig.contentConfiguration.value.uiSettings.headline}
          subheadline={componentConfig.contentConfiguration.value.uiSettings.subtitle}
          campaignMedias={componentConfig.contentConfiguration.value.apiSettings.sources}
          dispatchPageConfig = {dispatchPageConfig}
          defaultOpen={false} />
        <ManualProductSelectionInput
          componentConfig={productsComponentConfig}
          setComponentConfig={setManualProductSelectionConfig}
          currentSelectedProductMap={currentSelectedProductMap}
          backgroundGradient={''}
          openWhenNotSelected={false} />
      </div>
      : <div></div>
  )
}

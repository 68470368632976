import React, { FC } from 'react'
import { Box, TextField } from '@mui/material'
import { ComponentTypeResponse } from 'src/views/Components/types'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  backgroundGradient: string
}

export const KlaviyoInput: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  backgroundGradient,
}) => {
  return (
    componentConfig.contentConfiguration.type === 'KLAVIYO_PRIMITIVE' ?
      <Box sx={{
        display: 'flex',
      }}>
        <TextField
          id='klaviyo_id'
          name='klaviyo_name'
          style={{
            flex: '1',
            marginRight: '12px',
            marginBottom: '8px',
          }}
          margin="dense" type="string" variant="outlined"
          value={componentConfig.contentConfiguration.value.uiSettings.text}
          onChange={(e) => {
            // NOTE: because of the ts matching
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'KLAVIYO_PRIMITIVE') return
            newComponent.contentConfiguration.value.uiSettings.text = e.target.value
            setComponentConfig(newComponent)
          }}
          label={'Text'}
        />

        <TextField
          id='klaviyo_value_id'
          name='klaviyo_value'
          style={{
            flex: '1',
            marginRight: '12px',
            marginBottom: '8px',
          }}
          margin="dense" type="string" variant="outlined"
          value={componentConfig.contentConfiguration.value.uiSettings.buttonText}
          onChange={(e) => {
            // NOTE: because of the ts matching
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'KLAVIYO_PRIMITIVE') return
            newComponent.contentConfiguration.value.uiSettings.buttonText = e.target.value
            setComponentConfig(newComponent)
          }}
          label={'Button Text'}
        />
      </Box>
      :
      <div></div>
  )
}

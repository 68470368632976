import { render, screen } from '@testing-library/react'

import wrapComponent from 'src/tests/wrapComponent'
import NotFoundView from './NotFoundView'

const setup = () => render(wrapComponent(NotFoundView, {}))

describe('Not found page', () => {
  beforeEach(setup)

  it('renders not found page', async () => {
    await screen.findByText('404: The page you are looking for isn’t here')
    expect(screen.getByText('404: The page you are looking for isn’t here')).toBeVisible()
    expect(screen.getByText('You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.')).toBeVisible()
    expect(screen.getByRole('link', { name: 'Back to home'})).toBeVisible()
  })

  // it('return the page back home', () => {
  //   render(<NotFoundView />)
  //   userEvent.click(screen.getByRole('button', { name: 'Back to home'}))
  // })
})

import React from 'react'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,

} from '@mui/material'

import Page from 'src/components/Page'
import { useTranslation } from 'react-i18next'
import { logging } from 'src/utils/logging'

const PREFIX = 'ErrorPage'

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
}

const StyledPage = styled(Page)((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },

  [`& .${classes.image}`]: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
}))

interface ErrorPageProps {
  mainText: string
  subText?: string
}

const ErrorPage: FC<ErrorPageProps> = ({ mainText, subText }) => {

  const {t} = useTranslation()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'))
  logging(mainText, { tags: { section: 'error page'} })

  return (
    <StyledPage className={classes.root}
      title={t('general.not found message')}>
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          {`Error: ${mainText}`}
        </Typography>
        {subText && <Typography align="center"
          variant="subtitle2"
          color="textSecondary">
          {subText}
        </Typography>}
        <Box mt={6}
          display="flex"
          justifyContent="center">
          <Button
            color="secondary"
            component={RouterLink}
            to="/"
            variant="outlined"
          >
            Back to home
          </Button>
        </Box>
      </Container>
    </StyledPage>
  )
}

export default ErrorPage

import { Box, CardMedia, Chip, Grid, MenuItem, Skeleton, TextField } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'

import { formatDataValue } from 'src/utils/analyticsHelpers'
import { currencySymbolMap } from 'src/utils/currencySymbolMap'
import { ListIcons } from './ListIcons'
import { TopCampaign, TopCampaignSortBy, TopCampaignSortDir } from 'src/contexts/api/getTopCampaigns'
import useGenericContext from 'src/hooks/useGenericContext'
import { gifToCanvas } from 'src/utils/gifToCanvas'
import { formatPercent } from 'src/utils/helpers'
import Icon from './Icon/Icon'
import { analyticsHomeRefreshMillis } from 'src/config'
import { logging } from 'src/utils/logging'

const getPlaceholderData = (): TopCampaign => {
  return ({
    startDate: 1,
    campaignId: '',
    campaignName: '',
    campaignDescription: '',
    campaignImages: [],
    campaignSources: [],
    metrics: Array.from({length: 4}, () => ({
      label: '',
      value: {
        metricType: 'CURRENCY',
        value: 0,
        currency: 'USD',
      },
    })),
  })
}

const placeholderDatas = Array.from({length: 6}, () => getPlaceholderData())

export const TopCampaigns: FC = () => {
  const { homeCustomDate, getTopCampaigns2, isBrand } = useGenericContext()

  const [sortDir, setSortDir] = useState<TopCampaignSortDir>('DESC')
  const [sortBy, setSortBy] = useState<TopCampaignSortBy>('revenue')
  const [topCampaigns, setTopCampaigns] = useState<TopCampaign[]>(placeholderDatas)
  const [isLoading, setIsLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(false)
  const [lastFetchedCustomDate, setLastFetchedCustomDate] = useState<number[]>([])

  useEffect(() => {
    const callback = async () => {
      try {
        if (homeCustomDate[0] === 0 && homeCustomDate[1] === 0) return

        if (
          lastFetchedCustomDate[0] !== homeCustomDate[0] ||
        lastFetchedCustomDate[1] !== homeCustomDate[1]
        ) {
          setLoadingError(false)
          setTopCampaigns(placeholderDatas)
          setIsLoading(true)
          setLastFetchedCustomDate(homeCustomDate)
        }
        const result = await getTopCampaigns2(isBrand, homeCustomDate[0], homeCustomDate[1], sortBy, sortDir)
        setLoadingError(false)
        setIsLoading(false)
        if (result && result.data) { setTopCampaigns(result.data)} else throw new Error('No data')
      } catch (error) {
        logging(error, {tags: {section: 'getTopSocialChannels2'}})
        setLoadingError(true)
      }
    }
    callback()
    const interval = setInterval(callback, analyticsHomeRefreshMillis)
    return () => clearInterval(interval)
  }, [homeCustomDate, sortBy, sortDir, getTopCampaigns2, lastFetchedCustomDate, isBrand])

  const imgLoaded = ((ev) => {
    const img = ev.target
    gifToCanvas(img)
  })

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // border: '1px solid red',
        }}
      >
        <Box sx={{
          fontWeight: 700,
        }}>
          Top Performing Social Media Campaigns
        </Box>

        <Box sx={{
          display: 'flex',
          marginBottom: 1,
        }}>
          <TextField
            sx={{
              width: '100%',
              // flex: '0 0 150px',
              mr: 0.5,
              backgroundColor: '#F0F5FE',
              color: '#344563',
            }}
            select

            value={sortBy}
            size='small' margin='dense' type='string' variant='outlined'
            label='Sort By'
            onChange={(e) => {
              const val: any = e.target.value
              setSortBy(val)
            }}
          >
            <MenuItem key={'startDate'} value={'startDate'}>Date</MenuItem>
            <MenuItem key={'name'} value={'name'}>Name</MenuItem>
            <MenuItem key={'revenue'} value={'revenue'}>Revenue</MenuItem>
            <MenuItem key={'CAC'} value={'CAC'}>CAC</MenuItem>
            <MenuItem key={'ROAS'} value={'ROAS'}>ROAS</MenuItem>
            <MenuItem key={'bounce'} value={'bounce'}>Bounce</MenuItem>
          </TextField>

          <TextField
            sx={{
              width: '100%',
              // flex: '0 0 120px',
              // mr: 2,
              backgroundColor: '#F0F5FE',
              color: '#344563',
            }}
            select

            value={sortDir}
            size='small' margin='dense' type='string' variant='outlined'
            label='Sort Direction'
            onChange={(e) => {
              const val: any = e.target.value
              setSortDir(val)
            }}
          >
            <MenuItem key={'ASC'} value={'ASC'}>Ascending</MenuItem>
            <MenuItem key={'DESC'} value={'DESC'}>Descending</MenuItem>
          </TextField>
        </Box>
      </Box>

      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        sx={{
        }}
      >
        {topCampaigns.map((topCampaign, i) => {
          return (
            <Grid container item xs={12} lg={6}
              key={topCampaign.campaignId + i}
              sx={{
                flex: 1,
              }}
            >
              <Box sx={{
                display: 'flex',
                flex: 1,
              }}>
                <Box
                  sx={{
                    mr: 1.5,
                    borderRadius: '5px',
                    // border: '1px solid red',
                    width: '160px',
                    height: '160px',
                    backgroundColor: '#F2F4F7',
                  }}
                >
                  {isLoading &&  <Skeleton sx={{height: '100%', transform: 'scale(1, 1)'}} />}
                  {loadingError  && <Icon name='NoData' root={true} size={160}/> }
                  {!isLoading && !loadingError && (topCampaign.campaignImages[0]?.smallUrl ?
                    <CardMedia
                      sx={{
                        width: '100%',
                        height: '100%',
                      }}
                      component='img'
                      src={topCampaign.campaignImages[0]?.smallUrl}
                      onLoad={imgLoaded}
                    />
                    : <Icon
                      name='SocialCampaignUnknown'
                      root={true}
                      color={'#B0BEC5'}
                      size={160}
                    />)
                  }
                  <Box sx={{ position: 'absolute', ml: 1, backgroundColor: 'white', transform: 'translate(0%, -120%)', borderRadius: '4px' }}>
                    <ListIcons size={20} icons={topCampaign.campaignSources} />
                  </Box>
                </Box>

                <Box sx={{ position: 'absolute' }}>
                  <Chip
                    size='small'
                    label={i + 1}
                    sx={{
                      backgroundColor: '#F2F4F7',
                      border: '1px solid lightgrey',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    borderRadius: '5px',
                    flex: 1,
                    height: '160px',
                    flexDirection: 'column',
                    backgroundColor: '#F2F4F7',
                    p: 1,
                  }}
                >
                  <Box sx={{
                    fontSize: '1.1em',
                    minHeight: '1em',
                    pb: 1,
                    fontWeight: 'bold',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    width: '85%',
                    display: '-webkit-box',
                  }}>
                    {isLoading ? <Skeleton width={50} /> : topCampaign.campaignName}
                  </Box>

                  {/* <Box sx={{ position: 'relative', height: 0, overflow: '' }}>
                    <Box sx={{
                      transform: 'translate(0%, -100%)',
                      width: '100%',
                    }}>
                      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <ChainLinkIcon style={{ width: 23, height: 23, padding: 2 }} />
                      </Box>
                    </Box>
                  </Box> */}

                  <Box sx={{
                    minHeight: '3em',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    display: '-webkit-box',
                  }}>
                    {isLoading ? <Skeleton width='50%' /> : topCampaign.campaignDescription}
                  </Box>

                  {
                    topCampaign.metrics.map((metric, j) => {
                      if (isLoading) return <Skeleton key={j} width={110 + j * 10 - (j % 2 ? (j * 10 + 10): 0)} />
                      if (loadingError) return <Box key={j} sx={{height: '20px', backgroundColor: '#ebecef'}} />
                      return (
                        <Box key={j} sx={{display: 'flex'}}>
                          <Box sx={{fontWeight: 'bold', pr: 1}}>{metric.label}</Box>
                          {metric.value.metricType === 'PERCENT' && formatPercent(metric.value.value)}
                          {metric.value.metricType === 'CURRENCY' && formatDataValue(metric.value.value, 2, currencySymbolMap[metric.value.currency])}
                          {metric.value.metricType === 'COUNT' && formatDataValue(metric.value.value)}
                        </Box>
                      )
                    })
                  }

                  {/* <Box sx={{ position: 'relative', height: 0, overflow: '' }}>
                    <Box sx={{
                      transform: 'translate(0%, -100%)',
                    }}>
                      <ListIcons size={20} icons={topCampaign.campaignSources} />
                    </Box>
                  </Box> */}

                </Box>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

import React, { useRef, useState, memo, useEffect } from 'react'
import type { FC } from 'react'
// Material UI date picker is outdated and archived it required react 16, but we are on 17 we need to move to date pickers in MUI@5 as part of MUI5 upgrade
// We have forced material-ui/pickers to accept React 17 types instead of 16 using ovverides in package.json
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import DatePickerReact from 'react-datepicker'

import {
  ListItemText,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  Box,
  Divider,
  Grid,
  MenuList,
  SxProps,
} from '@mui/material'
import {
  DatePicker as DatePickerInput,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import type { TimeRange } from 'src/types/redux'

import { ReactComponent as CalendarIcon } from 'src/assets/icons/Calendar.svg'
import { ReactComponent as ArrowIcon } from 'src/assets/icons/ArrowDownSolid.svg'
import { useDispatch, useSelector } from 'src/appRedux/store'
import { actions } from 'src/appRedux/actions'
import { TIME_OPTIONS, DEFAULT_SELECTION, getDateRange, getTimeDelim, TimeRangeOption } from 'src/utils/time'
import { saveTimeRange } from 'src/utils/sessionStorage'
import { Root, classes } from 'src/styles/DatePickerStyles'
import { Theme } from '@emotion/react'

const DatePicker: FC<{
  sxStyle?: SxProps<Theme>
  isDayMinimum?: boolean
  getFullDays?: boolean
  onCustomDateChange?: (x: number[]) => void
}> = ({ sxStyle, isDayMinimum, onCustomDateChange, getFullDays}) => {
  const { t } = useTranslation()
  const moreRef = useRef(null)
  const [buttonText, setButtonText] = useState<string>(DEFAULT_SELECTION)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isCustomCalendarVisible, setIsCustomCalendarVisible] =
    useState<boolean>(false)
  const [customDate, setCustomDate] = useState<any>([0, 0])
  const [pickedDate, setPickedDate] = useState<Pick<TimeRange, 'start' | 'end' | 'selection'>>({ start: 0, end: 0, selection: 'custom' })
  const [timeStart, setTimeStart] = useState<Date | Moment | number | null>(null)
  const [timeEnd, setTimeEnd] = useState<Date | Moment | number | null>(null)

  const date = useSelector((state) => state.date)
  const dispatch = useDispatch()
  useEffect(() => {
    // This is hard to decipher, but we only get 'selection' property on timerange when this component loads initially.
    // We need to make it clearer when this useeffect should run
    if (date.timeRange.selection) {
      setButtonText(t(`date picker.${date.timeRange.selection}`))
    }
    const c = [date.timeRange.start, date.timeRange.end]
    setCustomDate(c)
    onCustomDateChange?.(c)

  }, [date, t])

  useEffect(() => {
    if (pickedDate.start !== 0 && pickedDate.end !== 0) {
      const delim = getTimeDelim(pickedDate.end, pickedDate.start)
      const dateToSetInState = { ...pickedDate, delim }
      dispatch(actions.date.setDate(dateToSetInState))
    }
  }, [buttonText, dispatch, pickedDate])

  const selectOption = (selection: TimeRangeOption): void => {
    setButtonText(t(`date picker.${selection}`))
    const { start, end, delim } = getDateRange(TIME_OPTIONS[selection], getFullDays)
    saveTimeRange({ start, end, selection, delim })
    setPickedDate({ start, end, selection })
    const c = [start, end]
    setCustomDate(c)
    onCustomDateChange?.(c)
    setIsMenuOpen(false)
  }

  const handleCustomDateSelection = (): void => {
    setTimeStart(moment(customDate[0]).startOf('day').valueOf())
    setTimeEnd(moment(customDate[1]).endOf('day').valueOf())
    setIsCustomCalendarVisible(true)
  }

  const handleMenuOpen = (): void => {
    setIsMenuOpen(true)
  }

  const handleDateChange = (value): void => {
    setCustomDate([new Date(value[0]).getTime(), new Date(value[1]).getTime()])
    setTimeStart(moment(customDate[0]).startOf('day').valueOf())
    setTimeEnd(moment(customDate[1]).endOf('day').valueOf())
  }

  const saveCustomDate = () => {
    const delim = getTimeDelim(customDate[1], customDate[0])
    const dateToSave: Required<TimeRange> = {
      start: customDate[0],
      end: customDate[1],
      selection: 'custom',
      delim,
    }
    saveTimeRange(dateToSave)
    if (dateToSave.start !== pickedDate.start || dateToSave.end !== pickedDate.end) {
      setButtonText(t('date picker.custom'))
      setPickedDate({ start: dateToSave.start, end: dateToSave.end, selection: 'custom' })
    }
    handleMenuClose()
  }
  // handle close popup
  const handleMenuClose = (): void => {
    setIsMenuOpen(false)
    setTimeout(() => {
      setIsCustomCalendarVisible(false)
    }, 300)
  }

  const handleStartTimeChange = (value) => {
    const start = moment(customDate[0])
    start.hours(moment(value).hours())
    start.minutes(moment(value).minutes())
    setTimeStart(start.valueOf())
    setCustomDate([start.valueOf(), timeEnd])
  }

  const handleEndTimeChange = (value) => {
    const end = moment(customDate[1])
    end.hours(moment(value).hour())
    end.minutes(moment(value).minute())
    setTimeEnd(end.valueOf())
    setCustomDate([timeStart, end.valueOf()])
  }

  return (
    <Root className={classes.root}>
      <Tooltip ref={moreRef} title={t('date picker.select new date')}>
        <Button
          data-testid="datePickerButton"
          variant="contained"
          color="secondary"
          onClick={handleMenuOpen}
          aria-controls={isCustomCalendarVisible ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          style={{ textTransform: 'none', height: 40 }}
          sx={sxStyle}
        >
          <CalendarIcon style={{marginRight: 12}}/>
          {buttonText}
          <ArrowIcon style={{marginLeft: 12}} />
        </Button>
      </Tooltip>
      {/* Menu */}
      <Menu
        anchorEl={moreRef.current}
        onClose={handleMenuClose}
        open={isMenuOpen}
        PaperProps={{ className: classes.menu }}
      >
        {/* Fast selection menu */}
        {!isCustomCalendarVisible && (
          <Root className={classes.root}>
            <MenuList className={classes.menu}>
              {Object.keys(TIME_OPTIONS)
                .filter((time: TimeRangeOption) => {
                  if (!isDayMinimum) return true
                  const a: TimeRangeOption[] = ['last hour', 'last 4 hours']
                  return a.indexOf(time) === -1
                })
                .map((time: TimeRangeOption, i) => {
                  return (
                    <MenuItem
                      key={`time-${i}`}
                      onClick={() => selectOption(time)}
                    >
                      <ListItemText primary={t(`date picker.${time}`)} />
                    </MenuItem>
                  )
                })}
              <MenuItem onClick={handleCustomDateSelection}>
                <ListItemText primary={t(`date picker.custom dots`)} />
              </MenuItem>
            </MenuList>
          </Root>
        )}
        {/* Calendar - custom picker */}
        {isCustomCalendarVisible && (
          <Root className={classes.root}>
            <Box className={classes.calendar}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePickerReact
                  inline
                  selected={timeStart}
                  startDate={customDate[0]}
                  endDate={customDate[1]}
                  onChange={handleDateChange}
                  monthsShown={2}
                  shouldCloseOnSelect={false}
                  focusSelectedMonth={false}
                  selectsRange
                />

                <Divider className={classes.divider} />

                <Grid className={classes.inputGrid}>
                  <div>
                    <DatePickerInput
                      disabled
                      label={t('date picker.start date')}
                      value={customDate[0]}
                      onChange={(newValue) => newValue}
                      className={classes.inputDate}
                    />
                    <TimePicker
                      ampm={false}
                      label={t('date picker.start time')}
                      value={timeStart}
                      onChange={handleStartTimeChange}
                      className={classes.inputTime}
                      disabled={isDayMinimum}
                    />
                  </div>
                  <span className={classes.dividerInput}>to</span>
                  <div>
                    <DatePickerInput
                      disabled
                      label={t('date picker.end date')}
                      value={customDate[1]}
                      onChange={(newValue) => newValue}
                      className={classes.inputDate}
                    />

                    <TimePicker
                      ampm={false}
                      label={t('date picker.end time')}
                      value={timeEnd}
                      onChange={handleEndTimeChange}
                      className={classes.inputTime}
                      disabled={isDayMinimum}
                    />
                  </div>
                  <Button
                    onClick={saveCustomDate}
                    className={classes.saveButton}
                    disabled={customDate[0] === 0 || customDate[1] === 0}
                    variant="contained"
                    color="secondary"
                  >
                    {t('date picker.apply')}
                  </Button>
                </Grid>
              </LocalizationProvider>
            </Box>
          </Root>
        )}
      </Menu>
    </Root>
  )
}

export default memo(DatePicker)

import { PageConfig } from 'src/types/interpretor'
import { ContainerConfig } from 'storefront-interpreter/src/api/components'

export const isComponentEditable = (component: ContainerConfig) =>
  isComponentCustom(component) ||
  component.componentType === 'CAMPAIGN_STORYBOOK' ||
  component.componentType === 'SIMILAR_PRODUCTS' ||
  component.componentType === 'ALTERNATES_IN_CATEGORY'

export const isComponentCustom = (component: ContainerConfig) => {
  if (isComponentIntegration(component)) return false // integration
  if (isComponentHook(component)) return false // hook
  if (isComponentHidden(component)) return false

  return true // whatever is left means custom
}

export const isComponentHook = (component: ContainerConfig) => {
  if (component.componentType === 'SIMILAR_PRODUCTS') return true // hook
  if (component.componentType === 'ALTERNATES_IN_CATEGORY') return true // hook
  if (component.componentType === 'ALL_PUBLISHED_CAMPAIGNS') return true // hook
  if (component.componentType === 'CAMPAIGN_STORYBOOK') return true // hook
  return false
}

export const isComponentIntegration = (component: ContainerConfig) => {
  if (component.class === 'INTEGRATION') return true
  return false
}

export const isComponentHidden = (component: ContainerConfig) => {
  if (component.componentType === 'ATTRIBUTION_SURVEY') return true
  if (component.componentType === 'FOOTER') return true
  if (component.componentType === 'HEADER') return true
  return false
}

export const shouldDisplayInCustomLibraryTab = (component: ContainerConfig, pageConfig: PageConfig) => {
  return isComponentCustom(component)
}

export const shouldDisplayInHooksTab = (component: ContainerConfig, pageConfig: PageConfig) => {
  if (!isComponentHook(component)) return false


  if (component.componentType === 'ALTERNATES_IN_CATEGORY') {
    if (pageConfig.layout === 'CATEGORY') return false
  }

  if (component.componentType === 'SIMILAR_PRODUCTS') {
    if (pageConfig.layout === 'CATEGORY') return false
    if (pageConfig.layout === 'SOCIAL_HOME') return false
  }

  if (component.componentType === 'CAMPAIGN_STORYBOOK') {
    // Only show the storybook with hero image if the page is a social home page.
    const storybookHasHeroImage = component.contentConfiguration.value.uiSettings['displayHero']
    if (storybookHasHeroImage) return pageConfig.layout === 'SOCIAL_HOME'
  }

  return true
}

export const shouldDisplayInIntegrationsTab = (component: ContainerConfig, pageConfig: PageConfig) => {
  if (!isComponentIntegration(component)) return false

  if (component.componentType === 'REVIEWS') {
    if (pageConfig.layout === 'CATEGORY') return false
  }

  if (component.componentType === 'BACK_IN_STOCK_NOTIFICATION') {
    if (pageConfig.layout === 'CATEGORY') return false
    if (pageConfig.layout === 'SOCIAL_HOME') return false
  }

  return true
}

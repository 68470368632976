export const gifToCanvas = ((img: HTMLImageElement) => {
  if (img && img.src?.indexOf('.gif') === -1) return
  const c: HTMLCanvasElement = document.createElement('canvas')
  const w = c.width = img.width
  const h = c.height = img.height
  c.getContext('2d')?.drawImage(img, 0, 0, w, h)
  try {
    img.src = c.toDataURL('image/gif')
  } catch (e) {
    for (const entry of Object.entries(img.attributes)) {
      const attr: any = entry[1]
      c.setAttribute(attr.name, attr.value)
    }
    if (img.parentNode) img.parentNode.replaceChild(c, img)
  }
})

import { rest } from 'msw'
import { samplePagesConfig, sampleComponents, sampleConfig, sampleCheckouts, defaultPages, sampleVariants, metaIntegrations,
  productPrimitives, categoryPrimitives, instagramPosts, sampleMerchantDetails } from './fixtures'

export const handlers = [
  rest.get('https://dev-use2.product-service.simplicitydx.com/v1/shop', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ defaultCurrencyCode: 'USD' })
    )
  }),

  rest.get('https://analytics-service.dev-use2.simplicitydx.com/v1/analytics', (req, res, ctx) => {
    // exclude_base=true&
    const exclude_base = req.url.searchParams.get('exclude_base')
    const metrics = req.url.searchParams.get('metrics')
    const group_by = req.url.searchParams.get('group_by')
    if (exclude_base === 'true' && metrics === 'revenue,previous_revenue') {
      return res(
        ctx.status(200),
        ctx.json({
          'revenue': 2237.58,
          'previous_revenue': 5244.55,
        })
      )
    }

    if (exclude_base === 'true' && metrics === 'revenue,previous_revenue,conversion_rate,best_source' && group_by === 'utm_campaign') {
      return res(
        ctx.status(200),
        ctx.json({
          '': {
            'revenue': 2237.58,
            'previous_revenue': 2432.19,
            'conversion_rate': 0.06,
          },
          '2 DO NOT TOUCH - HK ANALYTICS TEST': {
            'previous_revenue': 1782.79,
          },
          'Content Creator Campaign': {
            'previous_revenue': 873.6,
          },
          'HK External name': {
            'conversion_rate': 0,
          },
          'Kyle Campaign': {
            'previous_revenue': 69.99,
          },
          'Kyle Cote': {
            'previous_revenue': 42.99,
          },
          'Nosto campaign': {
            'conversion_rate': 0,
          },
          'Test': {
            'previous_revenue': 42.99,
            'conversion_rate': 0,
          },
          'd': {
            'conversion_rate': 0,
          },
          'test': {
            'conversion_rate': 0,
          },
        })
      )
    }

    if (exclude_base === 'true' && metrics === 'revenue,previous_revenue,conversion_rate' && group_by === 'utm_source') {
      return res(
        ctx.status(200),
        ctx.json({
          '': {
            'revenue': 2237.58,
            'previous_revenue': 2033.79,
            'conversion_rate': 0.06,
          },
          'Content Creator': {
            'previous_revenue': 916.59,
          },
          'Email': {
            'previous_revenue': 42.99,
          },
          'Facebook': {
            'previous_revenue': 1086.79,
            'conversion_rate': 0,
          },
          'Instagram': {
            'previous_revenue': 696,
            'conversion_rate': 0,
          },
          'Kyle': {
            'previous_revenue': 69.99,
          },
          'Pinterest': {
            'previous_revenue': 398.4,
          },
          'Twitter': {
            'conversion_rate': 0,
          },
        })
      )
    }
    console.log('returning undefined=>', exclude_base, metrics, group_by)
  }),

  rest.get('https://analytics-service.dev-use2.simplicitydx.com/v1/checkouts', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(sampleCheckouts)
    )
  }),

  rest.get('https://dev-use2.component-service.simplicitydx.com/v1/pages', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(samplePagesConfig)
    )
  }),

  rest.get('https://dev-use2.component-service.simplicitydx.com/v1/components', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(sampleComponents)
    )
  }),

  rest.get('https://analytics-service.dev-use2.simplicitydx.com/v1/graph', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([
        {
          'timestamp': 1687392000000,
          'base_revenue': 227.98,
          'experience_revenue': 193.59,
          'experience_base_revenue_pct': 0.85,
        },
        {
          'timestamp': 1686096000000,
          'base_revenue': null,
          'experience_revenue': 979.99,
          'experience_base_revenue_pct': null,
        },
        {
          'timestamp': 1687737600000,
          'base_revenue': null,
          'experience_revenue': 1064,
          'experience_base_revenue_pct': null,
        },
      ])
    )
  }),

  rest.get('https://dev-use2.product-service.simplicitydx.com/v1/variants', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(sampleVariants)
    )
  }),

  rest.get('https://dev-use2.component-service.simplicitydx.com/v1/third-party', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        'shop': 'sdx-qa-test.myshopify.com',
        'klaviyo': {
          'apiKey': '1234',
          'lists': [
            {
              'id': 'abc',
              'name': 'newsletter',
            },
          ],
        },
      })
    )
  }),


  rest.get('https://dev-use2.component-service.simplicitydx.com/v1/third-party', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        'shop': 'sdx-qa-test.myshopify.com',
        'klaviyo': {
          'apiKey': '1234',
          'lists': [
            {
              'id': 'abc',
              'name': 'newsletter',
            },
          ],
        },
      })
    )
  }),

  rest.get('https://dev-use2.social-service.simplicitydx.com/v1/integrations/meta', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(metaIntegrations),
    )
  }),

  rest.get('https://dev-use2.component-service.simplicitydx.com/v1/pages/dynamic/default', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(defaultPages),
    )
  }),

  rest.post('https://dev-use2.social-service.simplicitydx.com/v1/instagram/posts', async (req, res, ctx) => {
    const requestArray = await req.json()
    const mockResponse = requestArray.map(request => { return {
      postId: request.post,
      thumbnail: 'https://scontent-ord5-2.cdninstagram.com/v/t51.29350-15/348982536_796417391749165_1677813055912308589_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=c4dd86&_nc_ohc=NVLvPaM20xoAX9EF0FO&_nc_ht=scontent-ord5-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AfDZ5pqfP8k1ZDep2Xa0VQxFpecqR7vNK-i24Xmrwm5tYw&oe=6599B771',
      accountId: request.accountId,
    }})
    return res(
      ctx.status(200),
      ctx.json(mockResponse)
    )
  }),

  rest.post('https://dev-use2.social-service.simplicitydx.com/v2/meta-ads/ads', async (req, res, ctx) => {
    const requestArray = await req.json()
    const mockResponse = requestArray.map(request => { return {
      postId: request.post,
      thumbnail: 'https://scontent-ord5-1.xx.fbcdn.net/v/t45.1600-4/338247559_23853678331640590_3601841804662310176_n.jpg?_nc_cat=111&ccb=1-7&_nc_ohc=ellTgGcb1b4AX9mvmex&_nc_ht=scontent-ord5-1.xx&edm=AAT1rw8EAAAA&stp=c0.5000x0.5000f_dst-emg0_p500x500_q75&ur=46b8c0&_nc_sid=58080a&oh=00_AfAyIJH-MtXOxJ4j8CmoZy_k1-OKvyCCnkzQdJ6FuzFPCg&oe=659685F8',
      accountId: request.accountId,
    }})
    return res(
      ctx.status(200),
      ctx.json(mockResponse)
    )
  }),

  rest.get('https://dev-use2.product-service.simplicitydx.com/v1/products/primitives', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(productPrimitives),
    )
  }),

  rest.get('https://dev-use2.product-service.simplicitydx.com/v1/categories/primitives', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(categoryPrimitives),
    )
  }),

  rest.get('http://localhost/KlaviyoIntegration.svg', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json('KlaviyoIntegration.svg'),
    )
  }),

  rest.get('http://localhost/Plus.svg', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json('Plus.svg'),
    )
  }),

  rest.get('http://localhost/MetaIntegration.svg', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json('MetaIntegration.svg'),
    )
  }),

  rest.get('http://localhost/Checkmark.svg', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json('Checkmark.svg'),
    )
  }),

  rest.get('https://dev-use2.social-service.simplicitydx.com/v1/instagram/17841454710484688/posts', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(instagramPosts),
    )
  }),

  rest.get('https://dev-use2.ecommerce-service.simplicitydx.com/v1/merchant', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(sampleMerchantDetails),
    )
  }),

]

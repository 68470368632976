import axios, { AxiosResponse } from 'axios'
import { logging } from 'src/utils/logging'
import { ecommerceServiceBaseUrl } from 'src/config'

export type AppHandle = {handle: string}
export type GetAppHandle = (shop: string) => Promise<AxiosResponse<AppHandle> | undefined>
export const getAppHandleApi: GetAppHandle = async (activeShop: string) => {
  try {
    const url = '/v1/shopify/app'

    const response =  await axios.get<AppHandle>(url, { baseURL: ecommerceServiceBaseUrl, headers: {
      shop: activeShop,
    } })
    return response

  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get app handle api' } })
  }

}

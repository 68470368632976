import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'

import type { FC } from 'react'
import { Paper, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, useTheme, Checkbox } from '@mui/material'
import { ReactComponent as StartImg } from 'src/assets/icons/StartImg.svg'
import { ReactComponent as HooksIcon } from 'src/assets/icons/Hooks.svg'
import { ReactComponent as IntegrationsIcon } from 'src/assets/icons/Integrations.svg'
import { ReactComponent as LibraryIcon } from 'src/assets/icons/LibraryFilled.svg'
import { ReactComponent as KlaviyoLogoIcon } from 'src/assets/icons/KlaviyoLogo.svg'
import {ReactComponent as ReviewsIcon} from 'src/assets/icons/ReviewsIcon.svg'
import { ReactComponent as SdxLogoMark } from 'src/assets/icons/SdxLogoMark.svg'

import useGenericContext from 'src/hooks/useGenericContext'
import { ComponentType } from 'src/types/interpretor'
import { COMPONENT_CLASS } from './types'
import { EmbedModal } from './EmbedModal'
import { getRoutePath } from 'src/routes'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'



const PREFIX = 'Components'

const classes = {
  paper: `${PREFIX}-paper`,
  header: `${PREFIX}-header`,
  marginRound: `${PREFIX}-marginRound`,
  marginRoundText: `${PREFIX}-marginRoundText`,
  table: `${PREFIX}-table`,
  searchFilter: `${PREFIX}-searchFilter`,
  group: `${PREFIX}-group`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.paper}`]: {
    margin: theme.spacing(4),
    padding: theme.spacing(8),
    textAlign: 'center',
    flexGrow: 1,
  },

  [`& .${classes.searchFilter}`]: {
    marginLeft: 'auto',
    marginRight: 20,
  },

  [`& .${classes.group}`]: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'unset',
    margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },

  [`& .${classes.marginRound}`]: {
    margin: theme.spacing(3),
  },

  [`& .${classes.marginRoundText}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    width: 'unset',
    margin: theme.spacing(4),
    marginTop: '0px',
  },
}))

export const componentTypeMap: Record<ComponentType, string> = {
  'ALTERNATES_IN_CATEGORY': 'Alternates In Category',
  'SIMILAR_PRODUCTS': 'Similar Products',
  'VISUAL_COLLECTION': 'Visual Collection',
  'ALL_PUBLISHED_CAMPAIGNS': 'All Published Campaigns',
  'MANUAL_PRODUCT_SELECTION': 'Manual Product Selection',
  'MANUAL_CATEGORY_NAVIGATION': 'Manual Category Navigation',
  'ALL_PRODUCTS_IN_CATEGORY': 'All Products In Category',
  'HTML': 'Html',
  'EMAIL_CAPTURE': 'Email Capture',
  'BACK_IN_STOCK_NOTIFICATION': 'Back In Stock Notification',
  'CAMPAIGN_STORYBOOK': 'Campaign Storybook',
  'ATTRIBUTION_SURVEY': 'Attribution Survey',
  'BANNER': 'Banner',
  'YOUTUBE': 'Youtube',
  'FOOTER': 'Footer',
  'HEADER': 'Header',
  'REVIEWS': 'Product Reviews',
  'CART': 'Cart',
}

const Components: FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const { components, loadingComponents, refetchComponents } = useGenericContext()
  const [searchFilter, setSearchFilter] = useState<Record<COMPONENT_CLASS, boolean>>({
    SAVED: true,
    HOOK: true,
    INTEGRATION: true,
  })
  const [embedModalComponentId, setEmbedModalComponentId] = useState('')
  const onEmbedModalClose = () => setEmbedModalComponentId('')

  if (loadingComponents) {
    return <SdxLoading/>
  }

  const handleEditComponent = (componentId) => {
    history.push(getRoutePath('component_edit', {id: componentId}))
  }

  return (
    <Root>
      <div className={classes.header}>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 'large',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >Component Library</Typography>
        <div className={classes.searchFilter}>
          {Object.keys(searchFilter).map((key: COMPONENT_CLASS ) => (
            <FormControlLabel
              key={key}
              label={(
                <span style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  {key === 'SAVED' && (
                    <span className={classes.group}>
                      <LibraryIcon style={{ marginRight: 5, transform: 'translateY(-1px)', color: theme.palette.brand.urIndigo }} /> Library
                    </span>
                  )}
                  {key === 'HOOK' && (
                    <span className={classes.group}>
                      <HooksIcon style={{ marginRight: 5, transform: 'translateY(-2px)', color: theme.palette.brand.urGreen}} /> Hooks
                    </span>
                  )}
                  {key === 'INTEGRATION' && (
                    <span className={classes.group}>
                      <IntegrationsIcon style={{ marginRight: 5, transform: 'translateY(-1px)', color: theme.palette.brand.urOrange}} /> Integrations
                    </span>
                  )}
                </span>
              )}
              control={
                <Checkbox
                  checked={searchFilter[key]}
                  onChange={(e) => {
                    const val = e.target.checked
                    searchFilter[key] = val
                    setSearchFilter({ ...searchFilter })
                    refetchComponents(searchFilter)
                  }}
                />
              }
            />
          ))}
        </div>
        <div>
          <Button onClick={() => history.push(getRoutePath('component_create'))} variant='contained' color="secondary">New Component</Button>
        </div>
      </div>

      {components?.length > 0 ?
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="component table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Component Name</TableCell>
                <TableCell align="center">Component Label</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Group</TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Last Modified</TableCell>
                <TableCell align="center">Creation time</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {components
                .sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt))
                .map((comp) => (
                  <TableRow
                    key={comp.componentId}
                  >
                    <TableCell align="left">{comp.internalName}</TableCell>
                    <TableCell align="center">{comp.title}</TableCell>
                    <TableCell align="center">{comp.componentType?.replaceAll('_', ' ')}</TableCell>
                    <TableCell align="center">
                      {comp.class === 'HOOK' && (
                        <span className={classes.group}>
                          <HooksIcon style={{ marginRight: 5, color: theme.palette.brand.urGreen }} /> Hooks
                        </span>
                      )}
                      {comp.class === 'INTEGRATION' && (
                        <span className={classes.group}>
                          <IntegrationsIcon style={{ marginRight: 5, color: theme.palette.brand.urOrange }}></IntegrationsIcon> Integrations
                        </span>
                      )}
                      {comp.class === 'SAVED' && (
                        <span className={classes.group}>
                          <LibraryIcon style={{ marginRight: 5, color: theme.palette.brand.urIndigo }}></LibraryIcon> Library
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {comp.class ==='HOOK' && (
                        <span className={classes.group}>
                          <SdxLogoMark style={{ marginLeft: 5, height: 20 }}></SdxLogoMark>
                        </span>
                      )}
                      {comp.class ==='INTEGRATION' && (
                        <span className={classes.group}>
                          {comp.contentType === 'KLAVIYO' && <KlaviyoLogoIcon style={{ marginLeft: 5, height: 20 }}></KlaviyoLogoIcon>}
                          {comp.contentType === 'REVIEWS' && <ReviewsIcon style={{ marginLeft: 5, height: 20,color: theme.palette.brand.urOrange }}></ReviewsIcon>}
                        </span>
                      )}
                      {comp.class ==='SAVED' && (
                        <span className={classes.group}>
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="center">{new Date(Number(comp.updatedAt)).toLocaleString()}</TableCell>
                    <TableCell align="center">{new Date(Number(comp.createdAt)).toLocaleString()}</TableCell>
                    <TableCell align="right">
                      <Button
                        color='secondary'
                        onClick={() => {
                          if (comp.componentType === 'ATTRIBUTION_SURVEY')
                            history.push(getRoutePath('store_settings'))
                          else if (comp.componentType === 'FOOTER')
                            history.push(getRoutePath('store_settings'))
                          else if (comp.componentType === 'HEADER')
                            history.push(getRoutePath('store_settings'))
                          else handleEditComponent(comp.componentId)
                        }}>
                        {comp.componentType === 'ATTRIBUTION_SURVEY' || comp.componentType === 'FOOTER' || comp.componentType === 'HEADER' ? 'Edit in Set Up' : 'Edit'}
                      </Button>
                    </TableCell>
                    {comp.componentType === 'ALL_PUBLISHED_CAMPAIGNS' ? <TableCell align="center"><Button
                      color='secondary'
                      onClick={() => {
                        setEmbedModalComponentId(comp.componentId)
                      }}>Embed</Button></TableCell> : <TableCell align="center"></TableCell>}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        : <Paper className={classes.paper}>
          <StartImg className={classes.marginRound} />
          <div className={classes.marginRoundText}>
            <Typography variant='h1'>Start building components to use across all your campaign stores</Typography>
          </div>
          <Typography>Components are the elements on your storefront and include things like lists of products, categories and newsletter signups.</Typography>
          <Typography>You can build as many components as you like and use them across all your campaign stores. </Typography>
        </Paper>}
      <EmbedModal isOpen={!!embedModalComponentId} onClose={onEmbedModalClose} componenentConfigId={embedModalComponentId} />
    </Root>
  )
}

export default Components

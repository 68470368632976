import { combineReducers } from '@reduxjs/toolkit'
// Need to get rid of redux form package - https://www.npmjs.com/package/redux-form
import { reducer as formReducer } from 'redux-form'
import { reducer as dateReducer } from '../slices/date'

const rootReducer = combineReducers({
  form: formReducer,
  date: dateReducer,
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>

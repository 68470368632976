import { server } from './mocks/server.js'

// jest-dom adds custom jest matchers for asserting on DOM nodes.
// allows you to do things like:
// expect(element).toHaveTextContent(/react/i)
// learn more: https://github.com/testing-library/jest-dom
import '@testing-library/jest-dom/extend-expect'

jest.mock('react-apexcharts', () => jest.fn(() => {}))

Object.defineProperty(global.window, 'FB', {
  value: { getLoginStatus: jest.fn()},
})

class ResizeObserver {
  observe() {
    // do nothing
  }
  unobserve() {
    // do nothing
  }
  disconnect() {
    // do nothing
  }
}

Object.defineProperty(global.window, 'ResizeObserver', {
  value: ResizeObserver,
})

// Establish API mocking before all tests.
beforeAll(() => {server.listen()})
afterEach(() => server.resetHandlers())
afterAll(() => server.close())

// These SVGElement polyfill are for Apexchart graphs
Object.defineProperty(global.SVGElement.prototype, 'getScreenCTM', {
  writable: true,
  value: jest.fn(),
})

Object.defineProperty(global.SVGElement.prototype, 'getBBox', {
  writable: true,
  value: jest.fn().mockReturnValue({
    x: 0,
    y: 0,
  }),
})

Object.defineProperty(global.SVGElement.prototype, 'getComputedTextLength', {
  writable: true,
  value: jest.fn().mockReturnValue(0),
})

Object.defineProperty(global.SVGElement.prototype, 'createSVGMatrix', {
  writable: true,
  value: jest.fn().mockReturnValue({
    x: 10,
    y: 10,
    inverse: () => {},
    multiply: () => {},
  }),
})

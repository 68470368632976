import React, { FC, useEffect, useState } from 'react'
import { Button, Dialog, TextField, IconButton, Snackbar, Grid, DialogTitle, DialogContent, Link, FormControlLabel, Checkbox, Box } from '@mui/material'
import { copyTextToClipboard } from 'src/utils/helpers'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import useGenericContext from 'src/hooks/useGenericContext'
import { getPlatformInfo } from 'src/utils/getPlatformSymbol'

export type EmbedModalProps = {
  isOpen: boolean
  onClose: () => void
  componenentConfigId: string
}

export const EmbedModal: FC<EmbedModalProps> = ({
  isOpen,
  onClose,
  componenentConfigId,
}) => {
  const [copied, setCopied] = useState(false)
  const [iframeString, setIframeString] = useState('')
  const { shop } = useGenericContext()

  const isLocalhostIframeName = 'isLocalhostIframe_Embed'
  const [isLocalhost, setIsLocalhost] = useState(window.location.hostname === 'localhost')
  const [isLocalhostIframe, setIsLocalhostIframe] = useState(localStorage.getItem(isLocalhostIframeName) === 'true')

  const handleSetIsLocalhostIframe = ((val: boolean) => {
    setIsLocalhostIframe(val)
    localStorage.setItem(isLocalhostIframeName, val ? 'true' : '')
  })

  useEffect(() => {
    const {interpreterDomain, selectedStore, platformSymbol} = getPlatformInfo(shop)
    const iframeSrc = isLocalhostIframe ?
      `http://localhost:3001?hostname=${interpreterDomain}&s=${selectedStore}&p=${platformSymbol}&cid=${componenentConfigId}`
      : `https://${interpreterDomain}?s=${selectedStore}&p=${platformSymbol}&cid=${componenentConfigId}`

    // NOTE: page-width is a shopify specific class
    const iframeStringToSet = `<iframe src="${iframeSrc}" class="page-width" width="100%" height="362px" frameborder="0" scrolling="auto" style="margin: auto; display: block;"></iframe>`
    setIframeString(iframeStringToSet)
  }, [componenentConfigId, shop, isLocalhostIframe])

  return (
    <>
      <Snackbar
        open={copied}
        onClose={() => setCopied(false)}
        autoHideDuration={900}
        message="Copied to clipboard"
      />

      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle alignSelf='center'>Copy embed link</DialogTitle>
        <IconButton
          disableRipple
          sx={{
            m: 1,
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        {
          !isLocalhost ? '' :
            <Box sx={{
              position: 'absolute',
              right: 0,
              mr: 2,
              mt: 3,
            }}>
              <FormControlLabel
                label='localhost iframe'
                control={
                  <Checkbox
                    checked={isLocalhostIframe}
                    onChange={(e) => handleSetIsLocalhostIframe(e.target.checked)}
                  />
                }
              />
            </Box>
        }

        <DialogContent>
          <Grid container direction={'column'} spacing={2}>
            <Grid item alignSelf='center'>
              <Grid container direction={'column'} >
                <Grid item>
                Config options:
                </Grid>
                <Grid item alignSelf='center'><Link target="_blank" rel="noopener" href='https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attributes'>https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attributes</Link></Grid>
              </Grid>
            </Grid>

            <Grid item>
              <TextField
                id="embed_iframe"
                label="iFrame"
                multiline
                rows={4}
                value={iframeString}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIframeString(event.target.value)
                }}
                variant="filled"
                fullWidth
              />
            </Grid>

            <Grid item alignSelf='center'>
              <Button
                variant='contained'
                onClick={() => {
                  copyTextToClipboard(iframeString)
                  setCopied(true)
                  onClose()
                }}
              >
              Copy iFrame Code To Clipboard
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

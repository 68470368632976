import React, { FC } from 'react'
import { Box, FormControlLabel, useTheme, Typography, Switch, TextField, MenuItem, Button, Modal } from '@mui/material'
import { deepCloneJson } from 'src/utils/helpers'
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy.svg'
import useGenericContext from 'src/hooks/useGenericContext'

export type AttributionSurveyType = {
  containerType: 'GRID'
  internalName: string // '<shopName>-ATTRIBUTION_SURVEY'
  title: string
  contentType: 'ATTRIBUTION'
  componentType: 'ATTRIBUTION_SURVEY'
  createdAt: number
  updatedAt: number
  shop: string
  visibleInLibrary: true
  internalDescription: string
  class: 'HOOK'
  contentConfiguration: {
    type: 'ATTRIBUTION'
    value: {
      uiSettings: {
        subhead: string
      }
      apiSettings: {
        enabled: boolean
        sharingEnabled: ('Instagram' | 'Facebook' | 'Email')[]
      }
    }
  }
}

export const ExitAttributionUI: FC<{
  componentConfig: AttributionSurveyType
  setComponentConfig: (config: AttributionSurveyType) => void
}> = ({ componentConfig, setComponentConfig }) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { shop } = useGenericContext()
  const region = process.env.REACT_APP_DEPLOY_REGION

  const scriptContent = `<script>
  window.__sdx_checkoutAttributes = {};
  {%- for checkoutAttr in checkout.attributes -%}
    window.__sdx_checkoutAttributes["{{checkoutAttr[0]}}"] = "{{ checkoutAttr[1] }}";
  {% endfor %}
  // SDX tag init
  window.__sdx_region = '${region}';
  !function(){if(!Shopify)return;window.__sdx_project_id=Shopify.shop,window.__sdx_config_url="https://config.ur2inc.com/shop/"+Shopify.shop+".conf";var e=document.createElement("script");e.src="https://tag.ur2inc.com/sdx-${region === 'dev-use2' ? 'dev' : ''}${region === 'stage-use2' ? 'stage' : ''}${region === 'prod-use2' ? 'tag' : ''}.js",e.async=!0,e.onload=function(){window.sdxCapture.load(window.SdxConfig)},document.body.appendChild(e),window.__sdx_ocp=!0;let t=document.getElementsByTagName("HEAD")[0],s=document.createElement("link");s.rel="stylesheet",s.type="text/css",s.href="https://tag.ur2inc.com/survey-widget/index${region === 'dev-use2' ? '-dev' : ''}${region === '-stage-use2' ? 'stage' : ''}${region === 'prod-use2' ? '' : ''}.css",t.appendChild(s)}();
  </script>
  <script src="https://tag.ur2inc.com/survey-widget/index${region === 'dev-use2' ? '-dev' : ''}${region === 'stage-use2' ? '-stage' : ''}${region === 'prod-use2' ? '' : ''}.js"></script>`

  const copyScript = () => {
    navigator.clipboard.writeText(scriptContent)
  }

  const getToggleComponent = ({ channel, icon }) => {
    let newSharingEnabled = [...(componentConfig.contentConfiguration.value.apiSettings.sharingEnabled || [])]
    const enabled = newSharingEnabled.includes(channel)
    const onChange = () => {
      const newComponentConfig = deepCloneJson(componentConfig)
      if (enabled) newSharingEnabled = newSharingEnabled.filter((x) => x !== channel)
      else newSharingEnabled = [...newSharingEnabled, channel]
      newComponentConfig.contentConfiguration.value.apiSettings.sharingEnabled = newSharingEnabled
      setComponentConfig(newComponentConfig)
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
        onChange={onChange}
      >
        <FormControlLabel
          sx={{
            marginLeft: '0px',
            marginRight: '10px',
          }}
          label={<Typography sx={{
            color: !enabled ? theme.palette.brand.frenchBlue : 'inherit',
          }}>
            {icon}
          </Typography>}
          labelPlacement='start'
          control={
            <Switch
              checked={enabled}
              color='primary'
            />
          }
        />
      </Box>
    )
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            mb: 2,
          }}
        >
          Exit Attribution Settings
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <FormControlLabel
            sx={{
              marginLeft: '0px',
              marginRight: '10px',
            }}
            label={<Typography sx={{
              color: !componentConfig.contentConfiguration.value.apiSettings.enabled ? theme.palette.brand.urIndigo : 'inherit',
            }}>
              Off
            </Typography>}
            labelPlacement='start'
            control={
              <Switch
                style={{ color: !componentConfig.contentConfiguration.value.apiSettings.enabled ? theme.palette.brand.urIndigo : '' }}
                checked={componentConfig.contentConfiguration.value.apiSettings.enabled}
                onChange={() => {
                  const newComponentConfig = deepCloneJson(componentConfig)
                  newComponentConfig.contentConfiguration.value.apiSettings.enabled = !newComponentConfig.contentConfiguration.value.apiSettings.enabled
                  setComponentConfig(newComponentConfig)
                }}
                color='primary'
              />
            }
          />
          <Box
            sx={{
              marginTop: '8px',
            }}
          >
            On
          </Box>

        </Box>

        <TextField
          label='Title'
          sx={{
            flex: '1',
            marginRight: '12px',
            mb: 1,
            mr: 0,
          }}
          required
          size='small' margin='dense' type='string' variant='outlined'
          value={componentConfig.title}
          onChange={(e) => {
            setComponentConfig({
              ...componentConfig,
              title: e.target.value,
            })
          }}
        />
        <TextField
          label='Subhead'
          sx={{
            flex: '1',
            marginRight: '12px',
            mb: 1,
            mr: 0,
          }}
          required
          size='small' margin='dense' type='string' variant='outlined'
          value={componentConfig.contentConfiguration.value.uiSettings.subhead}
          onChange={(e) => {
            const newComponentConfig = deepCloneJson(componentConfig)
            newComponentConfig.contentConfiguration.value.uiSettings.subhead = e.target.value
            setComponentConfig(newComponentConfig)
          }}
        />

        {/*
      <Box sx={{fontWeight: 'bold', pb: 1}}>Enable social sharing</Box>

      <Box
        sx={{
          display: 'flex',
        }}
      >

        {getToggleComponent({channel: 'Facebook', icon: <FacebookIcon />})}
        {getToggleComponent({channel: 'Instagram', icon: <InstagramIcon />})}
        {getToggleComponent({channel: 'Email', icon: <MailOutlineIcon sx={{backgroundColor: 'black', color: 'white', borderRadius: '50%', p: .5, zoom: 1.8}} />})}

      </Box> */}

      </Box>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        aria-controls='menu-list-grow'
        aria-haspopup="true"
        style={{ textTransform: 'none', height: 40 }}
      >Embed code</Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: 540,
          backgroundColor: 'white',
          borderRadius: 4,
          padding: '16px',
        }}>
          <Box sx={{
            backgroundColor: '#eee',
            borderRadius: 1,
            height: '35px',
            padding: '0 8px',
            width: '100%',
            display: 'inline-block',
            lineHeight: '35px',
            fontWeight: '500',
          }}>
            Add here:
            {
              typeof shop === 'string' && shop.indexOf('.myshopify.com') !== -1 &&
              <a target='_blank' style={{
                marginLeft: '5px',
              }} href={`https://admin.shopify.com/store/${shop.replace('.myshopify.com', '')}/settings/checkout#additionalscripts`} rel="noreferrer">Additional scripts</a>
              ||
              <span style={{
                marginLeft: '5px',
              }}>Shopify Admin &gt; Settings &gt; Checkout &gt; Additional scripts</span>
            }
          </Box>
          <Box sx={{ position: 'relative' }}>
            <TextField
              multiline
              rows={10}
              sx={{
                width: '100%',
                marginTop: '16px',
              }}
              value={scriptContent}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={copyScript}
              aria-controls='menu-list-grow'
              aria-haspopup="true"
              sx={{
                textTransform: 'none',
                height: 40,
                position: 'absolute',
                top: '24px',
                right: '8px',
                opacity: 0.7,
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              Copy
              &nbsp;
              <CopyIcon
                style={{
                  fill: 'white',
                  width: '15px',
                }}
              ></CopyIcon>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

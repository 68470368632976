import { AxiosResponse } from 'axios'
import { axiosInstance } from 'src/utils/axios'
import { ecommerceServiceBaseUrl } from 'src/config'

interface CognitoUser {
    key?: string
    email?: string
    firstName?: string
    lastName?: string
    shops?: string[]
}

export type PostUserSync = () => Promise<AxiosResponse<CognitoUser> | undefined>

export const postUserSync: PostUserSync = async () => {
  try {
    const path = '/v2/users/sync'
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: ecommerceServiceBaseUrl,
      headers: headers,
    }

    return await axiosInstance.post<CognitoUser>(path, null, opts)
  } catch (error) {
    console.error('Error syncing user:', error)
    throw error
  }
}

import extractFirstTwoLettersOfWords from './extractFirstTwoLettersOfWords'

test('Extracts from full name', () => {
  expect(extractFirstTwoLettersOfWords('James wood')).toBe('JA')
})

test('Extract from single name', () => {
  expect(extractFirstTwoLettersOfWords('John')).toBe('JO')
})

test('Return empty string', () => {
  expect(extractFirstTwoLettersOfWords('')).toBe('')
})

import { PageConfig } from 'storefront-interpreter/src/config'
import { PrependShop } from '../types'
import { AxiosResponse } from 'axios'
import { logging } from 'src/utils/logging'
import { axiosInstance } from 'src/utils/axios'
import { componentServiceBaseUrl } from 'src/config'

export type SdxMetric =  {
  'label': string
  'value': {
    'value': number | undefined
    'metricType': 'COUNT' | 'PERCENT' | 'CURRENCY'
    'currency': string | undefined
  }
}
export type OptimalPage = {
  page: PageConfig
  componentMetrics: {
    componentId: string
    isFixed: boolean
    metrics: SdxMetric[]
  }[]
}

export type GetOptimalPage = (storefrontId: string) => Promise<AxiosResponse<OptimalPage> | undefined>
export type GetOptimalPageApi = PrependShop<GetOptimalPage>

export const getOptimalPageApi: GetOptimalPageApi = async (activeShop: string, storefrontId: string) => {
  try {
    let url = '/v1/pages/optimal'
    url += `?id=${storefrontId}`

    const response = await axiosInstance.get<OptimalPage>(url, { baseURL: componentServiceBaseUrl, headers: {
      shop: activeShop,
    } })
    return response

  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get optimal page api' } })
  }
}

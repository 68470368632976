import React, { FC } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/lab/Alert'
import { AlertType } from 'src/types/components'

// there is a memory leak here. when another alert is rendered successively, we still try to render the inital alert. You can see this in console when you create a new user
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

interface AlertSnackbarsProps {
  alertType: AlertType
  message: string
  clearAlert: () => void
}

export const AlertToast: FC<AlertSnackbarsProps> = ({ alertType, clearAlert, message}) => {
  const [open, setOpen] = React.useState(true)

  const handleClose = (e, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    clearAlert()
    setOpen(false)
  }

  return (
    <div>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertType}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

import { ProductItemFull, ProductPrimitive, RawImage } from 'src/components/ProductSelectorLarge'
import { ImageType } from 'src/types/interpretor'
import { Category, CategoryPrimitive } from './types'


export const convertImage = (rawImage?: RawImage): ImageType => {
  const fallback: string = (rawImage as any)?.['url'] || ''
  return {
    originalUrl: rawImage?.originalUrl || fallback,
    largeUrl: rawImage?.largeUrl || fallback,
    mediumUrl: rawImage?.mediumUrl || fallback,
    smallUrl: rawImage?.smallUrl || fallback,
    altText: rawImage?.altText || fallback,
  }
}

export const normalizeProducts = (x: ProductPrimitive): ProductItemFull => {
  return {
    // TODO: this should be unique, but not generated here, so external_id is used temporarily
    objId: x.externalId,
    productId: x.id,
    images: [x.featuredImage].map(convertImage),
    description: x.description || '',
    // TODO: remove || 0 when backend fix is deployed
    price: x.price || 0,
    comparePrice: x.comparePrice,
    currency: x.currencyCode || 'USD',
    title: x.title || '',
    variantExternalId: x.externalId,
    totalInventory: x.totalInventory,
  }
}

export const normalizeCategories = (x: CategoryPrimitive): Category => {
  return {
    id: x.id,
    externalId: x.externalId,
    title: x.title,
    cdpUrl: x.cdpUrl || '',
    handle: x.handle,
    description: x.description || '',
    featuredImage: x.featuredImage ? [x.featuredImage].map(convertImage) : [],
  }
}

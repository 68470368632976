import { render, screen } from '@testing-library/react'

import wrapComponent from 'src/tests/wrapComponent'
import CreateStorefront from './index'

const setup = () => render(wrapComponent(CreateStorefront, {}))

describe('CreateStorefront', () => {
  beforeEach(setup)

  it('renders screen to create storefront', async () => {
    await screen.findByText('Create Campaign Store')
    screen.getByText('Select Layout')
    screen.getByText('Single Product')
    screen.getByText('Promote single product from your store')
    screen.getByText('Category')
    screen.getByText('Promote products from within a specific category')
    screen.getByText('Campaign Image')
    screen.getByText('Category')

    screen.getByText('COMPONENT SELECTOR')
    screen.getByText('Campaign Image')
    screen.getByText('Category')

    screen.getAllByRole('tab', { name: 'New.svg New'})
    screen.getAllByRole('tab', { name: 'LibraryOutline.svg Library'})
    screen.getAllByRole('tab', { name: 'Hooks.svg Hooks'})
    screen.getAllByRole('tab', { name: 'Integrations.svg Integrations'})

    screen.getAllByRole('button', { name: 'Cancel'})
    screen.getAllByRole('textbox', { name: new RegExp('Internal Name')})
    screen.getAllByRole('textbox', { name: new RegExp('Description')})
    screen.getAllByRole('textbox', { name: new RegExp('Component Label')})
    screen.getAllByRole('button', { name: 'Manual Product Selection'})
    screen.getByTitle('Live preview')
  })
})

import React, {FC} from 'react'
import {Box, IconButton, TextField, MenuItem, Select, FormControlLabel, Switch} from '@mui/material'
import {arrayMove, List} from 'react-movable'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import {LineItemFieldEnum, ImageFitEnum, DisplayTypesEnum, DeepRequiredStorePrimitiveSettings } from './types'
import {ImageFitSelector} from './ImageFitSelector'
import { DisplayTypeSelector } from './DisplayTypeSelector'
import { ContainerConfig } from 'storefront-interpreter/src/api/components'
import Spacer from 'src/components/Spacer'
import { ContainerTypeSelector } from './ContainerTypeSelector'

enum SectionEnum {
  STOREFRONT = 'storefront',
  PRODUCT = 'product',
  CATEGORY = 'category',
}

export const ComponentSettingsUI: FC<{
  primitiveSettings: DeepRequiredStorePrimitiveSettings
  setPrimitiveSettings: (primitiveSettings: DeepRequiredStorePrimitiveSettings) => void
}> = ({primitiveSettings, setPrimitiveSettings}) => {
  const handleImageFitChange = (section: SectionEnum, imageFitValue: ImageFitEnum) => {
    const newSettings = { ...primitiveSettings }
    newSettings[section].uiSettings.imageFit = imageFitValue
    setPrimitiveSettings(newSettings)
  }

  const handleDisplayTypeChange = (section: SectionEnum, displayTypeValue: DisplayTypesEnum) => {
    const newSettings = { ...primitiveSettings }
    newSettings[section].displayType = displayTypeValue
    setPrimitiveSettings(newSettings)
  }

  const handleContainerTypeChange = (section: SectionEnum, containerTypeValue: ContainerConfig['containerType']) => {
    const newSettings = { ...primitiveSettings }
    newSettings[section].containerType = containerTypeValue
    setPrimitiveSettings(newSettings)
  }

  const handleShowImageSearchToggle = (shouldShow: boolean) => {
    const newSettings = { ...primitiveSettings }
    newSettings.product.uiSettings.showImageSearch = shouldShow
    setPrimitiveSettings(newSettings)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '18px',
          mb: 2,
        }}
      >
          Component Settings
      </Box>

      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '16px',
          mb: 2,
          mt: 2,
        }}
      >
          Product Components
      </Box>
      <FormControlLabel
        control={
          <Switch
            checked={primitiveSettings.product.uiSettings.showImageSearch}
            onChange={(event) => {
              handleShowImageSearchToggle(event.target.checked)
            }}
          />
        }
        label="Show Image Search"
        sx={{
          mb: 3,
          ml: '0px',
          borderRadius: '5px',
          width: '200px',
          outline: '1px solid lightgrey',

        }}
      />
      <ImageFitSelector
        label="Product image fit"
        imageFitValue={primitiveSettings.product.uiSettings.imageFit}
        onImageFitChange={(value) => handleImageFitChange(SectionEnum.PRODUCT, value)}
        imageFitDisplay={primitiveSettings.product.uiSettings.imageFit}
      />

      <Spacer space={2} />

      <DisplayTypeSelector
        label="Product display type"
        displayTypeValue={primitiveSettings.product.displayType}
        onDisplayTypeChange={(value) => handleDisplayTypeChange(SectionEnum.PRODUCT, value)}
      />

      <Spacer space={2} />

      <ContainerTypeSelector
        label="Product container type"
        containerTypeValue={primitiveSettings.product.containerType}
        onContainerTypeChange={(value) => handleContainerTypeChange(SectionEnum.PRODUCT, value)}
      />

      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '16px',
          mb: 2,
          mt: 2,
        }}
      >
          Category Components
      </Box>
      <ImageFitSelector
        label="Category image fit"
        imageFitValue={primitiveSettings.category.uiSettings.imageFit}
        onImageFitChange={(value) => handleImageFitChange(SectionEnum.CATEGORY, value)}
        imageFitDisplay={primitiveSettings.category.uiSettings.imageFit}
      />


      <Spacer space={2} />

      <DisplayTypeSelector
        label="Category display type"
        displayTypeValue={primitiveSettings.category.displayType}
        onDisplayTypeChange={(value) => handleDisplayTypeChange(SectionEnum.CATEGORY, value)}
      />

      <Spacer space={2} />

      <ContainerTypeSelector
        label="Category container type"
        containerTypeValue={primitiveSettings.category.containerType}
        onContainerTypeChange={(value) => handleContainerTypeChange(SectionEnum.CATEGORY, value)}
      />

      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '16px',
          mb: 2,
          mt: 2,
        }}
      >
          Storefront Components
      </Box>
      <ImageFitSelector
        label="Storefront image fit"
        imageFitValue={primitiveSettings.storefront.uiSettings.imageFit}
        onImageFitChange={(value) => handleImageFitChange(SectionEnum.STOREFRONT, value)}
        imageFitDisplay={primitiveSettings.storefront.uiSettings.imageFit}
      />


      <Spacer space={2} />

      <DisplayTypeSelector
        label="Storefront display type"
        displayTypeValue={primitiveSettings.storefront.displayType}
        onDisplayTypeChange={(value) => handleDisplayTypeChange(SectionEnum.STOREFRONT, value)}
      />

      <Spacer space={2} />

      <ContainerTypeSelector
        label="Storefront container type"
        containerTypeValue={primitiveSettings.storefront.containerType}
        onContainerTypeChange={(value) => handleContainerTypeChange(SectionEnum.STOREFRONT, value)}
      />
    </Box>
  )
}

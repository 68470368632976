import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2 } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShopCurrency } from '../types'
import { CurrencyCode } from 'src/utils/currencySymbolMap'

export type CampaignAggregateDayOfWeek = {
  currency: CurrencyCode
  monday: number
  tuesday: number
  wednesday: number
  thursday: number
  friday: number
  saturday: number
  sunday: number
}
export const getInitialCampaignAggregateDayOfWeek = (): CampaignAggregateDayOfWeek => ({
  currency: 'USD',
  monday: 0,
  tuesday: 0,
  wednesday: 0,
  thursday: 0,
  friday: 0,
  saturday: 0,
  sunday: 0,
})

export type GetCampaignAggregateDayOfWeek = (
  isBrand:boolean, startTimestamp: number, endTimestamp: number
) => Promise<AxiosResponse<CampaignAggregateDayOfWeek> | undefined>

export const getCampaignAggregateDayOfWeekApi: PrependShopCurrency<GetCampaignAggregateDayOfWeek> = async (
  activeShop, currencyCode,
  isBrand, startTimestamp, endTimestamp,
) => {
  try {
    const baseUrl = '/v1/analytics/campaign-aggregate-day-of-week'
    const params = new URLSearchParams()
    params.append('currency', currencyCode)
    params.append('startTimestamp', startTimestamp.toString())
    params.append('endTimestamp', endTimestamp.toString())
    if(isBrand) params.append('isBrand', '1')
    const url = `${baseUrl}?${params.toString()}`

    const cfg = {
      baseURL: analyticsServiceBaseUrl2,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<CampaignAggregateDayOfWeek>(url, cfg)
    return response
  }

  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

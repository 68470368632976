import { useRef, useState, memo } from 'react'
import type { FC } from 'react'

import {
  ListItemText,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  MenuList,
  SxProps,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FilterIcon } from 'src/assets/icons/Filter.svg'
import { Root, classes } from 'src/styles/DatePickerStyles'
import { Theme } from '@emotion/react'

const FilterSelector: FC<{
  sxStyle?: SxProps<Theme>
}> = ({ sxStyle }) => {
  const { t } = useTranslation()
  const moreRef = useRef(null)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  // handle close popup
  const handleMenuClose = (): void => {
    setIsMenuOpen(false)
  }

  return (
    <Root className={classes.root}>
      <Tooltip title={t('Select a filter')}>
        <Button
          data-testid="datePickerButton"
          variant="contained"
          color="secondary"
          aria-haspopup="true"
          style={{ textTransform: 'none', height: 40 }}
          sx={sxStyle}
        >
          <FilterIcon style={{ marginRight: 12 }} />
          Filter
        </Button>
      </Tooltip>
      {/* Menu */}
      <Menu
        anchorEl={moreRef.current}
        onClose={handleMenuClose}
        open={isMenuOpen}
        PaperProps={{ className: classes.menu }}
      >
        <Root className={classes.root}>
          <MenuList className={classes.menu}>
            <MenuItem>
              <ListItemText primary={'Filter1'} />
            </MenuItem>
          </MenuList>
        </Root>
      </Menu>
    </Root>
  )
}

export default memo(FilterSelector)

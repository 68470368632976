import React from 'react'
import { render, screen } from '@testing-library/react'
import '@testing-library/dom'
import CardError from './CardError'

const setup = () => render(<CardError />)


describe('Error Card', () => {
  test('Renders error image', async () => {
    setup()
    expect(screen.getByTestId('questionImage')).toBeInTheDocument()
  })
})

import { YoutubePrimitiveContent, MediaPrimitiveContent, CategoryPrimitiveContent, ContainerConfig, HtmlPrimitiveContent, KlaviyoPrimitiveContent, ProductField, ProductPrimitiveContent, StorefrontPagePrimitiveContent } from 'storefront-interpreter/src/api/components'

export const getMinifiedFields = (): ProductField[] => ['image']
export const getExtendedFields = (): ProductField[] => ['image', 'title', 'price']
export const getCategoryFields = (): ProductField[] => ['image', 'title', 'description']
export const getFullFields = (): ProductField[] => ['image', 'title', 'description', 'price']

export type COMPONENT_CLASS = 'HOOK' | 'INTEGRATION' | 'SAVED'

export type ComponentTypeResponse = ContainerConfig & {
  componentId: string
  createdAt: number
  updatedAt: number
  class?: COMPONENT_CLASS
  mlOrdering?: boolean
}

export type ComponentTypeResponseML = ContainerConfig & {
  componentId: string
  createdAt: number
  updatedAt: number
  class?: COMPONENT_CLASS
  mlOrdering?: boolean
}

export const productPrimitiveBase: ProductPrimitiveContent = {
  type: 'PRODUCT_PRIMITIVE',
  value: {
    uiSettings: {
      fields: [],
    },
    apiSettings: {
      productIds: [],
    },
  },
}

export const categoryPrimitiveBase: CategoryPrimitiveContent = {
  type: 'CATEGORY_PRIMITIVE',
  value: {
    uiSettings: {
      fields: [],
    },
    apiSettings: {
      categoryIds: [],
    },
  },
}

export const storefrontPagePrimitiveBase: StorefrontPagePrimitiveContent = {
  type: 'STOREFRONT_PAGE_PRIMITIVE',
  value: {
    uiSettings: {
      fields: [],
    },
    apiSettings: {
      pageIds: [],
    },
  },
}


export const htmlPrimitiveBase: HtmlPrimitiveContent = {
  type: 'HTML_PRIMITIVE',
  value: {
    uiSettings: {},
    apiSettings: {
      htmls: [],
    },
  },
}

export const klaviyoPrimitiveBase: KlaviyoPrimitiveContent = {
  type: 'KLAVIYO_PRIMITIVE',
  value: {
    uiSettings: {
      text: '',
      buttonText: '',
    },
    apiSettings: {
      apiKey: '',
      lists: [],
    },
  },
}

export const mediaPrimitiveBase: MediaPrimitiveContent = {
  type: 'MEDIA_PRIMITIVE',
  value: {
    uiSettings: {
      headline: '',
      subtitle: '',
      link: '',
    },
    apiSettings: {
      sources: [],
      productIds: [],
    },
  },
}

export const youtubePrimitiveBase: YoutubePrimitiveContent = {
  type: 'YOUTUBE_PRIMITIVE',
  value: {
    uiSettings: {},
    apiSettings: {
      htmls: [],
    },
  },
}

export type ComponentsDefaultPagesRequest = {
  layout: string
  ComponentIDS: string[]
  mlOrderingUsed: boolean[]
}

import React, { Fragment, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Card, CardHeader  } from '@mui/material'

import type { FC } from 'react'
import Icon from 'src/components/Icon/Icon'

import CardError from 'src/components/CardError/CardError'
import CardEmpty from 'src/components/CardEmpty/CardEmpty'
const PREFIX = 'UrBaseCard'

const classes = {
  header: `${PREFIX}-header`,
  minimizeBtn: `${PREFIX}-minimizeBtn`,
  minimize: `${PREFIX}-minimize`,
  avatar: `${PREFIX}-avatar`,
  title: `${PREFIX}-title`,
  root: `${PREFIX}-root`,
}

const StyledCard = styled(Card)((
  {
    theme,
  },
) => ({
  [`& .${classes.header}`]: {
    lineHeight: '39px',
    padding: '24px 16px',
  },

  [`& .${classes.minimizeBtn}`]: {
    position: 'absolute',
    top: 16,
    right: 19,
    width: '36px',
    height: '40px',
    background: '#D9F4F5',
    borderRadius: '6px',
    border: 0,
    '& svg': {
      width: 12,
    },
  },

  [`& .${classes.minimize}`]: {
    position: 'relative',
    maxHeight: '71px',
    minHeight: '71px',
    overflow: 'hidden',
  },

  [`& .${classes.title}`]: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '1px',
    color: '#546E7A',
  },

  [`& .${classes.root}`]: {
    margin: '0 15px',
  },

  [`& .${classes.avatar}`]: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

interface UrBaseCardProps {
  className?: string
  mainIcon: React.ReactNode
  action?: React.ReactNode
  title: string | React.ReactNode
  subHeader?: React.ReactNode
  content: React.ReactNode
  footer?: React.ReactNode
  error?: boolean
  empty?: boolean
  minimizeBtn?: boolean
  emptyScreen?: React.ReactNode
}

const UrBaseCard: FC<UrBaseCardProps> = ({
  className,
  mainIcon,
  action,
  title,
  subHeader,
  content,
  footer,
  error,
  empty,
  minimizeBtn,
  emptyScreen,
  ...rest
}) => {

  const isError = !!error
  const [minimize, setMinimize] = useState(false)

  const minimizeCard = (() => {
    setMinimize(!minimize)
  })

  return (
    <StyledCard className={minimize ? classes.minimize : className} style={{ position: 'relative' }} {...rest}>
      <CardHeader
        classes={{
          title: classes.title,
          avatar: classes.avatar,
        }}
        className={classes.header}
        action={action}
        title={title}
        avatar={mainIcon}
      />
      <div className={classes.root}>
        {
          minimizeBtn && <button onClick={minimizeCard} className={classes.minimizeBtn}>
            <Icon
              name={!minimize ? 'eye' : 'eye-close'}
              root={true}
              color={'#09949D'}
            />
          </button>
        }
        {
          isError ? <CardError /> :
            empty ? ( emptyScreen ? emptyScreen : <CardEmpty /> ) :
              minimize ? <div></div> :
                <Fragment>
                  <Fragment>{subHeader}</Fragment>
                  <Fragment>{content}</Fragment>
                  <Fragment>{footer}</Fragment>
                </Fragment>
        }
      </div>
    </StyledCard>
  )
}

export default UrBaseCard

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, useTheme } from '@mui/material'
import React, { FC } from 'react'
import { ComponentTypeResponse, getExtendedFields, getMinifiedFields } from 'src/views/Components/types'
import { Category } from 'src/contexts/types'
import { CategoryMultiSelect } from 'src/components/CategoryMultiSelect'
import { ProductSelectionInput } from 'src/components/ProductSelectionInput'
import ProductSelectorLarge, { ProductItemFull } from 'src/components/ProductSelectorLarge'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  error?: boolean | undefined
  label?: string | undefined
  relatedCategory?: Category | undefined
  currentSelectedCategoryMap: Record<string, Category>
  currentSelectedProductMap: Record<string, ProductItemFull>
}

const SimilarProductsCommonInput: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  error,
  label,
  relatedCategory,
  currentSelectedCategoryMap,
  currentSelectedProductMap,
}) => {
  const theme = useTheme()
  if (componentConfig.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return <span />
  return (
    <div>
      <Box style={{
        display: 'flex',
        gap: 4,
        marginBottom: 2,
      }}>
        <FormControl sx={{ marginTop: 1, width: '100%' }}>
          <InputLabel id="image-fit-label">Image fit</InputLabel>
          <Select
            size='small' margin='dense'
            labelId="image-fit-label"
            id="image-fit"
            value={componentConfig.contentConfiguration.value.uiSettings.imageFit || 'contain'} // 'cover' | 'contain' | 'fill'
            label="Image fit"
            onChange={(event: SelectChangeEvent) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return

              newComponent.contentConfiguration.value.uiSettings.imageFit = event.target.value as 'contain' | 'cover' | 'fill'
              setComponentConfig(newComponent)
            }}
          >
            <MenuItem value='contain'>Contain</MenuItem>
            <MenuItem value='cover'>Cover</MenuItem>
            <MenuItem value='fill'>Fill</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id='similar_products_type'
          style={{
            width: '100%',
          }}
          select
          value={componentConfig.contentConfiguration.value.uiSettings.fields.length > 1 ? 'EXTENDED' : 'MINIFIED'}
          size='small' margin='dense' type='string' variant='outlined'
          label="Display Type"
          onChange={(e) => {
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
            newComponent.contentConfiguration.value.uiSettings.fields =
              e.target.value === 'MINIFIED' ? getMinifiedFields() : getExtendedFields()
            setComponentConfig(newComponent)
          }}
        >
          <MenuItem key={'MINIFIED'} value={'MINIFIED'}>Minified</MenuItem>
          <MenuItem key={'EXTENDED'} value={'EXTENDED'}>Extended</MenuItem>
        </TextField>

        <TextField
          id='similar_product_view'
          style={{
            width: '100%',
          }}
          select
          value={componentConfig.containerType}
          size='small' margin='dense' type='string' variant='outlined'
          label="Container Type"
          onChange={(e) => {
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
            newComponent.containerType = e.target.value as any
            setComponentConfig(newComponent)
          }}
        >
          <MenuItem key={'GRID'} value={'GRID'}>Grid</MenuItem>
          <MenuItem key={'CAROUSEL'} value={'CAROUSEL'}>Carousel</MenuItem>
        </TextField>

        <TextField
          id='similar_product_promotion_filter'
          style={{
            width: '100%',
          }}
          select
          value={componentConfig.contentConfiguration.value.apiSettings.filterByPromotion || 'ALL'}
          size='small' margin='dense' type='string' variant='outlined'
          label="Filter by promotion"
          onChange={(e) => {
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
            newComponent.contentConfiguration.value.apiSettings.filterByPromotion = e.target.value as any
            setComponentConfig(newComponent)
          }}
        >
          <MenuItem key={'ALL'} value={'ALL'}>All Products</MenuItem>
          <MenuItem key={'ON_SALE'} value={'ON_SALE'}>Products on Promotion</MenuItem>
          <MenuItem key={'FULL_PRICE'} value={'FULL_PRICE'}>Full Price Products</MenuItem>
        </TextField>
      </Box>
      <Box style={{
        display: 'flex',
        width: '100%',
        marginBottom: '8px',
      }}>
        <div style={{
          width: '100%',
          marginBottom: '8px',
        }}>
          <div style={{ paddingBottom: theme.spacing(1) }}>Category Filters</div>
          <CategoryMultiSelect
            categoryIds={componentConfig.contentConfiguration.value.apiSettings.relatedCategoryIds ? componentConfig.contentConfiguration.value.apiSettings.relatedCategoryIds : []}
            currentSelectedCategoryMap={currentSelectedCategoryMap}
            setCategoryIds={(value) => {
              if (value) {
                const newComponent: ComponentTypeResponse = { ...componentConfig }
                if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
                newComponent.contentConfiguration.value.apiSettings.relatedCategoryIds = value
                setComponentConfig(newComponent)
              }
            }}
            backgroundGradient='none'
            openWhenNotSelected={false}
            label='Category For Filtering'
          />
          <div style={{ paddingBottom: theme.spacing(1) }}>Products To Suppress</div>
          <ProductSelectionInput
            productIds={componentConfig?.contentConfiguration?.value?.apiSettings?.blacklistedProductIds || []}
            setProductIds={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
              newComponent.contentConfiguration.value.apiSettings.blacklistedProductIds = value
              setComponentConfig(newComponent)
            }}
            currentSelectedProductMap={currentSelectedProductMap}
            backgroundGradient='none'
            openWhenNotSelected={false}
            label='Suppressed Product'
          />
        </div>
      </Box>
    </div>
  )
}

type SimilarProductsInputProps = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  currentSelectedProductMap: Record<string, ProductItemFull>
  currentSelectedCategoryMap: Record<string, Category>
  productSelectorLabel?: string
  error?: boolean | undefined
  label?: string | undefined
  relatedCategory?: Category | undefined
  isRenderedInStoreFrontComposer?: boolean
}

export const SimilarProductsInput: FC<SimilarProductsInputProps> = ({
  componentConfig,
  setComponentConfig,
  currentSelectedProductMap,
  currentSelectedCategoryMap,
  productSelectorLabel = 'Comparison Product',
  error,
  label,
  relatedCategory,
  isRenderedInStoreFrontComposer = false,
}) => {
  if (componentConfig.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return <span />
  return (
    <div>
      {!isRenderedInStoreFrontComposer && <ProductSelectorLarge
        openWhenNotSelected={false}
        onSelect={(item) => {
          if (!item) return
          const newComponent: ComponentTypeResponse = { ...componentConfig }
          if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
          newComponent.contentConfiguration.value.apiSettings.productIds = [item.productId]
          setComponentConfig(newComponent)
        }}
        selected={currentSelectedProductMap[componentConfig.contentConfiguration.value.apiSettings.productIds[0]]}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        previouslySelected={componentConfig?.contentConfiguration?.value.apiSettings?.productIds}
        label={productSelectorLabel}
      />}
      <SimilarProductsCommonInput
        componentConfig={componentConfig}
        setComponentConfig={setComponentConfig}
        relatedCategory={relatedCategory}
        error={error}
        label={label}
        currentSelectedCategoryMap={currentSelectedCategoryMap}
        currentSelectedProductMap={currentSelectedProductMap}
      />
    </div>
  )
}


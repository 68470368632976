// https://github.com/mui/material-ui-pickers/issues/1852

// we HAVE TO UPDATE mui from v4 to at least v5

import { MouseEvent as ReactMouseEvent } from 'react'

export const hasParentWithClass = <T extends HTMLElement>(el: T, classname: string) => {
  let next: T | null = el.parentElement as T

  while (next) {
    if (next.classList && next.classList.contains(classname)) {
      return true
    }
    next = next.parentElement as T | null
  }

  return false
}

export function handlePickersModalClick<T extends HTMLElement>(e: ReactMouseEvent<T, MouseEvent>) {
  if (!hasParentWithClass(e.target as T, 'MuiPickersPopper-paper')) {
    if (
      document.activeElement &&
      document.activeElement.classList.contains('MuiPickersPopper-paper')
    ) {
      (document.activeElement as T).blur()
    }
  }
}

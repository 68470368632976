import { ReactComponent as HtmlIcon } from 'src/assets/icons/Html.svg'
// import { ReactComponent as KlaviyoIcon } from 'src/assets/icons/Klaviyo.svg'
import { ReactComponent as KlaviyoLogoIcon } from 'src/assets/icons/KlaviyoLogo.svg'
import { ReactComponent as AlternatesInCategoryIcon } from 'src/assets/icons/AlternatesInCategory.svg'
import { ReactComponent as AllPublishedCampaignsIcon } from 'src/assets/icons/AllPublishedCampaigns.svg'
import { ReactComponent as AllProductsInCategoryIcon } from 'src/assets/icons/AllProductsInCategory.svg'
import { ReactComponent as ManualProductSelectionIcon } from 'src/assets/icons/ManualProductSelection.svg'
import {ReactComponent as ReviewsIcon} from 'src/assets/icons/ReviewsIcon.svg'
// import { ReactComponent as ManualCategoryNavigationIcon } from 'src/assets/icons/ManualCategoryNavigation.svg'
import { ComponentType } from 'storefront-interpreter/src/api/components'
import { ReactComponent as PlaceholderIcon } from 'src/assets/icons/IntegrationsIcon.svg'

import { Theme } from '@mui/material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

export const getComponentIcon = (componentType: ComponentType, theme: Theme): ReactJSXElement => {
  // HOOKS
  const b = theme.palette.brand
  if (componentType === 'SIMILAR_PRODUCTS') return <AlternatesInCategoryIcon style={{color: b.urGreen}} />
  if (componentType === 'ALTERNATES_IN_CATEGORY') return <AlternatesInCategoryIcon style={{color: b.urGreen}} />
  if (componentType === 'ALL_PUBLISHED_CAMPAIGNS') return <AllPublishedCampaignsIcon style={{color: b.urGreen}} />
  // LIBRARY
  if (componentType === 'ALL_PRODUCTS_IN_CATEGORY') return <AllProductsInCategoryIcon style={{color: b.urIndigo}}/>
  if (componentType === 'MANUAL_PRODUCT_SELECTION') return <ManualProductSelectionIcon style={{color: b.urIndigo}} />
  if (componentType === 'MANUAL_CATEGORY_NAVIGATION') return <ManualProductSelectionIcon style={{color: b.urOrange}} />
  if (componentType === 'HTML') return <HtmlIcon style={{color: b.urIndigo}} />
  // INTEGRATIONS
  if (componentType === 'EMAIL_CAPTURE') return <KlaviyoLogoIcon style={{color: b.urOrange}} />
  if (componentType === 'REVIEWS') return <ReviewsIcon style={{color: b.urOrange}} />

  // console.warn('Missing icon for ' + componentType)
  return <PlaceholderIcon />
}

import React, { FC, ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import {   Box, Modal } from '@mui/material'


const PREFIX = 'StorefrontModal'

const classes = {
  linkModal: `${PREFIX}-linkModal`,
}

const StyledModal = styled(Modal)((
  {
    theme,
  },
) => ({
  [`& .${classes.linkModal}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    maxWidth: 460,
    width: '100%',
    padding: '24px',
    borderRadius: '4px',
  },
}))

export type StorefrontModalProps = {
  open: boolean
  onClose: () => void
  children: ReactElement
}
export const StorefrontModal: FC<StorefrontModalProps> = ({
  open,
  onClose,
  children,
}) => {


  return (
    <StyledModal
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <Box className={classes.linkModal}>
        {children}
      </Box>
    </StyledModal>
  )
}

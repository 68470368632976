import { Ad, PrependShop } from 'src/contexts/types'
import { axiosInstance } from 'src/utils/axios'
import { socialServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { AxiosResponse } from 'src/types/axios'

export type GetAd = (accountId: string, adId: string) => Promise<AxiosResponse<Ad> | undefined>
export type GetAdApi = PrependShop<GetAd>

export const getAdApi: GetAdApi = async (activeShop: string, accountId: string, adId: string) => {
  try {
    const url = `/v2/meta-ads/${accountId}/ads/${adId}`

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      shop: activeShop,
    }

    const response = await axiosInstance.get<Ad>(url, {
      baseURL: socialServiceBaseUrl,
      headers,
    })

    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'get ad' } })
  }
}

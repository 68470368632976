import { Fragment, useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import {matchPath, useHistory, useLocation } from 'react-router-dom'
import { Box, Drawer, List,   Divider, IconButton, Backdrop, ListItem, MenuItem, Button, TextField, Collapse, Tooltip } from '@mui/material'
// ICONS
// import { ReactComponent as UserSettingsIcon } from 'src/assets/icons/UserSettingsIcon.svg'
import { ReactComponent as FullLogoSvg } from 'src/assets/icons/Full_logo.svg'
import { ReactComponent as SdxMenuClosed } from 'src/assets/icons/SdxmenuClosed.svg'
import { ReactComponent as SdxMenuOpen } from 'src/assets/icons/SdxmenuOpen.svg'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Icon from 'src/components/Icon/Icon'
import LanguageIcon from '@mui/icons-material/Language'
import useAuth from 'src/hooks/useAuth'
import {languageStorageKey, languageKey, languageOptions} from 'src/i18n'
import extractFirstTwoLettersOfWords from 'src/utils/extractFirstTwoLettersOfWords'
import TextIcon from 'src/components/TextIcon/TextIcon'
import { extractStoreNameFromUrl } from 'src/utils/storeName'
import { NavbarRoute, getNavbarRoutes, openInNewTab } from 'src/routes'
import useGenericContext from 'src/hooks/useGenericContext'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const navbarIconSize = 60
export const navbarExtendedWidth = 320

export const getInitialsInCircle = (initials: string) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: theme => theme.palette.brand.blueGrey,
        color: theme => theme.palette.text.white,
        width: 30,
        height: 30,
        fontWeight: 'bold',
        fontSize: 16,
      }}
    >
      {initials}
    </Box>
  )
}

const NavBar = (): JSX.Element => {
  // const {t} = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const {user, stores, selectedStore, setStore, logout} = useAuth()
  const [navbarRoutes, setNavbarRoutes] = useState<NavbarRoute[]>([])
  const flag = useFlags()
  const {merchantDetails} = useGenericContext()

  const [isNavbarExtended, setIsNavbarExtended] = useState(false)
  const handleSetIsNavbarExtended = (value?: boolean) => {
    const newValue = value !== undefined ? value : !isNavbarExtended
    if (!newValue) setUncollapsedNavItems({}) // collapse all menus
    setIsNavbarExtended(newValue)
  }

  useEffect(() => {
    const items = getNavbarRoutes(flag, 'standalone')
    setNavbarRoutes(items)
  }, [flag])

  let topNavbarItems: NavbarRoute[] = [
    {
      id: 'select_store',
      getIcon: (): ReactNode => {
        const initials = extractFirstTwoLettersOfWords(selectedStore)
        return (
          <TextIcon content={initials} width="35px" padding="3px" />
        )
      },
      getContent: (): ReactNode => {
        return (
          <Box
            sx={{
              pl: 1, mb: -1,
              width: '240px',
            }}
          >
            <TextField
              select
              label='Select Store'

              value={selectedStore}
              onChange={(e) => setStore(e.target.value as string)}

              variant='standard' size='small' margin='dense'
              fullWidth
            >
              {stores.map(store => (
                <MenuItem value={store} key={store}>{extractStoreNameFromUrl(store)}</MenuItem>
              ))}
            </TextField>
          </Box>
        )
      },
      title: selectedStore,
      onClickExtendNavbar: true,

      children: [],
    },

    ...navbarRoutes,
  ]
  topNavbarItems = topNavbarItems.filter(x => x.id === 'home' || x.id === 'select_store' || ![undefined, 'NONE'].includes(merchantDetails?.plan))

  const bottomNavbarItems: NavbarRoute[] = [
    {id: 'divider', title: ''},

    {
      id: 'user',
      title: user.name,
      getIcon: (): ReactNode => getInitialsInCircle(user.name[0]),
      getContent: (): ReactNode => (
        <Box
          sx={{
            borderRadius: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            pl: 1,
            cursor: 'pointer',
          }}
          onClick={() => setUncollapsedNavItems(x => ({...x, user: !x.user}))}
        >
          {/* TODO: make this part of the item */}
          <Button sx={{
            position: 'absolute',
            right: '50%',
            transform: 'translate(50%, 0)',
            top: 0,
          }}> {uncollapsedNavItems.user ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Button>

          <Box sx={{fontWeight: 500}}>{user.name}</Box>
          <Box>{user.email}</Box>

        </Box>
      ),
      onClickExtendNavbar: true,
      children: [
        {id: 'divider', title: ''},

        {
          id: 'select_language',
          title: 'Select Language',
          getIcon: (): ReactNode => <LanguageIcon />,
          getContent: (): ReactNode => {
            return (
              <Box sx={{pl: 1, width: '240px'}}>
                <TextField
                  select
                  label='Select Language'

                  value={languageKey}
                  onChange={(e) => {
                    const newLanguageKey = e.target.value as string
                    if (newLanguageKey !== languageKey) {
                      localStorage.setItem(languageStorageKey, newLanguageKey)
                      ;(window.location as Location).reload()
                    }
                  }}

                  variant='standard' size='small' margin='dense'
                  fullWidth
                >
                  {languageOptions.map(option => (
                    <MenuItem value={option.languageKey} key={option.languageKey}>
                      <Box sx={{
                        width: '100%',

                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                        {option.language}
                        <Box sx={{height: '16px'}}>
                          <Icon name={languageOptions.find(x => x.languageKey === option.languageKey)?.languageFlag || ''} root />
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )},
        },
        {id: 'divider', title: ''},
        {
          id: 'help_center',
          title: 'Help Center',
          href: 'https://support.simplicitydx.com',
        },
        {
          id: 'status_page',
          title: 'Status Page',
          href: 'https://twitter.com/simplicitydx',
        },
        {
          id: 'terms_and_privacy',
          title: 'Terms & Privacy',
          href: 'https://www.simplicitydx.com/privacy-policy',
        },

        {id: 'divider', title: ''},

        {
          id: 'logout',
          title: 'Logout',
          onClick: logout,
        },
      ],
    },
  ]

  const drawerWidth = isNavbarExtended ? navbarExtendedWidth : navbarIconSize

  const [uncollapsedNavItems, setUncollapsedNavItems] = useState<{[id: string]: boolean}>({})

  const renderNavbarItem = (navbarItem: NavbarRoute, i, depth = 0) => {
    if (navbarItem.id === 'divider') return <Divider key={i} />

    const isExternalLink = navbarItem.href?.startsWith('https')

    const handleOnClick = (e) => {
      // NOTE: this is needed to not trigger drawer onClick
      e.stopPropagation()
      // NOTE: this is needed to prevent anchor from navigating but keep the browser url preview
      e.preventDefault()

      navbarItem.onClick?.()

      if (navbarItem.children) {
        setUncollapsedNavItems({
          ...uncollapsedNavItems,
          [navbarItem.id]: !uncollapsedNavItems[navbarItem.id],
        })
      }

      if (navbarItem.href) {
        handleSetIsNavbarExtended(false)

        if (isExternalLink) openInNewTab(navbarItem.href)
        else history.push(navbarItem.href)
      }
    }
    const getNavItemBackground = theme => {
      const isCurrentRoute = matchPath(location.pathname, {path: navbarItem.href, exact: false})
      return isCurrentRoute ? theme.palette.background.dark : undefined
    }

    return (
      <Fragment key={navbarItem.id}>
        <ListItem
          sx={{
            backgroundColor: getNavItemBackground,
            width: '100%',
            display: 'flex',
            p: 0,
          }}
        >
          <Box
            sx={{
              width: navbarIconSize,
              height: navbarIconSize,
            }}
          >
            {navbarItem.getIcon &&
              <Tooltip
                title={isNavbarExtended ? '': navbarItem.title}
                placement='right'
              >
                <Button
                  size='small'
                  sx={{
                    color: '#546D78',
                    borderRadius: 0,

                    // NOTE: for some reason mui button size only works with setting min and max
                    minWidth: navbarIconSize,
                    maxWidth: navbarIconSize,
                    minHeight: navbarIconSize,
                    maxHeight: navbarIconSize,
                  }}
                  onClick={(e) => {
                    if (!navbarItem.getIcon) return
                    navbarItem.onClickExtendNavbar && handleSetIsNavbarExtended(true)
                    handleOnClick(e)
                  }}
                >
                  {navbarItem.getIcon()}
                </Button>
              </Tooltip>
            }
          </Box>

          <Box sx={{
            flex: 1,
            height: navbarIconSize,
            alignContent: 'center',
          }}>
            {navbarItem.getContent ? navbarItem.getContent()
              :
              <Button
                {...(isExternalLink && {
                  component: 'a',
                  rel: 'noreferrer',
                  target: '_blank',
                  href: navbarItem.href,
                })}
                onClick={handleOnClick}
                fullWidth
                sx={{
                  borderRadius: 0,
                  height: '100%',
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  pl: 1,
                  color: 'unset',
                  fontWeight: 'normal',
                }}
              >
                {navbarItem.title}
                {isExternalLink &&
                  <Box sx={{
                    right: 0,
                    position: 'absolute',
                    color: theme => theme.palette.brand.blueGrey,
                    px: 2,
                    scale: '0.75',
                  }}>
                    <OpenInNewIcon />
                  </Box>}
              </Button>
            }
          </Box>
        </ListItem>
        <Collapse in={uncollapsedNavItems[navbarItem.id] || false}>
          {navbarItem.children?.map(((item, j) => renderNavbarItem(item, j, depth + 1)))}
        </Collapse>
      </Fragment>
    )
  }

  return (
    <Box>
      <Drawer
        onClick={() => handleSetIsNavbarExtended(true)}
        variant='persistent'
        open
        sx={{
          display: 'flex',

          flexShrink: 0,
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            transition: 'width 0.3s',

            overflowX: 'hidden',
          },
        }}
      >
        <Box sx={{
          width: navbarExtendedWidth,

          height: '60px',
          position: 'relative',

        }}>
          {/* Logo */}
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '60px',
              width: '120px',
              ml: 2,

              opacity: isNavbarExtended ? 1 : 0,
              transition: 'opacity 0.5s ease',
            }}
          >
            <FullLogoSvg />
          </Box>
        </Box>

        {/* Toggle overlay */}
        <Box
          sx={{
            height: navbarIconSize,
            width: '100%',
            position: 'absolute',
            cursor: 'pointer',
            justifyContent: 'right',
            display: 'flex',
          }}
          onClick={(e) => {
            e.stopPropagation()
            handleSetIsNavbarExtended()
          }}
        >
          <Box sx={{
            height: navbarIconSize,
            width: navbarIconSize,
            // center horizontally and vertically
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(to right, transparent, white)',
          }}>
            {isNavbarExtended ? <SdxMenuOpen /> : <SdxMenuClosed />}
          </Box>
        </Box>

        <Divider />

        {/* Top list */}
        <List sx={{py: 0, width: navbarExtendedWidth}}>
          {topNavbarItems.map((x, i) => renderNavbarItem(x, i))}
        </List>

        {/* Push bottom list to bottom */}
        <Box style={{flexGrow: 1}}></Box>

        {/* Bottom list */}
        <List sx={{py: 0, width: navbarExtendedWidth}}>
          {bottomNavbarItems.map((x, i) => renderNavbarItem(x, i))}
        </List>
      </Drawer>

      {isNavbarExtended && (
        <Backdrop
          open={isNavbarExtended}
          sx={{zIndex: theme => theme.zIndex.drawer - 1}}
          onClick={() => handleSetIsNavbarExtended()}
        />
      )}
    </Box>
  )
}

export default NavBar

import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import type { FC  } from 'react'

import {   Typography } from '@mui/material'
import { DataTrend } from 'src/types/components'

const PREFIX = 'ColoredBackgroundText'

const classes = {
  greenBackground: `${PREFIX}-greenBackground`,
  pinkBackground: `${PREFIX}-pinkBackground`,
}

const Root = styled('div')({
  [`&.${classes.greenBackground}`]: {
    backgroundColor: '#D9FFF6',
    color: '#00CA99',
    alignSelf: 'center',
    padding: '3px',
  },
  [`&.${classes.pinkBackground}`]: {
    backgroundColor: '#FFE2EE',
    color: '#F60067',
    alignSelf: 'center',
    padding: '3px',
  },
})

interface ColoredBackgroundTextProps {
  direction: DataTrend
  text: string
}

const ColoredBackgroundText: FC<ColoredBackgroundTextProps> = ({ direction, text }) => {

  return (
    <Root className={clsx({[classes.greenBackground]: direction === 'positive', [classes.pinkBackground]: direction === 'negative'  })}>
      <Typography variant="body1">{text}</Typography>
    </Root>
  )
}

export default ColoredBackgroundText

import { Box, CardMedia, Collapse, styled } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { formatDataValue } from 'src/utils/analyticsHelpers'
import { currencySymbolMap } from 'src/utils/currencySymbolMap'
import { gifToCanvas } from 'src/utils/gifToCanvas'
import useGenericContext from 'src/hooks/useGenericContext'
import { LatestOrder2 } from 'src/contexts/api/getLatestOrders'
import sound from './../assets/audio/notification.mp3'
import Icon from './Icon/Icon'
import moment from 'moment'
import { analyticsHomeRefreshMillis } from 'src/config'

const AnimateColorBox = styled(Box)((
  {
    theme,
  },
) => ({
  [`&.animate-color`]: {
    transition: 'background-color 2s ease-in',
  },
}))
interface LatestOrdersProps {
  animation: boolean
  notification: boolean
}

const maxVisibleOrders = 15
let lastOrderTimestamp = 0

export const LatestOrders: FC<LatestOrdersProps> = ({ animation, notification }) => {
  const { homeCustomDate, getLatestOrders2, isBrand } = useGenericContext()
  const [items, setItems] = useState<LatestOrder2[]>([])
  const [uncollapsed, setUncollapsed] = useState<{ [key: string]: boolean }>({})
  const audio = new Audio(sound)

  const playSound = (() => {
    if (notification) {
      audio.play()
    }
  })

  useEffect(() => {
    const callback = () => {
      if (!homeCustomDate[0] && !homeCustomDate[1]) return

      getLatestOrders2(isBrand, homeCustomDate[0], homeCustomDate[1], maxVisibleOrders).then(result => {
        if (!result) return
        setItems(result.data)
      })
    }
    callback()
    const interval = setInterval(callback, analyticsHomeRefreshMillis)
    return () => {
      clearInterval(interval)
    }
  }, [homeCustomDate, getLatestOrders2, isBrand])

  const getAndChangeUncollapsed = (orderId: string) => {
    const isUncollapsed = uncollapsed[orderId]
    if (!isUncollapsed) {
      setTimeout(() => {
        setUncollapsed((prev) => ({ ...prev, [orderId]: true }))
      }, 0)
    }
    return isUncollapsed
  }

  const imgLoaded = ((ev) => {
    const img = ev.target
    gifToCanvas(img)
  })

  if(items.length) {
    if(lastOrderTimestamp && lastOrderTimestamp < items[0].timestamp) {
      playSound()
    }
    lastOrderTimestamp = items[0].timestamp
  }

  const latestTimeStamp = [...items].sort((a, b) => a.timestamp - b.timestamp).pop()?.timestamp
  const latestItemRelativeTime = latestTimeStamp ? moment(latestTimeStamp).fromNow() : ''

  return (
    <Box>

      <Box sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{
          fontWeight: 'bold',
        }}>Latest Order</Box>

        <Box
          sx={{
            color: 'grey',
          }}
        > {latestItemRelativeTime}</Box>
      </Box>

      {items.map((item, i) => {
        const id = item.timestamp + item.variantExternalIds[0]
        return (
          <Collapse sx={{ transitionDuration: !animation ? '0ms!important' : '300ms' }} key={id} in={getAndChangeUncollapsed(id) && i < maxVisibleOrders}>
            <AnimateColorBox key={id}
              className='animate-color'
              sx={{
                p: 2,
                display: 'flex',
                borderRadius: '5px',
                backgroundColor: animation ? uncollapsed[id] ? '' : '#A9FFA9' : '',
                borderBottom: '1px solid #E5E5E5',
              }}
            >
              {item.campaignImages[0]?.smallUrl ? <CardMedia
                component='img'
                sx={{
                  width: '38px',
                  height: '45px',
                  borderRadius: '5px',
                  mr: 1,
                }}
                src={item.campaignImages[0]?.smallUrl}
                onLoad={imgLoaded}
              /> :
                <Box
                  sx={{
                    width: '38px',
                    height: '45px',
                    borderRadius: '5px',
                    mr: 1,
                  }}
                >
                  <Icon
                    name='OrderUnknown'
                    root={true}
                    color={'#B0BEC5'}
                    size={38}
                  />
                </Box>
              }
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    fontWeight: 'bold',
                  }}
                >
                  {currencySymbolMap[item.currency]}{formatDataValue(item.netRevenue)}
                </Box>

                { item.variantTitles && item.variantTitles.map((variantTitle, j) => {
                  const title = (item?.productTitle?.[j] ? item.productTitle[j] : '' ) + (variantTitle ? ` - ${variantTitle}` : '')
                  return (
                    <Box
                      key={j}
                      sx={{
                        // border: '1px solid red',
                        flex: 1,
                      }}
                    >
                      {title}
                    </Box>
                  )
                })}
              </Box>
            </AnimateColorBox>
          </Collapse>
        )
      })}
    </Box>
  )
}

import React, { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { ImageFitEnum, DisplayTypesEnum, ContainerTypeEnum } from 'src/views/ComponentSettingsPage/types'
import { ImageFit } from 'storefront-interpreter/src/api/components'

type ImageFitSelectorProps = {
  value: ImageFit
  onChange: (value: ImageFitEnum) => void
  section: 'storefront' | 'product' | 'category'
}

export const ImageFitSelector: FC<ImageFitSelectorProps> = ({ value, onChange, section }) => (
  <FormControl sx={{ marginTop: 1, width: '100%' }}>
    <InputLabel id={`${section}-image-fit-label`}>Image fit</InputLabel>
    <Select
      size='small' margin='dense'
      labelId={`${section}-image-fit-label`}
      id={`${section}-image-fit`}
      value={value}
      label="Image fit"
      onChange={(event: SelectChangeEvent) => onChange(event.target.value as ImageFitEnum)}
    >
      <MenuItem value={ImageFitEnum.CONTAIN}>Contain</MenuItem>
      <MenuItem value={ImageFitEnum.COVER}>Cover</MenuItem>
      <MenuItem value={ImageFitEnum.FILL}>Fill</MenuItem>
    </Select>
  </FormControl>
)

type DisplayTypeSelectorProps = {
  value: DisplayTypesEnum
  onChange: (value: DisplayTypesEnum) => void
}

export const DisplayTypeSelector: FC<DisplayTypeSelectorProps> = ({ value, onChange }) => (
  <TextField
    sx={{ marginTop: 1, width: '100%' }}
    select
    value={value}
    size='small' margin='dense' type='string' variant='outlined'
    label="Type"
    onChange={(e) => onChange(e.target.value as DisplayTypesEnum)}
  >
    <MenuItem key={DisplayTypesEnum.MINIFIED} value={DisplayTypesEnum.MINIFIED}>Minified</MenuItem>
    <MenuItem key={DisplayTypesEnum.EXTENDED} value={DisplayTypesEnum.EXTENDED}>Extended</MenuItem>
  </TextField>
)

type ContainerTypeSelectorProps = {
  value: ContainerTypeEnum
  onChange: (value: ContainerTypeEnum) => void
}

export const ContainerTypeSelector: FC<ContainerTypeSelectorProps> = ({ value, onChange }) => (
  <FormControl sx={{ marginTop: 1, width: '100%' }}>
    <InputLabel id="container-type-label">Container type</InputLabel>
    <Select
      size='small' margin='dense'
      labelId="container-type-label"
      id='container-type'
      value={value}
      label="Container type"
      onChange={(e) => onChange(e.target.value as ContainerTypeEnum)}
    >
      <MenuItem key={ContainerTypeEnum.CAROUSEL} value={ContainerTypeEnum.CAROUSEL}>Carousel</MenuItem>
      <MenuItem key={ContainerTypeEnum.GRID} value={ContainerTypeEnum.GRID}>Grid</MenuItem>
    </Select>
  </FormControl>
)

type RowSelectorProps = {
  value: number | undefined
  onChange: (value: number) => void
  onBlur: () => void
  error: string
}

export const RowSelector: FC<RowSelectorProps> = ({ value, onChange, onBlur, error }) => (
  <TextField
    style={{
      width: '100%',
      marginRight: '12px',
      marginBottom: '8px',
    }}
    onBlur={onBlur}
    error={!!error}
    helperText={error && error}
    value={value}
    size='small' margin='dense' variant='outlined'
    label="Rows"
    onChange={(e) => {
      const val = Number(e.target.value)
      if (!isNaN(val)) onChange(val)
    }}
  />
)

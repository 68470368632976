import React from 'react'
import { render, screen} from '@testing-library/react'

import LoadingPage from './index'

describe('Auth Loading page', () => {
  it('renders auth loading spinner', () => {
    render(<LoadingPage />)
    expect(screen.getByTestId('auth-loading-spinner')).toBeVisible()
  })
})

import React from 'react'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'
import { Box, useTheme } from '@mui/material'


const Img = styled('img')(() => ({
  animationDuration: '1.5s',
  animationTimingFunction: 'ease-in-out',
  animationDelay: '0.5s',
  animationIterationCount: 'infinite',
  animationDirection: 'normal',
  animationFillMode: 'none',
  animationPlayState: 'running',
  animationName: 'Simplicity-keyframes-pulse',

  '@keyframes Simplicity-keyframes-pulse' : {
    '0%':  {
      opacity: 1,
    },
    '50%':  {
      opacity: 0.4,
    },
    '100%':  {
      opacity: 1,
    },
  },
}))

const SdxLoading: FC<{subText?: string}> = ({subText}) => {
  const theme = useTheme()

  return (
    <Box data-testid="auth-loading-spinner" sx={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      <div style={{
        margin: theme.spacing(1),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      }}>
        <Img alt='Simplicity logo' width={250} height={250} src="/static/images/simplicity_circular.png"/>
      </div>
      <p>{subText || 'Loading ...'}</p>
    </Box>
  )
}

export default SdxLoading

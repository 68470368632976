import React from 'react'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'
import { Box, LinearProgress  } from '@mui/material'


const PREFIX = 'LoadingBar'

const classes = {
  root: `${PREFIX}-root`,
  verticalCenter: `${PREFIX}-verticalCenter`,
}

const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },

  [`& .${classes.verticalCenter}`]: {
    backgroundColor: 'unset',
    paddingTop: '20%',
  },
}))

const LoadingBar: FC = () => {


  return (
    <Root className={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </Root>
  )
}

export default LoadingBar

export const mockOptimalPage = { page:
  {
    'id': 'Rv5CbokGbV7v',
    'layout': 'SINGLE_PRODUCT',
    'internalName': 'ninos test page',
    'componentIds': [
      'eda214cf-deec-4be5-b1e7-1bd2e13c662c',
    ],
    'mlOrderingUsed': [
      false,
    ],
    'shop': 'krave-demo.myshopify.com',
    'creatdAt': 1688041605806,
    'updatedAt': 1690303162200,
    'headline': 'headline1',
    'subheadline': 'Tennessee Orange 💗 Limited edition official merchandise out now!',
    'redirectUrl': '',
    'utmMedium': 'Social',
    'utmSource': 'Instagram',
    'utmCampaign': 'asdf',
    'startDate': 1690303161669,
    'products': [
      {
        'objId': 'gid://shopify/Product/7927314415848',
        'productId': 'b7a2a3e8-153c-fd10-f9cc-e055faa5cec3',
      },
    ],
    'categories': {
      'uiSettings': {
        'fields': [],
      },
      'apiSettings': {
        'sortBy': 'PRICE',
        'categoryIds': [
          '9c653a97-52a9-0ba1-6c3f-69c522427893',
        ],
      },
    },
    'campaignMedias': [
      {
        'postId': '17966389628445965',
        'postType': 'instagram',
        'postAccountId': '17841454710484688',
        'objId': '8b815451-4956-4ab0-abe5-150ce762e798',
      },
    ],
    'pageComponentOverrides': {},
    'components': [
      {
        'componentId': 'eda214cf-deec-4be5-b1e7-1bd2e13c662c',
        'shop': 'krave-demo.myshopify.com',
        'createdAt': 1690302891163,
        'updatedAt': 1690303162021,
        'componentType': 'ALL_PRODUCTS_IN_CATEGORY',
        'containerType': 'GRID',
        'internalName': 'ROCKS',
        'title': 'ninos rocks',
        'contentType': 'PRODUCT',
        'contentConfiguration': {
          'type': 'CATEGORY_PRIMITIVE',
          'value': {
            'uiSettings': {
              'fields': [
                'image',
              ],
            },
            'apiSettings': {
              'sortBy': 'SALES',
              'sortDir': 'DESC',
              'filterBy': 'IN_CATEGORY',
              'categoryIds': [
                '008c75c8-a0ac-d65f-6e6e-8cd30f3e2465',
              ],
              'categoryHandles': [],
            },
          },
        },
        'visibleInLibrary': false,
        'internalDescription': '',
        'class': 'SAVED',
      },
    ],
    'pageUrl': 'https://krave-demo.social.dev.simplicitydx.io?id=Rv5CbokGbV7v&p=s&s=krave-demo',
    'pageUrlWithUtms': 'https://krave-demo.social.dev.simplicitydx.io?id=Rv5CbokGbV7v&p=s&s=krave-demo&utm_campaign=asdf&utm_medium=Social&utm_source=Instagram',
    'componentOrderId': '4cbafb98-fdb5-5d10-b9f4-195128f59087',
  },
// click through rate
ctr: [{
  componentId: '12345',
  componentCtr: 30,
  isFixed: true,
},
{
  componentId: '12345678',
  componentCtr: 20,
  isFixed: false,
}],
}

import { axiosInstance } from 'src/utils/axios'
import { componentServiceBaseUrl } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShop } from '../types'
import { StorePrimitiveSettings } from 'src/views/ComponentSettingsPage/types'

export type GetStorePrimitiveSettings = () => Promise<AxiosResponse<StorePrimitiveSettings> | undefined>
export type GetStorePrimitiveSettingsApi = PrependShop<GetStorePrimitiveSettings>

export const getStorePrimitiveSettingsApi: GetStorePrimitiveSettingsApi = async (activeShop: string) => {
  try {
    const url = '/v2/primitives/settings'

    const response = await axiosInstance.get<StorePrimitiveSettings>(url, {
      baseURL: componentServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    })

    return response
  } catch (error) {
    logging(error, { tags: { section: 'get store primitive settings' } })
  }
}


export type SaveStorePrimitiveSettings = (payload: StorePrimitiveSettings) => Promise<AxiosResponse<{ response: string }> | undefined>
export type SaveStorePrimitiveSettingsApi = PrependShop<SaveStorePrimitiveSettings>

export const saveStorePrimitiveSettingsApi: SaveStorePrimitiveSettingsApi = async (activeShop: string, payload: StorePrimitiveSettings): Promise<AxiosResponse<{ response: string }> | undefined> => {
  try {
    const response = await axiosInstance.post(`/v2/primitives/settings`, payload, {
      baseURL: componentServiceBaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'Shop': activeShop,
      },
    })
    return response
  } catch (error) {
    console.log('set store primitive settings api error', error)
    logging(error, { tags: { section: 'set store primitive settings api error' } })
  }
}

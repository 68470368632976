import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2, productServiceBaseUrl } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShopCurrency } from '../types'
import { CurrencyCode } from 'src/utils/currencySymbolMap'
import { CampaignImage } from './getTopCampaigns'
import { Variants } from 'src/components/ProductSelectorLarge'
import { extendCampaignImages } from './extendCampaignImages'

export type LatestOrder2 = {
  netRevenue: number
  currency: CurrencyCode
  variantExternalIds: string[]
  productTitle?: string[]
  variantTitles?: string[]
  campaignImages: CampaignImage[]
  socialChannel: string
  timestamp: number
}

export type GetLatestOrders2 = (
  isBrand:boolean, startTimestamp: number, endTimestamp: number, limit: number
) => Promise<AxiosResponse<LatestOrder2[]> | undefined>

export const getLatestOrders2Api: PrependShopCurrency<GetLatestOrders2> = async (
  activeShop, currencyCode,
  isBrand, startTimestamp, endTimestamp, limit,
) => {
  try {
    const baseUrl = '/v1/analytics/orders'
    const params = new URLSearchParams()
    params.append('currency', currencyCode)
    params.append('limit', limit.toString())
    params.append('startTimestamp', startTimestamp.toString())
    params.append('endTimestamp', endTimestamp.toString())
    if(isBrand) params.append('isBrand', '1')
    const url = `${baseUrl}?${params.toString()}`

    const cfg = {
      baseURL: analyticsServiceBaseUrl2,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<LatestOrder2[]>(url, cfg)

    let variantIds = response?.data?.map((order) => order.variantExternalIds).flat()
    variantIds = Array.from(new Set(variantIds))

    const getVariantDetailsMap = async () => {
      const url2 = `/v1/variants?variantExternalIds=${variantIds.join(',')}`
      const cfg2 = {baseURL: productServiceBaseUrl, headers: {shop: activeShop}}
      const variantDetails = await axiosInstance.get<Variants[]>(url2, cfg2)
      const variantDetailsIdMap: Record<string, Variants> = variantDetails?.data?.reduce((acc, curr) => {
        acc[curr.externalId] = curr
        return acc
      }, {})
      return variantDetailsIdMap
    }

    await Promise.all([
      extendCampaignImages(activeShop, response.data),
      getVariantDetailsMap(),
    ]).then(([
      _,
      variantDetailsIdMap,
    ]) => {
      response.data = response.data.map((order) => {

        order.variantTitles = order.variantExternalIds.map((id) => variantDetailsIdMap[id]?.title)
        order.productTitle = order.variantExternalIds.map((id) => variantDetailsIdMap[id]?.productTitle?.replace('with no Variant', '')) || ''
        return order
      })

    })

    return response
  }

  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

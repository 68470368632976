import { socialServiceBaseUrl } from 'src/config'
import { ImageType, MultiSourceImage, PageConfig } from 'storefront-interpreter/src/config'
import { getProductsByIdsApi } from './storefrontsApi'
import { NormalizedCategoryPrimitive } from 'storefront-interpreter/src/api/getCategoryPrimitives'
import { getCategoryPrimitives } from './getCategoryPrimitives'
import axios from 'axios'

type ChannelType = 'instagram' | 'ad'
type PostsRequest = {postId: string, accountId: string, channelType: ChannelType}
type PostsResponse = {postId: string, accountId: string, thumbnail: string}

export const getMultiSourceImageWithDefaults = (s?: Partial<MultiSourceImage>): MultiSourceImage => {
  const fallback: string = (s as any)?.['url'] || ''
  return {
    ...s,
    altText: s?.altText || fallback,
    // if there is no smallUrl get the first next available
    smallUrl: s?.smallUrl ||s?.mediumUrl || s?.largeUrl || s?.originalUrl || fallback,
    // then fill other sizes that are missing so there is always a url for any of these
    mediumUrl: s?.mediumUrl || s?.smallUrl || fallback,
    largeUrl: s?.largeUrl || s?.mediumUrl || s?.smallUrl || fallback,
    originalUrl: s?.originalUrl || s?.largeUrl || s?.mediumUrl || s?.smallUrl || fallback,
  }
}

export const attachImageUrlToPageConfigs = async (shop: string, configs: PageConfig[]) => {
  try {
    const posts: PostsRequest[] = []

    for (const config of configs) {
      const firstImage = config.campaignMedias[0]
      if (!firstImage) continue

      const channelType = firstImage.postType
      if (channelType !== 'instagram' && channelType !== 'ad') continue

      const postAccountId = firstImage.postAccountId
      const postId = firstImage.postId
      if (!postAccountId || !postId) continue

      if (!posts.find(p => p.accountId === postAccountId && p.postId === postId))
        posts.push({channelType, accountId: postAccountId, postId: postId})
    }

    const postsPromise: Promise<PostsResponse[]> = new Promise(async (resolve, reject) => {
      try {
        const instagramPosts = posts.filter(p => p.channelType === 'instagram')
        if (!instagramPosts.length) resolve([])

        const url = `${socialServiceBaseUrl}/v1/instagram/posts`
        const cfg = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            shop,
          },
          body: JSON.stringify(instagramPosts.map (x => ({
            postId: x.postId,
            accountId: x.accountId,
          }))),
        }
        const response = await axios.post<PostsResponse[]>(url, cfg.body, {
          headers: cfg.headers,
        })

        if (!response) {
          console.warn ('Instagram fetch failed')
          resolve([])
        }

        return resolve(response.data)
      } catch(err) {
        console.warn(`fetch request failed: ${err}`)
        resolve([])
      }
    })


    const adsPromise: Promise<PostsResponse[]> = new Promise(async (resolve, reject) => {
      try {
        const instagramPosts = posts.filter(p => p.channelType === 'ad')
        if (!instagramPosts.length) resolve([])

        const url = `${socialServiceBaseUrl}/v2/meta-ads/ads`
        const cfg = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            shop,
          },
          body: JSON.stringify(instagramPosts.map (x => ({
            adId: x.postId,
            accountId: x.accountId,
          }))),
        }

        const response = await axios.post<PostsResponse[]>(url, cfg.body, {
          headers: cfg.headers,
        })

        if (!response) {
          console.warn ('Instagram fetch failed')
          // const errBody = await response.json()
          // throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
          resolve([])
        }

        return resolve(response.data)
      } catch(err) {
        console.warn(`fetch request failed: ${err}`)
        resolve([])
      }
    })

    const categoryIdToImageDict: {[key: string]: ImageType} = {}
    const categoriesPromise = async () => {
      const categoryIds: string[] = []
      configs.forEach(x => {
        const categoryId = x.categories?.apiSettings?.categoryIds?.[0] || ''
        if (categoryId) categoryIds.push(categoryId)
      })
      const categoryPrimitives: NormalizedCategoryPrimitive[] | undefined = await getCategoryPrimitives(shop, categoryIds)

      categoryPrimitives?.forEach(categoryPrimitive => {
        categoryIdToImageDict[categoryPrimitive.id] = getMultiSourceImageWithDefaults(categoryPrimitive)
      })

      return [] as any as PostsResponse
    }

    const productIdToImageDict: {[key: string]: ImageType} = {}
    const productsPromise = async () => {
      const productIds = configs.map(x => x.products[0]?.productId).filter(x => x)
      await getProductsByIdsApi(shop, productIds)
        .then(products => {
          products = products || []
          for (let i = 0; i < products.length; ++i) {
            const product = products[i]
            if (!product) {
              console.warn(`Product with id ${productIds[i]} not found`)
              continue
            }
            productIdToImageDict[product.productId] = product.images[0]
          }
        })
      return [] as any as PostsResponse
    }


    const promises = [postsPromise, adsPromise, productsPromise(), categoriesPromise()]

    const responses = (await Promise.all(promises))
      .filter(x => x)
      .flat()
      .map (x => {
      // NOTE: this is because we hack the postId with postType === 'id'
        x.postId = x.postId || (x as any).adId
        return x
      })

    configs.forEach(config => {
      const firstImage: any = config.campaignMedias[0] || {}
      const postAccountId = firstImage?.postAccountId
      const postId = firstImage?.postId

      if (!(postAccountId && postId) && !firstImage.objId)
      {
        const productImage =
        productIdToImageDict[config.products[0]?.productId || ''] ||
        categoryIdToImageDict[config.categories?.apiSettings?.categoryIds?.[0] || '']

        if (productImage) Object.assign(firstImage, productImage)
      }

      if (!(firstImage as any)['url']) {
        (firstImage as any)['url'] =
      responses.find(x => x.postId === postId)?.thumbnail || ''
      }
      Object.assign(firstImage, getMultiSourceImageWithDefaults(firstImage))

      config.campaignMedias[0] = firstImage

    // console.log(config.internalName, firstImage, config)
    })

    return configs
  } catch (error) {
    console.warn('Error attaching images to URL', error)
    return configs
  }
}

import React, { FC } from 'react'
import { FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { List, arrayMove } from 'react-movable'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import AsyncSelectCategories from 'src/components/AsyncSelectCategories'
import { Category } from 'src/contexts/types'
import { ComponentTypeResponse, getCategoryFields, getMinifiedFields } from 'src/views/Components/types'
import { getInitialStorePrimitiveSettings } from 'src/contexts/GenericContext'
import { ContainerTypeEnum, DisplayTypesEnum, ImageFitEnum } from 'src/views/ComponentSettingsPage/types'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  currentSelectedCategoryMap: Record<string, Category>
  backgroundGradient: string
}

export const ManualCategoryNavigationInput: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  currentSelectedCategoryMap,
  backgroundGradient,
}) => {
  const lastIndexId = 'lastIndexId'
  return (
    componentConfig.contentConfiguration.type === 'CATEGORY_PRIMITIVE' ?
      <>
        <div style={{
          display: 'flex',
          gap: 4,
          marginBottom: 2,
        }}>
          <FormControl sx={{ marginTop: 1, width: '100%' }}>
            <InputLabel id="manual_category_image_fit_label">Image fit</InputLabel>
            <Select
              size='small' margin='dense'
              labelId="manual_category_image-fit-label"
              id="manual_category_image-fit"
              value={componentConfig.contentConfiguration.value.uiSettings.imageFit || getInitialStorePrimitiveSettings().category.uiSettings.imageFit}
              label="Image fit"
              onChange={(event: SelectChangeEvent) => {
                const newComponent: ComponentTypeResponse = { ...componentConfig }
                if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return

                newComponent.contentConfiguration.value.uiSettings.imageFit = event.target.value as ImageFitEnum
                setComponentConfig(newComponent)
              }}
            >
              <MenuItem value={ImageFitEnum.CONTAIN}>Contain</MenuItem>
              <MenuItem value={ImageFitEnum.COVER}>Cover</MenuItem>
              <MenuItem value={ImageFitEnum.FILL}>Fill</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: 1, width: '100%' }}>
            <InputLabel id="manual_category_display">Display type</InputLabel>
            <Select
              size='small' margin='dense'
              labelId="manual_category_display"
              id='manual_category_display'
              value={componentConfig.contentConfiguration.value.uiSettings.fields.length > 1 ? DisplayTypesEnum.EXTENDED : DisplayTypesEnum.MINIFIED}
              label="Display type"
              onChange={(e) => {
                const newComponent: ComponentTypeResponse = { ...componentConfig }
                if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
                newComponent.contentConfiguration.value.uiSettings.fields =
                e.target.value === DisplayTypesEnum.MINIFIED ? getMinifiedFields() : getCategoryFields()
                setComponentConfig(newComponent)
              }}
            >
              <MenuItem key={DisplayTypesEnum.MINIFIED} value={DisplayTypesEnum.MINIFIED}>Minified</MenuItem>
              <MenuItem key={DisplayTypesEnum.EXTENDED} value={DisplayTypesEnum.EXTENDED}>Extended</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: 1, width: '100%' }}>
            <InputLabel id="manual_category_container">Container type</InputLabel>
            <Select
              size='small' margin='dense'
              labelId="manual_category_container"
              id='manual_category_container'
              value={componentConfig.containerType}
              label="Container type"
              onChange={(e) => {
                const newComponent: ComponentTypeResponse = { ...componentConfig }
                if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
                newComponent.containerType = e.target.value as ContainerTypeEnum
                setComponentConfig(newComponent)
              }}
            >
              <MenuItem key={ContainerTypeEnum.CAROUSEL} value={ContainerTypeEnum.CAROUSEL}>Carousel</MenuItem>
              <MenuItem key={ContainerTypeEnum.GRID} value={ContainerTypeEnum.GRID}>Grid</MenuItem>
            </Select>
          </FormControl>
        </div>

        <List
          lockVertically
          values={componentConfig.contentConfiguration.value.apiSettings.categoryIds.concat([lastIndexId])}
          onChange={({ oldIndex, newIndex }) => {
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return

            newComponent.contentConfiguration.value.apiSettings.categoryIds = arrayMove(newComponent.contentConfiguration.value.apiSettings.categoryIds, oldIndex, newIndex)
            setComponentConfig(newComponent)
          }}
          renderList={(list) => <div {...list.props}>{list.children}</div>}
          renderItem={(listItem) => {
            const { isDragged } = listItem
            const categoryId = listItem.value
            const j = listItem.index
            const isLast = listItem.value === lastIndexId
            return (
              <div {...listItem.props}
                key={isLast ? j : categoryId}
              >
                <div style={{
                  paddingLeft: '20px',
                  background: backgroundGradient,
                }}>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div style={{
                      flex: 1,
                      marginRight: '12px',
                    }}>
                      <AsyncSelectCategories
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        previouslySelected={componentConfig.contentConfiguration.value.apiSettings.categoryIds}
                        selected={currentSelectedCategoryMap[categoryId]}
                        onChange={(e, value) => {
                          if (value && typeof value === 'object') {
                            currentSelectedCategoryMap[value.id] = value
                            const newComponent: ComponentTypeResponse = { ...componentConfig }
                            if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return

                            if (isLast) {
                              newComponent.contentConfiguration.value.apiSettings.categoryIds = [...newComponent.contentConfiguration.value.apiSettings.categoryIds, value.id]
                            }
                            else {
                              newComponent.contentConfiguration.value.apiSettings.categoryIds =
                                newComponent.contentConfiguration.value.apiSettings.categoryIds
                                  .map(categoryIdItem => categoryIdItem === categoryId ? value.id : categoryIdItem)
                            }

                            setComponentConfig(newComponent)
                          }
                        }}
                      />
                    </div>

                    <div
                      data-movable-handle
                      style={{
                        margin: '16px 6px 10px 0px',
                        cursor: isDragged ? 'grabbing' : 'grab',

                        opacity: isLast ? 0 : 1,
                        pointerEvents: isLast ? 'none' : 'auto',
                      }}
                    >
                      <DragIcon />
                    </div>

                    <IconButton
                      style={{
                        opacity: isLast ? 0 : 1,
                      }}
                      disabled={isLast}

                      onClick={() => {
                        const newComponent: ComponentTypeResponse = { ...componentConfig }
                        if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return

                        newComponent.contentConfiguration.value.apiSettings.categoryIds = newComponent.contentConfiguration.value.apiSettings.categoryIds.filter(categoryIdItem => categoryIdItem !== categoryId)
                        setComponentConfig(newComponent)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            )
          }}
        />
      </>
      :
      <div></div>
  )
}

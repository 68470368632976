import { FC } from 'react'
import { Box, Skeleton, Typography, useTheme } from '@mui/material'
import Chart from 'react-apexcharts'
import { ReactComponent as LiftIcon } from 'src/assets/icons/Lift.svg'
import { ListIcons } from './ListIcons'
import { SocialSource } from 'src/components/SocialChannelPicker'
import { CurrencyCode, currencySymbolMap } from 'src/utils/currencySymbolMap'
import {formatDataValue, formatNumber} from 'src/utils/analyticsHelpers'
import Icon from './Icon/Icon'

const SocialChart: FC<{
  error?: boolean
  isLoading: boolean
  title: SocialSource
  revenue: number
  lift: number | undefined
  flipLiftSign?: boolean
  data: any
  currency: CurrencyCode
}> = ({ isLoading, title, revenue, lift, flipLiftSign, data, currency, error }) => {
  const isLiftZero = lift === 0
  const isLiftUndefined = lift === undefined
  const isLiftNegative = !isLiftUndefined && lift < 0
  let iconColorPositive = !isLiftNegative
  const isLiftNeutral = isLiftZero || isLiftUndefined
  if (flipLiftSign) iconColorPositive = !iconColorPositive

  const theme = useTheme()
  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      padding: '16px',
      display: 'flex',
    }}>
      <Box sx={{
        minWidth: 50,
      }}>
        <Box sx={{
          fontSize: '16px',
          fontWeight: 700,
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center',
          color: '#5E6C84',
        }}>
          {error &&  <Icon name='NoData' root={true} size={45}/>}
          {isLoading && <Box sx={{
            flex: '0 0 42px',
            height: 43,
          }}>
            <Skeleton sx={{width: '100%', height: '100%'}} variant='circular' />
          </Box>}
          {!error && !isLoading && <ListIcons size={39} icons={[title]} />}

          <span style={{marginLeft: '9px'}}>{
            isLoading ? <Skeleton height={40} width={80} />
              : title
          }</span>
        </Box>
        <Typography sx={{
          fontSize: '32px',
          fontWeight: 700,
        }}>
          {error &&  <Box sx={{height: '20px', backgroundColor: 'whitesmoke'}} />}
          {isLoading && <Skeleton height={48} width={100} />}
          {!error && !isLoading && formatDataValue(revenue, 2, currencySymbolMap[currency])}
        </Typography>
        {<Typography sx={{
          fontSize: '16px',
          color: (isLoading || isLiftNeutral) ? 'lightgrey' : (iconColorPositive ? theme.palette.brand.liftPositive: theme.palette.brand.liftNegative),

        }}>
          <Box sx={{
            display: 'flex',
            width: '80px',
            height: '26px',
          }}>
            <Box sx={{
              display: 'flex',
              width: '25px',
              height: '26px',
              alignItems: 'center',
            }}>
              <LiftIcon style={{
                transform: (isLoading || isLiftNeutral) ? 'rotate(45deg)' : ( isLiftNegative ? 'rotate(90deg)' : ''),
                // marginRight: '5px',
                margin: 'auto',
              }} />
            </Box>
            {isLoading ? <Box sx={{
              width: 25,
              height: 25,
            }}>
              <Skeleton sx={{width: '100%', height: '100%'}} />
            </Box>
              : !isLiftUndefined && lift.toFixed(2) + '%'}
          </Box>
        </Typography>}
      </Box>
      <Box sx={{
        position: 'relative',
        height: '69px',
        width: '100%',
        '& > *': {
          position: 'absolute',
          bottom: '-14px',
          right: '-10px',
        },
        '& svg': {
          height: '100%',
          '& path[selected="true"], & path:hover': {
            fill: '#61a4e4',
            filter: 'none',
          },
        },
      }}>
        <Chart
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 0,
            },
            grid: {
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            fill: {
              colors: [(p) => p.dataPointIndex === 8 ? '#eef6fc' : '#eef6fc'],
            },
            markers: {
              size: 0,
            },
            yaxis: {
              floating: true,
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
              labels: {
                show: false,
              },
            },
            xaxis: {
              floating: true,
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
              labels: {
                show: false,
              },
            },
            tooltip: {
              enabled: true,
              x: {
                formatter(val: number, opts?: any): string {
                  const date = new Date(val)
                  return date.toLocaleDateString()
                },
              },
              y: {
                formatter(val: number, opts?: any): string {
                  return `${currencySymbolMap[currency]}${formatNumber(val)}`
                },
              },
            },
          }}
          style={{
            top: '21px',
            right: '-17px',
          }}
          series={[
            {
              name: 'Value',
              data: data ? data.map(item => ({
                x: item.timestamp,
                y: item.value,
              })) : [],
            },
          ]}
          type='bar'
          width='150px'
        />
      </Box>

    </Box >
  )
}

export default SocialChart

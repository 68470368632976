import { styled } from '@mui/material/styles'

const PREFIX = 'datePickerComponent'

export const classes = {
  root: `${PREFIX}-root`,
  menu: `${PREFIX}-menu`,
  saveButton: `${PREFIX}-saveButton`,
  divider: `${PREFIX}-divider`,
  calendar: `${PREFIX}-calendar`,
  inputDate: `${PREFIX}-inputDate`,
  inputTime: `${PREFIX}-inputTime`,
  inputGrid: `${PREFIX}-inputGrid`,
  dividerInput: `${PREFIX}-dividerInput`,
  clockIcon: `${PREFIX}-clockIcon`,
  arrowIcon: `${PREFIX}-arrowIcon`,
}

export const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    outline: 'none',
    '& .MuiInputLabel-formControl': {
      left: 8,
      top: 4,
    },
    '& .MuiInputLabel-formControl.Mui-focused, & .MuiInputLabel-formControl.MuiFormLabel-filled':
    {
      left: 0,
      top: 0,
    },
  },

  [`& .${classes.menu}`]: {
    width: 'auto',
    minWidth: 240,
    maxWidth: '100%',
    '& .MuiInput-underline:after, & .MuiInput-underline:before, & .MuiFormHelperText-root, & .MuiInputAdornment-root':
    {
      display: 'none',
    },
  },

  [`& .${classes.saveButton}`]: {
    textTransform: 'none',
    height: 38,
    marginRight: 16,
  },

  [`& .${classes.divider}`]: {},

  [`& .${classes.calendar}`]: {
    marginBottom: -8,
    maxWidth: '560px',
    '& .react-datepicker': {
      position: 'relative',
      width: '100%',
      display: 'flex',
    },
    '& .react-datepicker__aria-live': {
      display: 'none',
    },
    '& .react-datepicker__navigation--previous': {
      position: 'absolute',
      top: 2,
      left: 9,
      fontSize: '20px',
      display: 'flex',
      color: 'transparent',
      backgroundColor: 'transparent',
      width: 20,
      height: 20,
      border: 0,
      cursor: 'pointer',
      '& span:before': {
        content: '""',
        transform: 'rotate(135deg)',
        border: 'solid #546E7A',
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: 4,
        position: 'absolute',
        top: 3,
        right: 2,
      },
    },
    '& .react-datepicker__navigation--next': {
      position: 'absolute',
      top: 2,
      right: 9,
      fontSize: '20px',
      display: 'flex',
      color: 'transparent',
      backgroundColor: 'transparent',
      width: 20,
      height: 20,
      border: 0,
      cursor: 'pointer',
      '& span:before': {
        content: '""',
        transform: 'rotate(-45deg)',
        border: 'solid #546E7A',
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: 4,
        position: 'absolute',
        top: 3,
        left: 2,
      },
    },
    '& .react-datepicker__day-names': {
      display: 'flex',
      width: '100%',
      height: 40,
      '& .react-datepicker__day-name': {
        width: 40,
        height: 40,
        textAlign: 'center',
        lineHeight: '40px',
        fontSize: '0.75rem',
      },
    },
    '& .react-datepicker__week': {
      display: 'flex',
      width: '100%',
      height: 40,
      marginBottom: 2,
      '& .react-datepicker__day': {
        width: 40,
        height: 40,
        textAlign: 'center',
        lineHeight: '40px',
        cursor: 'pointer',
        fontSize: '0.75rem',
        '&.react-datepicker__day--outside-month': {
          backgroundColor: 'white!important',
          opacity: 0,
          cursor: 'default',
        },
        '&:not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range):hover': {
          border: '1px solid #eceff1',
          borderRadius: '50%',
          lineHeight: '38px',
        },
        '&.react-datepicker__day--selecting-range-start': {
          position: 'relative',
          color: 'white',
          borderRadius: '50%',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        '&.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selecting-range-start)': {
          position: 'relative',
          backgroundColor: '#eceff1',
          color: 'black',
          borderRadius: '50%',
          borderTopLeftRadius: '0!important',
          borderBottomLeftRadius: '0!important',
        },
        '&.react-datepicker__day--in-selecting-range': {
          backgroundColor: '#eceff1',
          color: 'black',
        },
        '&.react-datepicker__day--in-range': {
          backgroundColor: '#eceff1',
          color: 'black',
          '&.react-datepicker__day--range-start, &.react-datepicker__day--range-end': {
            position: 'relative',
            backgroundColor: theme.border.dark,
            color: 'white',
            borderRadius: '50%',
          },
          '&.react-datepicker__day--range-start': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
          '&.react-datepicker__day--range-end': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
          '&.react-datepicker__day--range-start.react-datepicker__day--range-end': {
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
          },
        },
      },
    },
    '& .react-datepicker__month-container': {
      width: '50%',
    },
    '& .react-datepicker__current-month': {
      fontWeight: 400,
      fontSize: '1rem',
      textAlign: 'center',
    },
  },

  [`& .${classes.inputDate}`]: {
    marginRight: 16,
    '& input': {
      height: 36,
      borderRadius: theme.border.radius,
      padding: '0 8px',
    },
  },

  [`& .${classes.inputTime}`]: {
    marginRight: 16,
    marginTop: 8,
    '& input': {
      height: 36,
      borderRadius: theme.border.radius,
      padding: '0 8px',
    },
  },

  [`& .${classes.inputGrid}`]: {
    margin: '20px 0 20px 16px',
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.dividerInput}`]: {
    display: 'inline-block',
    marginRight: 16,
    fontFamily: 'Roboto',
  },

  [`& .${classes.clockIcon}`]: {
    color: 'white',
    width: 16,
    marginRight: 5,
    '& path': {
      stroke: '#344563',
    },
  },

  [`& .${classes.arrowIcon}`]: {
    width: 12,
    marginLeft: 5,
    '& path': {
      stroke: 'white',
    },
  },
}))

// import {CartContent, ContainerConfig} from '../api/components'

const cartContent: any = {
  type: 'LINE_ITEM',
  value: {
    uiSettings: {
      fields: [
        'PRODUCT_NAME',
        'ITEMIZED_OPTIONS',
      ],
      icon: {
        url: '',

        // TODO: rename on backend and frontend to separate badge colors
        textColor: '#000000', // badgeTextColor
        textHoverColor: '#000000', // badgeTextHoverColor

        badgingColor: '#000000', // badgeBackgroundColor
        badgingHoverColor: '#000000', // badgeBackgroundHoverColor

        backgroundColor: '#ffffff',
        backgroundHoverColor: '#ffffff',
      },
      header: {
        text: 'Your shopping cart44h4h4',
        html: `
            <span style="color: red">Your shopping cart testing html</span>
        `,
      },
      footer: {
        text: 'Shipping, taxes and discount codes calculated at checkout.33',
        html: '<h1>Shipping, taxes and discount codes calculated at checkouttesting123 html</h1>',
      },
      checkoutButton: {
        text: 'Continue to checkout3333',
        backgroundColor: '#ffffff',
        backgroundHoverColor: '#ffffff',
        textColor: '#000000',
        textHoverColor: '#000000',
        outlineColor: '#000000',
        outlineHoverColor: '#000000',
        outlineWidthPixels: 2,
        fontUrl: 'ffj',
      },
    },
    apiSettings: {},
  },
}

export const cartMock: any = {
  visibleInLibrary: false,
  status: 'PUBLISHED',
  createdAt: 1234567890,
  updatedAt: 1234567890,
  componentType: 'CART',
  containerType: 'GRID',
  contentType: 'CART',
  internalDescription: 'Cart',
  internalName: 'Cart',
  title: '',
  componentId: 'uninitialized_cart',
  contentConfiguration: cartContent,
  class:'HOOK',
}

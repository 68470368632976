import { Section } from '../types'

export default function processSections(sections: Section[], layout: string, selectedSection?: Section['title']) {
  return sections.map(s => {

    let newS = {...s, hidden: false}
    if (selectedSection) {
      newS = {...newS, collapsed: selectedSection !== s.title}
    }


    if (layout === 'SOCIAL_HOME') {
      if (s.type === 'select_layout') {
        newS.hidden = true
        newS.collapsed = true
      }
      if (s.type === 'category_product') {
        newS.hidden = true
        newS.collapsed = true
      }
    }

    if (layout === 'CATEGORY' && newS.type === 'category_product') {
      newS.title = 'Category'
    }
    if (layout === 'SINGLE_PRODUCT' && newS.type === 'category_product') {
      newS.title = 'Product'
    }

    return newS
  })
}

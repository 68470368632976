import {PrependShop } from 'src/contexts/types'
import { axiosInstance } from 'src/utils/axios'
import { componentServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { PageConfig } from 'src/types/interpretor'
import { deepCloneJson } from 'src/utils/helpers'
import { AxiosResponse } from 'src/types/axios'

export type PublishPageConfig = (payload: PageConfig) => Promise<AxiosResponse<PageConfig> | undefined>
export type PublishPageConfigApi = PrependShop<PublishPageConfig>

export const publishPageConfigApi: PublishPageConfigApi = async (activeShop: string, payload: PageConfig) => {
  try {
    payload = deepCloneJson(payload)
    if (payload.id === 'home') {
      payload.utmCampaign = payload.utmCampaign || 'Campaign Home Page'
      payload.utmSource = payload.utmSource || 'Direct'
      payload.utmMedium = payload.utmMedium || 'Direct'
    }
    payload.campaignMedias.forEach(media => {
      if (media.postId) {
        delete (media as any).smallUrl
        delete (media as any).mediumUrl
        delete (media as any).largeUrl
        delete (media as any).originalUrl
      }
    })

    const url = payload.id ? `/v1/pages/${payload.id}` : '/v1/pages'

    const cfg = {
      baseURL: componentServiceBaseUrl,
      headers: {
        shop: activeShop,
      },
    }

    const response = await axiosInstance.post<PageConfig>(url, payload, cfg)

    // normalize/validate response data here

    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'publish store' } })
  }
}

import React from 'react'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'
import { Container, Grid, Link  } from '@mui/material'
import { ReactComponent as FullLogoSvg } from 'src/assets/icons/Full_logo.svg'
import Page from 'src/components/Page'



const PREFIX = 'ErrorBoundry'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  titleText: `${PREFIX}-titleText`,
  image: `${PREFIX}-image`,
}

const StyledPage = styled(Page)((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.title}`]: {
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(6),
  },

  [`& .${classes.titleText}`]: {
    fontSize: theme.spacing(4),
  },

  [`& .${classes.image}`]: {
    height: theme.spacing(20),
    width: theme.spacing(50),
  },
}))

const ErrorBoundry: FC = () => {


  return (
    <StyledPage className={classes.root}>
      <Container fixed>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item>
            <FullLogoSvg className={classes.image} />
          </Grid>
          <Grid item>
            <span
              className={classes.title}
            >
              <div className={classes.titleText} />
              Something went wrong
              <div />
            </span>
          </Grid>
          <Grid item>
            <span >
             Sorry for that, this error has been automatically reported.
            </span>
          </Grid>
          <Grid item>
            <span>
              You can go home to <Link href='/'>TAKE ME HOME</Link> to see more awesome stuff.
            </span>
          </Grid>
        </Grid>
      </Container>
    </StyledPage>
  )
}

export default ErrorBoundry

import React from 'react'

import { ReactComponent as Question } from 'src/assets/icons/Question.svg'

export default function CardError(): JSX.Element {
  return (
    <div data-testid='questionImage' style={{textAlign: 'center'}}>
      <Question />
    </div>
  )
}

import React, { FC } from 'react'
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { ContainerConfig } from 'storefront-interpreter/src/api/components'
import { ContainerTypeEnum } from './types'


interface ContainerTypeSelectorProps {
  label: string
  containerTypeValue: ContainerConfig['containerType']
  onContainerTypeChange: (containerTypeValue: ContainerConfig['containerType']) => void
}

export const ContainerTypeSelector: FC<ContainerTypeSelectorProps> = ({ label, containerTypeValue, onContainerTypeChange }) => {
  const containerOptions: ContainerConfig['containerType'][] = [ContainerTypeEnum.CAROUSEL, ContainerTypeEnum.GRID]
  const labelInLowerCase = label.toLowerCase()
  return (
    <Box sx={{ display: 'flex'}}>
      <FormControl sx={{ maxWidth: '600px', width: { xs: '100%', md: '75%' } }}>
        <InputLabel id={`${labelInLowerCase}-container-label`}>{label}</InputLabel>
        <Select
          size="small"
          margin="dense"
          labelId={`${labelInLowerCase}-container-label`}
          id={`${labelInLowerCase}-container`}
          value={containerTypeValue}
          label={label}
          onChange={(event) => onContainerTypeChange(event.target.value as ContainerConfig['containerType'])}
        >
          {containerOptions.map((type) => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

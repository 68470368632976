import React, { FC } from 'react'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'

const AuthLoadingPage: FC = () =>  {

  return (
    <SdxLoading/>
  )
}

export default AuthLoadingPage

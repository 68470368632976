import React, { forwardRef } from 'react'
import type { HTMLProps, ReactNode } from 'react'

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode
  title?: string
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', ...rest }, ref) => {
    document.title = title

    return (
      <div ref={ref}
        {...rest}>
        {children}
      </div>
    )
  },
)

export default Page

import { PageConfig } from 'storefront-interpreter/src/config'
import { PrependShop } from '../types'
import { AxiosResponse } from 'axios'
import { logging } from 'src/utils/logging'
import { axiosInstance } from 'src/utils/axios'
import { componentServiceBaseUrl } from 'src/config'

export type ManualPage = PageConfig[]
export type GetManualPage = (storefrontId: string) => Promise<AxiosResponse<ManualPage> | undefined>
export type GetManualPageApi = PrependShop<GetManualPage>
export const getManualPageApi: GetManualPageApi = async (activeShop: string, storefrontId: string) => {
  try {
    let url = '/v1/pages/manual'
    url += `?id=${storefrontId}`

    const response =  await axiosInstance.get<ManualPage>(url, { baseURL: componentServiceBaseUrl, headers: {
      shop: activeShop,
    } })
    return response

  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get manual api' } })
  }

}

import React from 'react'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,

} from '@mui/material'

import Page from 'src/components/Page'
import { useTranslation } from 'react-i18next'
import { logging } from 'src/utils/logging'

const PREFIX = 'NotFoundView'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledPage = styled(Page)((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
}))

const NotFoundView: FC = () => {

  const {t} = useTranslation()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'))
  logging('Hit a 404', { tags: { section: '404'} })

  return (
    <StyledPage className={classes.root}
      title={t('general.not found message')}>
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          404: The page you are looking for isn’t here
        </Typography>
        <Typography align="center"
          variant="subtitle2"
          color="textSecondary">
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation.
        </Typography>
        <Box mt={6}
          display="flex"
          justifyContent="center">
          <Button
            color="secondary"
            component={RouterLink}
            to="/"
            variant="outlined"
          >
            Back to home
          </Button>
        </Box>
      </Container>
    </StyledPage>
  )
}

export default NotFoundView

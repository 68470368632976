import React from 'react'
import { styled } from '@mui/material/styles'
import type { FC  } from 'react'

import {   Box } from '@mui/material'

const PREFIX = 'TextIcon'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledBox = styled(Box)({
  [`&.${classes.root}`]: {
    color: '#00A5D9',
    background: 'rgb(0, 165, 217, 0.15)',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '0.8em',
  },
})

interface TextIconProps {
  content: string
  width: string
  padding?: string
}

const TextIcon: FC<TextIconProps> = ({ content, width, padding='0px' }) => {

  return (
    <StyledBox style={{ width, padding }} className={classes.root}>
      {content}
    </StyledBox>
  )
}

export default TextIcon

import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2 } from 'src/config'
import { logging } from 'src/utils/logging'
import { CampaignAnalyticsItem, DataStat, PrependShopCurrency } from 'src/contexts/types'
import { AxiosResponse } from 'src/types/axios'

export type GetPerformanceAnalytics = (storefrontId: string, isBrand:boolean, startTimestamp: number, endTimestamp: number) => Promise<AxiosResponse<Record<string, DataStat>> | undefined>
export type GetPerformanceAnalyticsApi = PrependShopCurrency<GetPerformanceAnalytics>

export const getPerformanceAnalyticsApi: GetPerformanceAnalyticsApi = async (activeShop: string, currencyCode: string, storefrontId: string, isBrand:boolean, startTimestamp: number, endTimestamp: number) => {
  try {
    // TODO: rename backend to storefront
    let url = '/v1/analytics/page-comparison'
    // TODO: rename backend to storefront
    url += `?pageId=${storefrontId}`
    url += `&startTimestamp=${startTimestamp}`
    url += `&endTimestamp=${endTimestamp}`
    url += `&currency=${currencyCode}`

    if(isBrand) url += `&isBrand=1`

    const response = await axiosInstance.get<Record<string, DataStat>>(url, { baseURL: analyticsServiceBaseUrl2, headers: {
      shop: activeShop,
    } })

    return response
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

export type GetCampaignAnalytics = (storefrontId: string, isBrand:boolean, startTimestamp: number, endTimestamp: number) => Promise<AxiosResponse<CampaignAnalyticsItem> | undefined>
export type GetCampaignAnalyticsApi = PrependShopCurrency<GetCampaignAnalytics>
export const getCampaignAnalyticsApi: GetCampaignAnalyticsApi = async (activeShop: string, currencyCode: string, storefrontId: string, isBrand:boolean, startTimestamp: number, endTimestamp: number) => {

  try {
    let url = '/v1/analytics/utm'
    // TODO: rename backend to storefront
    url += `?pageId=${storefrontId}`
    url += `&startTimestamp=${startTimestamp}`
    url += `&endTimestamp=${endTimestamp}`
    url += `&currency=${currencyCode}`
    if(isBrand) url += `&isBrand=1`

    const response = await axiosInstance.get<CampaignAnalyticsItem>(url, { baseURL: analyticsServiceBaseUrl2, headers: {
      shop: activeShop,
    } })
    return response

  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get campaign analytics api' } })
  }

}

import React, { FC, useEffect, useRef } from 'react'
import { Box, useTheme, TextField, Button, IconButton } from '@mui/material'
import { validateUrl } from 'src/utils/helpers'
import SdxColorPicker from '../Components/SdxColorPicker'
import { List, arrayMove } from 'react-movable'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'

type Link = {
  name: string
  url: string
}

type Column = {
  name: string
  links: Link[]
}

const defaultConfig = {
  containerType: 'GRID',
  internalName: 'Footer component',
  title: '',
  contentType: 'FOOTER',
  componentType: 'FOOTER',
  createdAt: 1,
  updatedAt: 1,
  shop: '',
  visibleInLibrary: false,
  internalDescription: '',
  class: 'HOOK',
  contentConfiguration: {
    type: 'FOOTER',
    value: {
      uiSettings: {
        backgroundColor: '',
        textColor: '',
      },
      apiSettings: {
        columns: [],
      },
    },
  },
}

export type FooterSettingsType = {
  containerType: string
  internalName: string
  title: string
  contentType: string
  componentType: string
  createdAt: number
  updatedAt: number
  shop: string
  visibleInLibrary: boolean
  internalDescription: string
  class: string
  contentConfiguration: {
    type: string
    value: {
      uiSettings: {
        backgroundColor: string
        textColor: string
      }
      apiSettings: {
        columns: Column[]
      }
    }
  }
}

export const FooterSettingsUI: FC<{
  config: FooterSettingsType
  checkErrors: boolean
  setConfig: (config: FooterSettingsType) => void
  setFormValid: (isFormValid: boolean) => void
}> = ({ config, checkErrors, setConfig, setFormValid }) => {
  const theme = useTheme()
  const formRef = useRef<HTMLObjectElement | null>(null)

  useEffect(() => {
    setConfig(defaultConfig)
  }, [])

  const addColumn = (() => {
    const newConfig = Object.assign({}, config)
    newConfig.contentConfiguration.value.apiSettings.columns.push({
      name: '',
      links: [],
    })
    setConfig(newConfig)
    setFormValid(isFormValid())
  })

  const removeColumn = ((index) => {
    const newConfig = Object.assign({}, config)
    newConfig.contentConfiguration.value.apiSettings.columns.splice(index, 1)
    setConfig(newConfig)
    setFormValid(isFormValid())
  })

  const addLink = ((index) => {
    const newConfig = Object.assign({}, config)
    if (newConfig.contentConfiguration.value.apiSettings.columns[index] && newConfig.contentConfiguration.value.apiSettings.columns[index].links) {
      newConfig.contentConfiguration.value.apiSettings.columns[index].links.push({
        name: '',
        url: '',
      })
      setConfig(newConfig)
    }
    setFormValid(isFormValid())
  })

  const removeLink = ((index, linkIndex) => {
    const newConfig = Object.assign({}, config)
    newConfig.contentConfiguration.value.apiSettings.columns[index].links.splice(linkIndex, 1)
    setConfig(newConfig)
    setFormValid(isFormValid())
  })

  const handleInputChange = ((event, field) => {
    const newConfig = Object.assign({}, config)
    newConfig.contentConfiguration.value.uiSettings[field] = event.target.value
    setConfig({ ...newConfig })
  })

  const editColumnName = ((event, index) => {
    const newConfig = Object.assign({}, config)
    newConfig.contentConfiguration.value.apiSettings.columns[index].name = event.target.value
    setConfig({ ...newConfig })
  })

  const editLinkName = ((event, index, linkIndex) => {
    const newConfig = Object.assign({}, config)
    newConfig.contentConfiguration.value.apiSettings.columns[index].links[linkIndex].name = event.target.value
    setConfig({ ...newConfig })
  })

  const editLinkUrl = ((event, index, linkIndex) => {
    const newConfig = Object.assign({}, config)
    newConfig.contentConfiguration.value.apiSettings.columns[index].links[linkIndex].url = event.target.value
    setConfig({ ...newConfig })
  })

  const isRequired = ((value) => !!value)

  const isFormValid = (() => {
    if (!isRequired(config.contentConfiguration.value.uiSettings.backgroundColor)) return false
    if (!isRequired(config.contentConfiguration.value.uiSettings.textColor)) return false

    let isColumnValid = true
    for (const column of config.contentConfiguration.value.apiSettings.columns) {
      if (!isRequired(column.name)) {
        isColumnValid = false
        break
      }
      for (const link of column.links) {
        // A link url may be empty, but if it exists it must be valid
        const isNameInvalid = !isRequired(link.name)
        const isUrlInvalid = isRequired(link.url) && !validateUrl(link.url)
        if (isNameInvalid || isUrlInvalid) {
          isColumnValid = false
          break
        }
      }
    }
    return isColumnValid
  })

  if(!config) return (
    <Box></Box>
  )

  const borderRadius = '5px'
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            mb: 2,
          }}
        >
          Footer Settings
        </Box>
        <Box>
          <Box sx={{ marginTop: '16px' }}
            component="form"
            noValidate
            onChange={() => {
              setFormValid(isFormValid())
            }}
            ref={formRef}
            autoComplete="off"
          >
            <Box sx={{
              display: 'flex', gap: '6px',
            }}>
              <SdxColorPicker
                label='Background color'
                sx={{flex: 1, mb: 1}}
                size='small'

                value={config.contentConfiguration.value.uiSettings.backgroundColor} onChange={(value) => handleInputChange({target: {value}}, 'backgroundColor')}

                helperText={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.backgroundColor) ? 'This field is required' : ''}
                error={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.backgroundColor)}
              />

              <SdxColorPicker
                label='Text color'
                sx={{flex: 1, mb: 1}}
                size='small'

                value={config.contentConfiguration.value.uiSettings.textColor} onChange={(value) => handleInputChange({target: {value}}, 'textColor')}

                helperText={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.textColor) ? 'This field is required' : ''}
                error={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.textColor)}
              />
            </Box>
            <h4 style={{
              fontWeight: 'bold',
              fontSize: '16px',
            }}>Columns</h4>
            <Box sx={{
              maxHeight: '350px',
              p: 1,
              overflow: 'auto',
              borderRadius,
              outline: '1px solid lightgrey',
            }}>
              <List
                lockVertically
                values={config.contentConfiguration.value.apiSettings.columns.map(column => column.name)}
                onChange={({oldIndex, newIndex}) => {
                  const newConfig = Object.assign({}, config)
                  newConfig.contentConfiguration.value.apiSettings.columns = arrayMove(newConfig.contentConfiguration.value.apiSettings.columns, oldIndex, newIndex)
                  setConfig(newConfig)
                }}
                renderList={(list) => <div {...list.props}>{list.children}</div>}
                renderItem={(listItem) => {
                  const columnIndex = listItem.index as number
                  const column = config.contentConfiguration.value.apiSettings.columns[columnIndex]
                  // const isLast = listItem.value === 'lastIndexId'

                  return (
                    <Box
                      sx={{display: 'flex', flexDirection: 'column'}}
                      {...listItem.props}
                      key={columnIndex}
                    >
                      <Box sx={{display: 'flex'}}>
                        <TextField
                          label='Column name'
                          sx={{

                            width: '100%',
                            my: 1,
                          }}
                          onChange={(ev) => editColumnName(ev, columnIndex)}
                          size='small' type='string' variant='outlined'
                          value={column.name}
                          helperText={checkErrors && !isRequired(column.name) ? 'This field is required' : ''}
                          error={checkErrors && !isRequired(column.name)}
                        />

                        <Box sx={{display: 'flex', mt: 2, alignItems: 'baseline'}}>
                          <Box
                            data-movable-handle
                            sx={{
                              mr: 1,
                              ml: 2,
                              cursor: listItem.isDragged ? 'grabbing' : 'grab',

                              // opacity: isLast ? 0 : 1,
                              // pointerEvents: isLast ? 'none' : 'auto',
                            }}
                          >
                            <DragIcon />
                          </Box>

                          <IconButton
                            sx={{
                              transform: 'translateY(-5px)',
                              // opacity: isLast ? 0 : 1,
                            }}
                            // disabled={isLast}

                            onClick={() => {
                              removeColumn(columnIndex)
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          outline: '1px solid lightgrey',
                          borderRadius,
                          pt: 1,
                          pb: 1,
                          pl: 1,
                          mb: 1,
                          ml: 2,
                        }}
                      >
                        <List
                          lockVertically
                          values={column.links.map(link => link.name)}
                          onChange={({oldIndex, newIndex}) => {
                            const newConfig = Object.assign({}, config)
                            newConfig.contentConfiguration.value.apiSettings.columns[columnIndex].links = arrayMove(newConfig.contentConfiguration.value.apiSettings.columns[columnIndex].links, oldIndex, newIndex)
                            setConfig(newConfig)
                          }}

                          renderList={(list) => <div {...list.props}>{list.children}</div>}
                          renderItem={(listItem2) => {
                            const linkIndex = listItem2.index as number
                            const link = column.links[linkIndex]
                            return (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: '6px',
                                }}
                                {...listItem2.props}
                                key={linkIndex}
                              >
                                <TextField
                                  label='Link name'
                                  sx={{
                                    width: '100%',
                                    my: 1,
                                  }}
                                  onChange={(ev) => editLinkName(ev, columnIndex, linkIndex)}
                                  size='small' type='string' variant='outlined'
                                  value={link.name}
                                  helperText={checkErrors && !isRequired(link.name) ? 'This field is required' : ''}
                                  error={checkErrors && !isRequired(link.name)}
                                />

                                <TextField
                                  label='Link URL (optional)'
                                  sx={{
                                    width: '100%',
                                    my: 1,
                                  }}
                                  onChange={(ev) => editLinkUrl(ev, columnIndex, linkIndex)}
                                  size='small' type='string' variant='outlined'
                                  value={link.url}
                                  helperText={checkErrors && link.url && !validateUrl(link.url) ? 'Valid https URL is required' : ''}
                                  error={checkErrors && !!link.url && !validateUrl(link.url)}
                                />

                                <Box sx={{display: 'flex', mt: 2, alignItems: 'baseline'}}>
                                  <Box
                                    data-movable-handle
                                    sx={{
                                      mr: 1,
                                      ml: 1,
                                      cursor: listItem2.isDragged ? 'grabbing' : 'grab',
                                    }}
                                  >
                                    <DragIcon />
                                  </Box>

                                  <IconButton
                                    sx={{
                                      transform: 'translateY(-5px)',
                                    }}
                                    onClick={() => {
                                      removeLink(columnIndex, linkIndex)
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            )
                          }}
                        />
                        <Button
                          sx={{
                          }}
                          onClick={() => addLink(columnIndex)}
                          variant='outlined'
                        >
                          Add link
                        </Button>
                      </Box>

                    </Box>
                  )
                }}
              />

              <Button
                variant='outlined'
                onClick={addColumn}
              >Add column</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  )
}

import React, { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import { RouteId, SdxRoute, getRoute, renderRoutes } from './routes'
import SdxLoading from './components/SdxLoading/SdxLoading'

const routes: SdxRoute[] = []
const routeIds: RouteId[] = [
  // Add new routes to top, not bottom
  'store_create',
  'store_edit',
  'store_analytics',
  'stores',
  'components',
  'component_create',
  'component_edit',
  'analytics',
  'shopify_plans',
  '404',
  'shopify_settings',
  'store_settings',
  'page_settings',
  'home',
  'validate_plan',
  'view_shopify_plans',
  'store_integrations',
  '/',
  '*',
]
routeIds.forEach(routeId => {
  const r = getRoute(routeId)
  if (r) routes.push(r)
})

const ShopifyRoutes = (): JSX.Element => (
  <Suspense fallback={<SdxLoading />}>
    <Switch>
      {renderRoutes(routes)}
    </Switch>
  </Suspense>
)

export default ShopifyRoutes

import { Box, Button, Paper } from '@mui/material'
import type { FC } from 'react'
import { Resizable } from 'react-resizable'
import EditPermalinkConfigUI from './EditPermalinkConfigUI'

const EditPermalinkConfig: FC = () => {

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        height: `calc(100%)`,
        alignItems: 'stretch',
        justifyContent: 'space-between',
      }}>
        <Paper sx={{
          overflow: 'auto',
          minWidth: 280,
          padding: '24px',
          textAlign: 'center',
          flex: 1,
          mb: '1px',
          mr: '1px',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
          }}>
            <EditPermalinkConfigUI></EditPermalinkConfigUI>
          </Box>
        </Paper>

      </Box>
    </Box>
  )
}

export default EditPermalinkConfig

import React, { FC, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { logging } from 'src/utils/logging'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'
import useGenericContext from 'src/hooks/useGenericContext'
import { getRoutePath } from 'src/routes'

const ValidatePlan: FC = () =>  {

  const { refreshMerchantDetails, merchantDetails } = useGenericContext()
  const history = useHistory()
  const [errorMessage, setErrorMessage] = React.useState('')

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null
    let tryCount = 0
    async function validatePlan () {
      try {
        if (merchantDetails?.plan !== 'NONE') {
          intervalId && clearInterval(intervalId)
          history.push(getRoutePath('home'))
        } else {
          intervalId = setInterval(() => {
            if (tryCount > 3) {
              intervalId && clearInterval(intervalId)
              return setErrorMessage('Error validating plan. Please reach out to support.')
            }
            tryCount++
            refreshMerchantDetails()
          }, 5000)
        }
      } catch (error) {
        logging(error, { tags: { section: 'Validate plan'}})
      }
    }
    validatePlan()
  }, [history, merchantDetails, merchantDetails?.plan, refreshMerchantDetails])

  if (errorMessage) {
    return (
      <div>
        <SdxLoading subText={errorMessage}/>
      </div>
    )
  }

  return (
    <div>
      <SdxLoading subText="Validating your plan ..."/>
    </div>
  )
}

export default ValidatePlan

/* eslint-disable jsx-a11y/alt-text */
import React, { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Autocomplete, TextField } from '@mui/material'
import { ImageType } from 'src/types/interpretor'

const Root = styled('div')({
  '.MuiOutlinedInput-root': {
    padding: '0px !important',
  },
})

export type ProductItem = {
  id: string
  images: ImageType[]
  title: string
}

type ProductSelectorProps = {
  onSelect: (item: ProductItem) => void
  selected?: ProductItem
  items: ProductItem[]
  error?: boolean
}

// TODO: Refactor this to be a generic ItemSelector so that no matter what is actually fed into here, it does not matter.
//       Ticket: https://ur2inc.atlassian.net/browse/DEV-2976
const ProductSelector: FC<ProductSelectorProps> = ({
  onSelect,
  selected,
  items,
  error,
}) => {

  const filterStringRef = useRef<HTMLElement>(null)
  const [filterString, setFilterString] = useState('')
  const [mainProductFocus, setMainProductFocus] = useState(false)

  useEffect(() => {
    if (!mainProductFocus)
      setFilterString('')
  }, [mainProductFocus])

  return (
    <Root>
      <Autocomplete
        // freeSolo ???? why this casts values to string..
        openOnFocus
        onClose={() => {
          // NOTE: setTimeout otherwise the blur doesn't work...
          setTimeout(() => {
            filterStringRef.current?.blur()
          }, 0)
        }}
        clearIcon={filterString ? undefined : <span />}
        getOptionLabel={product => product.title || ''}
        options={items}

        inputValue={filterString}
        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e) return
          const value = e?.target?.value || ''
          setFilterString(value)
        }}
        value={selected}
        onChange={(e, value) => {
          if (value && typeof value === 'object') {
            onSelect(value)
            filterStringRef.current?.blur()
          }
        }}

        renderOption={(props, product) => {
          return (
            <li
              {...props}
            >
              <span
                style={{
                  display: 'flex',
                  pointerEvents: 'none',
                }}>
                <img
                  style={{
                    margin: '0px 10px',
                    objectFit: 'cover',
                  }}
                  loading='lazy'
                  width={20}
                  height={20}
                  src={product.images[0]?.mediumUrl}
                />
                {product.title}
              </span>
            </li>
          )
        }}

        renderInput={(params) => {
          return (
            <TextField
              fullWidth={true}
              onBlur={() => setMainProductFocus(false)}
              onFocus={() => setMainProductFocus(true)}

              inputRef={filterStringRef}
              error={error}

              margin="dense" type="string" variant="outlined"
              inputProps={{
                ...params.inputProps,
                value: filterStringRef.current === document.activeElement ? filterString : selected?.title,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: <img
                  style={{
                    marginLeft: '5px',
                    marginRight: '5px',
                    opacity: selected && !mainProductFocus ? 1 : 0,
                  }}
                  loading='lazy'
                  width={20}
                  height={20}
                  src={selected?.images[0]?.mediumUrl}
                />,
              }}
              InputLabelProps={params.InputLabelProps}
            />
          )
        }}
      />

    </Root>
  )
}

export default ProductSelector

import React, { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Autocomplete } from '@mui/material'
import { Category } from 'src/contexts/types'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import useGenericContext from 'src/hooks/useGenericContext'
import { logging } from 'src/utils/logging'
import { debounce } from 'lodash'

const PREFIX = 'AsyncSelectCategories'

const classes = {
  listbox: `${PREFIX}-listbox`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.listbox}`]: {
    display: 'grid',
    padding: '0px !important',
    gridGap: '20px',
    textAlign: 'center',
    paddingBottom: '18px !important',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fill, 150px)',
  },
}))

interface AsyncSelectCategoriesProps {
  selected?: Category
  onChange: (e, value: Category | null) => void
  inputRef?: React.MutableRefObject<HTMLElement | null>
  previouslySelected?: string[]
  openWhenNotSelected?: boolean
  error?: boolean | undefined
  label?: string | undefined
}

const AsyncSelectCategories: FC<AsyncSelectCategoriesProps> = ({ selected, onChange, inputRef, previouslySelected, openWhenNotSelected, error, label }) => {
  const filterStringRef = useRef<HTMLElement | null>(null)

  const {searchCategories} = useGenericContext()
  const [searchedCategories, setSearchedCategories] = React.useState<Category[]>([])
  const [categorySearchString, setCategorySearchString] = React.useState<string>('')
  const [isFetchingCategories, setIsFetchingCategories] = React.useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [offset, setOffset] = useState(0)
  const limit = 10
  const [handleSetAsyncOffset, setHandleSetAsyncOffset] = useState(false)

  useEffect(() => {
    if ((searchedCategories.length > 0) && !selected) {
      // NOTE: delay needed because there is no batching of hook dependencies so
      //       'items' come in while 'selected' is undefined, then 'selected' comes in
      let timerId
      if (openWhenNotSelected)
        timerId = setTimeout(() => setOpen(true), 200)

      return () => {
        clearTimeout(timerId)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedCategories, selected])

  useEffect(() => {
    // NOTE: wait for end of fething which is asynchronous as it will set offset to -1 if it reached the end
    // however if in the meantime during that request we delete the search string we need to reset the offset
    // but if we do it immediatelly it will get overriden by the fetch categories function
    if (isFetchingCategories) return

    if (!handleSetAsyncOffset) return
    setHandleSetAsyncOffset(false)

    setOffset(0)
  }, [categorySearchString, isFetchingCategories, handleSetAsyncOffset, setHandleSetAsyncOffset])

  // NOTE: call this instead of setOffset(0)
  const handleResetOffset = () => {
    setOffset(0)
    setHandleSetAsyncOffset(true)
  }

  useEffect(() => {
    setIsFetchingCategories(true)

    const fetchCategories = async () => {
      try {
        if (offset === -1) return // reached end

        const nextCategories = await searchCategories(categorySearchString, limit, offset) || []

        if (offset > 0) setSearchedCategories((prev) => [...prev, ...nextCategories])
        else setSearchedCategories(nextCategories)

        if (nextCategories.length < limit) setOffset(-1) // mark reached end
      }
      catch (err) {
        setSearchedCategories([])
        logging(err, {tags: {section: 'get categories in AsyncSelectCategories'}})
      }
      finally {
        setIsFetchingCategories(false)
      }
    }

    const getCategoriesTimeout = setTimeout(fetchCategories, 200)
    return () => clearTimeout(getCategoriesTimeout)
  }, [categorySearchString, searchCategories, offset])

  const onScrollLoadNext = () => {
    if (offset === -1) return
    if (isFetchingCategories) return
    setOffset(offset + limit)
  }
  const debouncedOnScrollLoadNext = debounce(onScrollLoadNext, 100)

  useEffect(() => {
    if (open) setTimeout(() => filterStringRef?.current?.focus(), 0)
  }, [open, filterStringRef])

  const onClose = () => {
    setCategorySearchString('')
    handleResetOffset()
    setOpen(false)

    // NOTE: setTimeout otherwise the blur doesn't work...
    setTimeout(() => filterStringRef.current?.blur(), 0)
  }

  return (
    <Autocomplete
      id='search-categories'
      open={open}
      onClose={onClose}
      classes={{listbox: classes.listbox}}
      options={searchedCategories.filter((category) => !previouslySelected?.find(selectedCategoryId => selectedCategoryId === category.id) || category?.id === selected?.id)}
      filterOptions={x => x} // NOTE: we are doing the filtering on the backend by providing a search string
      noOptionsText='No categories found'
      isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
      clearIcon={categorySearchString ? undefined : <span />}
      getOptionLabel={category => category.title || ''}
      value={selected}
      inputValue={categorySearchString}
      onInputChange={(e, value) => {
        handleResetOffset()
        setCategorySearchString(value)
      }}
      onChange={(e, value) => {
        onChange(e, value)
        onClose()
      }}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listbox = event.currentTarget
          if (listbox.scrollTop + listbox.clientHeight < listbox.scrollHeight - 180) return
          debouncedOnScrollLoadNext()
        },
        style: {
          textAlign: 'center',
          display: 'grid',
          gridGap: '24px',
          justifyContent: 'center',
          gridTemplateColumns: 'repeat(auto-fill, 150px)',
          padding: '0px !important',
          paddingBottom: '18px !important',
        },
      }}
      renderOption={(props, category) => {
        return (
          <li {...props} style={{
            justifyContent: 'center',
          }}>
            <span style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pointerEvents: 'none',
              height: '100%',
              padding: '3px',
              zIndex: 100,
            }}
            >
              <img
                style={{
                  objectFit: 'cover',
                  width: '100px',
                  height: '100px',
                }}
                loading='lazy'
                src={category.featuredImage[0]?.smallUrl}
                alt={category.featuredImage[0]?.altText || category.title}
              />
              <Box
                style={{
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  display: '-webkit-box',
                }}
              >
                <Typography variant='h5'
                  style={{
                    padding: '6px 0px',
                  }}
                >
                  {category.title}
                </Typography>
              </Box>
              {/* <Box
          style={{
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            marginTop: 'auto',
            overflow: 'hidden',
            display: '-webkit-box',
          }}
        >
          {stripHtml(category.description || '')}
        </Box> */}
            </span>
          </li>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField
            onBlur={onClose}
            onFocus={() => setOpen(true)}
            inputRef={(element) => {
              filterStringRef.current = element
              if (inputRef)
                inputRef.current = element
            }}
            fullWidth={true}
            // NOTE: not necessary because it's handled by the Autocomplete but react still throws a warning about an uncontrolled component
            value={categorySearchString}
            label={label || 'Search categories'}
            error={error}
            size='small' margin='dense' type='string' variant='outlined'
            inputProps={{
              ...params.inputProps,
              value: filterStringRef.current === document.activeElement ? categorySearchString : selected?.title || categorySearchString,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Root>
                  {isFetchingCategories ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Root>
              ),
            }}
            InputLabelProps={params.InputLabelProps}
          />
        )
      }}
    />
  )
}

export default AsyncSelectCategories

import React, { createContext, useEffect, useReducer, useCallback } from 'react'
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react'
import type { FC } from 'react'
import type { Store } from 'src/types/auth'
import type { AuthState, Action, AuthProviderProps, AuthContextValue } from './types'
import { loadStoreSelection, saveStoreSelection } from 'src/utils/sessionStorage'
import ErrorPage from 'src/views/ErrorPage'
import { User } from 'src/types/user'
import { logging } from 'src/utils/logging'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'
import { postUserSync } from './api/postUserSync'
import { Box } from '@mui/material'
import { ReactComponent as SdxLogoMark } from 'src/assets/icons/SdxLogoMark.svg'
import { useHistory } from 'react-router'



const dummyUser: User = {
  id: '0000000',
  email: 'none@test_user',
  name: 'Test User',
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: dummyUser,
  selectedStore: 'SampleOne',
  stores: [],
  authError: false,
}

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
  case 'SET_STORE': {
    return {
      ...state,
      selectedStore: action.payload.selectedStore,
    }
  }
  case 'SET_STORES': {
    return {
      ...state,
      selectedStore: action.payload.selectedStore,
      stores:  action.payload.stores,
    }
  }
  case 'INITIALISE': {
    const { isAuthenticated, user, selectedStore, stores } = action.payload

    // prevent execution of login redirect request

    return {
      ...state,
      isAuthenticated,
      isInitialised: true,
      user,
      selectedStore,
      stores,
    }
  }
  case 'LOGIN': {
    const { user, selectedStore } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
      selectedStore,
    }
  }
  case 'LOGOUT': {
    return {
      ...state,
      isAuthenticated: false,
      user: dummyUser,
      selectedStore: '',
      stores: [],
    }
  }
  case 'AUTHERROR': {
    return {
      ...state,
      isAuthenticated: false,
      authError: true,
    }
  }
  default: {
    return { ...state }
  }
  }
}

export const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Cognito',
  logout: () => {
    // Do nothing
  },
  setStore: () => {
    // Do nothing
  },
  refreshStoreList: () => Promise.resolve(),
})

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const { user, signOut, authStatus } = useAuthenticator((context) => [context.user])
  const history = useHistory()

  const setStore = (store: Store) => {
    saveStoreSelection(store)
    dispatch({
      type: 'SET_STORE',
      payload: {
        selectedStore: store,
      },
    })
  }

  const logout = useCallback(() => {
    signOut()

    // refresh tab, stop all network requests, show login page
    history.go(0)

    dispatch({
      type: 'LOGOUT',
    })
  }, [signOut])

  useEffect(() => {
    const initialise = async () => {
      try {
        if (authStatus === 'authenticated') {
          try {

            // Get stores from Permit.io
            const data = await postUserSync()
            const { key, email, firstName, shops = [] } = data?.data || {}
            const stores = shops
            const savedStoreSelection = loadStoreSelection()
            const storeToLoad: string = stores?.includes(savedStoreSelection) ? savedStoreSelection : stores?.[0] || ''

            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true ,
                user: {
                  id: key || '',
                  email: email || '',
                  name: firstName || '',
                },
                selectedStore: storeToLoad,
                stores: (stores && stores?.length > 0) ? stores : [],
              },
            })
          } catch (error) {
            throw new Error('Error fetching user data')
          }
        }
      } catch (err) {
        logging(err, { tags: { section: 'auth error'} })
        dispatch({
          type: 'AUTHERROR',
        })
      }
    }

    initialise()
  }, [authStatus, user])

  console.log('Auth status', authStatus)

  if (state.authError) {
    return <ErrorPage mainText="User authentication error" subText="There was an error trying to authenticate the user" />
  }

  if (authStatus === 'configuring') {
    return <SdxLoading/>
  }

  const components = {
    Header() {
      return (
        <Box textAlign="center" padding='2rem'>
          <SdxLogoMark />
        </Box>
      )
    },
  }

  if (authStatus !== 'authenticated') {
    return <Authenticator hideSignUp={true} components={components}/>
  }

  if (!state.isInitialised) {
    return <SdxLoading/>
  }

  const refreshStoreList = async (): Promise<void> => {
    return  new Promise<void>(async (resolve, reject) => {
      try {
        console.log('Noting is using this')
        resolve()
      } catch (error) {
        console.log('refreshing store list failed', error)
        logging(error, { tags: { section: 'keyloak context'} })
        reject()
      }
    })
  }


  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Cognito',
        logout,
        setStore,
        refreshStoreList,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext

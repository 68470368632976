import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2 } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShop } from '../types'


export type GetSurveyExists = () => Promise<AxiosResponse<{
  'surveyExists': boolean
  }> | undefined>

export type GetSurveyExistsApi = PrependShop<GetSurveyExists>

export const getSurveyExistsApi: GetSurveyExistsApi = async (
  activeShop: string,
) => {
  try {
    const url = '/v1/analytics/survey-exists'

    const cfg = {
      baseURL: analyticsServiceBaseUrl2,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<{
      'surveyExists': boolean
      }>(url , cfg)

    return response
  }

  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get survey exists api' } })
    return error?.data
  }
}

// Check missing styling
import React, { useState } from 'react'
import { Skeleton } from '@mui/material'
import { Select, MenuItem, Box, Typography } from '@mui/material'

import type { FC } from 'react'
import type { BarElement } from '../duck/barElement'

import Icon from 'src/components/Icon/Icon'
import ProgressBarContainer from 'src/components/ProgressBarContainer'
import CardRowsWrapper from 'src/components/CardRowsWrapper'
import UrBaseCard from 'src/components/UrBaseCard'

interface HorizontalProgressTableProps {
  className?: string
  title: string
  icon: string
  data: { page: number, limit: number, total: number, elements: BarElement[] }
  error?: boolean
  isLoading: boolean
  handlePagination: (page: number, limit: number) => void
}

type View = 'order_value' | 'page_view'

const HorizontalProgressTable: FC<HorizontalProgressTableProps> = ({ className, title, icon, data, error, isLoading, handlePagination }) => {
  const fetchLimit = 5

  const loadMoreData = () => {
    // Not yet implemented
    handlePagination(data.elements.length+1, fetchLimit )
  }
  const [view, setView] = useState<View>('order_value')

  return (
    <UrBaseCard
      className={className}
      title={
        isLoading ?
          <Skeleton variant="text" width="20%" />
          : <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} >
            <div>{title}</div>
            <div>
              <Select
                id="analytics-view-select"
                value={view}
                onChange={e => setView(e.target.value as 'order_value' | 'page_view')}
                variant='standard'
                // size='small'
              >
                <MenuItem value={'order_value'}><Typography fontSize="x-small" lineHeight="unset">Sales</Typography></MenuItem>
                <MenuItem value={'page_view'}><Typography fontSize="x-small" lineHeight="unset">Views</Typography></MenuItem>
              </Select>
            </div>
          </Box>
      }
      mainIcon={
        <Icon
          name={icon}
          root={true}
          color="#86A0AC"
          loading={isLoading}
        />
      }
      content={
        <CardRowsWrapper
          data={data.elements}
          initialNumberOfVisibleItems={5}
          loading={isLoading}
          loadMoreData={loadMoreData}
          page={data.page}
          limit={data.limit}
          totalResults={data.total}
          fetchLimit={fetchLimit}
          view={view}
        >
          <ProgressBarContainer />
        </CardRowsWrapper>
      }
      error={error}
      empty={data?.elements?.length ? data.elements.length < 1 : !isLoading}
    />
  )
}

export default HorizontalProgressTable

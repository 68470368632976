import React from 'react'
import { Paper, Typography, Grid, FormControl, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Spacer from 'src/components/Spacer'

const ScriptSettings = ({
  trackingScripts,
  setTrackingScripts,
  trackingScriptConfigLoading,
  submitTrackingScripts,
  headerTrackingScripts,
  setHeaderTrackingScripts,
  headerTrackingScriptConfigLoading,
  submitHeaderTrackingScripts,
  classes,
}) => {
  const { t } = useTranslation()

  return (
    <>

      <Paper className={classes.form}>
        <Typography variant="h3" color="textPrimary" className={classes.header}>
          {t(`Additional Header Scripts`)}
        </Typography>
        <form autoComplete="off" onSubmit={(e) => submitHeaderTrackingScripts(e)}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <div className={classes.formText}>
                {t(`Add your tracking or consent management scripts (e.g., GA, Elevar, Triple Whale, OneTrust) to the header of your SimplicityDX campaigns. Note that scripts are loaded asynchronously.`)}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', width: '70%' }}>
                <FormControl fullWidth>
                  <textarea
                    id="headerTrackingScripts"
                    name="headerTrackingScripts"
                    value={headerTrackingScripts}
                    onChange={(e) => setHeaderTrackingScripts(e.target.value)}
                    aria-describedby="secondary-color"
                    className={classes.trackingScriptsField}
                    rows={5}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Spacer space={2} />
          <Button color="secondary" variant="contained" type="submit" disabled={headerTrackingScriptConfigLoading}>
            {headerTrackingScriptConfigLoading ? t('Saving...') : t('Save')}
          </Button>
        </form>
      </Paper>

      <Paper className={classes.form}>
        <Typography variant="h3" color="textPrimary" className={classes.header}>
          {t(`Additional Body Scripts`)}
        </Typography>
        <form autoComplete="off" onSubmit={(e) => submitTrackingScripts(e)}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <div className={classes.formText}>
                {t(`Add scripts for email capture pop-ups and marketing tools to engage customers with offers like discount codes. This section is perfect for scripts that don't need to be loaded in the header.`)}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', width: '70%' }}>
                <FormControl fullWidth>
                  <textarea
                    id="trackingScripts"
                    name="trackingScripts"
                    value={trackingScripts}
                    onChange={(e) => setTrackingScripts(e.target.value)}
                    aria-describedby="secondary-color"
                    className={classes.trackingScriptsField}
                    rows={5}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Spacer space={2} />
          <Button color="secondary" variant="contained" type="submit" disabled={trackingScriptConfigLoading}>
            {trackingScriptConfigLoading ? t('Saving...') : t('Save')}
          </Button>
        </form>
      </Paper>
    </>
  )
}

export default ScriptSettings

import { PageConfig } from 'src/types/interpretor'

export type PageStatus = 'PUBLISHED' | 'UNPUBLISHED' | 'SCHEDULED'
export const getPageStatus = (pageConfig: PageConfig): PageStatus => {
  const now = new Date().getTime()
  const startDate = pageConfig.startDate
  const endDate = pageConfig.endDate || Infinity

  if (startDate === undefined)
    return 'UNPUBLISHED'

  if (startDate >= endDate) {
    console.warn('Start date must be smaller than the end date.')
    return 'UNPUBLISHED'
  }

  if (now < startDate) return 'SCHEDULED'
  if (now < endDate) return 'PUBLISHED'
  return 'UNPUBLISHED'
}

import React from 'react'
import { Modal, TextContainer} from '@shopify/polaris'

function AccountConnectionModal({ handleChange, active, handleSetConnection }) {

  return (
    <div style={{height: '500px'}}>
      <Modal
        open={active}
        onClose={handleChange}
        title={<div><img src="/static/images/simplicity_circular.png" alt='SDX Icon' width='30px' height='30px' style={{ verticalAlign: 'bottom'}}/>  Reach more shoppers with edge shopping experiences</div>}
        primaryAction={{
          content: 'Connect',
          onAction: handleSetConnection,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Connect your SimplicityDX account to start building edge shopping experiences using the products from your Shopify account.
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  )
}

export default AccountConnectionModal

import { AdsResponse, PrependShop } from 'src/contexts/types'
import { axiosInstance } from 'src/utils/axios'
import { socialServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { AxiosResponse } from 'src/types/axios'

export type GetAds = (accountId: string, limit?: number, offset?: number, search?: string) => Promise<AxiosResponse<AdsResponse> | undefined>
export type GetAdsApi = PrependShop<GetAds>

export const getAdsApi: GetAdsApi = async (activeShop: string, accountId: string, limit?: number, offset?: number, search?: string) => {
  try {
    const url = `/v2/meta-ads/${accountId}/ads?offset=${offset || 0}&limit=${limit || 10}${search ? `&search=${search}` : ''}`

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      shop: activeShop,
    }

    const response = await axiosInstance.get<AdsResponse>(url, {
      baseURL: socialServiceBaseUrl,
      headers,
    })
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'get ads' } })
  }
}

import { axiosInstance } from 'src/utils/axios'
import { componentServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { PrependShop } from 'src/contexts/types'
import { AxiosResponse } from 'src/types/axios'
import { ComponentTypeResponse, ComponentsDefaultPagesRequest } from 'src/views/Components/types'
import { ComponentType } from 'storefront-interpreter/src/api/components'

export type GetAllDefaultPages = () => Promise<AxiosResponse<{ layout: string, components: ComponentTypeResponse[], mlOrderingUsed: boolean[] }[]> | undefined>
export type GetAllDefaultPagesApi = PrependShop<GetAllDefaultPages>

export const getAllDefaultPagesAPI: GetAllDefaultPagesApi = async (activeShop: string) => {
  try {
    const url = `${componentServiceBaseUrl}/v1/pages/dynamic/default`

    const response = await axiosInstance.get(url, {
      baseURL: componentServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    })
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'Failed to get default pages for dynamic pages' } })
    return undefined
  }
}

export type CreateDynamicPages = (data: ComponentsDefaultPagesRequest[]) => Promise<AxiosResponse<{ layout: string, components: ComponentType[] }[]> | undefined>
export type CreateDynamicPagesApi = PrependShop<CreateDynamicPages>

export const createDynamicPagesAPI: CreateDynamicPagesApi = async (activeShop: string, data) => {
  try {
    const url = componentServiceBaseUrl + '/v1/pages/dynamic'

    const response = await axiosInstance.post(url, data, {
      baseURL: componentServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    })
    return response
  } catch (error) {
    logging(error, { tags: { section: 'Failed to create dynamic pages config' } })
  }
}

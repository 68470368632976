import { axiosInstance } from 'src/utils/axios'
import { socialServiceBaseUrl } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShop } from '../types'

export interface MetaUrl {
  downloadUrl: string
}

export type GetMetaDownloadAsyncV2 = (
  startTimestamp: number, endTimestamp: number,
) => Promise<AxiosResponse<object> | undefined>

export type GetMetaDownloadAsyncApiV2 = PrependShop<GetMetaDownloadAsyncV2>

export const getMetaDownloadAsyncApiV2: GetMetaDownloadAsyncApiV2 = async (
  activeShop: string, startTimestamp: number, endTimestamp: number,
) => {
  try {
    const baseUrl = '/v1/meta-ads/insights/async'
    const params = new URLSearchParams()
    params.append('startTimestamp', startTimestamp.toString())
    params.append('endTimestamp', endTimestamp.toString())
    const url = `${baseUrl}?${params.toString()}`

    const cfg = {
      baseURL: socialServiceBaseUrl,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<object>(url, cfg)

    return response
  }
  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get meta ad insights async v2' } })
    throw error
  }
}

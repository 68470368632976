import { configureStore, Store } from '@reduxjs/toolkit'
import { MemoryRouter } from 'react-router-dom'
import React, { ComponentType, ReactElement } from 'react'
import 'src/i18n'
import { Provider } from 'react-redux'
import { middleware } from '../appRedux/store/index'
import root from '../appRedux/store/rootReducer'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { createCustomTheme } from 'src/theme'

import AuthContext from 'src/contexts/CognitoContext'
import { GenericProvider } from 'src/contexts/GenericContext'

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

export const makeStore = (): Store => {
  return configureStore({ reducer: root, middleware })
}

const theme = createCustomTheme()
const wrapComponent = (Component: ComponentType, props = {}): ReactElement => {
  return (
    <MemoryRouter>
      <Provider store={makeStore()}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AuthContext.Provider  value={{
              isAuthenticated: true,
              isInitialised: true,
              user: {
                id:'1234',
                email: 'test@test.com',
                name: 'June May',
              },
              stores: ['SampleOne', 'SampleTwo'],
              selectedStore: 'SampleOne',
              method: 'Cognito',
              logout: () => console.log('logoutfired'),
              setStore: () => console.log('setsorefired'),
              refreshStoreList: async () => console.log('refreshstorelistfired'),
              authError: false,
            }}>
              <GenericProvider view='web' host='testhost' shop='testshop'>
                <Component {...props} />
              </GenericProvider>
            </AuthContext.Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </MemoryRouter>
  )
}

export default wrapComponent

import React from 'react'
import useShopifyContext from 'src/hooks/useShopifyContext'
import ShopifyConnectApp from './ShopifyConnectApp'
import ShopifyRoutes from 'src/shopifyRoutes'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'

function ShopifyEmbeddedApp() {

  const { appLoading, appConnected } = useShopifyContext()

  if (appLoading) {
    return (
      <SdxLoading/>
    )
  }

  if (!appConnected) {
    return (
      <ShopifyConnectApp />
    )
  }

  return (
    <ShopifyRoutes />
  )
}

export default ShopifyEmbeddedApp


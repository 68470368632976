import React, { FC } from 'react'
import { Box, IconButton } from '@mui/material'
import { List, arrayMove } from 'react-movable'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import ProductSelectorLarge, { ProductItemFull } from 'src/components/ProductSelectorLarge'
import { ComponentTypeResponse, getFullFields, getMinifiedFields } from 'src/views/Components/types'
import { ContainerTypeEnum, DisplayTypesEnum } from 'src/views/ComponentSettingsPage/types'
import { getInitialStorePrimitiveSettings } from 'src/contexts/GenericContext'
import { ContainerTypeSelector, DisplayTypeSelector, ImageFitSelector } from './SharedSelectors'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  currentSelectedProductMap: Record<string, ProductItemFull>
  backgroundGradient: string
  openWhenNotSelected: boolean | undefined
}

export const ManualProductSelectionInput: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  currentSelectedProductMap,
  backgroundGradient,
  openWhenNotSelected,
}) => {
  const lastIndexId = 'lastIndexId'
  return (
    componentConfig.contentConfiguration.type === 'PRODUCT_PRIMITIVE' ?
      <Box>
        <div style={{
          display: 'flex',
          gap: 4,
          marginBottom: 2,
        }}>
          <ImageFitSelector
            value={componentConfig.contentConfiguration.value.uiSettings.imageFit || getInitialStorePrimitiveSettings().product.uiSettings.imageFit}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
              newComponent.contentConfiguration.value.uiSettings.imageFit = value
              setComponentConfig(newComponent)
            }}
            section="product"
          />
          <DisplayTypeSelector
            value={componentConfig.contentConfiguration.value.uiSettings.fields.length > 1 ? DisplayTypesEnum.EXTENDED : DisplayTypesEnum.MINIFIED}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
              newComponent.contentConfiguration.value.uiSettings.fields =
                value === DisplayTypesEnum.MINIFIED ? getMinifiedFields() : getFullFields()
              setComponentConfig(newComponent)
            }}
          />
          <ContainerTypeSelector
            value={componentConfig.containerType as ContainerTypeEnum}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return
              newComponent.containerType = value
              setComponentConfig(newComponent)
            }}
          />
        </div>
        <List
          lockVertically
          values={componentConfig.contentConfiguration.value.apiSettings.productIds.concat([lastIndexId])}
          onChange={({ oldIndex, newIndex }) => {
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return

            newComponent.contentConfiguration.value.apiSettings.productIds = arrayMove(newComponent.contentConfiguration.value.apiSettings.productIds, oldIndex, newIndex)
            setComponentConfig(newComponent)
          }}
          renderList={(list) => <div {...list.props}>{list.children}</div>}
          renderItem={(listItem) => {
            const { isDragged } = listItem
            const prodId = listItem.value
            const j = listItem.index
            const isLast = listItem.value === lastIndexId
            return (
              <div
                {...listItem.props}
                key={isLast ? j : prodId}
              >
                <div style={{
                  paddingLeft: '20px',
                  background: backgroundGradient,
                }}>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        marginRight: '12px',
                      }}
                    >
                      <ProductSelectorLarge
                        openWhenNotSelected={openWhenNotSelected}
                        onSelect={(item) => {
                          if (!item) return
                          currentSelectedProductMap[item.productId] = item
                          const newComponent: ComponentTypeResponse = { ...componentConfig }
                          if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return

                          if (isLast) {
                            newComponent.contentConfiguration.value.apiSettings.productIds = [...newComponent.contentConfiguration.value.apiSettings.productIds, item.productId]
                          }
                          else {
                            newComponent.contentConfiguration.value.apiSettings.productIds =
                              newComponent.contentConfiguration.value.apiSettings.productIds
                                .map(productId => productId === prodId ? item.productId : productId)
                          }
                          setComponentConfig(newComponent)
                        }}
                        selected={currentSelectedProductMap[prodId]}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        previouslySelected={componentConfig?.contentConfiguration?.value.apiSettings?.productIds}
                        label='Component Product'
                      />
                    </div>

                    <div
                      data-movable-handle
                      style={{
                        margin: '16px 6px 10px 0px',
                        cursor: isDragged ? 'grabbing' : 'grab',

                        opacity: isLast ? 0 : 1,
                        pointerEvents: isLast ? 'none' : 'auto',
                      }}
                    >
                      <DragIcon />
                    </div>

                    <IconButton
                      style={{
                        opacity: isLast ? 0 : 1,
                      }}
                      disabled={isLast}

                      onClick={() => {
                        const newComponent: ComponentTypeResponse = { ...componentConfig }
                        if (newComponent.contentConfiguration.type !== 'PRODUCT_PRIMITIVE') return

                        newComponent.contentConfiguration.value.apiSettings.productIds = newComponent.contentConfiguration.value.apiSettings.productIds.filter(productId => productId !== prodId)
                        setComponentConfig(newComponent)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            )
          }}
        />
      </Box>
      :
      <div></div>
  )
}

import React, { useCallback, useEffect } from 'react'

import type { FC }from 'react'
import { Page, Spinner } from '@shopify/polaris'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material'
import StarIcon from '@mui/icons-material/StarBorder'
import { getAvailablePlans, registerShopifyPlan, ShopifyPlans } from './duck/api'
import { useNavigate } from '@shopify/app-bridge-react'
import useGenericContext from 'src/hooks/useGenericContext'


const ShopifyPlansPage: FC = () => {
  const { shop } = useGenericContext()
  const navigate = useNavigate()
  const [plans, setPlans] = React.useState<ShopifyPlans[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string>('')

  useEffect(() => {
    const getPlans = async () => {
      try {
        const shopifyPlans = await getAvailablePlans(shop)
        if (shopifyPlans && shopifyPlans.data) {
          setPlans(shopifyPlans.data)
          setLoading(false)
          setError('')
        }
      } catch (e) {
        setError('An error occurred while fetching plans')
        setLoading(false)
      }
    }
    getPlans()
  }, [])

  const subscribeToPlan = useCallback(async(plan: string) => {
    try {
      setLoading(true)
      const response = await registerShopifyPlan(plan, shop)
      if (!response?.data) throw new Error('An error occurred while subscribing to plan.')
      navigate(response?.data.confirmationUrl)
    } catch (e) {
      setLoading(false)
      setError('An error occurred while subscribing to plan. Please reach out to the team for support.')
    }
  }, [navigate, shop])

  return (
    <Page>
      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography variant="h4" align="center" color="text.secondary" component="p">
          SimplicityDX Insights product allows you to monitor and uncover insights on the performance of your social advertising spend. With a simple one click setup,
          you get tons of insights about the how traffic is directed to your shopping sites.
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        {loading && <Box sx={{textAlign: 'center'}}><Spinner/></Box>}
        {error && <Box sx={{textAlign: 'center'}}>{error}</Box>}
        {!loading && !error && <Grid container spacing={5} alignItems="flex-end" justifyContent="center">
          {plans.map((plan) => (
            <Grid
              item
              key={plan.name}
              xs={12}
              sm={plan.name === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={plan.name}
                  // subheader={plan?.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={plan.type === 'BASE' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {plan.currency}
                    </Typography>
                    <Typography component="h2" variant="h3" color="text.secondary">
                      {plan.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /{plan.intervalDays === 30 ? 'month' : 'year'}
                    </Typography>
                  </Box>
                  <Box sx={{mb: 2}}>
                    {plan.description}
                  </Box>
                  {plan.trialDays && <Typography
                    variant="subtitle1"
                    align="center"
                  >
                    {plan.trialDays} days free trial
                  </Typography>}
                  {plan.discountPct && <Typography
                    variant="subtitle1"
                    align="center"
                  >
                    {plan.discountPct}% discount
                  </Typography>}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={'contained'}
                    onClick={() => subscribeToPlan(plan.name)}
                  >
                    Subscribe
                  </Button>
                </CardActions>
                {plan.discountIntervals && <Typography
                  variant="caption"
                  align="center"
                  ml={3}
                >
                    *Discount applies for {plan.discountIntervals} cycles only
                </Typography>}
              </Card>
            </Grid>
          ))}
        </Grid>}
      </Container>
    </Page>
  )
}

export default ShopifyPlansPage

import { productServiceBaseUrl } from 'src/config'
import { CategoryPrimitive } from '../types'
import { getMultiSourceImageWithDefaults } from './attachImageUrlToPageConfigs'
import { NormalizedCategoryPrimitive } from 'storefront-interpreter/src/api/getCategoryPrimitives'
import React from 'react'
import { logging } from 'src/utils/logging'

const normalizeCategoryPrimitive = (categoryPrimitive: CategoryPrimitive): NormalizedCategoryPrimitive => {
  return {
    id: categoryPrimitive.id,
    title: categoryPrimitive.title,
    description: React.createElement('span'), // getElementFromHtmlString(categoryPrimitive.description),
    ...getMultiSourceImageWithDefaults(categoryPrimitive.featuredImage),
    url: categoryPrimitive.cdpUrl || '',
    type: 'CATEGORY_PRIMITIVE',
  }
}

export const getCategoryPrimitives = async (shop: string, categoryIds: string[]) => {
  try {
    const config : RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        shop: shop,
      },
    }

    const query = {} as any
    if (categoryIds) query['categoryIds'] = categoryIds.join(',')
    const queryString = new URLSearchParams(query).toString()
    const url = `${productServiceBaseUrl}/v1/categories/primitives/?${queryString}`

    // .then handles a non-200 response from server, .catch handles the fetch api failing.
    const response: CategoryPrimitive[] = await fetch(url, config)
      .then(async (r) => {
        if (!r.ok) {
          const errBody = await r.json()
          throw new Error(`request failed with status ${r.status}: ${errBody.error}`)
        }
        return r.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    return response.map(normalizeCategoryPrimitive)
  } catch(error) {
    logging(error, { tags: { section: 'getCategoryPrimitives' } })
  }
}

import { CampaignTicker } from 'src/contexts/api/getCampaignTickers'

export const mergeByProperty = (target: CampaignTicker[], source: CampaignTicker[], prop: string) => {
  source.forEach(sourceElement => {
    const targetElement = target.find(el => {
      return sourceElement[prop] === el[prop]
    })
    targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement)
  })
  return target
}

import { axiosInstance } from 'src/utils/axios'
import { ComponentsSearchFilter, PrependShop } from 'src/contexts/types'
import { componentServiceBaseUrl } from 'src/config'
import { ComponentTypeResponse } from 'src/views/Components/types'
import { logging } from 'src/utils/logging'

import { ContainerConfig } from 'storefront-interpreter/src/api/components'
import { AxiosResponse } from 'src/types/axios'

export type GetComponents = (searchFilter?: ComponentsSearchFilter) => Promise<AxiosResponse<ComponentTypeResponse[]> | undefined>
export type GetComponentsApi = PrependShop<GetComponents>

export const getComponentsApi: GetComponentsApi = async (activeShop: string, searchFilter?: ComponentsSearchFilter) => {
  try {
    let url = '/v1/components'

    if(searchFilter) {
      const searchFilterString = Object.keys(searchFilter).filter((key) => {
        if(searchFilter[key]) {
          return `${key}`
        }
      }).join(',')
      url += `?classes=${searchFilterString}`
    }

    const response = await axiosInstance.get<ComponentTypeResponse[]>(url, {
      baseURL: componentServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    })

    return response
  } catch (error) {
    logging(error, { tags: { section: 'get components api' } })
  }
}

export type GetComponent = (componentId: string, componentType?: string) => Promise<AxiosResponse<ComponentTypeResponse> | undefined>
export type GetComponentApi = PrependShop<GetComponent>

export const getComponentApi: GetComponentApi = async (activeShop: string, componentId: string, componentType?: string) => {
  try {
    let url = `/v1/components/${componentId}`

    if (componentType) url = url + `?componentType=${componentType}`

    const response =  await axiosInstance.get<ComponentTypeResponse>(url, {
      baseURL: componentServiceBaseUrl, headers: {
        'shop': activeShop,
      },
    })
    return response
  } catch (error) {
    logging(error, { tags: { section: 'get components api' } })
  }
}

export type PublishComponent = (payload: ContainerConfig, componentId?: string) => Promise<AxiosResponse<ComponentTypeResponse> | undefined>
export type PublishComponentsApi = PrependShop<PublishComponent>

export const publishComponentApi: PublishComponentsApi = async (activeShop: string, payload: ContainerConfig, componentId?: string) => {
  try {

    const url = componentId ? `/v1/components/${componentId}` : '/v1/components'

    const headers = {
      'shop': activeShop,
    }

    const response = await axiosInstance[componentId ? 'put' : 'post']<ComponentTypeResponse>(url, payload, {
      baseURL: componentServiceBaseUrl, headers,
    })

    return response
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'publish component api' } })
  }
}



import { axiosInstance } from 'src/utils/axios'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { ecommerceServiceBaseUrl } from 'src/config'
import { MerchantDetails } from 'src/contexts/types'

export interface ShopifyPlans
  {
    'name': string
    'description': string
    'type': MerchantDetails['plan']
    'intervalDays': 30 | 365
    'price': number
    'currency': string
    'trialDays': number
    'isTest': boolean
    'discountPct': number
    'discountIntervals': number
    'isActive': boolean
    'isSelected': boolean
  }

export const getAvailablePlans = async (shop: string): Promise<AxiosResponse<ShopifyPlans[] > | undefined> => {
  try {
    const response =  await axiosInstance.get<ShopifyPlans[]>(`/v1/plans`, { baseURL: ecommerceServiceBaseUrl, headers: { shop } })
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'Shopify plans'}})
    return undefined
  }
}

export const registerShopifyPlan = async (planName: string, shop: string): Promise<AxiosResponse<{ 'confirmationUrl': string }> | undefined> => {
  try {
    const cfg = {
      baseURL: ecommerceServiceBaseUrl,
      headers: { shop },
    }
    const response =  await axiosInstance.put<{ 'confirmationUrl': string }>(`/v1/shopify/plans`, { name: planName }, cfg)
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'Shopify plan registration'}})
    return undefined
  }
}

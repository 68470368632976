import { Box, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import AnalyticsChart from 'src/components/AnalyticsChart'
import { NewCampaignSummaryMetric, getDefaultSummaryMetrics } from 'src/contexts/api/getCampaignSummaryMetrics'
import useGenericContext from 'src/hooks/useGenericContext'
import { formatDataValue } from 'src/utils/analyticsHelpers'
import { analyticsHomeRefreshMillis } from 'src/config'
import { logging } from 'src/utils/logging'
import { currencySymbolMap } from 'src/utils/currencySymbolMap'
import { formatPercent } from 'src/utils/helpers'

export const AnalyticsCharts = () => {
  const {homeCustomDate, getCampaignSummaryMetrics, isBrand} = useGenericContext()
  const [items, setItems] = useState<NewCampaignSummaryMetric[]>(getDefaultSummaryMetrics())
  const [isLoading, setIsLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(false)
  const [lastFetchedCustomDate, setLastFetchedCustomDate] = useState<number[]>([])

  useEffect(() => {
    const callback = async() => {
      try {
        if (!homeCustomDate[0] && !homeCustomDate[1]) return

        if (
          lastFetchedCustomDate[0] !== homeCustomDate[0] ||
        lastFetchedCustomDate[1] !== homeCustomDate[1]
        ) {
          setItems(getDefaultSummaryMetrics())
          setLoadingError(false)
          setIsLoading(true)
          setLastFetchedCustomDate(homeCustomDate)
        }

        const result = await getCampaignSummaryMetrics(isBrand, homeCustomDate[0], homeCustomDate[1])
        setLoadingError(false)
        setIsLoading(false)
        if (result && result.data) {setItems(result.data)} else throw new Error('No data')
      } catch (error) {
        logging(error, {tags: {section: 'getCampaignSummaryMetrics'}})
        setLoadingError(true)
      }
    }
    callback()

    const interval = setInterval(callback, analyticsHomeRefreshMillis)
    return () => clearInterval(interval)
  }, [homeCustomDate, getCampaignSummaryMetrics, lastFetchedCustomDate, isBrand])

  return (

    <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row'}} spacing={1}>
      {/* Analytics Charts */}

      {items.map((item, i) =>  {
        const m = item.metric

        const isGraph = item.graph.length > 0
        return <Box
          key={m.label + i}
          sx={{
            backgroundColor: 'white',
            borderRadius: '12px',
            width: '100%',
          }}>

          <AnalyticsChart
            error={loadingError}
            isLoading={isLoading}
            currency={(m.value.metricType === 'CURRENCY' && m.value.currency) || undefined}
            title={m.label}
            subTitle={
              m.value.metricType === 'CURRENCY' ? formatDataValue(m.value.value, 2, currencySymbolMap[m.value.currency])
                : m.value.metricType === 'PERCENT' ? formatPercent(m.value.value)
                  : m.value.metricType === 'COUNT' ? formatDataValue(m.value.value, 0) : ''
            }
            lift={item.lift.percent}
            flipLiftSign={item.lift.isFlipped}
            data={isGraph ?
              item.graph.map(x => ({timestamp: x.timestamp, value: x.data, label: ''}))
              : item.pieChart.map(x => ({value: x.value.value || 0, label: x.label}))}
            chartType={isGraph ? 'area' : 'donut'}
          />
        </Box>
      })}
    </Stack>
  )
}

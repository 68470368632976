import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { stripHtml } from 'src/utils/helpers'
import { Post } from 'src/contexts/types'
import { PostStats, PostDate } from './PostStats'


type PostSelectorProps = {
  onRenderNext: () => void

  onSelect: (item: Post) => void
  selected: Post | null | undefined
  items: Post[]

  error?: boolean

  nameRef?: React.MutableRefObject<HTMLElement | null>
  nameErrorString?: string
  inputRef?: React.MutableRefObject<HTMLElement | null>
  label?: string

  campaignName: string
  onCampaignNameChange: (name: string) => void
  campaignDescription: string
  onCampaignDescriptionChange: (name: string) => void
  defaultOpen?: boolean
  handleSearchChange: (search: string) => void
  searchString: string
}

const PostSelector: FC<PostSelectorProps> = ({
  onRenderNext,

  onSelect,
  selected,
  items,
  error,
  nameRef,
  nameErrorString,
  inputRef,
  label,
  campaignName,
  onCampaignNameChange,
  campaignDescription,
  onCampaignDescriptionChange,
  defaultOpen = true,
  handleSearchChange,
  searchString,
}) => {

  const filterStringRef = useRef<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)
  const [calledNextItemsLength, setCalledNextItemsLength] = useState(-1)
  const skipDefaultOpen = useRef(false)

  useEffect(() => {
    if ((items.length > 0) && !selected && defaultOpen && !skipDefaultOpen.current) {
      // NOTE: delay needed because there is no batching of hook dependencies so
      //       'items' come in while 'selected' is undefined, then 'selected' comes in
      const timerId = setTimeout(() => setOpen(true), 200)
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [defaultOpen, items, selected])

  useEffect(() => {
    if (!open) {
      if (searchString !== '') {
        handleSearchChange('')
        skipDefaultOpen.current = true
      }
    }
  }, [handleSearchChange, open, searchString])

  useEffect(() => {
    if (open) setTimeout(() => filterStringRef?.current?.focus(), 0)
  }, [open, filterStringRef])

  useEffect(() => {
    if (items.length === 10) {
      setCalledNextItemsLength(-1)
    }
  }, [items.length])

  const close = () => {
    filterStringRef.current?.blur()
    setOpen(false)
  }

  const handleRenderNext = (scrollElement?: Element) => {
    if (!scrollElement) return
    const nextTreshold = 200
    const distanceFromEnd = scrollElement.scrollHeight - Math.round(scrollElement.scrollTop + scrollElement.getBoundingClientRect().height)

    if (distanceFromEnd < nextTreshold) {
      setCalledNextItemsLength(items.length)
      if (calledNextItemsLength !== items.length){
        onRenderNext()
      }
    }
  }

  return (
    <Box sx={{
      position: 'relative',
    }}>
      <Button
        style={{
          top: '4px',
          display: open ? 'none' : undefined,
        }}
        variant='outlined'
        onClick={() => setOpen(!open)}
      >
        {open ? 'Close' : '← Back to'} Gallery
      </Button>

      <Autocomplete
        filterOptions={(options, state) => options}
        ListboxProps={{
          // ref: (r) => {handleRenderNext(r)},
          onScroll: (e) => handleRenderNext(e.target as HTMLElement),
          style: {
            textAlign: 'center',
            display: 'grid',
            gridGap: '24px',
            justifyContent: 'center',
            gridTemplateColumns: 'repeat(auto-fill, 150px)',
            padding: '0px !important',
            paddingBottom: '18px !important',
          },
        }}
        open={open}
        noOptionsText={items.length === 0 && searchString !== '' ? 'Searching...' : 'No options'}
        // Disabling this prevent clicking an empty input box from closing popup
        // onClose={() => {
        //   // NOTE: setTimeout otherwise the blur doesn't work...
        //   setTimeout(() => {
        //     close()
        //   }, 0)
        // }}
        clearIcon={searchString ? undefined : <span />}
        getOptionLabel={item => item.caption || ''}
        options={items}

        inputValue={searchString}
        onInputChange={(e, value) => {
          if (e?.type !== 'change') return
          handleSearchChange(value)
        }}
        value={selected}
        onChange={(e, value) => {
          if (value && typeof value === 'object') {
            onSelect(value)
            close()
          }
        }}

        renderOption={(props, item) => {
          return (
            <li
              {...props}
              style={{
                display: 'unset',
                width: '150px',
                borderBottom: '1px solid lightgrey',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                backgroundColor: 'unset !important',
              }}
            >
              <span style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pointerEvents: 'none',
                height: '100%',
              }}
              >
                <Box style={{
                  position: 'relative',
                }}>
                  <div style={{
                    position: 'absolute',
                    right: 0,
                    margin: '10px',
                    padding: '2px 5px',
                    backgroundColor: '#F2F4F7',
                  }}>
                    {item.mediaType}
                  </div>
                  <img
                    style={{
                      objectFit: 'contain',
                      width: '150px',
                      height: '150px',
                    }}
                    loading='lazy'
                    src={item.thumbnail}
                    alt=''
                  />
                </Box>
                <Box
                  style={{
                    width: '100%',
                    margin: '10px 0px',
                  }}
                >
                  <PostStats comments={item.commentCount} likes={item.likeCount} />
                </Box>
                <Box
                  style={{
                    color: 'black',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    marginTop: 'auto',
                    overflow: 'hidden',
                    display: '-webkit-box',
                  }}
                >
                  {item?.caption && stripHtml(item.caption)}
                </Box>
                <Box>
                  <PostDate timestamp={item.timestamp} />
                </Box>
              </span>
            </li>
          )
        }}

        renderInput={(params) => {
          return (
            <Box>
              {
                !(!open && selected) ? '' :
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    transform: 'translateY(20px)',
                  }}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '12px',
                      justifyContent: 'space-between',
                    }}>
                      <Box style={{
                        position: 'relative',
                      }}>
                        <div style={{
                          position: 'absolute',
                          right: 0,
                          margin: '10px',
                          padding: '2px 5px',
                          backgroundColor: '#F2F4F7',
                        }}>
                          {/* TODO: mediaType string format */}
                          {selected.mediaType}
                        </div>
                        <img
                          style={{
                            width: 150,
                            height: 150,
                            objectFit: 'contain',
                          }}
                          alt=''
                          src={selected.thumbnail}
                        />
                      </Box>
                      <Box style={{
                        padding: '6px',
                      }}>
                        <PostDate timestamp={selected.timestamp} />
                      </Box>
                      <PostStats comments={selected.commentCount} likes={selected.likeCount} />
                    </div>

                  </div>
              }
              <Box
                style={{
                  opacity: open ? 1 : 0,
                }}
              >
                <TextField
                  fullWidth={true}
                  onBlur={() => setTimeout(() => close(), 1)}
                  onFocus={() => { if (!selected) setOpen(true) }}

                  // NOTE: not necessary because it's handled by the Autocomplete but
                  //       react still throws a warning about an uncontrolled component
                  value={searchString}

                  inputRef={(element) => {
                    filterStringRef.current = element
                    if (inputRef)
                      inputRef.current = element
                  }}

                  label={label}
                  error={error}

                  margin="dense" type="string" variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    value: filterStringRef.current === document.activeElement ? searchString : '',
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <img
                      alt={selected?.caption}
                      style={{
                        marginLeft: '5px',
                        marginRight: '5px',
                        opacity: selected && !open ? 1 : 0,
                      }}
                      loading='lazy'
                      width={20}
                      height={20}
                      src={selected?.thumbnail}
                    />,
                    endAdornment: null,
                  }}
                  InputLabelProps={params.InputLabelProps}
                />
              </Box>
            </Box>
          )
        }}
      />
      {
        !open && selected &&
        <div style={{

          position: 'absolute',
          right: 0,
          top: 37,
          display: 'flex',
          flexDirection: 'column',
          width: 'calc(100% - 166px)',
          justifyContent: 'space-between',
        }}>
          <div style={{
            width: '100%',
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <TextField
                style={{
                  flex: 1,
                  marginTop: 20,
                }}
                margin="dense" type="string" variant="outlined"
                label="Headline"

                inputRef={(element) => {
                  if (nameRef) nameRef.current = element
                }}
                error={!!nameErrorString}
                helperText={nameErrorString}
                value={campaignName}
                onChange={(e) => {
                  onCampaignNameChange(e.target.value)
                }}
              />
              <TextField
                multiline
                minRows={7}
                maxRows={10}
                style={{
                  flex: 1,
                }}
                margin="dense" type="string" variant="outlined"
                label="Optional Subtitle"

                value={campaignDescription}
                onChange={(e) => onCampaignDescriptionChange(e.target.value)}
              />
            </div>
          </div>
          <div>
          </div>
        </div>
      }
    </Box >
  )
}

export default PostSelector

import { FC } from 'react'
import { Box, Skeleton, Typography, useTheme } from '@mui/material'
import Chart from 'react-apexcharts'
import { ReactComponent as LiftIcon } from 'src/assets/icons/Lift.svg'
import { CurrencyCode, currencySymbolMap } from 'src/utils/currencySymbolMap'
import { formatNumber } from '../utils/analyticsHelpers'
import { formatPercent } from 'src/utils/helpers'
import Icon from './Icon/Icon'

const AnalyticsChart: FC<{
  error?: boolean
  isLoading: boolean
  currency?: CurrencyCode
  title: string
  subTitle: string
  lift?: number
  flipLiftSign?: boolean
  data: {timestamp?: number, value: number, label: string}[]
  chartType: string
  comingSoon?: string
}> = ({isLoading, currency, title, subTitle, lift, flipLiftSign, data, chartType, comingSoon, error}) => {
  const theme = useTheme()
  const isLiftNegative = lift !== undefined && lift < 0
  const isLiftZero = lift === 0
  let iconColorPositive = !isLiftNegative
  if (flipLiftSign) iconColorPositive = !iconColorPositive

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      padding: '12px',
      display: 'flex',
    }}>
      <Box sx={{
        minWidth: 5,
      }}>
        <Typography sx={{
          fontSize: '14px',
          fontWeight: 700,
          whiteSpace: 'nowrap',
        }}>
          {title}
        </Typography>

        {
          comingSoon && <Typography sx={{
            color: 'lightgrey',
          }}>
            {comingSoon}
          </Typography>
        }

        {
          !comingSoon && <Typography sx={{
            fontSize: '20px',
            fontWeight: 700,
          }}>
            {isLoading ? <Skeleton /> : subTitle}
          </Typography>
        }

        {!comingSoon &&
          <Box sx={{
            fontSize: '12px',
            color: (isLoading || isLiftZero) ? 'lightgrey' : (iconColorPositive ? theme.palette.brand.liftPositive: theme.palette.brand.liftNegative),
          }}>
            <LiftIcon
              style={{
                transform: (isLoading || isLiftZero) ? 'rotate(45deg)' : ( isLiftNegative ? 'rotate(90deg)' : ''),
                marginRight: '5px',
                position: 'relative',
                top: '1px',
              }}
            />
            <div>
              {isLoading ? <Skeleton width={30} /> : formatPercent(lift)}
            </div>
          </Box>
        }
      </Box>

      <Box sx={{
        position: chartType === 'donut' ? '' : 'relative',
        height: '69px',
        width: '100%',
        '& > *': {
          position: 'absolute',
          bottom: '-14px',
          right: '0',
        },
        '& svg': {
          height: '100%',
        },
        '& .apexcharts-datalabels text': {
          background: 'rgba(0,0,0,0)',
          fill: 'black',
          filter: 'none',
          fontSize: '12px',
        },
      }}>
        {error &&  <Icon name='NoData' root={true} size={60}/>}
        {/* AREA */}
        {chartType === 'area' && <Chart
          options={
            {
              colors: ['#61A4E4'],
              chart: {
                toolbar: {
                  show: false,
                },
                foreColor: '#ccc',
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                width: 1,
              },
              grid: {
                borderColor: '#44a047',
                yaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              fill: {
                type: 'gradient',
                colors: ['#61A4E4'],
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.5,
                  opacityTo: 0.9,
                  stops: [0, 200, 140],
                },
              },
              markers: {
                size: 0,
              },
              yaxis: {
                floating: true,
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
                labels: {
                  show: false,
                },
              },
              xaxis: {
                floating: true,
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
                labels: {
                  show: false,
                },
              },
              tooltip: {
                enabled: true,
                x: {
                  formatter(val: number, opts?: any): string {
                    const date = new Date(val)
                    return date.toLocaleDateString()
                  },
                },
              },
            }
          }
          series={[{
            name: 'Value',
            data: data ? data.map(item => ({
              x: item.timestamp,
              y: item.value,
            })) : [],
          }]}
          type='area'
          width='100%'
          height='50px'
          sx={{
            opacity: '0.3',
          }}
        />}
        {/* DONUT */}
        {chartType === 'donut' && <Chart
          options={{
            labels: data.map(item => item.label),
            legend: {
              show: false,
            },
            stroke: {
              width: 0,
            },
            dataLabels: {
              formatter(val, opts) {
                return Number(val).toFixed(0) + '%'
              },
            },
            tooltip: {
              y: {
                formatter: function(val) {
                  return `${currency? currencySymbolMap[currency] : ''}${formatNumber(val)}`
                },
              },
            },
          }}
          style={{
            top: 'auto',
            bottom: '0px',
            left: 'auto',
            right: '0',
            display: 'flex',
            justifyContent: 'center',
          }}
          series={data.map(item => item.value)}
          type='donut'
          width='85'
        />}
      </Box>

    </Box >
  )
}

export default AnalyticsChart

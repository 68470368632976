import React, { FC, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography,   Button, TextField, MenuItem, Box, Modal, FormControl, Snackbar } from '@mui/material'

import Icon from 'src/components/Icon/Icon'

import { ReactComponent as CopyIcon } from 'src/assets/icons/copy.svg'
import { ReactComponent as PreviewLinkIcon } from 'src/assets/icons/PreviewLinkIcon.svg'

const PREFIX = 'StorefrontUtmPicker'

const classes = {
  select: `${PREFIX}-select`,
  linkModal: `${PREFIX}-linkModal`,
  copyIcon: `${PREFIX}-copyIcon`,
  linkSelect: `${PREFIX}-linkSelect`,
  linkSelectIcon: `${PREFIX}-linkSelectIcon`,
  htmlHook: `${PREFIX}-htmlHook`,
  htmlHookInput: `${PREFIX}-htmlHookInput`,
}

const Root = styled('span')((
  {
    theme,
  },
) => ({
  [`& .${classes.select}`]: {
    textAlign: 'left',
  },

  [`& .${classes.linkModal}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    maxWidth: 460,
    width: '100%',
    padding: '24px',
    borderRadius: '4px',
  },

  [`& .${classes.copyIcon}`]: {
    width: 12,
    '& path': {
      stroke: 'white',
    },
  },

  [`& .${classes.linkSelect}`]: {
    '& #linkSocialChannel': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  [`& .${classes.linkSelectIcon}`]: {
    position: 'relative',
    height: 16,
    display: 'inline-block',
    overflow: 'hidden',
    marginRight: 4,
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },

  [`& .${classes.htmlHook}`]: {
    height: '100%',
  },

  [`& .${classes.htmlHookInput}`]: {
    padding: 0,
    height: '100%',

    '& textarea': {
      height: '200% !important',
      overflow: 'auto !important',
    },
  },
}))

export type StorefrontUtmPickerProps = {
  experienceUrl?: string
}

export const StorefrontUtmPicker: FC<StorefrontUtmPickerProps> = ({
  experienceUrl,
}) => {


  const [copied, setCopied] = useState(false)
  const [link, setLink] = useState('')
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const [linkSocialChannel, setLinkSocialChannel] = useState('')
  const [linkCampaignName, setLinkCampaignName] = useState('')

  useEffect(() => {
    getLink()
  }, [linkSocialChannel, linkCampaignName])


  const handleOpenLinkModal = () => {
    getLink()
    setOpenLinkModal(true)
  }

  const handleCloseLinkModal = () => {
    setOpenLinkModal(false)
    setLinkSocialChannel('')
    setLinkCampaignName('')
    setLink('')
  }

  const handleChangeLinkSocialChannel = (event) => {
    setLinkSocialChannel(event?.target?.value)
  }

  const handleChangeLinkCampaignName = (event) => {
    setLinkCampaignName(event?.target?.value)
  }

  const copyLink = () => {
    if (copied === false) {
      navigator.clipboard.writeText(link)
      setCopied(true)
    }
  }

  const getLink = () => {
    let url = experienceUrl || ''
    if(!url) return

    url += linkCampaignName ? `&utm_campaign=${linkCampaignName}` : ''

    if(
      linkSocialChannel === 'Instagram' ||
      linkSocialChannel === 'Facebook' ||
      linkSocialChannel === 'Twitter' ||
      linkSocialChannel === 'TikTok' ||
      linkSocialChannel === 'Youtube' ||
      linkSocialChannel === 'Snapchat' ||
      linkSocialChannel === 'Content Creator' ||
      linkSocialChannel === 'Meta'
    ) {
      url += `&utm_source=${linkSocialChannel}`
      url += '&utm_medium=social'
    }
    else if(
      linkSocialChannel === 'Email'
    ) {
      url += `&utm_source=${linkSocialChannel}`
      url += '&utm_medium=email'
    } else if(
      linkSocialChannel === 'Direct'
    ) {
      url += '&utm_source=direct'
      url += '&utm_medium=direct'
    }

    setLink(encodeURI(url))
  }

  return (
    <Root>
      <Button
        color='primary'
        variant='contained'
        style={{
          width: 'fit-content',
        }}
        disabled={!experienceUrl}
        onClick={handleOpenLinkModal}
      >
        Campaign Store link
        &nbsp;<PreviewLinkIcon />
      </Button>

      <Modal
        open={openLinkModal}
        onClose={handleCloseLinkModal}
      >
        <Box className={classes.linkModal}>
          <Typography
            style={{
              color: '#546D78',
              fontSize: 13,
              textTransform: 'uppercase',
              fontWeight: 600,
              marginBottom: 7,
            }}
            component="h3"
          >
            Campaign Store link
          </Typography>
          <Typography
            style={{
              color: '#000000',
              fontSize: 13,
            }}
            id="modal-modal-description">
            Share this link to send customers directly to this storefront.  To improve attribution, select which channel you intend to share the link on.
          </Typography>
          <FormControl fullWidth>
            <TextField
              select
              id="linkSocialChannel"
              value={linkSocialChannel}
              label="Select Social Channel"
              variant="outlined"
              style={{
                marginTop: 24,
              }}
              className={classes.linkSelect}
              onChange={handleChangeLinkSocialChannel}
            >
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'Instagram'}>
                <Icon
                  name='Instagram'
                  root={true}
                  className={classes.linkSelectIcon}
                /> Instagram</MenuItem>
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'Meta'}>
                <Icon
                  name='Meta'
                  root={true}
                  className={classes.linkSelectIcon}
                /> Meta</MenuItem>
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'Facebook'}>
                <Icon
                  name='Facebook'
                  root={true}
                  className={classes.linkSelectIcon}
                /> Facebook</MenuItem>
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'Twitter'}>
                <Icon
                  name='Twitter'
                  root={true}
                  className={classes.linkSelectIcon}
                /> Twitter</MenuItem>
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'Snapchat'}>
                <Icon
                  name='Snapchat'
                  root={true}
                  className={classes.linkSelectIcon}
                /> Snapchat</MenuItem>
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'Content Creator'}>
                <Icon
                  name='contentCreator'
                  root={true}
                  className={classes.linkSelectIcon}
                /> Content Creator</MenuItem>
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'TikTok'}>
                <Icon
                  name='TikTok'
                  root={true}
                  className={classes.linkSelectIcon}
                /> TikTok</MenuItem>
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'Youtube'}>
                <Icon
                  name='Youtube'
                  root={true}
                  className={classes.linkSelectIcon}
                /> Youtube</MenuItem>
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'Email'}>
                <Icon
                  name='Email'
                  root={true}
                  className={classes.linkSelectIcon}
                />
                Email</MenuItem>
              <MenuItem style={{
                display: 'flex',
                alignItems: 'center',
              }} value={'Direct'}>
                <Icon
                  name='PreviewLinkIcon'
                  root={true}
                  className={classes.linkSelectIcon}
                />
                Direct</MenuItem>
            </TextField>

            <TextField
              id="campaignName"
              value={linkCampaignName}
              label="Campaign name"
              variant="outlined"
              style={{
                marginTop: 24,
              }}
              onChange={handleChangeLinkCampaignName}
            >
            </TextField>

            {link && <TextField
              id="storefrontLink"
              value={link}
              label="Campaign Store URL"
              variant="outlined"
              style={{
                marginTop: 24,
              }}
            >
            </TextField>}

            <Snackbar
              open={copied}
              onClose={() => setCopied(false)}
              autoHideDuration={900}
              message="Copied to clipboard"
            />

            <Button
              color='primary'
              variant='contained'
              style={{
                width: '100%',
                marginTop: 24,
              }}
              disabled={!link}
              onClick={copyLink}
            >
              Copy link to clipboard
              &nbsp;
              <CopyIcon
                className={classes.copyIcon}
              ></CopyIcon>
            </Button>

            <Button
              color='primary'
              variant='text'
              style={{
                width: '100%',
                marginTop: 1,
              }}
              onClick={handleCloseLinkModal}
            >
              Cancel
            </Button>

          </FormControl>
        </Box>
      </Modal>


    </Root>
  )
}

export const stylesMock = {
  'shop': 'sdx-qa-test.myshopify.com',
  'colors': {
    'primary': '#331188',
    'secondary': '#fff000',
  },
  'fonts': {
    'main': {
      'name': 'Fellix-Regular2',
      'url': 'https://cdn.shopify.com/s/files/1/1102/4588/files/Futura_Light.otf?v=1709763749',
    },
    'header': {
      'name': 'Test',
      'url': 'https://cdn.shopify.com/s/files/1/1102/4588/files/Futura_Light.otf?v=1709763749',
    },
  },
  'buyNow': {
    'text': 'Buy noooowww',
    'backgroundColor': '#FF5733',
    'backgroundHoverColor': '#FF8D70',
    'textColor': '#FFFFFF',
    'textHoverColor': '#000000',
    'outlineColor': '#333333',
    'outlineHoverColor': '#666666',
    'outlineWidthPixels': 3,
    'fontUrl': '',
  },
  'addToCart': {
    'text': 'Baggit',
    'backgroundColor': '#FF5733',
    'backgroundHoverColor': '#FF8D70',
    'textColor': '#FFFFFF',
    'textHoverColor': '#000000',
    'outlineColor': '#333333',
    'outlineHoverColor': '#666666',
    'outlineWidthPixels': 2,
    'fontUrl': '',
  },
}

import { useEffect, useMemo, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Provider } from '@shopify/app-bridge-react'
import { Banner, Layout, Page } from '@shopify/polaris'
import createApp from '@shopify/app-bridge'
import { getShopifyApiKey } from 'src/config'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'
import { getAppHandleApi } from 'src/contexts/api/getAppHandle'

/**
 * A component to configure App Bridge.
 * @desc A thin wrapper around AppBridgeProvider that provides the following capabilities:
 *
 * 1. Ensures that navigating inside the app updates the host URL.
 * 2. Configures the App Bridge Provider, which unlocks functionality provided by the host.
 *
 * See: https://shopify.dev/apps/tools/app-bridge/react-components
 */
export function AppBridgeProvider({ children }) {
  const location = useLocation()
  const historyRouter = useHistory()
  const history = useMemo(
    () => ({
      replace: (path) => {
        historyRouter.push(path, { replace: true })
      },
    }),
    [historyRouter]
  )

  const routerConfig = useMemo(
    () => ({ history, location }),
    [history, location]
  )

  const [initializingAppBridge, setInitializingAppBridge] = useState(true)

  // The host may be present initially, but later removed by navigation.
  // By caching this in state, we ensure that the host is never lost.
  // During the lifecycle of an app, these values should never be updated anyway.
  // Using state in this way is preferable to useMemo.
  // See: https://stackoverflow.com/questions/60482318/version-of-usememo-for-caching-a-value-that-will-never-change
  const [appBridgeConfig, setAppBridgetConfig] = useState(() => {
    const host =
      new URLSearchParams(location.search).get('host') ||
      window.__SHOPIFY_DEV_HOST

    window.__SHOPIFY_DEV_HOST = host
    return {
      host,
      forceRedirect: true,
    }
  })

  useEffect(() => {
    const initializeApp = async () => {
      const shop = new URLSearchParams(location.search).get('shop')
      const host = new URLSearchParams(location.search).get('host') ||
      window.__SHOPIFY_DEV_HOST || appBridgeConfig.host

      if (shop && host ) {
        const appHandle = await getAppHandleApi(shop)
        const apiKey = getShopifyApiKey(appHandle.data.handle)
        window.__app = createApp({ apiKey, host  })

        // Not sure window is the best place to store this, but ti works now. Maybe we can put it in a context later.
        window.__appHandle = appHandle.data.handle
        setAppBridgetConfig({...appBridgeConfig, apiKey})
        setInitializingAppBridge(false)
      } else {
        console.error('Missing shop or host query argument from shopify')
        window.alert('Please refresh the browser window.')
      }

    }
    initializeApp()
    // No dependencies, as we only want to run this once.
  }, [])

  if (initializingAppBridge) {
    return <SdxLoading />
  }

  if (!appBridgeConfig.apiKey || !appBridgeConfig.host) {
    const bannerProps = !appBridgeConfig.apiKey
      ? {
        title: 'Missing Shopify API Key',
        children: (
          <>
              Your app is running without the SHOPIFY_API_KEY environment
              variable. Please ensure that it is set when running or building
              your React app.
          </>
        ),
      }
      : {
        title: 'Missing host query argument',
        children: (
          <>
              Your app can only load if the URL has a <b>host</b> argument.
              Please ensure that it is set, or access your app using the
              Partners Dashboard <b>Test your app</b> feature
          </>
        ),
      }

    return (
      <Page narrowWidth>
        <Layout>
          <Layout.Section>
            <div style={{ marginTop: '100px' }}>
              <Banner {...bannerProps} status="critical" />
            </div>
          </Layout.Section>
        </Layout>
      </Page>
    )
  }

  return (
    <Provider config={appBridgeConfig} router={routerConfig}>
      {children}
    </Provider>
  )
}

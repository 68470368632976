import { render, screen } from '@testing-library/react'

import wrapComponent from 'src/tests/wrapComponent'
import DefaultPagesSettings from './index'

const setup = () => render(wrapComponent(DefaultPagesSettings, {}))

describe('DefaultPagesSettings page', () => {
  beforeEach(setup)

  it('renders DefaultPagesSettings', async () => {
    await screen.findByText('Page Settings')
    screen.getByRole('button', { name: 'Save Changes'})
    screen.getByText('Control which components are shown on default layouts.')
    screen.getByRole('heading', { name: 'Single Product Layout'})
    screen.getByRole('heading', { name: 'Category Layout'})

    expect(screen.getAllByRole('button', { name: 'Add a component... ​'}).length).toEqual(2)

    screen.getByRole('heading', { name: 'Similar Products'})
    screen.getByRole('heading', { name: 'OH SO SWEET'})
    expect(screen.getAllByRole('heading', { name: 'All Published Campaigns'}).length).toEqual(2)
  })
})

import {  PrependShop } from 'src/contexts/types'
import { axiosInstance } from 'src/utils/axios'
import { socialServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { AxiosResponse } from 'src/types/axios'

export type PostLinkToMeta = (accountId: string, adId: string, newLink: string) => Promise<AxiosResponse<object> | undefined>
export type PostLinkToMetaApi = PrependShop<PostLinkToMeta>

export const postLinkToMetaApi: PostLinkToMetaApi = async (activeShop: string, accountId: string, adId: string, newLink: string) => {
  try {
    const url = `/v1/meta-ads/write-link`

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      shop: activeShop,
    }

    const body = {
      accountId: accountId,
      adId: adId,
      newLink: newLink,
    }

    const response = await axiosInstance.post<object>(url, body,{
      baseURL: socialServiceBaseUrl,
      headers,
    })
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'post link to meta' } })
  }
}

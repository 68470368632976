import React, { useState, useEffect, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'
import {
  KlaviyoThirdParty,
  PowerReviewsThirdParty,
  ThirdParty,
  ThirdPartyName,
  ThirdPartyType,
} from 'src/types/storeSettings'

import useGenericContext from 'src/hooks/useGenericContext'
import { logging } from 'src/utils/logging'
import KlaviyoRow from './KlaviyoRow'
import { submit } from 'redux-form'
import { OutlinedFlagTwoTone } from '@mui/icons-material'
import PowerReviewsRow from './PowerReviewsRow'
import { Divider } from '@mui/material'

export const ModalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  maxWidth: 460,
  width: '100%',
  padding: '24px',
  borderRadius: '4px',
}

export const Root = styled('span')((
  {
    theme,
  },
) => ({
  //[`& .${classes.formText}`]: {
  [`& .third-party-root`]: {
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
  },
}))


const getDefaultKlaviyoConfig = (): KlaviyoThirdParty => {
  return {
    name: ThirdPartyName.Klaviyo,
    type: ThirdPartyType.Email,
    config: {
      apiKey:'',
      lists:[],
    },
  }
}

const getDefaultPowerReviewsConfig = (): PowerReviewsThirdParty => {
  return {
    name: ThirdPartyName.PowerReviews,
    type: ThirdPartyType.Reviews,
    config: {
      apiKey:'',
      merchantId:'',
    },
  }
}

const ThirdPartyIntegrationRows: FC = () => {
  const {
    shop,
    saveThirdParty,
    getThirdParty,
  } = useGenericContext()
  const [thirdParties, setThirdParties] = useState<ThirdParty[]>([])
  const [thirdPartiesLoading, setThirdPartiesLoading] = useState(false)
  const [klaviyoIntegration, setKlaviyoIntegration] = useState<KlaviyoThirdParty>(getDefaultKlaviyoConfig())
  const [powerReviewsIntegration, setPowerReviewsIntegration] = useState<PowerReviewsThirdParty>(getDefaultPowerReviewsConfig())

  // fetch all existing third party integrations.
  useEffect(() => {
    async function fetchThirdParty() {
      setThirdPartiesLoading(true)
      try {
        const response = await getThirdParty()
        const tps = response?.data
        if (tps) {
          setThirdParties(tps)
        }
      } catch (error) {
        console.log(error)
        logging(error, { tags: { section: 'third-party-integration' } })
      } finally {
        setThirdPartiesLoading(false)
      }
    }

    fetchThirdParty()
  }, [getThirdParty, shop])

  // set our individual third party integrations
  useEffect(() => {
    if (thirdPartiesLoading) return

    thirdParties.forEach((tp) => {
      if (tp.name === ThirdPartyName.Klaviyo && tp.type === ThirdPartyType.Email) {
        setKlaviyoIntegration(tp)
      }
      if (tp.name === ThirdPartyName.PowerReviews && tp.type === ThirdPartyType.Reviews) {
        setPowerReviewsIntegration(tp)
      }
    })
  }, [thirdParties, thirdPartiesLoading])

  const submitThirdParty = useCallback(async (e: React.MouseEvent<HTMLButtonElement>, newTp: ThirdParty) => {
    // prevent the form from submitting by default
    e.preventDefault()

    // update the third party in the api
    setThirdPartiesLoading(true)
    try {
      const response = await saveThirdParty([newTp])
      if (response?.status !== 201) {
        window.alert('Error saving settings')
      }
      const tps = response?.data
      if (tps) {
        // update the list of third parties with the one that was just saved.
        setThirdParties(thirdParties.map(oldTp => {
          if (oldTp.name === newTp.name && oldTp.type === newTp.type) {
            return newTp
          }
          return oldTp
        }))
      }
    } catch (error) {
      window.alert('Error saving settings')
      console.warn('Error saving third party', error)
      logging(error, {tags:{section:'Third party integrations'}})
    } finally{
      setThirdPartiesLoading(false)
    }
  },[setThirdPartiesLoading, setThirdParties, saveThirdParty,thirdParties])


  return (
    <>
      <Root>
        <KlaviyoRow
          thirdParty={klaviyoIntegration}
          thirdPartiesLoading={thirdPartiesLoading}
          submitThirdParty={submitThirdParty}
        />
        <Divider
          light
          style={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
        />
        <PowerReviewsRow
          thirdParty={powerReviewsIntegration}
          thirdPartiesLoading={thirdPartiesLoading}
          submitThirdParty={submitThirdParty}
        />
      </Root>
    </>
  )
}

export default ThirdPartyIntegrationRows

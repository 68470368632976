import React, { createContext, useEffect, useState } from 'react'
import { ChannelMenu, AppLink, NavigationMenu } from '@shopify/app-bridge/actions'
import type { FC, ReactNode } from 'react'
import type { ShopifyContextValue, ProductCountResponse } from './types'
import { productServiceBaseUrl, ecommerceServiceBaseUrl, getAppType} from 'src/config'
import { logging } from 'src/utils/logging'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { NavbarRoute, getNavbarRoutes } from 'src/routes'
import { useAppBridge } from '@shopify/app-bridge-react'
import { axiosInstance } from 'src/utils/axios'
import useGenericContext from 'src/hooks/useGenericContext'

declare global {
  interface Window {
    __appHandle: string
  }
}
interface ShopifyProviderProps {
  shop: string
  host: string
  children: ReactNode
}

export const ShopifyContext = createContext<ShopifyContextValue>({
  appConnected: false,
  connectApp: () => Promise.resolve(),
  disconnectApp: () => Promise.resolve(),
  appLoading: false,
  navMenu: undefined,
  host: 'host',
  shop: 'shop',
  publishedProductsCount: 0,
})

export const ShopifyProvider: FC<ShopifyProviderProps> = ({
  shop,
  host,
  children,
}) => {

  // const fetch = useAuthenticatedFetch()

  const [appLoading, setAppLoading] = useState(true)
  const [connectAppLoading, setConnectAppLoading] = useState(false)
  const [disconnectAppLoading, setDisconnectAppLoading] = useState(false)
  const [appConnected, setAppConnected] = useState(false)
  const [publishedProductsCount, setPublishedProductsCount] = useState(0)
  const [navbarRoutes, setNavbarRoutes] = useState<NavbarRoute[]>([])
  const { merchantDetails } = useGenericContext()
  const app = useAppBridge()
  const flag = useFlags()
  const appType = getAppType(window.__appHandle)

  useEffect(() => {
    if (merchantDetails?.plan === undefined || merchantDetails?.plan === 'NONE') return setNavbarRoutes([])
    const items = getNavbarRoutes(flag, 'shopify', appType)
    setNavbarRoutes(items)
  }, [appType, flag, merchantDetails?.plan])

  useEffect(() => {
    async function checkConnectedState () {
      try {
        // we will use our own token iinjection now, but need backedn to update to accept `X-Shopify-Access-Token` header so we can use the `useAuthenticatedFetch` hook.
        // we wont need to create app here or use getSessionToken. We will also get rid of shop and host props.
        // We will also need to do another step to use react-quer becasue it is hooked to useAuthenticatedFetch internally
        const response =  await axiosInstance({
          method: 'get',
          url: ecommerceServiceBaseUrl + `/v1/shopify/app/connection`,
          headers: { 'shop': shop },
        })

        if(response) {
          setAppConnected(response?.data.isConnected)
          setAppLoading(false)
        }
      } catch (error) {
        setAppLoading(false)
        logging(error, { tags: { section: 'checkConnectedState'}})
      }
    }
    checkConnectedState()
  }, [])

  useEffect(() => {
    async function checkPublishedProducts () {

      let url = productServiceBaseUrl
      url += '/v1/products/count'


      const countResponse = await axiosInstance.get<ProductCountResponse>(
        url,
        {
          baseURL: productServiceBaseUrl,
          headers:  { 'Accept': 'application/json', 'Content-Type': 'application/json', 'shop': shop },
        },
      )

      if(countResponse) {
        setPublishedProductsCount(countResponse?.data.count)
      }
    }
    checkPublishedProducts()
  }, [shop])


  const connectApp = async () => {
    setConnectAppLoading(true)
    const response =  await axiosInstance({
      method: 'put',
      url: ecommerceServiceBaseUrl + '/v1/shopify/app/connection',
      headers: {'shop': shop},
      data: {
        'isConnected': true,
      },
    })

    if (!response?.data.isConnected) {
      window.alert('Unable to connect store to app')
    } else {
      setAppConnected(response?.data.isConnected)
    }
    setConnectAppLoading(false)
  }

  const disconnectApp = async () => {
    setDisconnectAppLoading(true)
    const response =  await axiosInstance({
      method: 'put',
      url: ecommerceServiceBaseUrl + '/v1/shopify/app/connection',
      headers: {'shop': shop},
      data: {
        'isConnected': false,
      },
    })

    if (response?.data.isConnected) {
      window.alert('Unable to disconnect store from app')
    } else {
      setAppConnected(response?.data.isConnected)
    }
    setDisconnectAppLoading(false)
  }

  const navMenu = getAppType(window.__appHandle) === 'SALES_CHANNEL' ? ChannelMenu.create(app, {
    items: navbarRoutes.map(x => AppLink.create(app, {label: x.title, destination: x.href || ''})),
  }) : NavigationMenu.create(app, {
    items: navbarRoutes.map(x => AppLink.create(app, {label: x.title, destination: x.href || ''})),
  })

  return (
    <ShopifyContext.Provider
      value={{
        appConnected,
        connectApp,
        disconnectApp,
        appLoading,
        navMenu,
        host,
        shop,
        publishedProductsCount,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  )
}

export const ShopifyConsumer = ShopifyContext.Consumer

export default ShopifyContext

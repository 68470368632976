import React, {FC} from 'react'

type Props = {
  bla?: any
}
export const PageComponents: FC<Props> = ({
}) => {
  return (
    <div>page components</div>
  )
}

export enum AlertType { ERROR = 'error', WARNING='warning', INFO='info', SUCCESS='success' }

export interface Element {
  // Enforce with TS - Must have either title or id
  id?: string
  title: string
  total?: string
  percentage?: number
  imageLink?: string
  badge?: string
}

export interface AnalyticsData {
  title: string
  value: string
  lift?: string
  direction?: DataTrend
}

export type DataTrend = 'positive'|'negative'

import { ecommerceServiceBaseUrl } from 'src/config'
import { axiosInstance } from 'src/utils/axios'
import { logging } from 'src/utils/logging'
import { MerchantDetails, PrependShop } from '../types'
import { AxiosResponse } from 'src/types/axios'

export type GetMerchantDetails = () => Promise<AxiosResponse<MerchantDetails> | undefined>
export type GetMerchantDetailsApi = PrependShop<GetMerchantDetails>

export const getMerchantDetailsApi: GetMerchantDetailsApi = async (activeShop: string) => {
  try {
    const url = `/v1/merchant`

    const opts = {
      baseURL: ecommerceServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    }
    const response = await axiosInstance.get<MerchantDetails>(url, opts)
    return response
  } catch (error) {
    logging(error, { tags: { section: 'get merchant details api' } })
  }
}

import React from 'react'
import { render, screen } from '@testing-library/react'
import userEvent from '@testing-library/user-event'
import '@testing-library/dom'
import  SimpleSelect from './SimpleSelect'

const changeFn = jest.fn()
const setup = () => render(< SimpleSelect selected="tone" options={['tone', 'ttwo', 'three', 'tfour']} onChange={changeFn} />)


describe('SimpleSelect', () => {
  test('Renders component', async () => {
    setup()
    expect(screen.getByTestId('Checkmark')).toBeInTheDocument()
    expect(screen.getByText('TO')).toBeInTheDocument()
    expect(screen.getByText('tone')).toBeInTheDocument()
    expect(screen.getByText('TT')).toBeInTheDocument()
    expect(screen.getByText('ttwo')).toBeInTheDocument()
    expect(screen.getByText('TH')).toBeInTheDocument()
    expect(screen.getByText('three')).toBeInTheDocument()
    expect(screen.getByText('TF')).toBeInTheDocument()
    expect(screen.getByText('tfour')).toBeInTheDocument()
  })

  test('Indicates the selected tenant', async () => {
    setup()
    expect(screen.getByTestId('tone')).toContainElement(screen.getByTestId('Checkmark'))
  })

  test('Changes selected tenant', async () => {
    setup()
    userEvent.click(screen.getByRole('button', { name: 'TT ttwo'}))
    expect(changeFn).toHaveBeenCalledWith('ttwo')
  })
})

import React, { FC, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, useTheme,   Divider } from '@mui/material'
import { Skeleton } from '@mui/material'



import Icon from 'src/components/Icon/Icon'
import { AnalyticsData } from 'src/types/components'
import ColoredBackgroundText from './ColoredBackgroundText'
const PREFIX = 'MultiColumnRowCard'

const classes = {
  root: `${PREFIX}-root`,
  columnItem: `${PREFIX}-columnItem`,
  footerText: `${PREFIX}-footerText`,
  greenBackground: `${PREFIX}-greenBackground`,
  pinkBackground: `${PREFIX}-pinkBackground`,
}

const StyledBox = styled(Box)((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: { display: 'flex', justifyContent: 'space-around' },

  [`& .${classes.columnItem}`]: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '12px',
    alignItems: 'center',
    width: '16.6%',
  },

  [`& .${classes.footerText}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: '2px',
  },

  [`& .${classes.greenBackground}`]: {
    backgroundColor: '#D9FFF6',
    color: '#00CA99',
    alignSelf: 'center',
    padding: '3px',
  },

  [`& .${classes.pinkBackground}`]: {
    backgroundColor: '#FFE2EE',
    color: '#F60067',
    alignSelf: 'center',
    padding: '3px',
  },
}))

export interface MultiColumnRowCardProps {
  className?: string
  data: Array<AnalyticsData>
  loading: boolean
}

const MultiColumnRowCard: FC<MultiColumnRowCardProps> = ({
  data,
  loading,
}) => {
  const theme = useTheme()


  return (
    <StyledBox className={classes.root}>
      {loading ?
        <Skeleton height={60} width='100%' />
        : <Fragment>
          { data.map((item, i) =>
            <Fragment key={item.title}>
              { i !==0 && <Divider orientation="vertical" flexItem />}
              <Box className={classes.columnItem}>
                <Typography variant="body1" color='textPrimary' gutterBottom>
                  {item.title}
                </Typography>
                <Typography variant="h1" gutterBottom>
                  {item.value}
                </Typography>
                {item?.lift && item?.direction && (
                  <span className={classes.footerText}>{item?.direction === 'positive' ? <Icon name="ArrowUp" root color='#00CA99'/> : <Icon name="ArrowDown"root color={theme.palette.brand.urPink} />} <ColoredBackgroundText direction={item.direction} text={`${item.lift}% LIFT`}/></span>
                )}
              </Box>
            </Fragment>)}

        </Fragment>
      }
    </StyledBox>
  )
}

export default MultiColumnRowCard

import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { ReactComponent as EditIcon } from 'src/assets/icons/Edit.svg'
import { ReactComponent as AnalyticsIcon } from 'src/assets/icons/Analytics.svg'
import { ReactComponent as UnpublishIcon } from 'src/assets/icons/Unpublish.svg'
import { ReactComponent as LinkIcon } from 'src/assets/icons/Link.svg'
import { ReactComponent as PublishIcon } from 'src/assets/icons/Publish.svg'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import type { FC }from 'react'
import { Paper, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link, FormControlLabel, Switch, useTheme, Box, IconButton, Tooltip } from '@mui/material'
import { ReactComponent as StartImg } from 'src/assets/icons/StartImg.svg'
import { ReactComponent as PlaceholderIcon } from 'src/assets/icons/IntegrationsIcon.svg'

import useGenericContext from 'src/hooks/useGenericContext'
import { RedirectModal } from './RedirectModal'
import { PageConfig } from 'src/types/interpretor'
import { getPageStatus } from 'src/utils/GetPageStatus'
import { LinkBuilderModal } from './components/LinkBuilderModal'
import { getRoutePath } from 'src/routes'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'
import LoadingBar from 'src/components/LoadingBar/LoadingBar'

const PREFIX = 'PageConfigs'

const classes = {
  paper: `${PREFIX}-paper`,
  header: `${PREFIX}-header`,
  marginRound: `${PREFIX}-marginRound`,
  marginRoundText: `${PREFIX}-marginRoundText`,
  table: `${PREFIX}-table`,
}

const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.paper}`]: {
    margin: theme.spacing(4),
    padding: theme.spacing(8),
    textAlign: 'center',
    flexGrow: 1,
  },

  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'unset',
    margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },

  [`& .${classes.marginRound}`]: {
    margin: theme.spacing(3),
  },

  [`& .${classes.marginRoundText}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    width: 'unset',
    margin: theme.spacing(4),
    marginTop: '0px',
  },
}))

const PageConfigs: FC = () => {
  const theme = useTheme()
  const history = useHistory()

  const {statusBasedPageConfigs, setStatusBasedPageConfigs, fetchStatusBasedPageConfigs, publishPageConfig, showUnpublished, setShowUnpublished, setLoadingPages, loadingPages, loadingMore, utmLinkBuilderPageId, setUtmLinkBuilderPageId, duplicateStorefront} = useGenericContext()
  const [redirectModalOpen, setRedirectModalOpen] = useState(false)
  const [linkBuilderModalOpen, setLinkBuilderModalOpen] = useState(false)
  const [activePageConfig, setActivePageConfig] = useState({} as PageConfig)
  const [loadMore, setLoadMore] = useState<boolean>(false)

  useEffect(() => {
    setLoadMore(false)
  }, [statusBasedPageConfigs])

  useEffect(() => {
    const cfg = statusBasedPageConfigs.find((pageConfig) => pageConfig.id === utmLinkBuilderPageId)
    if (!cfg) {
      setLinkBuilderModalOpen(false)
      return
    }
    setActivePageConfig(cfg)
    setTimeout(() => {
      setLinkBuilderModalOpen(true)
    }, 100)
  }, [utmLinkBuilderPageId, setLinkBuilderModalOpen, setActivePageConfig, statusBasedPageConfigs])

  // TODO: this is actually a last step in redirect change and a status toggle, needs better name
  const onSaveRedirectModal = async (cfg: PageConfig) => {
    setRedirectModalOpen(false)
    await publishPageConfig(cfg)
    // status was toggled, remove from status based configs
    setStatusBasedPageConfigs(statusBasedPageConfigs.filter(c => c.id !== cfg.id))
  }

  if (loadingPages) {
    return <SdxLoading />
  }

  const handleDuplicateStorefront = async (storefrontId) => {
    const storefront = statusBasedPageConfigs.find((pageConfig) => pageConfig.id === storefrontId)
    if (!storefront) {console.log('noop'); return}

    const confirmed = window.confirm(`Are you sure you want to Duplicate "${storefront.internalName}" Campaign Store?`)
    if (!confirmed) return

    const response = await duplicateStorefront(storefrontId)
    const duplicateSorefrontId = response?.data?.id
    if (!duplicateSorefrontId) {
      window.alert('Failed to duplicate storefront')
      return
    }

    handleEditStorefront(duplicateSorefrontId)
  }
  const handleEditStorefront = (storefrontId) => {
    history.push(getRoutePath('store_edit', {id: storefrontId}))
  }

  const handleViewStorefrontAnalytics = (storefrontId) => {
    history.push(getRoutePath('store_analytics', {id: storefrontId}))
  }

  const UnpublishIconWithToolTip = () =>
    <Tooltip title="Unpublish" placement="top">
      <UnpublishIcon  role="img" aria-label="Unpublish" style={{width: 20, height: 20}}/>
    </Tooltip>


  const PublishIconWithToolTip = () =>
    <Tooltip title="Publish" placement="top">
      <PublishIcon role="img" aria-label="Publish" style={{width: 20, height: 20}}/>
    </Tooltip>

  const handleOnScroll = (ev) => {
    const scrollableHeight = ev.target.scrollHeight - ev.target.offsetHeight
    if (ev.target.scrollTop >= scrollableHeight - 200 && !loadingMore) {
      setLoadMore(true)
      fetchStatusBasedPageConfigs(undefined, true)
    }
  }

  return (
    <Root>
      {/* <TabbedHeader
        title='PageConfigs'
        rightSide={
          <Button onClick={() => history.push(getRoutePath('store_create'))} variant='contained' color="primary">New Campaign Store</Button>
        }>
        <Fragment>
          <Tabs
            TabIndicatorProps={{children: <span/>}}
            classes={{
              indicator: 'indicator',
              flexContainer: 'flexContainer',
            }}
            value={0}
            indicatorColor="primary"
          >
            {<Tab label='List' />}
          </Tabs>
        </Fragment>
      </TabbedHeader> */}

      <RedirectModal
        isOpen={redirectModalOpen}
        onSave={onSaveRedirectModal}
        onClose={() => setRedirectModalOpen(false)}
        pageConfig={activePageConfig}
      />

      <LinkBuilderModal
        key={activePageConfig.id + linkBuilderModalOpen}
        isOpen={linkBuilderModalOpen}
        onSave={(cfg: PageConfig) => {
          return Promise.resolve()
        }}
        onClose={() => {
          setUtmLinkBuilderPageId('')
          setLinkBuilderModalOpen(false)
        }}
        pageConfig={activePageConfig}
      />

      <div className={classes.header}>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 'large',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          {showUnpublished ? 'Inactive ' : ''}
          Campaign Stores
        </Typography>
        <div>
          <FormControlLabel
            style={{
              marginRight: 10,
            }}
            label={<Typography style={{
              marginBottom: '4px',
              color: showUnpublished ? theme.palette.brand.urIndigo : '',
            }}>
              Show Inactive Campaign Stores
            </Typography>}
            labelPlacement='start'
            control={
              <Switch
                style={{color: showUnpublished ? theme.palette.brand.urIndigo : ''}}
                checked={showUnpublished}
                onChange={(e) => {
                  setLoadingPages(true)
                  setShowUnpublished(!showUnpublished)}
                }
                color='primary'
              />
            }
          />
          <Button onClick={() => history.push(getRoutePath('store_create'))} variant='contained' color="secondary">+ Create Store</Button>
        </div>
      </div>

      {statusBasedPageConfigs?.length > 0 ?
        <TableContainer className={classes.table} component={Paper} onScroll={handleOnScroll} sx={{
          height: 'calc(100vh - 90px)',
          overflow: 'auto',
          marginBottom: '0!important',
        }}>
          <Table aria-label="storefront table">
            <TableHead sx={{
              position: 'sticky',
              top: 0,
              background: 'white',
              zIndex: 1,
              '&:after': {
                content: '""',
                position: 'absolute',
                top: 'calc(100% - 1px)',
                left: 0,
                zIndex: 2,
                width: '100%',
                height: '1px',
                backgroundColor: 'rgba(224, 224, 224, 1)',
              },
            }}>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Campaign Store Name</TableCell>
                <TableCell align="center">Public URL</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Start</TableCell>
                <TableCell align="center">Finish</TableCell>
                <TableCell align="center">Last Modified</TableCell>
                <TableCell align="center">Layout</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {statusBasedPageConfigs
                .map((pageConfig, i) => (
                  <TableRow
                    key={pageConfig.pageUrl + i}
                  >
                    <TableCell align="left">
                      <Box sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '5px',
                        backgroundColor: pageConfig.campaignMedias[0]?.smallUrl === '' ? '' : '#E8E8E8',
                      }}>
                        {pageConfig.campaignMedias[0]?.smallUrl === '' ? <PlaceholderIcon style={{
                          width: '100%',
                          height: '100%',
                        }} /> :
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain',
                              borderRadius: '5px',
                            }}
                            src={pageConfig.campaignMedias[0]?.smallUrl}
                            alt=''
                          />
                        }
                      </Box>
                    </TableCell>
                    <TableCell align="left">{pageConfig.internalName}</TableCell>
                    {/* TODO: rename backend to pageConfig */}
                    <TableCell align="center"><Link target='_blank' href={pageConfig.pageUrl} rel="noreferrer">Visit</Link></TableCell>
                    <TableCell align="center">
                      {
                        getPageStatus(pageConfig) === 'PUBLISHED' ? 'Published'
                          : getPageStatus(pageConfig) === 'SCHEDULED' ? 'Scheduled'
                            : showUnpublished ? 'Unpublished' : 'Published'}
                    </TableCell>
                    <TableCell align="center">{pageConfig.startDate === undefined ? '' : new Date(Number(pageConfig.startDate)).toLocaleString()}</TableCell>
                    <TableCell align="center">{pageConfig.endDate === undefined ? '' : new Date(Number(pageConfig.endDate)).toLocaleString()}</TableCell>
                    <TableCell align="center">{new Date(Number(pageConfig.updatedAt)).toLocaleString()}</TableCell>
                    <TableCell align="center">{
                      // TODO: solve with types from interpreter
                      pageConfig.layout === 'SINGLE_PRODUCT' ? 'Single Product' :
                        pageConfig.layout === 'SOCIAL_HOME' ? 'Campaign Home' :
                          pageConfig.layout === 'CATEGORY' ? 'Category' :
                            pageConfig.layout
                    }</TableCell>
                    <TableCell align="right" sx={{width: '200px', '& svg path': {fill: theme.palette.brand.urIndigo}}}>

                      <IconButton
                        color="secondary"
                        sx={{padding: '4px', width: 30, minWidth: 0}}
                        onClick={() => {
                          setActivePageConfig(pageConfig)
                          setTimeout(() => {
                            setLinkBuilderModalOpen(true)
                          }, 100)
                        }}
                        disabled={pageConfig.isLegacy}
                      >
                        <Tooltip title="UTM Links" placement="top">
                          <LinkIcon role="img" aria-label="UTM Links" style={{width: 20, height: 20}}/>
                        </Tooltip>
                      </IconButton>


                      <IconButton
                        color="secondary"
                        sx={{padding: '4px', width: 30, minWidth: 0}}
                        onClick={() => handleEditStorefront(pageConfig.id)}
                        disabled={pageConfig.isLegacy}
                      ><Tooltip title="Edit" placement="top"><EditIcon role="img" aria-label="Edit" style={{width: 20, height: 20}}/>
                        </Tooltip>
                      </IconButton>


                      { pageConfig.layout !== 'SOCIAL_HOME' &&
                        <IconButton
                          color="secondary"
                          sx={{padding: '4px', width: 30, minWidth: 0}}
                          onClick={() => handleViewStorefrontAnalytics(pageConfig.id)}
                        >
                          <Tooltip title="Analytics" placement="top">
                            <AnalyticsIcon role="img" aria-label="Analytics" style={{width: 20, height: 20}}/>
                          </Tooltip>
                        </IconButton>

                      }

                      <IconButton
                        color="secondary"
                        sx={{padding: '4px', width: 30, minWidth: 0}}
                        onClick={async () => {
                          setActivePageConfig(pageConfig)
                          setRedirectModalOpen(true)
                        }}
                        disabled={pageConfig.isLegacy === true && getPageStatus(pageConfig) !== 'PUBLISHED'}
                      >
                        {getPageStatus(pageConfig) === 'PUBLISHED' ?
                          <UnpublishIconWithToolTip/>: showUnpublished ? <PublishIconWithToolTip/> : <UnpublishIconWithToolTip/>}
                      </IconButton>


                      { pageConfig.layout !== 'SOCIAL_HOME' &&
                      <IconButton
                        color='secondary'
                        sx={{padding: '4px', width: 30, minWidth: 0}}
                        onClick={() => handleDuplicateStorefront(pageConfig.id)}
                        disabled={pageConfig.isLegacy}
                      >
                        <Tooltip title='Duplicate' placement='top'>
                          <ContentCopyOutlinedIcon />
                        </Tooltip>
                      </IconButton>
                      }

                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          { loadMore &&
            <Box sx={{
              width: '100%',
              height: 113,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}><LoadingBar/></Box>
          }
        </TableContainer>

        : <Paper className={classes.paper}>
          <StartImg className={classes.marginRound}/>
          <div className={classes.marginRoundText}>
            <Typography variant='h1'>{showUnpublished? 'There are no unpublished campaign stores': 'Start increasing your social conversion with'}</Typography>
            <Typography variant='h1'>{!showUnpublished ? 'campaign stores' : ''}</Typography>
          </div>
          <Typography>{showUnpublished? 'All your campaign stores are currently published' :  'Create your first campaign store to use in a campaign'}</Typography>
        </Paper>}
    </Root>
  )
}

export default PageConfigs

import React, { useState, createContext, Fragment, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Link, Typography } from '@mui/material'
import { Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'

import type { FC } from 'react'

import { BarElement } from 'src/views/CampaignAnalytics/duck/barElement'


const PREFIX = 'CardRowsWrapper'

const classes = {
  root: `${PREFIX}-root`,
  cardFooter: `${PREFIX}-cardFooter`,
  title: `${PREFIX}-title`,
  viewSelector: `${PREFIX}-viewSelector`,
  cardHeader: `${PREFIX}-cardHeader`,
  selectorButton: `${PREFIX}-selectorButton`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    margin: '0 15px',
  },

  [`& .${classes.cardFooter}`]: {
    padding: '20px 0 25px 0px',
    top: '5px',
    position: 'relative',
  },

  [`& .${classes.title}`]: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '1px',
    color: '#546E7A',
  },

  [`& .${classes.viewSelector}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.cardHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  [`& .${classes.selectorButton}`]: {
    fontSize: 'x-small',
  },
}))

enum MoreClicks {
  FIRSTEXPAND = 'FIRSTEXPAND',
  SUBSEQUENTEXPAND = 'SUBSEQUENTEXPAND',
  SHOWLESS = 'SHOWLESS',
  SHOWALL = 'SHOWALL',
}
interface CardRowsWrapperProps {
  className?: string
  children?: React.ReactNode
  data: Array<BarElement>
  initialNumberOfVisibleItems: number
  loading?: boolean
  loadMoreData: () => void
  page: number
  limit: number
  totalResults: number
  fetchLimit: number
  view: View
}

type View = 'order_value' | 'page_view'

export const CardContext = createContext<{ data: (BarElement)[], view: View  }>({ data: [], view: 'order_value' })

const CardRowsWrapper: FC<CardRowsWrapperProps> = ({
  className,
  data,
  initialNumberOfVisibleItems,
  loading,
  children,
  loadMoreData,
  page,
  limit,
  totalResults,
  fetchLimit,
  view,
  ...rest
}) => {
  const {t} = useTranslation()
  const [totalHiddenElements, setTotalHiddenElements] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const [appendData, setAppendData] = useState(false)
  const [visibleData, setVisibleData] = useState<(BarElement)[]>([])
  const [allData, setAllData] = useState<(BarElement)[]>([])
  const totalPages = Math.ceil(totalResults / limit)
  const moreToFetch = ((totalResults - (page * limit)) > 5) ? 5 : (totalResults - (page * limit))

  useEffect(() => {
    if (!loading) {
      setVisibleData(data.slice(0, initialNumberOfVisibleItems))
      appendData ? setAllData([...allData, ...data]) : setAllData(data)
      appendData ? setTotalHiddenElements([...allData, ...data].length - initialNumberOfVisibleItems) : setTotalHiddenElements(data.length - initialNumberOfVisibleItems)
      setAppendData(false)
    }
  }, [allData, appendData, data, initialNumberOfVisibleItems, loading])

  const onShowMore = (text: MoreClicks ) => {
    switch(text) {
    case MoreClicks.FIRSTEXPAND:
      setIsExpanded(true)
      break
    case MoreClicks.SUBSEQUENTEXPAND:
      setAppendData(true)
      loadMoreData()
      break
    case MoreClicks.SHOWLESS:
      setIsExpanded(false)
      break
    case MoreClicks.SHOWALL:
      setIsExpanded(true)
      break
    default:
    }

  }

  const isNextButtonDisabled = totalPages === page  || totalResults === 0

  return (
    <Root>
      <Box minWidth={330}>
        <CardContext.Provider value={{ data: isExpanded ? allData : visibleData, view }}>
          {loading ? (
            <Fragment>
              {[...Array(initialNumberOfVisibleItems)].map((e, i) => (
                <Skeleton key={i}
                  className={classes.root}
                  height={33} />
              ))}
            </Fragment>
          ) : (
            children
          )}
        </CardContext.Provider>
      </Box>
      {loading ? (
        <div className={classes.cardFooter}>
          <Skeleton className={classes.root} width={`20%`} />
        </div>
      ) : (
        <Box className={classes.cardFooter}>
          {totalHiddenElements > 0 ? (

            isNextButtonDisabled ?
              ( isExpanded ?
                <Link onClick={() => onShowMore(MoreClicks.SHOWLESS)}>
                  <Typography variant="body2" data-testid="show-less">
                    {t('storefront.show less')}
                  </Typography>
                </Link> :
                <Link onClick={() => onShowMore(MoreClicks.SHOWALL)}>
                  <Typography variant="body2" data-testid="show-all">
                    {t('storefront.show all')}
                  </Typography>
                </Link>
              )
              :
              ( isExpanded ?
                <Link onClick={() => onShowMore(MoreClicks.SUBSEQUENTEXPAND)}>
                  <Typography variant="body2" data-testid="show-fetch-more">
                    {t('storefront.show n more', {n: moreToFetch })}
                  </Typography>
                </Link> :
                <Link onClick={() => onShowMore(MoreClicks.FIRSTEXPAND)}>
                  <Typography variant="body2" data-testid="show-hidden-more">
                    {t('storefront.show n more', {n: totalHiddenElements })}
                  </Typography>
                </Link>
              )
          ) : (
            <Fragment />
          )}
        </Box>
      )}
    </Root>
  )
}


export default CardRowsWrapper

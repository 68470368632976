import { FC, useEffect, useRef, useState } from 'react'
import { Box, Skeleton, Typography, useTheme } from '@mui/material'

import { ReactComponent as IconUp } from 'src/assets/icons/IconUp.svg'
import { currencySymbolMap } from 'src/utils/currencySymbolMap'
import { formatDataValue } from 'src/utils/analyticsHelpers'
import { CampaignTicker } from 'src/contexts/api/getCampaignTickers'
import useGenericContext from 'src/hooks/useGenericContext'
import { gifToCanvas } from 'src/utils/gifToCanvas'
import { mergeByProperty } from 'src/utils/mergeByProperty'
import Icon from './Icon/Icon'
import { analyticsHomeRefreshMillis } from 'src/config'

const placeholderDatas: CampaignTicker[] = [
  {
    currency: 'USD',
    campaignId: 'demo1',
    campaignName: '',
    campaignImages: [],
    visitors: 10,
    revenue: 0,
    isIncrease: false,
  },
  {
    currency: 'USD',
    campaignId: 'demo2',
    campaignName: '',
    campaignImages: [],
    visitors: 2,
    revenue: 0,
    isIncrease: true,
  },
  {
    currency: 'USD',
    campaignId: 'demo3',
    campaignName: '',
    campaignImages: [],
    visitors: 2,
    revenue: 0,
    isIncrease: true,
  },
  {
    currency: 'USD',
    campaignId: 'demo4',
    campaignName: '',
    campaignImages: [],
    visitors: 2,
    revenue: 0,
    isIncrease: true,
  },
]

const CampaignTickers: FC<{
  animation: boolean
}> = ({ animation }) => {
  const { homeCustomDate, getCampaignTickers, isBrand } = useGenericContext()
  const [tickerData, setTickerData] = useState<CampaignTicker[]>(placeholderDatas)
  const [transitionPercentage, setTransitionPercentage] = useState<number>(0)
  const [blur, setBlur] = useState<boolean>(false)
  const itemsParent = useRef<HTMLElement | null>()
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const callback = () => {
      getCampaignTickers(isBrand).then(result => {
        setIsLoading(false)
        if (!result) return
        setTickerData((prevTickerData) => {
          const filteredPrevTickerData = prevTickerData.filter((item) => !item.campaignId.includes('demo'))
          const updatedTickerData = mergeByProperty(filteredPrevTickerData, result.data, 'campaignId')
          return [...updatedTickerData]
        })
      })
    }
    callback()
    const interval = setInterval(callback, analyticsHomeRefreshMillis)
    return () => {
      clearInterval(interval)
    }
  }, [homeCustomDate, getCampaignTickers, isBrand])

  useEffect(() => {
    const callback = () => {
      if (tickerData.length > 4 && itemsParent.current?.style) {
        if (transitionPercentage === tickerData.length) {
          itemsParent.current.style.transition = 'transform 0s linear'
          itemsParent.current.style.transform = 'translate3d(0, 0, 0)'
          setTimeout(() => {
            if (itemsParent.current?.style) {
              itemsParent.current.style.transition = 'transform 3.5s linear'
              itemsParent.current.style.transform = 'translate3d(-25%, 0, 0)'
            }
          }, 30)
          setTransitionPercentage(1)
        } else {
          itemsParent.current.style.transition = 'transform 3.5s linear'
          itemsParent.current.style.transform = `translate3d(-${(transitionPercentage + 1) * 25}%, 0, 0)`
          setTransitionPercentage(transitionPercentage + 1)
        }
      } else {
        setTransitionPercentage(0)
      }
    }

    if (itemsParent.current?.style) {
      if (!animation || blur) {
        itemsParent.current.style.transition = 'none'
        return
      } else if (itemsParent.current.style.transition.indexOf('none') !== -1) {
        callback()
      }
    }

    const timeout = window.setTimeout(callback, 3.5 * 1000)

    return () => {
      window.clearTimeout(timeout)
    }
  }, [transitionPercentage, animation, blur, tickerData.length])


  const imgLoaded = ((ev) => {
    const img = ev.target
    gifToCanvas(img)
  })


  useEffect(() => {
    const handleOnBlur = (() => {
      setBlur(true)
    })

    window.addEventListener('blur', handleOnBlur)
    return () => {
      window.removeEventListener('blur', handleOnBlur)
    }
  }, [])

  useEffect(() => {
    const handleOnFocus = (() => {
      setBlur(false)
    })

    window.addEventListener('focus', handleOnFocus)
    return () => {
      window.removeEventListener('focus', handleOnFocus)
    }
  }, [])

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      height: '70px',
      overflow: 'hidden',
    }}>
      <Box ref={itemsParent} sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '70px',
        display: 'flex',
        alignItems: 'center',
      }}>
        {tickerData.map((item, i) => {
          return (
            <Box
              key={item.campaignId + item.campaignName}
              sx={{
                width: '25%',
                minWidth: '25%',
                padding: '0 18px',
                display: 'flex',
              }}
            >
              <Box sx={{ width: 32, height: 39, minWidth: '30px', backgroundColor: '#F6F6F7', borderRadius: '6px', overflow: 'hidden' }}>
                {isLoading ? <Skeleton sx={{width: '100%', height: '100%', transform: 'scale(1, 1)'}} />
                  : item.campaignImages[0]?.smallUrl ? <img onLoad={imgLoaded} src={item.campaignImages[0]?.smallUrl} style={{ objectFit: 'cover', width: '100%', height: '100%' }} alt='' /> : <Icon
                    name='TickerImageUnknown'
                    root={true}
                    color={'#B0BEC5'}
                    size={160}
                  />}
              </Box>
              <Box sx={{ marginLeft: '10px', width: '100%' }}>

                <Typography sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#5E6C84',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 'calc(100% - 40px)',
                  width: '100%',
                }}>
                  {isLoading ? <Skeleton width={50} /> : item.campaignName}
                </Typography>

                {
                  <>
                    <Typography sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: (!item.isIncrease ? theme.palette.brand.liftNegative : theme.palette.brand.liftPositive),
                      letterSpacing: '1px',
                      display: 'flex',
                      alignItems: 'center',
                      '& svg path': {
                        fill: (!item.isIncrease ? theme.palette.brand.liftNegative : theme.palette.brand.liftPositive),
                      },
                    }}>
                      {isLoading ? <Skeleton width={15} /> : item.visitors}
                      <IconUp style={{
                        marginTop: '1px', marginLeft: '5px', marginRight: '5px',
                        transform: `rotate(${!item.isIncrease ? 180 : 0}deg)`,
                      }} />
                      {isLoading ? <Skeleton width={35} /> : currencySymbolMap['USD'] + formatDataValue(item.revenue)}
                    </Typography>
                  </>
                }

              </Box>
            </Box>
          )
        })}
        {tickerData.length > 4 && tickerData.map((item, i) => {
          return (
            <Box
              key={item.campaignId + item.campaignName}
              sx={{
                width: '25%',
                minWidth: '25%',
                padding: '0 18px',
                display: 'flex',
              }}
            >
              <Box sx={{ width: 32, height: 39, minWidth: '30px', backgroundColor: '#F6F6F7', borderRadius: '6px', overflow: 'hidden' }}>
                {item.campaignImages[0]?.smallUrl ? <img onLoad={imgLoaded} src={item.campaignImages[0]?.smallUrl} style={{ objectFit: 'cover', width: '100%', height: '100%' }} alt='' /> : <Icon
                  name='TickerImageUnknown'
                  root={true}
                  color={'#B0BEC5'}
                  size={160}
                />}
              </Box>
              <Box sx={{ marginLeft: '10px', width: '100%' }}>

                <Typography sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#5E6C84',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 'calc(100% - 40px)',
                  width: '100%',
                }}>
                  {item.campaignName}
                </Typography>

                {
                  <>
                    <Typography sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: (!item.isIncrease ? theme.palette.brand.liftNegative : theme.palette.brand.liftPositive),
                      letterSpacing: '1px',
                      display: 'flex',
                      alignItems: 'center',
                      '& svg path': {
                        fill: (!item.isIncrease ? theme.palette.brand.liftNegative : theme.palette.brand.liftPositive),
                      },
                    }}>
                      {item.visitors}
                      <IconUp style={{
                        marginTop: '1px', marginLeft: '5px', marginRight: '5px',
                        transform: `rotate(${!item.isIncrease ? 180 : 0}deg)`,
                      }} />
                      {currencySymbolMap['USD']}{formatDataValue(item.revenue)}
                    </Typography>
                  </>
                }

              </Box>
            </Box>
          )
        })}
      </Box>
    </Box >
  )
}

export default CampaignTickers

import React, { useState, useContext, useEffect, Fragment } from 'react'
import type { FC } from 'react'
import UrLineBar from 'src/components/UrLineBar/UrLineBar'
import useGenericContext from 'src/hooks/useGenericContext'
import { CardContext } from './CardRowsWrapper'
interface ProgressBarContainerProps {
  className?: string
}

const ProgressBarContainer: FC<ProgressBarContainerProps> = ({
  className,
  ...rest
}) => {
  const { data } = useContext(CardContext)
  const { currencyCode } = useGenericContext()
  const [state, setState] = useState(data)

  useEffect(() => {
    setState(data)
  }, [data])

  return (
    <Fragment>
      {state.map((element, ind) => (
        <UrLineBar
          key={ind}
          title={element.group}
          views_pct={Number(element.pageViewPct)}
          order_value_pct={Number(element.orderValuePct)}
          views={Number(element.pageViews)}
          order_value={Number(element.orderValue)}
          currencyCode={currencyCode}
        />
      ))}
    </Fragment>
  )
}


export default ProgressBarContainer

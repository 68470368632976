import { roundToDecimals } from 'src/utils/helpers'

export const getLift = (orig?: number, base?: number) => {
  if (orig === undefined) return
  if (base === undefined) return

  const r = roundToDecimals(orig/base)
  // NOTE: covers 0/0
  if (r + '' === 'NaN') return
  // NOTE: backend will always send non negative data
  if (Math.abs(r - 1) < 0.01) return

  return r < 1 ?
    -(1 - r) * 100 :
    (r - 1) * 100
}

export const formatDataValue = (num?: number, decimals = 2, prefix = '', postfix = '', defaultValue = '-'): string => {
  if (num === undefined) return defaultValue

  // There is an implicit assumption that percentages will not be more than 100
  const isThousands = (num / 1000) > 1 && (num / 1000) < 1000
  const isMillions = (num / 1000) > 1000 && (num / 1000) < 1000000
  const isBillions = (num / 1000) > 1000000

  if (isThousands) return `${prefix}${(num/1000).toFixed(decimals).toLocaleString()}K${postfix}`
  if (isMillions) return `${prefix}${(num/1000000).toFixed(decimals).toLocaleString()}M${postfix}`
  if (isBillions) return `${prefix}${(num/1000000000).toFixed(decimals).toLocaleString()}B${postfix}`

  return `${prefix}${num.toFixed(decimals).toLocaleString()}${postfix}`
}

export const formatNumber = (num: number | undefined, decimals = 2, defaultValue = '-') => {
  if (num === undefined) return defaultValue
  return `${(num).toLocaleString(undefined, { maximumFractionDigits: decimals, minimumFractionDigits: decimals})}`
}

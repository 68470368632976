import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2 } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShopCurrency } from '../types'

export interface MetaUrl {
  downloadUrl: string
}

export type GetMetaDownloadUrl = (
  isBrand:boolean, startTimestamp: number, endTimestamp: number
) => Promise<AxiosResponse<MetaUrl> | undefined>

export type GetMetaDownloadUrlApi = PrependShopCurrency<GetMetaDownloadUrl>

export const getMetaDownloadApi: GetMetaDownloadUrlApi = async (
  activeShop: string, currencyCode: string,
  isBrand:boolean, startTimestamp: number, endTimestamp: number,
) => {
  try {
    const baseUrl = '/v1/analytics/meta-download'
    const params = new URLSearchParams()
    params.append('currency', currencyCode)
    params.append('startTimestamp', startTimestamp.toString())
    params.append('endTimestamp', endTimestamp.toString())
    if(isBrand) params.append('isBrand', '1')
    const url = `${baseUrl}?${params.toString()}`

    const cfg = {
      baseURL: analyticsServiceBaseUrl2,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<MetaUrl>(url, cfg)

    return response
  }

  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

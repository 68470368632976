import React from 'react'

import { ReactComponent as NoData } from 'src/assets/icons/NoData.svg'

export default function CardEmpty(): JSX.Element {
  return (
    <div style={{textAlign: 'center'}}>
      <NoData />
    </div>
  )
}

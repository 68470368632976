import type { FC } from 'react'
import {
  Grid,
  Typography,

  Button,
} from '@mui/material'
import Icon from 'src/components/Icon/Icon'

export interface ThirdPartyRowBaseProps {
  imageName: string
  name: string
  subtext: string
  thirdPartyExists: boolean
  configPreview: string
  handleButtonClick: (e: any) => void
}

const ThirdPartyRowBase: FC<ThirdPartyRowBaseProps> = ({imageName, name, subtext, thirdPartyExists, configPreview, handleButtonClick}) => {
  return (
    <>
      <Grid item>
        <Grid
          container
          spacing={3}
          alignItems="center"
          direction="row"
        >
          <Grid item
            style={{
              width: '120px',
            }}>
            <Icon
              name={imageName}
              root={true}
            />
          </Grid>

          <Grid item>
            <Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  {name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                >
                  {subtext}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ textAlign: 'right' }}>
        <Button onClick={handleButtonClick}>
          <Grid
            direction='row'
            container
            spacing={1}
          >
            <Grid item>
              <Icon
                name='Plus'
                root={true}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
              >
                {`${thirdPartyExists ? 'Update' : 'Add'} Integration`}
              </Typography>
            </Grid>
          </Grid>
        </Button>
        {configPreview &&
      <Grid item>
        <div style={{
          fontWeight: 'bold',
          fontStyle: 'italic',
        }}>{configPreview}</div>
      </Grid>
        }
      </Grid>
    </>
  )
}

export default ThirdPartyRowBase

import React, { FC } from 'react'
import { IconButton, TextField } from '@mui/material'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import { List, arrayMove } from 'react-movable'
import { ComponentTypeResponse } from 'src/views/Components/types'
import { uuid4 } from '@sentry/utils'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  backgroundGradient: string
  inputClassName: string
}

export const Youtube: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  backgroundGradient,
  inputClassName,
}) => {

  const [lastItemUuid, setLastItemUuid] = React.useState<string>(uuid4())
  const [focusedId, setFocusedId] = React.useState<string | undefined>(undefined)

  return (
    componentConfig.contentConfiguration.type === 'YOUTUBE_PRIMITIVE' ?
      <List
        lockVertically
        values={componentConfig.contentConfiguration.value.apiSettings.htmls.concat({ id: lastItemUuid, value: '' })}
        onChange={({ oldIndex, newIndex }) => {
          const newComponent: ComponentTypeResponse = { ...componentConfig }
          if (newComponent.contentConfiguration.type !== 'YOUTUBE_PRIMITIVE') return

          newComponent.contentConfiguration.value.apiSettings.htmls = arrayMove(newComponent.contentConfiguration.value.apiSettings.htmls, oldIndex, newIndex)
          setComponentConfig(newComponent)
        }}
        renderList={(list) => <div {...list.props}>{list.children}</div>}
        renderItem={(listItem) => {
        // NOTE: because of the ts matching
          if (componentConfig.contentConfiguration.type !== 'YOUTUBE_PRIMITIVE') return

          const { isDragged } = listItem
          const htmlObj = listItem.value
          // TODO: why is this optional
          const j = listItem.index!
          const isLast = j === componentConfig.contentConfiguration.value.apiSettings.htmls.length

          return (
            <div {...listItem.props}
              style={{
                ...listItem.props.style,
                zIndex: 2,
              }}
              key={htmlObj.id}
            >
              <div style={{
                paddingLeft: '20px',
                background: backgroundGradient,
              }}>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      marginRight: '12px',
                    }}
                  >
                    <TextField
                      id="html_text"
                      onFocus={() => setFocusedId(htmlObj.id)}
                      onBlur={(e) => {
                        setFocusedId(undefined)

                        if (e.target?.tagName !== 'TEXTAREA') return
                        e.target.scrollTo(0, 0)
                      }}

                      fullWidth
                      label='Youtube Embed Code'
                      size='small' margin='dense' type='string' variant='outlined'
                      InputProps={{
                        className: inputClassName,
                      }}
                      multiline
                      maxRows={htmlObj.id === focusedId ? 5 : 1}
                      value={htmlObj.value}
                      onChange={(e) => {
                        const newComponent: ComponentTypeResponse = { ...componentConfig }
                        if (newComponent.contentConfiguration.type !== 'YOUTUBE_PRIMITIVE') return

                        const htmls = [...newComponent.contentConfiguration.value.apiSettings.htmls]

                        if (!htmls[j]) {
                          htmls[j] = { id: htmlObj.id, value: ''}
                          setLastItemUuid(uuid4())
                        }
                        htmls[j].value = e.target.value

                        newComponent.contentConfiguration.value.apiSettings.htmls = htmls
                        setComponentConfig(newComponent)
                      }}
                    />
                  </div>

                  <div
                    data-movable-handle
                    style={{
                      margin: '16px 6px 10px 0px',
                      cursor: isDragged ? 'grabbing' : 'grab',
                      opacity: isLast ? 0 : 1,
                      pointerEvents: isLast ? 'none' : 'auto',
                    }}
                  >
                    <DragIcon />
                  </div>

                  <IconButton
                    style={{
                      opacity: isLast ? 0 : 1,
                    }}
                    disabled={isLast}

                    onClick={() => {
                    // NOTE: because of the ts matching
                      const newComponent: ComponentTypeResponse = { ...componentConfig }
                      if (newComponent.contentConfiguration.type !== 'YOUTUBE_PRIMITIVE') return

                      let htmls = [...newComponent.contentConfiguration.value.apiSettings.htmls]
                      htmls = [...htmls.slice(0, j), ...htmls.slice(j + 1)]
                      newComponent.contentConfiguration.value.apiSettings.htmls = htmls

                      setComponentConfig(newComponent)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          )
        }}
      />
      :
      <div></div>
  )
}

import { axiosInstance } from 'src/utils/axios'
import type { AxiosResponse } from 'src/types/axios'
import { socialServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { PrependShop } from 'src/contexts/types'
import { Integration, IntegrationsResponse, MetaPixelsResponse, SaveCodeGetIntegrationsPayload, SetMetaPixelPayload } from 'src/views/StoreIntegrations/types'

export type GetIntegrations = () => Promise<AxiosResponse<IntegrationsResponse> | undefined>
export type GetIntegrationsApi = PrependShop<GetIntegrations>
export type GetMetaPixels = () => Promise<AxiosResponse<MetaPixelsResponse> | undefined>
export type GetMetaPixelsApi = PrependShop<GetMetaPixels>

export const getIntegrationsApi: GetIntegrationsApi = async (activeShop: string): Promise<AxiosResponse<IntegrationsResponse> | undefined> => {
  try {
    const response = await axiosInstance.get(`${socialServiceBaseUrl}/v1/integrations/meta?shop=${activeShop}`, {
      headers: {
        'Content-Type': 'application/json',
        'shop': activeShop,
      },
    })
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'get integrations error' } })
  }
}

export const getMetaPixelsApi: GetMetaPixelsApi = async (activeShop: string): Promise<AxiosResponse<MetaPixelsResponse> | undefined> => {
  try {
    const response = await axiosInstance.get(`${socialServiceBaseUrl}/v1/integrations/meta/pixels?shop=${activeShop}`, {
      headers: {
        'Content-Type': 'application/json',
        'shop': activeShop,
      },
    })
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'get meta pixels error' } })
  }
}

export type SetMetaPixel = (payload: SetMetaPixelPayload) => Promise<AxiosResponse<MetaPixelsResponse> | undefined>
export type SetMetaPixelApi = PrependShop<SetMetaPixel>

export const setMetaPixelApi: SetMetaPixelApi = async (activeShop: string, payload: SetMetaPixelPayload): Promise<AxiosResponse<MetaPixelsResponse> | undefined> => {
  try {
    const response = await axiosInstance.post(`${socialServiceBaseUrl}/v1/integrations/meta/pixels/selected`, {
      id: payload.pixelId,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'shop': activeShop,
      },
    })
    return response
  }
  catch (error) {
    console.log('set pixel error', error)
    logging(error, { tags: { section: 'set pixel error' } })
  }
}


export type SaveCodeGetIntegrations = (payload: SaveCodeGetIntegrationsPayload) => Promise<{err: string, data: Integration[]}>
export type SaveCodeGetIntegrationsApi = PrependShop<SaveCodeGetIntegrations>

export const saveCodeGetIntegrationsApi: SaveCodeGetIntegrationsApi = async (activeShop: string, rawPayload: SaveCodeGetIntegrationsPayload): Promise<{err: string, data: Integration[]}> => {

  try {
    const response = await axiosInstance.post(`${socialServiceBaseUrl}/v1/integrations/meta`, rawPayload, {
      headers: {
        'Content-Type': 'application/json',
        'shop': activeShop,
      },
    })
    return {err: '', data: response.data}
  }
  catch (error) {
    logging(error, { tags: { section: 'set integrations error' } })
    if (error?.response?.status === 401 || error?.response?.status === 403 ) {
      return {err: error?.response?.data?.error || 'Insufficient permissions', data: []}
    }

    return {err: 'An error has occured, please reach out to support.', data: []}

  }
}

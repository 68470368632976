import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { ThunkAction } from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import type { Action } from '@reduxjs/toolkit'
import { ENABLE_REDUX_DEV_TOOLS } from 'src/constants'
import rootReducer from './rootReducer'
import { getDefaultMiddleware } from '@reduxjs/toolkit'

export const middleware = [
  ...getDefaultMiddleware({ thunk: true, serializableCheck: false }),
]

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: ENABLE_REDUX_DEV_TOOLS,
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export const useDispatch = (): AppDispatch => useReduxDispatch<AppDispatch>()

export default store

import React, { FC, useRef } from 'react'
import { Box, useTheme, TextField, Button, Select, MenuItem, IconButton, FormControl} from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid'
import { List, arrayMove } from 'react-movable'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import useGenericContext from 'src/hooks/useGenericContext'
import SdxColorPicker from '../Components/SdxColorPicker'
import { validateUrl } from 'src/utils/helpers'

const defaultConfig = {
  containerType: 'GRID',
  internalName: 'Header component',
  title: '',
  contentType: 'HEADER',
  componentType: 'HEADER',
  createdAt: 1,
  updatedAt: 1,
  shop: '',
  visibleInLibrary: false,
  internalDescription: '',
  class: 'HOOK',
  contentConfiguration: {
    type: 'HEADER' as const,
    value: {
      uiSettings: {
        ribbon: {
          backgroundColor: '',
          textColor: '',
          font: {
            size: 24,
          },
          height: 50,
          scrolling: {
            direction: 'HORIZONTAL' as const,
            speed: 15,
          },
        },
        logo: {
          backgroundColor: '',
        },
      },
      apiSettings: {
        logo: {
          url: '',
          destinationUrl: '',
        },
        ribbon: {
          font: {
            url: '',
          },
          values: [{
            // id: string, // is text as id enough for react? we don't have dragging
            // should this be just string or html for further customization like bold/italic parts
            text: '',
            id: '',
            linkUrl: '',
          }],
        },
      },
    },
  },
}

export type HeaderRibbonValues = {
  id: string
  text: string
  linkUrl: string
}

export type HeaderSettingsType = {
  containerType: string
  internalName: string
  title: string
  contentType: string
  componentType: string
  createdAt: number
  updatedAt: number
  shop: string
  visibleInLibrary: boolean
  internalDescription: string
  class: string
  contentConfiguration: {
    type: 'HEADER'
    value: {
      uiSettings: {
        ribbon: {
          backgroundColor: string
          textColor: string
          font: {
            size: number
          }
          height: number
          scrolling: {
            direction: 'HORIZONTAL' | 'VERTICAL'
            speed: number
          }
        }
        logo: {
          backgroundColor: string
        }
      }
      apiSettings: {
        logo: {
          url: string
          destinationUrl: string
        }
        ribbon: {
          font: {
            url: string
          }
          values: HeaderRibbonValues[]
        }
      }
    }
  }
}

const getDefaultRibbonValue = () => {
  return {
    id: uuidv4(),
    text: '',
    linkUrl:'',
  }
}

export const HeaderSettingsUI: FC<{
  config: HeaderSettingsType
  checkErrors: boolean
  setConfig: (config: HeaderSettingsType) => void
  setFormValid: (isFormValid: boolean) => void
}> = ({ config, checkErrors, setConfig, setFormValid }) => {
  const theme = useTheme()
  const formRef = useRef<HTMLObjectElement | null>(null)
  const {
    uploadStorefrontImage,
  } = useGenericContext()

  // Lets fall back to default config if none is provided
  config = config || defaultConfig
  const currentLogo = config.contentConfiguration.value.apiSettings.logo.url

  const handleInputChange = (event, field: string) => {
    const keys = field.split('.')
    const newConfig = { ...config }
    let current = newConfig.contentConfiguration.value
    for (let i = 0; i < keys.length - 1; i++) {
      current = current[keys[i]]
    }

    current[keys[keys.length - 1]] = event.target.value
    setConfig(newConfig)

    return setFormValid(isFormValid())
  }

  const handleRibbonTextChange = ((index: number, event) => {
    const newConfig = Object.assign({}, config)
    const newValues = [...config.contentConfiguration.value.apiSettings.ribbon.values]
    newValues[index].text = event.target.value
    newConfig.contentConfiguration.value.apiSettings.ribbon.values = newValues
    setConfig({ ...newConfig })
    return setFormValid(isFormValid())
  })

  const handleRibbonLinkChange = ((index: number, event) => {
    const newConfig = Object.assign({}, config)
    const newValues = [...config.contentConfiguration.value.apiSettings.ribbon.values]
    newValues[index].linkUrl = event.target.value
    newConfig.contentConfiguration.value.apiSettings.ribbon.values = newValues
    setConfig({ ...newConfig })
    return setFormValid(isFormValid())
  })

  const handleAddField = (() => {
    const newConfig = Object.assign({}, config)
    const newValues = [...config.contentConfiguration.value.apiSettings.ribbon.values, getDefaultRibbonValue()]
    newConfig.contentConfiguration.value.apiSettings.ribbon.values = newValues
    setConfig({ ...newConfig })
    return setFormValid(isFormValid())
  })

  const handleRibbonTextRemove = ((index: number) => {
    const newConfig = Object.assign({}, config)
    const newValues = [...config.contentConfiguration.value.apiSettings.ribbon.values]
    newValues.splice(index, 1)
    newConfig.contentConfiguration.value.apiSettings.ribbon.values = newValues
    setConfig({ ...newConfig })
    return setFormValid(isFormValid())
  })

  const handleRibbonValueOrderChange = ((oldIndex: number, newIndex: number) =>{
    const newConfig = Object.assign({}, config)
    const newValues = [...config.contentConfiguration.value.apiSettings.ribbon.values]
    newConfig.contentConfiguration.value.apiSettings.ribbon.values = arrayMove(newValues, oldIndex, newIndex)
    setConfig({ ...newConfig })
    return setFormValid(isFormValid())
  })

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target
    if (files) {
      if ((files[0].size > 300000)) {
        window.alert('File is too big!')
      } else {
        const response: any = await uploadStorefrontImage(files[0])
        if(response?.data?.url) {
          const newConfig = Object.assign({}, config)
          newConfig.contentConfiguration.value.apiSettings.logo.url = response?.data?.url || ''
          setConfig({ ...newConfig })
        }
      }
    }
  }

  const isRequired = ((value) => !!value)

  const isFormValid = (() => {
    if (!config.contentConfiguration.value.apiSettings.ribbon.values.reduce((acc, value) => {
      // A link url may be empty, but if it exists it must be valid
      const isTextValid = isRequired(value.text)
      const isLinkValid = !value.linkUrl || validateUrl(value.linkUrl)

      return acc && isTextValid && isLinkValid
    }, true)) return false
    if (!config.contentConfiguration.value.uiSettings.ribbon.backgroundColor) return false
    if (!config.contentConfiguration.value.uiSettings.ribbon.textColor) return false
    // logo destination url must be a valid url.
    if (!validateUrl(config.contentConfiguration.value.apiSettings.logo.destinationUrl)) return false
    return true
  })

  if(!config) return (
    <Box></Box>
  )
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            mb: 2,
          }}
        >
          Header Settings
        </Box>
        <Box>
          <Box>
            <Box
              component="form"
              noValidate
              onChange={() => {
                setFormValid(isFormValid())
              }}
              ref={formRef}
              autoComplete="off"
            >
              Ribbon Banner Settings
              <p style={{fontStyle: 'italic'}}>{'Set a site wide banner to promote offers'}</p>
              <Box
                sx={{
                  my: 2,
                }}
              >
                <Box sx={{
                  display: 'flex',
                  gap: '6px',
                }}>
                  <SdxColorPicker
                    label='Background color'
                    sx={{flex: 1, mb: 1}}
                    size='small'

                    value={config.contentConfiguration.value.uiSettings.ribbon.backgroundColor}
                    onChange={(value) => {
                      handleInputChange({target: {value}}, 'uiSettings.ribbon.backgroundColor')}
                    }

                    helperText={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.backgroundColor) ? 'This field is required' : ''}
                    error={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.backgroundColor)}
                  />

                  <SdxColorPicker
                    label='Text color'
                    sx={{flex: 1, mb: 1}}
                    size='small'

                    value={config.contentConfiguration.value.uiSettings.ribbon.textColor}
                    onChange={(value) => {handleInputChange({target: {value}}, 'uiSettings.ribbon.textColor')}}

                    helperText={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.textColor) ? 'This field is required' : ''}
                    error={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.textColor)}
                  />
                </Box>

                <Box
                >
                  Ribbon copy
                </Box>
                <Box sx={{
                  maxHeight: '210px',
                  border: '1px solid lightgray',
                  borderRadius: '5px',
                  overflow: 'auto',
                  pt: 2,
                  mb: 2,
                  pr: 0.5,
                }}>
                  <List
                    lockVertically
                    values={config.contentConfiguration.value.apiSettings.ribbon.values}
                    onChange={({ oldIndex, newIndex }) => {
                      handleRibbonValueOrderChange(oldIndex, newIndex)
                    }}
                    renderList={(list) => <Box {...list.props}>{list.children}</Box>}
                    renderItem={(listItem) => {
                      const value = listItem.value
                      const index = listItem.index ? listItem.index : config.contentConfiguration.value.apiSettings.ribbon.values.findIndex((val) => val.id === value.id)
                      // const isLast = index === config.contentConfiguration.value.apiSettings.ribbon.values.length
                      return (
                        <Box
                          {...listItem.props}
                          // key={isLast ? index : value.id}
                          key={value.id}
                        >
                          <Box sx={{
                            ml: 1,
                          }}>
                            <Box
                              sx={{
                                display: 'flex',
                                gap: '6px',
                              }}
                            >
                              <Box
                                sx={{
                                  flex: 1,
                                  mb: 2,
                                }}
                              >
                                <TextField
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  label='Ribbon text'
                                  value={value.text}
                                  onChange={(event) => handleRibbonTextChange(index, event)}
                                  helperText={checkErrors && !isRequired(value.text) ? 'This field is required' : ''}
                                  error={checkErrors && !isRequired(value.text)}
                                />
                              </Box>
                              <Box
                                sx={{
                                  flex: 1,
                                  mb: 1,
                                }}
                              >
                                <TextField
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  label='Ribbon URL (optional)'
                                  value={value.linkUrl}
                                  onChange={(event) => handleRibbonLinkChange(index, event)}
                                  helperText={checkErrors && value.linkUrl && !validateUrl(value.linkUrl) ? 'Valid https URL is required' : ''}
                                  error={checkErrors && !!value.linkUrl && !validateUrl(value.linkUrl)}
                                />
                              </Box>

                              <Box sx={{display: 'flex', mt: 1, alignItems: 'baseline'}}>

                                <Box
                                  data-movable-handle
                                  sx={{
                                    mr: 1,
                                    ml: 1,
                                    cursor: listItem.isDragged ? 'grabbing' : 'grab',

                                    // opacity: isLast ? 0 : 1,
                                    // pointerEvents: isLast ? 'none' : 'auto',
                                  }}
                                >
                                  <DragIcon />
                                </Box>

                                <IconButton
                                  sx={{
                                    transform: 'translateY(-5px)',

                                    // opacity: isLast ? 0 : 1,
                                  }}
                                  // disabled={isLast}

                                  onClick={() => {
                                    handleRibbonTextRemove(index)
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )
                    }}
                  />
                  <Box display="flex" justifyContent="space-between" sx={{ml: 1, pb: 1}}>
                    <Button variant="contained" color="primary" onClick={handleAddField} startIcon={<AddIcon />}>
                      Add text field
                    </Button>
                  </Box>
                </Box>

                Font Override (Accepted Formats: .woff, .woff2, .ttf, .otf)
                <Box>
                  <TextField
                    sx={{mb: 3, mr: 1}}
                    onChange={(ev) => handleInputChange(ev, 'apiSettings.ribbon.font.url')}
                    size='small' type='string' variant='outlined' fullWidth
                    value={config.contentConfiguration.value.apiSettings.ribbon.font.url}
                  />
                </Box>

                <Box sx={{display: 'flex', gap: '6px'}}>
                  <TextField
                    label='Ribbon height'
                    InputProps={{endAdornment: <Box>px</Box>}}
                    onChange={(ev) => handleInputChange(ev, 'uiSettings.ribbon.height')}
                    size='small' type='number' variant='outlined'
                    value={config.contentConfiguration.value.uiSettings.ribbon.height}
                    helperText={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.height) && 'This field is required'}
                    error={checkErrors && checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.height)}
                  />

                  <TextField
                    label='Font size'
                    InputProps={{endAdornment: <Box>px</Box>}}
                    onChange={(ev) => handleInputChange(ev, 'uiSettings.ribbon.font.size')}
                    size='small' type='number' variant='outlined'
                    value={config.contentConfiguration.value.uiSettings.ribbon.font.size}
                    helperText={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.font.size) && 'This field is required'}
                    error={checkErrors && checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.font.size)}
                  />

                  {/* TODO: convert this to a component with min max and default */}
                  <TextField
                    label='Transition speed'
                    InputProps={{endAdornment: <Box>sec</Box>}}
                    onChange={(e) => {
                      // NOTE: let empty value until onBlur event
                      if (e.target.value !== '') {
                        const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
                        e.target.value = clamp(e.target.value, 2, 10) + ''
                      }
                      handleInputChange(e, 'uiSettings.ribbon.scrolling.speed')
                    }}
                    onBlur={(e) => {
                      // set empty string to default value
                      if (e.target.value === '') {
                        e.target.value = '5'
                        handleInputChange(e, 'uiSettings.ribbon.scrolling.speed')
                      }
                    }}
                    size='small' type='number' variant='outlined'
                    value={config.contentConfiguration.value.uiSettings.ribbon.scrolling.speed}
                    helperText={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.scrolling.speed) && 'This field is required'}
                    error={checkErrors && checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.scrolling.speed)}
                  />

                </Box>

                {/* Scrolling direction
                <Box>
                  <Select
                    sx={{
                      mb: 1,
                    }}
                    onChange={(ev) => handleInputChange(ev, 'uiSettings.ribbon.scrolling.direction')}
                    size='small' type='number' variant='outlined'
                    value={config.contentConfiguration.value.uiSettings.ribbon.scrolling.direction}
                    error={checkErrors && checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.ribbon.scrolling.direction)}
                  >
                    <MenuItem value={'HORIZONTAL'}>Scroll horizontally</MenuItem>
                    <MenuItem value={'VERTICAL'}>Scrroll vertically</MenuItem>
                  </Select>
                </Box> */}

              </Box>

              Logo Header Settings
              <Box sx={{
                marginTop: '8px',
                marginBottom: '16px',
              }}>
                <Box style={{fontStyle: 'italic'}}>{'Sets the background color of the campaign store header behind the logo'}</Box>

                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <SdxColorPicker
                    label='Header background color'
                    sx={{my: 2, maxWidth: '50%'}}
                    size='small'

                    value={config.contentConfiguration.value.uiSettings.logo.backgroundColor}
                    onChange={(value) => {handleInputChange({target: {value}}, 'uiSettings.logo.backgroundColor')}}

                    helperText={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.logo.backgroundColor) ? 'This field is required' : ''}
                    error={checkErrors && !isRequired(config.contentConfiguration.value.uiSettings.logo.backgroundColor)}
                  />

                  Store logo (PNG only. Maximum size of 300kb.)
                  <Box>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                      <Box sx={{display: 'flex', flexDirection: 'column'}}><img src={currentLogo} style={{ maxWidth: 300 }} height={'auto'} alt="Preview not available" /></Box>
                      {/* <Box sx={{display: 'flex', flexDirection: 'column', margin: 10}}><span>New logo</span>{formDetails.logo && <img src={formDetails.logo ? URL.createObjectURL(formDetails.logo) : ''} style={{ maxWidth: 300 }} height={'auto'} alt="Preview not available" />}</Box> */}
                    </Box>
                    <FormControl>
                      <input onChange={handleImageUpload} id="logo" name="logo" aria-describedby="store-logo" type="file" accept="image/png" />
                    </FormControl>
                  </Box>

                  URL to send users upon logo click
                  <Box>
                    <TextField
                      sx={{mb: 3, mr: 1}}
                      onChange={(ev) => handleInputChange(ev, 'apiSettings.logo.destinationUrl')}
                      size='small' type='string' variant='outlined' fullWidth
                      value={config.contentConfiguration.value.apiSettings.logo.destinationUrl}
                      helperText={checkErrors && !validateUrl(config.contentConfiguration.value.apiSettings.logo.destinationUrl) ? 'This field must be a valid https URL.' : ''}
                      error={checkErrors && !validateUrl(config.contentConfiguration.value.apiSettings.logo.destinationUrl)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  )
}

import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button,   LinearProgress,   useTheme } from '@mui/material'
import Icon from 'src/components/Icon/Icon'

import useGenericContext from 'src/hooks/useGenericContext'
import { generateVideoThumbnail } from 'src/utils/generateVideoThumbnail'
import { AxiosResponse } from 'axios'

const PREFIX = 'UploadComponent'

const classes = {
  uploadImageIcon: `${PREFIX}-uploadImageIcon`,
  uploadText: `${PREFIX}-uploadText`,
  uploadBox: `${PREFIX}-uploadBox`,
}

const Root = styled('div')(({theme}) => ({
  [`& .${classes.uploadImageIcon}`]: {
    width: '100%',
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: '48px',
      height: '48px',
      margin: '53px auto 20px',
    },
  },

  [`& .${classes.uploadText}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    color: theme.palette.brand.urIndigo,
  },

  [`& .${classes.uploadBox}`]: {
    position: 'relative',
    width: 160,
    height: 160,
    minHeight: 160,
    backgroundColor: theme.palette.background.dark,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '& input': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      zIndex: 9,
      cursor: 'pointer',
    },
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      jusitifyContent: 'center',
      alignItems: 'center',
      zIndex: 9,
    },
  },
}))

type UploadComponentProps = {
  selectedImage: string
  onImageUrlChange: (url: string) => void
  onCampaignVideoChange: ({url, thumbnail}) => void
  onRemovePreview: () => void
}
export const UploadComponent: FC<UploadComponentProps> = ({
  selectedImage,
  onImageUrlChange,
  onRemovePreview,
  onCampaignVideoChange,
}) => {

  const theme = useTheme()
  const [loading, setLoading] = React.useState(false)

  const {
    uploadStorefrontImage,
  } = useGenericContext()

  return (
    <Root>
      <Box className={classes.uploadBox} style={{ backgroundImage: selectedImage ? `url(${selectedImage})` : 'none' }}>
        {loading && <LinearProgress /> }
        {typeof selectedImage === 'string' && selectedImage && (
          <>
            <div>
              <Button
                variant='text'
                color='primary'
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  margin: '0 auto',
                  zIndex: 10,
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  color: 'white',
                  padding: '2px',
                  borderRadius: 0,
                }}
                onClick={() => {
                  onRemovePreview()
                }}
              >
              Remove
              </Button>
            </div>
          </>
        )}
        {!(typeof selectedImage === 'string' && selectedImage) && (
          <div>
            <Icon
              name='uploadImage'
              root={true}
              color={'#B0BEC5'}
              className={classes.uploadImageIcon}
            />
            <span className={classes.uploadText}>
              <Icon
                name='upload'
                root={true}
                color={theme.palette.brand.urIndigo}
              />
              Upload</span>
            <input
              type='file'
              name='campaignImage'
              accept='image/*, ,.mp4'
              onChange={async (event) => {
                try {
                  setLoading(true)
                  const file = event?.target?.files ? event.target.files[0] : null
                  if (file?.name?.endsWith('.mp4')) {
                    let thumbnailUrl = ''
                    const imageThumbnail = await generateVideoThumbnail(file, 0.5)
                    const thumbnailResponse: AxiosResponse<{url: string}> | undefined = await uploadStorefrontImage(imageThumbnail)
                    if(thumbnailResponse?.data?.url) {
                      thumbnailUrl = thumbnailResponse?.data?.url
                    }

                    let videoUrl = ''
                    const response: any = await uploadStorefrontImage(file)
                    if(response?.data?.url) {
                      videoUrl = response?.data?.url
                    }

                    onCampaignVideoChange({ url: videoUrl, thumbnail: thumbnailUrl })
                    setLoading(false)

                  } else if (file) {
                    const response: any = await uploadStorefrontImage(file)
                    if(response?.data?.url) {
                      onImageUrlChange(response?.data?.url)
                    }
                    setLoading(false)
                  } else {
                    setLoading (false)
                    throw new Error('Invalid file type. Please upload an image or video file.')
                  }

                } catch (error) {
                  setLoading(false)
                  throw new Error('Unable to upload file')
                }
              }}
            />
          </div>
        )}
      </Box>
    </Root>
  )
}

import _ from 'lodash'
import { createTheme } from '@mui/material/styles'
import { colors, responsiveFontSizes } from '@mui/material'
import type { Shadows as MuiShadows } from '@mui/material/styles/shadows'
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground,
} from '@mui/material/styles/createPalette'
import { THEMES } from 'src/constants'
import { softShadows } from './shadows'
import typography from './typography'
import { brand, text, system, TypeBrand, TypeSystem, TypeText} from './tokens'
declare module '@mui/material/styles' {
  interface Theme {
    name: string
    palette: Palette
    border: TypeBorder
    icon: {
      main: string
    }
    // status: {
    //   danger: string
    // }
  }
  // allow configuration using `createTheme`
  // interface ThemeOptions {
  //   status?: {
  //     danger?: string
  //   }
  // }
}

interface TypeBackground extends MuiTypeBackground {
  dark: string
  red: string
}

interface TypeBorder {
  dark: string
  radius: string
}

interface Palette extends MuiPalette {
  background: TypeBackground
  text: TypeText
  brand: TypeBrand
  system: TypeSystem
}

type Direction = 'ltr' | 'rtl'

interface DeprecatedThemeOptions {
  name?: string
  direction?: Direction
  typography?: Record<string, any>
  components?: Record<string, any>
  palette?: Record<string, any>
  shadows?: MuiShadows
  border?: Record<string, any>
  icon?: Record<string, any>
  props?: Record<string, any>
}

const baseOptions: DeprecatedThemeOptions = {
  direction: 'ltr',
  typography: {
    ...typography,
    body1: {
      fontSize: '14px',
    },
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'San Francisco',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'IBM Plex Sans',
      'sans-serif',
    ]
      .map(x => `"${x}"`)
      .join(','),
    button: {
      textTransform: 'none',
      // fontWeight: 900,
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.075)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          borderTop: '1px solid lightgrey',
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        },
        listBox: {
          padding: '0px !important',
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 20000999,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 20000999,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 20000999,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: 20000999,
        },
      },
    },
  },
}

let lightThemeOptions: DeprecatedThemeOptions = {}

lightThemeOptions = {
  name: THEMES.LIGHT,
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: brand.blueGrey,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          padding: '22px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
          color: brand.frenchBlue,
          backgroundColor: 'white',
          outline: '1px solid lightgrey',
        },
        arrow: {
          fontSize: 20,
          color: brand.frenchBlue,
          '&::before': {
            backgroundColor: 'white',
            outline: '1px solid lightgrey',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          padding: '10px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
          color: text.link,
          textDecoration: 'none',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          color: [brand.blueGrey, '!important'],
        },
      },
    },
  },
  palette: {
    mode: 'light',
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white,
    },
    primary: {
      main: brand.urIndigo,
      contrastText: '#ffffff',
    },
    secondary: {
      main: brand.frenchBlue,
      contrastText: '#ffffff',
      color: '#ffffff',
    },
    text: text,
    brand: brand,
    system: system,
  },
  icon: {
    main: brand.blueGrey,
  },
  border: {
    dark: '#263238',
    radius: '4px',
  },

  // props: {
  //   MuiIconButton: {
  //     disableRipple: true,
  //   },
  //   MuiButton: {
  //     disableRipple: true,
  //   }
  // },
  shadows: softShadows,
}

export const createCustomTheme = () => {
  let theme = createTheme(
    (_.merge({}, baseOptions, lightThemeOptions, { direction: 'ltr' })),
  )

  theme = responsiveFontSizes(theme)

  return theme
}

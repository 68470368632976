import {
  Card, EmptyState, Page,
  FooterHelp,
} from '@shopify/polaris'
import React, { FC } from 'react'
import {Link, AccountConnection} from '@shopify/polaris'
import {useState, useCallback} from 'react'
import AccountConnectionModal from './AccountConnectionModal'
import useShopifyContext from 'src/hooks/useShopifyContext'

const ShopifyConnectApp: FC = () => {

  const { appConnected, connectApp } = useShopifyContext()

  const [showConnectionModal, setShowConnectionModal] = useState(false)

  const handleCloseConnectModal = useCallback(() => {
    setShowConnectionModal(false)
  }, [])

  const handleOpenConnectModal = useCallback(() => {
    setShowConnectionModal(true)
  }, [])

  const handleSetConnection = useCallback(() => {
    connectApp()
    setShowConnectionModal(false)
  }, [])


  let link
  switch (process.env.REACT_APP_DEPLOY_REGION) {
  case `prod-use2`:
    link = <Link external url="https://app.simplicitydx.com/">https://app.simplicitydx.com/</Link>
    break
  case `stage-use2`:
    link = <Link external url="https://app.stage.simplicitydx.com/">https://app.stage.simplicitydx.com/</Link>
    break
  default:
    link = <Link external url="https://app.dev.simplicitydx.com/">https://app.dev.simplicitydx.com/</Link>
    break
  }


  const accountName = ''
  const buttonText = appConnected ? 'Disconnect' : 'Connect'
  const details = appConnected ? <span>Account connected - {link}</span> : 'No account connected'
  const terms = appConnected ? null : (
    <p>
      By clicking <strong>Connect</strong>, you agree to accept SimplicityDX’s{' '}
      <Link url="https://www.simplicitydx.com/terms-conditions">terms and conditions</Link>. You will not be charged a commission on sales made through SimplicityDX.
    </p>
  )

  return (
    <Page>
      <div style={{ background: 'white', display: 'flex', alignItems: 'center', marginBottom: '10px'}}><img src="/static/images/simplicity_circular.png" alt='SDX Icon' width='30px' height='30px' style={{ verticalAlign: 'bottom', margin: '8px'}}/> <div></div>SimplicityDX</div><div/>
      <Card sectioned>
        <EmptyState
          heading="Provide shoppers with dynamic edge shopping experiences"
          image="/static/images/connection_graphic.png"
          imageContained
        >
          <p>
          Reach e-commerce shoppers on the edge of the web with SimplicityDX. Provide frictionless e-commerce.
          </p>
        </EmptyState>
        <AccountConnection
          accountName={accountName}
          connected={appConnected}
          title="SimplicityDX"
          action={{
            content: buttonText,
            onAction: handleOpenConnectModal,
          }}
          details={details}
          termsOfService={terms}
        />
      </Card>
      <FooterHelp><p style={{textAlign: 'center', margin: '20px' }}>For more details and support on how to connect your account, <Link external url="https://support.simplicitydx.com/en/articles/6561816-how-to-connect-simplicitydx-to-your-shopify-account">click here</Link></p></FooterHelp>
      {showConnectionModal && <AccountConnectionModal handleChange={handleCloseConnectModal} active={showConnectionModal} handleSetConnection={handleSetConnection}/>}
    </Page>
  )

}

export default ShopifyConnectApp


import { axiosInstance } from 'src/utils/axios'
import type { AxiosResponse } from 'src/types/axios'
import { configAPIBaseURL, componentServiceBaseUrl } from 'src/config'
import type { StoreSettingsPayload, CustomDomainPayload, CustomDomainSettingsResponse, TrackingScriptsPayload, StoreSettingsResponse, ThirdParty, HeadScriptPayload } from 'src/types/storeSettings'
import { logging } from 'src/utils/logging'
import { PrependShop } from 'src/contexts/types'

export type SaveStoreSettings = (payload: { config: StoreSettingsPayload, logo: any }) => Promise<AxiosResponse<unknown> | undefined>
export type SaveStoreSettingsApi = PrependShop<SaveStoreSettings>

export const saveStoreSettingsApi : SaveStoreSettingsApi = async (activeShop: string, payload: { config: StoreSettingsPayload, logo: any }): Promise<AxiosResponse<StoreSettingsResponse> | undefined> => {
  try {
    const form = new FormData()
    form.append('config', JSON.stringify(payload.config))
    payload.logo ? form.append('logo', payload.logo, 'logo') : form.append('logo', '')
    const response = await axiosInstance.post<StoreSettingsResponse>(`/v1/style`, form, {
      baseURL: componentServiceBaseUrl, headers: {
        'Content-Type': undefined,
        'Shop': activeShop,
      },
    })
    return response
  } catch (error) {
    logging(error, { tags: { section: 'store settings api' } })
  }
}

export type GetStoreSettings = () => Promise<AxiosResponse<StoreSettingsResponse> | undefined>
export type GetStoreSettingsApi = PrependShop<GetStoreSettings>

export const getStoreSettingsApi : GetStoreSettingsApi = async (activeShop: string ): Promise<AxiosResponse<StoreSettingsResponse> | undefined> => {
  const path = '/v1/style'
  const params = new URLSearchParams()
  params.append('activeShop', activeShop)
  const url = `${path}?${params.toString()}`

  try {
    const response = await axiosInstance.get(url, {
      baseURL: componentServiceBaseUrl,
      headers:{
        'Shop': activeShop,
        'Cache-Control': 'no-cache',
      },
    })
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'store settings api' } })
  }
}

export type SaveCustomDomain = (payload: CustomDomainPayload) => Promise<AxiosResponse<Array<CustomDomainSettingsResponse>> | undefined>
export type SaveCustomDomainApi = PrependShop<SaveCustomDomain>

export const saveCustomDomainApi: SaveCustomDomainApi = async (activeShop: string, payload: CustomDomainPayload): Promise<AxiosResponse<Array<CustomDomainSettingsResponse>> | undefined> => {
  try {
    const response = await axiosInstance.post(`/v1/domain`, payload, {
      baseURL: componentServiceBaseUrl, headers: {
        'Content-Type': 'application/json',
        'Shop': activeShop,
      },
    })
    return response
  } catch (error) {
    console.log('custom domain setting api error', error)
    logging(error, { tags: { section: 'custom domain api error' } })
  }
}

export type GetActiveCustomDomain = () => Promise<AxiosResponse<{ domain: string }> | undefined>
export type GetActiveCustomDomainApi = PrependShop<GetActiveCustomDomain>

export const getActiveCustomDomainApi: GetActiveCustomDomainApi = async (activeShop: string): Promise<AxiosResponse<{ domain: string }> | undefined> => {
  try {
    const response = await axiosInstance.get(`/v1/domain`, { baseURL: componentServiceBaseUrl, headers: {
      'Shop': activeShop,
    } })
    return response
  } catch (error) {
    console.log('get active custom domain api error', error)
    logging(error, { tags: { section: 'get active custom domain api error' } })
  }
}

export type GetThirdParty = () => Promise<AxiosResponse<ThirdParty[]> | undefined>
export type GetThirdPartyApi = PrependShop<GetThirdParty>

export const getThirdPartyApi: GetThirdPartyApi = async (activeShop: string): Promise<AxiosResponse<ThirdParty[]> | undefined> => {
  try {
    const response = await axiosInstance.get(`${componentServiceBaseUrl}/v1/third-party`, {
      headers: {
        'Content-Type': 'application/json',
        'Shop': activeShop,
      },
    })
    return response
  }
  catch (error) {
    console.log('get third party error', error)
    logging(error, { tags: { section: 'get third party error' } })
  }
}

export type SaveThirdParty = (payload: ThirdParty[]) => Promise<AxiosResponse<ThirdParty[]> | undefined>
export type SaveThirdPartyApi = PrependShop<SaveThirdParty>

export const saveThirdPartyApi: SaveThirdPartyApi = async (activeShop: string, payload: ThirdParty[]): Promise<AxiosResponse<ThirdParty[]> | undefined> => {
  try {
    const response = await axiosInstance.post(`/v1/third-party`, payload, {
      baseURL: componentServiceBaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'Shop': activeShop,
      },
    })
    return response
  } catch (error) {
    console.log('set tracking scripts api error', error)
    logging(error, { tags: { section: 'set tracking scripts api error' } })
  }
}

export type GetTrackingScripts = () => Promise<AxiosResponse<{ response: { data: any } }> | undefined>
export type GetTrackingScriptsApi = PrependShop<GetTrackingScripts>

export const getTrackingScriptsApi: GetTrackingScriptsApi = async (activeShop: string): Promise<AxiosResponse<{ response: { data: any } }> | undefined> => {
  try {
    const response = await axiosInstance.get(`${configAPIBaseURL}/getconfig?tenant=${activeShop}&client=shop&configName=trackingPixel&realm=simplicitydx`, {
      headers: {
      },
    })
    return response
  } catch (error) {
    console.log('get tracking scripts api error', error)
    logging(error, { tags: { section: 'get tracking scripts api error' } })
  }
}

export type SaveTrackingScripts = (payload: TrackingScriptsPayload) => Promise<AxiosResponse<{ response: string }> | undefined>
export type SaveTrackingScriptsApi = PrependShop<SaveTrackingScripts>

export const saveTrackingScriptsApi: SaveTrackingScriptsApi = async (activeShop: string, payload: TrackingScriptsPayload): Promise<AxiosResponse<{ response: string }> | undefined> => {
  try {
    const response = await axiosInstance.post(`${configAPIBaseURL}/setconfig`, { ...payload, realm: 'simplicitydx' }, {
      headers: {
      },
    })
    return response
  } catch (error) {
    console.log('set tracking scripts api error', error)
    logging(error, { tags: { section: 'set tracking scripts api error' } })
  }
}

export type SaveHeadTrackingScripts = (payload: HeadScriptPayload) => Promise<AxiosResponse<{ response: string }> | undefined>
export type SaveHeadTrackingScriptsApi = PrependShop<SaveHeadTrackingScripts>

export const saveHeadTrackingScriptsApi: SaveHeadTrackingScriptsApi = async (activeShop: string, payload: HeadScriptPayload): Promise<AxiosResponse<{ response: string }> | undefined> => {
  try {
    const response = await axiosInstance.put(`/v1/head-script`, payload, {
      baseURL: componentServiceBaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'Shop': activeShop,
      },
    })
    return response
  } catch (error) {
    console.log('set tracking scripts api error', error)
    logging(error, { tags: { section: 'set tracking scripts api error' } })
  }
}


import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'

import type { FC }from 'react'

import { getRedirectEndpoint, authorizationEndpoint, registerStore } from './duck/api'
import { getAppHandleApi } from 'src/contexts/api/getAppHandle'

import { logging } from 'src/utils/logging'

const PREFIX = 'ShopifyAuthHome'

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  text: `${PREFIX}-text`,
  logo: `${PREFIX}-logo`,
}

const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',

    backgroundColor: theme.palette.background.default,
    justifyContent: 'center',
    flexDirection: 'column',

    height: '100vh',
    width: '100vw',
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },

  [`& .${classes.text}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.logo}`]: {
    animationDuration: '1.5s',
    animationTimingFunction: 'ease-in-out',
    animationDelay: '0.5s',
    animationIterationCount: 'infinite',
    animationDirection: 'normal',
    animationFillMode: 'none',
    animationPlayState: 'running',
    animationName: 'Simplicity-keyframes-pulse',
  },

  '@keyframes Simplicity-keyframes-pulse' : {
    '0%':  {
      opacity: 1,
    },
    '50%':  {
      opacity: 0.4,
    },
    '100%':  {
      opacity: 1,
    },
  },
}))

interface ShopifyAuthProps {
 shop: string
 hmac: string
 timestamp: string
 code: string
 host: string
 state: string
 queryParams: string
}

const ShopifyAuthHome: FC<ShopifyAuthProps> = ({ shop, hmac, timestamp, code, host, state, queryParams }) => {

  const history = useHistory()

  useEffect(() => {
    const initiateAuthorization = async () => {
      try {
        const response = await getRedirectEndpoint({queryParams})
        if(response?.data?.registrationUrl) {
          window.location.href = response?.data?.registrationUrl
        }
        else {
          logging('Error fetching redirect url', { tags: { section: 'Shopify registration'}})
          window.alert('Error fetching redirect url')
        }
      }
      catch (error) {
        logging(error, { tags: { section: 'ShopifyAuthHome registration'}})
        window.alert('Error fetching redirect url')
      }
    }

    const completeAuthorization = async () => {
      try {
        // Execute both calls in parallel and wait for both to complete
        const [appHandleResponse, authorizationResponse] = await Promise.all([
          getAppHandleApi(shop),
          authorizationEndpoint({ queryParams }),
        ])

        if(appHandleResponse && authorizationResponse) {
          registerStore({shop: shop})
          window.location.href = `https://${shop}/admin/apps/${appHandleResponse.data.handle}`
        }
        else {
          console.warn('Error authenticating user')
          window.alert('Error authenticating user')
        }
      }
      catch (error) {
        logging(error, { tags: { section: 'ShopifyAuthHome  completeAuthorization'}})
        window.alert('Error authenticating user')
      }
    }

    if (code) {
      completeAuthorization()
    }
    else {
      if (shop) initiateAuthorization()
    }
  }, [code, history, hmac, host, queryParams, shop, state, timestamp])

  return (
    <Root data-testid="auth-loading-spinner" className={classes.root}>
      <div className={classes.wrapper}>
        <img className={classes.logo} alt='Simplicity logo' width={250} height={250} src="/static/images/simplicity_circular.png"/>
        <div className={classes.text}><p>Installing app on your shop ...</p></div>
      </div>
    </Root>
  )
}

export default ShopifyAuthHome

import React, { FC } from 'react'
import { IconButton, MenuItem, TextField } from '@mui/material'
import { List, arrayMove } from 'react-movable'
import AsyncSelectCategories from 'src/components/AsyncSelectCategories'
import { ReactComponent as DragIcon } from 'src/assets/icons/Drag.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/CloseIcon.svg'
import { Category } from 'src/contexts/types'
import ProductSelectorLarge, { ProductItemFull } from 'src/components/ProductSelectorLarge'
import { ComponentTypeResponse, getExtendedFields, getMinifiedFields } from 'src/views/Components/types'
import { getInitialStorePrimitiveSettings } from 'src/contexts/GenericContext'
import { ContainerTypeEnum, DisplayTypesEnum } from 'src/views/ComponentSettingsPage/types'
import { ContainerTypeSelector, DisplayTypeSelector, ImageFitSelector } from './SharedSelectors'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
  currentSelectedProductMap: Record<string, ProductItemFull>
  currentSelectedCategoryMap: Record<string, Category>
  backgroundGradient: string
  openWhenNotSelected: boolean | undefined
  error?: boolean | undefined
  label?: string | undefined
}

export const AllProductsInCategoryInput: FC<Props> = ({
  componentConfig,
  setComponentConfig,
  currentSelectedProductMap,
  currentSelectedCategoryMap,
  backgroundGradient,
  openWhenNotSelected,
  error,
  label,
}) => {
  const lastIndexId = 'lastIndexId'
  return (
    componentConfig.contentConfiguration.type === 'CATEGORY_PRIMITIVE' ?
      <div>
        <AsyncSelectCategories
          selected={currentSelectedCategoryMap[componentConfig.contentConfiguration.value.apiSettings.categoryIds[0]]}
          onChange={(e, value) => {
            if (value && typeof value === 'object') {
              currentSelectedCategoryMap[value.id] = value
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
              newComponent.contentConfiguration.value.apiSettings.categoryIds = [value.id]
              setComponentConfig(newComponent)
            }
          }}
          error={error}
          label={label}
        />
        <div style={{
          display: 'flex',
          gap: 4,
          marginBottom: 2,
        }}>
          <TextField
            id="all_product_in_category_fields"
            style={{
              width: '100%',
              marginBottom: '8px',
            }}
            select
            value={componentConfig.contentConfiguration.value.apiSettings.sortBy}
            size='small' margin='dense' type='string' variant='outlined'
            label="Sort By"
            onChange={(e) => {
              const val: any = e.target.value
              if (val !== 'TITLE' && val !== 'PRICE' && val !== 'SALES') return console.warn(`Wrong value ${val}`)
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
              newComponent.contentConfiguration.value.apiSettings.sortBy = val
              setComponentConfig(newComponent)
            }}
          >
            <MenuItem key={'TITLE'} value={'TITLE'}>Name</MenuItem>
            <MenuItem key={'PRICE'} value={'PRICE'}>Price</MenuItem>
            <MenuItem key={'SALES'} value={'SALES'}>Sales</MenuItem>
          </TextField>
          <TextField
            id="all_product_in_category_sort"
            style={{
              width: '100%',
              marginBottom: '8px',
            }}
            select
            value={componentConfig.contentConfiguration.value.apiSettings.sortDir}
            size='small' margin='dense' type='string' variant='outlined'
            label="Direction"
            onChange={(e) => {
              const val: any = e.target.value
              if (val !== 'ASC' && val !== 'DESC') return console.warn(`Wrong value ${val}`)
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
              newComponent.contentConfiguration.value.apiSettings.sortDir = val
              setComponentConfig(newComponent)
            }}
          >
            <MenuItem key={'ASC'} value={'ASC'}>Ascending</MenuItem>
            <MenuItem key={'DESC'} value={'DESC'}>Descending</MenuItem>
          </TextField>
        </div>
        <div style={{
          display: 'flex',
          gap: 4,
          marginBottom: 2,
        }}>
          <ImageFitSelector
            value={componentConfig.contentConfiguration.value.uiSettings.imageFit || getInitialStorePrimitiveSettings().category.uiSettings.imageFit}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
              newComponent.contentConfiguration.value.uiSettings.imageFit = value
              setComponentConfig(newComponent)
            }}
            section="category"
          />
          <DisplayTypeSelector
            value={componentConfig.contentConfiguration.value.uiSettings.fields.length > 1 ? DisplayTypesEnum.EXTENDED : DisplayTypesEnum.MINIFIED}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
              newComponent.contentConfiguration.value.uiSettings.fields =
                value === DisplayTypesEnum.MINIFIED ? getMinifiedFields() : getExtendedFields()
              setComponentConfig(newComponent)
            }}
          />
          <ContainerTypeSelector
            value={componentConfig.containerType as ContainerTypeEnum}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return
              newComponent.containerType = value as ContainerTypeEnum
              setComponentConfig(newComponent)
            }}
          />
        </div>
        <List
          lockVertically
          values={(componentConfig.contentConfiguration.value.apiSettings.blacklistedProductIds || []).concat([lastIndexId])}
          onChange={({ oldIndex, newIndex }) => {
            const newComponent: ComponentTypeResponse = { ...componentConfig }
            if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return

            newComponent.contentConfiguration.value.apiSettings.blacklistedProductIds = arrayMove(newComponent.contentConfiguration.value.apiSettings.blacklistedProductIds || [], oldIndex, newIndex)
            setComponentConfig(newComponent)
          }}
          renderList={(list) => <div {...list.props}>{list.children}</div>}
          renderItem={(listItem) => {
            const { isDragged } = listItem
            const prodId = listItem.value
            const j = listItem.index
            const isLast = listItem.value === lastIndexId
            return (
              <div {...listItem.props}
                key={isLast ? j : prodId}
              >
                <div style={{
                  paddingLeft: '20px',
                  background: backgroundGradient,
                }}>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        marginRight: '12px',
                      }}
                    >
                      <ProductSelectorLarge
                        openWhenNotSelected={openWhenNotSelected}
                        onSelect={(item) => {
                          if (!item) return
                          currentSelectedProductMap[item.productId] = item
                          const newComponent: ComponentTypeResponse = { ...componentConfig }
                          if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return

                          if (isLast) {
                            newComponent.contentConfiguration.value.apiSettings.blacklistedProductIds = [...(newComponent.contentConfiguration.value.apiSettings.blacklistedProductIds || []), item.productId]
                          }
                          else {
                            newComponent.contentConfiguration.value.apiSettings.blacklistedProductIds =
                              (newComponent.contentConfiguration.value.apiSettings.blacklistedProductIds || [])
                                .map(productId => productId === prodId ? item.productId : prodId)
                          }
                          setComponentConfig(newComponent)
                        }}
                        selected={currentSelectedProductMap[prodId]}
                        previouslySelected={componentConfig.contentConfiguration.type === 'CATEGORY_PRIMITIVE' ? (componentConfig.contentConfiguration.value.apiSettings.blacklistedProductIds || []) : []}
                        label='Suppressed Product'
                      />
                    </div>

                    <div
                      data-movable-handle
                      style={{
                        margin: '16px 6px 10px 0px',
                        cursor: isDragged ? 'grabbing' : 'grab',

                        opacity: isLast ? 0 : 1,
                        pointerEvents: isLast ? 'none' : 'auto',
                      }}
                    >
                      <DragIcon />
                    </div>

                    <IconButton
                      style={{
                        opacity: isLast ? 0 : 1,
                      }}
                      disabled={isLast}

                      onClick={() => {
                        const newComponent: ComponentTypeResponse = { ...componentConfig }
                        if (newComponent.contentConfiguration.type !== 'CATEGORY_PRIMITIVE') return

                        newComponent.contentConfiguration.value.apiSettings.blacklistedProductIds = (newComponent.contentConfiguration.value.apiSettings.blacklistedProductIds || []).filter(productId => productId !== prodId)
                        setComponentConfig(newComponent)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            )
          }}
        />
      </div>
      :
      <div></div>
  )
}

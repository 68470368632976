import { componentServiceBaseUrl } from 'src/config'
import { axiosInstance } from 'src/utils/axios'
import { logging } from 'src/utils/logging'
import { PageConfig } from 'storefront-interpreter/src/config'
import { PrependShop } from 'src/contexts/types'

export type GetAffectedPages = (affectorId: string) => Promise<PageConfig[] | undefined>
export type GetAffectedPagesApi = PrependShop<GetAffectedPages>

export const getAffectedPagesApi: GetAffectedPagesApi = async (activeShop: string, affectorId: string) => {
  try {
    const url = `/v1/pages/with/${affectorId}`

    const opts = {
      baseURL: componentServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    }

    const response = await axiosInstance.get<{pages: PageConfig[]}>(url, opts)
    return response.data.pages || []
  } catch (error) {
    logging(error, { tags: { section: 'get affected pages api' } })
  }
}

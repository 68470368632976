import { axiosInstance } from 'src/utils/axios'
import { componentServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { PrependShop } from 'src/contexts/types'
import { AxiosResponse } from 'src/types/axios'
import { StorefrontConfig } from 'src/types/interpretor'

export type DuplicateStorefront = (storefrontId: string) => Promise<AxiosResponse<StorefrontConfig> | undefined>
export type DuplicateStorefrontApi = PrependShop<DuplicateStorefront>
export const duplicateStorefrontApi: DuplicateStorefrontApi = async (activeShop: string, storefrontId: string) => {
  try {
    const url = `/v1/pages/${storefrontId}/copy`
    const config = {
      baseURL: componentServiceBaseUrl,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.post<StorefrontConfig>(url, undefined, config)
    return response
  }
  catch (error) {
    console.log({error})
    logging(error, {tags: {section: 'duplicate storefront api'}})
  }
}

import { render, screen } from '@testing-library/react'

import wrapComponent from 'src/tests/wrapComponent'
import Components from './index'

jest.mock('launchdarkly-react-client-sdk', () => ({
  useFlags: () => { return { campaign_stores: true }},
}))

const setup = () => render(wrapComponent(Components, {}))

describe('Components list page', () => {
  beforeEach(setup)

  it('renders table of components', async () => {
    await screen.findByText('More Like This')
    screen.getByRole('checkbox', { name: 'LibraryFilled.svg Library'})
    screen.getByRole('checkbox', { name: 'Hooks.svg Hooks'})
    screen.getByRole('checkbox', { name: 'Integrations.svg Integrations'})
    screen.getByRole('button', { name: 'New Component'})

    screen.getByRole('row', { name: 'Component Name Component Label Type Group Last Modified Creation time'})
    expect(screen.getAllByRole('button', { name: 'Edit'}).length).toEqual(6)

    screen.getAllByRole('row', { name: new RegExp('Similar Products More Like This SIMILAR PRODUCTS Hooks.svg Hooks SdxLogoMark.svg')})
    screen.getAllByRole('row', { name: new RegExp('All Published Campaigns Hot On Social ALL PUBLISHED CAMPAIGNS Hooks.svg Hooks SdxLogoMark.svg')})
    screen.getAllByRole('row', { name: new RegExp('SFOM MAN CAT 6789000 SFOM MAN CAT 6789000 MANUAL CATEGORY NAVIGATION LibraryFilled.svg Library')})
    screen.getAllByRole('row', { name: new RegExp('sfom internal 1234 Sfom new one MANUAL CATEGORY NAVIGATION LibraryFilled.svg Library')})
    screen.getAllByRole('row', { name: new RegExp('Back In Stock Notification BACK IN STOCK NOTIFICATION Integrations.svg Integrations KlaviyoLogo.svg')})
    screen.getAllByRole('row', { name: new RegExp('Email Capture EMAIL CAPTURE Integrations.svg Integrations KlaviyoLogo.svg')})
  })
})

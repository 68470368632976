import { axiosInstance } from 'src/utils/axios'
import { socialServiceBaseUrl } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShop } from '../types'

export interface MetaUrl {
  downloadUrl: string
}

export type GetMetaDownloadUrlV2 = (
  startTimestamp: number, endTimestamp: number, adIds: string, accountId: string, splitByDay: boolean
) => Promise<AxiosResponse<MetaUrl> | undefined>

export type GetMetaDownloadUrlV2Api = PrependShop<GetMetaDownloadUrlV2>

export const getMetaDownloadApiV2: GetMetaDownloadUrlV2Api = async (
  activeShop: string,
  startTimestamp: number, endTimestamp: number, adIds: string, accountId: string, splitByDay: boolean,
) => {
  try {
    const baseUrl = '/v1/meta-ads/insights'
    const params = new URLSearchParams()
    params.append('startTimestamp', startTimestamp.toString())
    params.append('endTimestamp', endTimestamp.toString())
    params.append('accountId', accountId)
    params.append('adIds', adIds)
    params.append('splitByDay', splitByDay ? '1' : '0')
    const url = `${baseUrl}?${params.toString()}`

    const cfg = {
      baseURL: socialServiceBaseUrl,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<MetaUrl>(url, cfg)

    return response
  }

  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get meta ad insights v2' } })
    throw error
  }
}

import React, { FC } from 'react'
import { ComponentTypeResponse, getCategoryFields, getMinifiedFields } from 'src/views/Components/types'
import { ContainerTypeEnum, DisplayTypesEnum } from 'src/views/ComponentSettingsPage/types'
import { getInitialStorePrimitiveSettings } from 'src/contexts/GenericContext'
import { ContainerTypeSelector, DisplayTypeSelector, ImageFitSelector, RowSelector } from './SharedSelectors'
import { ImageFit } from 'storefront-interpreter/src/api/components'

type Props = {
  componentConfig: ComponentTypeResponse
  setComponentConfig: React.Dispatch<React.SetStateAction<ComponentTypeResponse | undefined>>
}

export const Storybook: FC<Props> = ({
  componentConfig,
  setComponentConfig,
}) => {
  const [numberError, setNumberError] = React.useState('')
  return (
    componentConfig.contentConfiguration.type === 'STOREFRONT_PAGE_PRIMITIVE' ?
      <div>
        <div style={{
          display: 'flex',
          marginRight: '12px',
        }}>
          <ImageFitSelector
            value={componentConfig.contentConfiguration.value.uiSettings.imageFit || getInitialStorePrimitiveSettings().storefront.uiSettings.imageFit as ImageFit}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'STOREFRONT_PAGE_PRIMITIVE') return
              newComponent.contentConfiguration.value.uiSettings.imageFit = value
              setComponentConfig(newComponent)
            }}
            section="storefront"
          />
          <DisplayTypeSelector
            value={componentConfig.contentConfiguration.value.uiSettings.fields.length > 1 ? DisplayTypesEnum.EXTENDED : DisplayTypesEnum.MINIFIED}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'STOREFRONT_PAGE_PRIMITIVE') return
              newComponent.contentConfiguration.value.uiSettings.fields =
                value === DisplayTypesEnum.MINIFIED ? getMinifiedFields() : getCategoryFields()
              setComponentConfig(newComponent)
            }}
          />
          <ContainerTypeSelector
            value={componentConfig.containerType as ContainerTypeEnum}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'STOREFRONT_PAGE_PRIMITIVE') return
              newComponent.containerType = value
              setComponentConfig(newComponent)
            }}
          />
          <RowSelector
            value={componentConfig.contentConfiguration.value.uiSettings.defaultRowCount}
            onChange={(value) => {
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'STOREFRONT_PAGE_PRIMITIVE') return
              if (value < 1) {
                newComponent.contentConfiguration.value.uiSettings.defaultRowCount = undefined
                setComponentConfig(newComponent)
                setNumberError('Minimum number of row is 1')
                return
              }
              if (value > 15) {
                newComponent.contentConfiguration.value.uiSettings.defaultRowCount = 15
                setComponentConfig(newComponent)
                setNumberError('Maximum number of row is 15')
                return
              }
              newComponent.contentConfiguration.value.uiSettings.defaultRowCount = value
              setComponentConfig(newComponent)
              setNumberError('')
            }}
            onBlur={() => {
              setNumberError('')
              const newComponent: ComponentTypeResponse = { ...componentConfig }
              if (newComponent.contentConfiguration.type !== 'STOREFRONT_PAGE_PRIMITIVE') return
              if (newComponent.contentConfiguration.value.uiSettings.defaultRowCount === undefined) {
                newComponent.contentConfiguration.value.uiSettings.defaultRowCount = 1
                setComponentConfig(newComponent)
              }
            }}
            error={numberError}
          />
        </div>
      </div>
      :
      <div></div>
  )
}

import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2 } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShopCurrency } from '../types'
import { CurrencyCode } from 'src/utils/currencySymbolMap'

export type MetricData = {
  lift: Lift
  graph: {
    timestamp: number
    data: number
  }[]
} & SdxValue

export type Lift = {
  percent?: number
  isFlipped: boolean
}

export type SdxValue = {
  value?: number
} & ({
    metricType: 'COUNT' | 'PERCENT'
  } |
{
  metricType: 'CURRENCY'
  currency: CurrencyCode
})

export type Metric = 'COUNT' | 'PERCENT' | 'CURRENCY'
export type CampaignSummaryMetric = {
  revenue: {
    currency: CurrencyCode
    metricType: Metric
    sdx?: number
    brand?: number
    lift: Lift
  }

  visitors: MetricData
  roas: MetricData
  cac: MetricData
  bounceRate: MetricData
  surveyCompletion: MetricData
}

export type NewCampaignSummaryMetric = {
  graph: {
    timestamp: number
    data: number
  }[]
  lift: Lift
  metric: {value: SdxValue, label: string}
  pieChart: {value: SdxValue, label: string}[]
}

export const getDefaultSummaryMetric = (): NewCampaignSummaryMetric => ({
  'graph': [],
  'lift': {
    'percent': 0,
    'isFlipped': false,
  },
  'metric': {
    'value': {
      'value': undefined,
      'metricType': 'COUNT',
    },
    'label': '',
  },
  'pieChart': [],
})

export const getDefaultSummaryMetrics = (): NewCampaignSummaryMetric[] => {
  return Array.from({length: 6}, () => getDefaultSummaryMetric())
}

export type GetCampaignSummaryMetrics = (
  isBrand:boolean, startTimestamp: number, endTimestamp: number
) => Promise<AxiosResponse<NewCampaignSummaryMetric[]> | undefined>

export const getCampaignSummaryMetricsApi: PrependShopCurrency<GetCampaignSummaryMetrics> = async (
  activeShop, currencyCode,
  isBrand, startTimestamp, endTimestamp,
) => {
  try {
    const baseUrl = '/v1/analytics/campaign-summary-metrics'
    const params = new URLSearchParams()
    params.append('currency', currencyCode)
    params.append('startTimestamp', startTimestamp.toString())
    params.append('endTimestamp', endTimestamp.toString())
    if(isBrand) params.append('isBrand', '1')
    const url = `${baseUrl}?${params.toString()}`

    const cfg = {
      baseURL: analyticsServiceBaseUrl2,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<NewCampaignSummaryMetric[]>(url, cfg)
    return response
  }

  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

import React, { FC, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { PageConfig } from 'storefront-interpreter/src/config'
import { getPageStatus } from 'src/utils/GetPageStatus'
import { Typography, Button, TextField, Modal, FormControl, Snackbar, Box, InputAdornment } from '@mui/material'
import { processUrlInput, removeProtocolFromUrl, validateUrl } from 'src/utils/helpers'


const PREFIX = 'RedirectModal'

const classes = {
  linkModal: `${PREFIX}-linkModal`,
}

const Root = styled('span')((
  {
    theme,
  },
) => ({
  [`&.${classes.linkModal}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    maxWidth: 460,
    width: '100%',
    padding: '24px',
    borderRadius: '4px',
  },
}))


export type RedirectModalProps = {
  isOpen: boolean
  onSave: (pageConfig: PageConfig) => Promise<void>
  onClose: () => void
  pageConfig: PageConfig
}

export const RedirectModal: FC<RedirectModalProps> = ({
  isOpen,
  onClose,
  onSave,
  pageConfig,
}) => {

  // const theme = useTheme()

  const [copied, setCopied] = useState(false)
  const [shouldPublishConfig, setShouldPublishConfig] = useState(false)
  const [redirectUrlErrorString, setRedirectUrlErrorString] = useState('')
  const [redirectUrl, setRedirectUrl] = useState('')

  useEffect(() => {
    // When you open this modal, you are trying to change the status of the page
    setShouldPublishConfig(!(getPageStatus(pageConfig) === 'PUBLISHED'))
    setRedirectUrl(pageConfig.redirectUrl || '')
  }, [pageConfig])

  // const handlePublishChange = () => {
  //   setShouldPublishConfig(!shouldPublishConfig)
  // }

  const onSaveSettings = async () => {
    if(!shouldPublishConfig){
      if(validateUrl(redirectUrl)) {
        setRedirectUrlErrorString('')
        const config: PageConfig = {
          ...pageConfig,
          endDate: new Date().getTime(),
          redirectUrl: redirectUrl,
        }
        await onSave(config)
        onClose()
      }
      else {
        setRedirectUrlErrorString('Please input a valid URL')
      }
    } else {
      const config: PageConfig = {
        ...pageConfig,
        startDate: new Date().getTime(),
        endDate: undefined,
        redirectUrl: redirectUrl,
      }
      await onSave(config)
    }
  }

  return (
    <Root>
      <Modal
        open={isOpen}
        onClose={onClose}
      >
        <Root className={classes.linkModal}>
          <Typography
            style={{
              color: '#546D78',
              fontSize: 13,
              fontWeight: 600,
              marginBottom: 7,
            }}
            component="h3"
          >
            {(shouldPublishConfig ? 'PUBLISH': 'UNPUBLISH') + ' CAMPAIGN STORE'}
            &nbsp;-&nbsp;{pageConfig.internalName}
          </Typography>
          <br/>
          {shouldPublishConfig &&
            <Typography
              style={{
                color: '#000000',
                fontSize: 13,
              }}
              id="modal-modal-description"
            >
              When you publish a page, it will be moved from the inactive pages list to the active pages list. The page's end date will be removed, and the page will become available on its public URL, as well as in certain types of components, like hooks.
            </Typography>
          }
          {!shouldPublishConfig &&
            <Typography
              style={{
                color: '#000000',
                fontSize: 13,
              }}
              id="modal-modal-description"
            >
              When you unpublish a page, it will be moved from the active pages list to the inactive pages list. The page's end date will be set to the current time, and it will no longer be displayed in hooks or other components. If someone tries to visit the page's public URL, they will be automatically redirected to a designated URL below. You can set this URL to a different page, such as a homepage or category page.
            </Typography>
          }
          <FormControl fullWidth>
            {/* <Grid container direction="row" spacing={1} alignItems="center" justifyContent='space-between'>
            <Spacer space={2}/>
              <Grid item>
                <Typography> Page Status </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <Typography
                      style={{
                        color: shouldPublishConfig ? 'inherit' : theme.palette.brand.urIndigo,
                      }}>Unpublish</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      style={{color: shouldPublishConfig ? '' : theme.palette.brand.urIndigo}}
                      checked={shouldPublishConfig}
                      onChange={handlePublishChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography style={{
                      color: shouldPublishConfig ? theme.palette.brand.urIndigo: 'inherit',
                    }}>Publish</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
 */}
            {!shouldPublishConfig &&
              <TextField
                id="url"
                label="Redirect unpublished page to this URL"
                variant="outlined"
                style={{
                  marginTop: 24,
                }}
                InputProps={{
                  startAdornment: <InputAdornment sx={{pr: 0, mr: 0, userSelect: 'none'}} position="start">https://</InputAdornment>,
                }}
                value={removeProtocolFromUrl(redirectUrl)}
                onChange={(e) => {
                  setRedirectUrlErrorString('')
                  setRedirectUrl(processUrlInput(e.target.value))
                }}
                error={!shouldPublishConfig && (redirectUrl === '' || !!redirectUrlErrorString)}
                helperText={redirectUrlErrorString}
              />
            }

            <Snackbar
              open={copied}
              onClose={() => setCopied(false)}
              autoHideDuration={900}
              message="Copied to clipboard"
            />

            <Button
              color='secondary'
              variant='contained'
              style={{
                width: '100%',
                marginTop: 24,
              }}
              disabled={!shouldPublishConfig && (redirectUrl === '' || !!redirectUrlErrorString)}
              onClick={onSaveSettings}
            >
              {'Confirm ' + (shouldPublishConfig ? 'publish': 'unpublish')}
            </Button>

            <Button
              color='secondary'
              variant='text'
              style={{
                width: '100%',
                marginTop: 1,
              }}
              onClick={onClose}
            >
              Cancel
            </Button>

          </FormControl>
        </Root>
      </Modal>


    </Root>
  )
}

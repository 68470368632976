import { logging } from 'src/utils/logging'
import { PrependShop } from '../types'
import { PermalinkConfig } from 'src/views/EditPermalinkConfig/EditPermalinkConfigUI'
import { axiosInstance } from 'src/utils/axios'
import { ecommerceServiceBaseUrl, productServiceBaseUrl } from 'src/config'
import { string } from 'joi'
import { RepeatOneSharp } from '@mui/icons-material'

type PermalinkConfigApi = {
  baseUrl: string
  itemsLocation: 'PATH' | 'QUERY'
  pathConfig: PathConfig | undefined
  queryConfig: QueryConfig | undefined
}

type PathConfig = {
  quantitySeparator: string
  itemSeparator: string
  isBase64Encoded: boolean
}

type QueryConfig = {
  queryParamName: string
  variantFieldName: string
  quantityFieldName: string
  optionsFieldName: string
  isBase64Encoded: boolean
}

type PermalinkApi = {
  cartPermalink: string
}

type Variant = {
  externalId: string
}

export type GetPermalinkConfig = () => Promise<PermalinkConfig | undefined>
export type GetPermalinkConfigApi = PrependShop<GetPermalinkConfig>

export const getPermalinkConfigApi: GetPermalinkConfigApi = async (activeShop: string) => {
  try {
    // fetch the data from the api.
    const url = '/v1/permalink/config'
    const opts = {
      baseURL: ecommerceServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    }
    const response = await axiosInstance.get<PermalinkConfigApi>(url, opts)

    // transform the data from the api into the format the frontend is expecting.
    if (response && response.status === 200 && response.data) {
      return permalinkApiToPermalinkFrontend(response.data)
    } else {
      window.alert('Error getting permalink config')
      console.error('Error getting permalink config')
      return undefined
    }
  } catch (error) {
    console.log(error)
    logging(error, {tags: {section: 'get permalink config api'}})
  }
}

export type PostPermalinkConfig = (frontendConfig: PermalinkConfig) => Promise<PermalinkConfig | undefined>
export type PostPermalinkConfigApi = PrependShop<PostPermalinkConfig>

export const postPermalinkConfigApi: PostPermalinkConfigApi = async(activeShop: string, frontendConfig: PermalinkConfig) => {
  const apiConfig = permalinkFrontendToPermalinkApi(frontendConfig)
  try {
    // fetch the data from the api.
    const url = '/v1/permalink/config'
    const opts = {
      baseURL: ecommerceServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    }
    const response = await axiosInstance.post<PermalinkConfigApi>(url, apiConfig, opts)

    // transform the data from the api into the format the frontend is expecting.
    if (response && response.status === 200 && response.data) {
      return permalinkApiToPermalinkFrontend(response.data)
    } else {
      window.alert('Error submitting permalink config')
      console.error('Error submitting permalink config')

      return undefined
    }
  } catch (error) {
    console.log(error)
    logging(error, {tags: {section: 'get permalink config api'}})
  }
}

export type GetPermalinkPreview = () => Promise<string | undefined>
export type GetPermalinkPreviewApi = PrependShop<GetPermalinkPreview>

export const getPermalinkPreviewApi: GetPermalinkPreviewApi = async(activeShop: string) => {
  // first, get an arbitrary variant id
  const variantId = await getVariantId(activeShop)

  // use the variant id to generate a permalink preview.
  if (variantId && variantId !== '') {
    const permalink =await getPermalinkPreview(activeShop, variantId)
    if (permalink && permalink !== '') {
      return permalink
    }
  }

  return undefined
}

const getPermalinkPreview = async(activeShop: string, variantId: string): Promise<string | undefined> => {
  const payload = {
    variantData: [
      {
        externalId: variantId,
        quantity: 1,
      },
    ],
  }
  try {
    // fetch the data from the api.
    const url = '/v1/permalink'
    const opts = {
      baseURL: ecommerceServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    }
    const response = await axiosInstance.post<PermalinkApi>(url, payload, opts)

    // transform the data from the api into the format the frontend is expecting.
    if (response && response.status === 200 && response.data && response.data.cartPermalink !== '') {
      return response.data.cartPermalink
    } else {
      console.error('Error getting permalink preview')

      return undefined
    }
  } catch (error) {
    console.log(error)
    logging(error, {tags: {section: 'get permalink preview api'}})
  }
}

const getVariantId = async(activeShop: string): Promise<string | undefined> => {
  try {
    // fetch the data from the api.
    const url = '/v1/variants'
    const opts = {
      baseURL: productServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    }
    const response = await axiosInstance.get<Variant[]>(url, opts)

    // transform the data from the api into the format the frontend is expecting.
    if (response && response.status === 200 && response.data && response.data.length > 1) {
      return response.data[0].externalId
    } else {
      window.alert('Error getting variant id')
      console.error('Error getting variant id')
      return undefined
    }
  } catch (error) {
    console.log(error)
    logging(error, {tags: {section: 'get variant id api'}})
  }
}

const permalinkApiToPermalinkFrontend = (apiConfig: PermalinkConfigApi): PermalinkConfig => {
  if (apiConfig.pathConfig) {
    return {
      baseUrl: apiConfig.baseUrl,
      itemsLocation: apiConfig.itemsLocation,
      isBase64Encoded: apiConfig.pathConfig?.isBase64Encoded,

      quantitySeparator: apiConfig.pathConfig?.quantitySeparator,
      itemSeparator: apiConfig.pathConfig?.itemSeparator,
    } as PermalinkConfig
  } //else if (apiConfig.queryConfig) {
  return {
    baseUrl: apiConfig.baseUrl,
    itemsLocation: apiConfig.itemsLocation,
    isBase64Encoded: apiConfig.queryConfig?.isBase64Encoded,

    queryParamName: apiConfig.queryConfig?.queryParamName,
    variantFieldName: apiConfig.queryConfig?.variantFieldName,
    quantityFieldName: apiConfig.queryConfig?.quantityFieldName,
    optionsFieldName: apiConfig.queryConfig?.optionsFieldName,
  } as PermalinkConfig

}

const permalinkFrontendToPermalinkApi = (frontendConfig: PermalinkConfig): PermalinkConfigApi => {
  if (frontendConfig.itemsLocation === 'PATH') {
    return {
      baseUrl: frontendConfig.baseUrl,
      itemsLocation: frontendConfig.itemsLocation,
      pathConfig: {
        quantitySeparator: frontendConfig?.quantitySeparator,
        itemSeparator: frontendConfig?.itemSeparator,
        isBase64Encoded: frontendConfig.isBase64Encoded,
      } as PathConfig,
    } as PermalinkConfigApi
  } // else if (frontend.itemsLocation === 'QUERY)
  return {
    baseUrl: frontendConfig.baseUrl,
    itemsLocation: frontendConfig.itemsLocation,
    queryConfig: {
      queryParamName: frontendConfig?.queryParamName,
      variantFieldName: frontendConfig?.variantFieldName,
      quantityFieldName: frontendConfig?.quantityFieldName,
      optionsFieldName: frontendConfig?.optionsFieldName,
      isBase64Encoded: frontendConfig.isBase64Encoded,
    } as QueryConfig,
  } as PermalinkConfigApi
}

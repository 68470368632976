import { useEffect, useState } from 'react'

import type { FC } from 'react'
import { ProviderConfig, asyncWithLDProvider  } from 'launchdarkly-react-client-sdk'
import useAuth from 'src/hooks/useAuth'
import GlobalStyles from '@mui/material/GlobalStyles'
import routes, { renderRoutes } from 'src/routes'
import { ProductFruits } from 'react-product-fruits'
import useGenericContext from './hooks/useGenericContext'
import SdxLoading from './components/SdxLoading/SdxLoading'

const inputGlobalStyles = <GlobalStyles styles={{
  '*':  {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
  },
  html: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    height: '100% !important',
    width: '100% !important',
  },
  body: {
    height: '100% !important' ,
    width: '100% !important',
    overflow: 'hidden',
  },
  '#root': {
    height: '100% !important',
    width: '100% !important',
  },
}} />

export type WebAppProps = {
  ldExtraConfig: ProviderConfig
}

const WebApp: FC<WebAppProps> = ({ldExtraConfig}) => {
  const auth = useAuth()
  const [LDProvider, setLDProvider] = useState<any>(null)
  const PFWorkspaceCode = process.env.PRODUCT_FRUITS_WORKSPACE_CODE || 'HlwYZqDgdZ9aVeOV'
  const { merchantDetails } = useGenericContext()

  useEffect(() => {
    async function initLd() {
      try {
        const LDProviderWrapper = await asyncWithLDProvider({
          ...ldExtraConfig,
          context: {
            kind: 'multi',
            user: {
              email: auth.user?.email,
              name: auth.user?.name,
              key: auth.user?.id,
            },
            shop: {
              key: auth.selectedStore,
              name: auth.selectedStore,
              tier: merchantDetails?.plan,
              platform: merchantDetails?.platform,
            },
          },
        })
        setLDProvider(() => LDProviderWrapper)
      } catch (err) {
        console.log('Error initializing LDProvider', err)
      }
    }
    initLd()

  }, [auth.selectedStore, auth.user?.email, auth.user?.id, auth.user?.name, ldExtraConfig, merchantDetails?.plan])

  if (!LDProvider) {
    return (<SdxLoading />)
  } else {
    return (
      <LDProvider>
        <ProductFruits workspaceCode={PFWorkspaceCode} language="en" user={{
          username: auth.user?.email,
          email: auth.user?.email,
          firstname: auth.user?.name, // Name is firstname as passed by Cognito
        }} />
        {inputGlobalStyles}
        {renderRoutes(routes)}
      </LDProvider>
    )}
}

export default WebApp

import React, { useEffect } from 'react'
import type { FC }from 'react'

import { useHistory } from 'react-router-dom'
import { getRoutePath } from 'src/routes'
import useGenericContext from 'src/hooks/useGenericContext'
import SdxLoading from 'src/components/SdxLoading/SdxLoading'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isShopifyEmbedded } from '@shopify/app-bridge-utils'

const Home: FC = () => {
  const history = useHistory()
  const { merchantDetails } = useGenericContext()
  const { analytics } = useFlags()
  const isShopify = isShopifyEmbedded()

  useEffect(() => {
    async function verifyPlan() {
      if (merchantDetails?.plan === undefined || merchantDetails?.plan === 'NONE' ) {
        if(isShopify) {
          history.push(getRoutePath('shopify_plans'))
        } else {
          // default view for no plans
          history.push(getRoutePath('no_plans'))
        }
      } else {
        history.push(getRoutePath('stores'))
      }
    }
    verifyPlan()
  }, [merchantDetails, history])

  return (
    <SdxLoading/>
  )
}

export default Home

import extractFirstname from './extractFirstname'

test('Extracts firstname', () => {
  expect(extractFirstname('James wood')).toBe('James')
})

test('returns empty string', () => {
  expect(extractFirstname('')).toBe('')
})

test('extract from single name', () => {
  expect(extractFirstname('John')).toBe('John')
})

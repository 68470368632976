import { render, screen } from '@testing-library/react'

import wrapComponent from 'src/tests/wrapComponent'
import CreateComponent from './index'

const setup = () => render(wrapComponent(CreateComponent, {}))

describe('CreateComponent', () => {
  beforeEach(setup)

  it('renders screen to create component', async () => {
    await screen.findByText('Create Component')
    screen.getByText('Create a component which you can use across all campaign stores.')
    screen.getAllByRole('button', { name: 'Cancel'})
    screen.getAllByRole('button', { name: 'Create'})
    screen.getAllByRole('textbox', { name: new RegExp('Internal Name')})
    screen.getAllByRole('textbox', { name: new RegExp('Description')})
    screen.getAllByRole('textbox', { name: new RegExp('Component Label')})
    screen.getAllByRole('button', { name: 'Manual Product Selection'})
    screen.getByTitle('Live preview')
  })
})

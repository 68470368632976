import { axiosInstance } from 'src/utils/axios'
import { analyticsServiceBaseUrl2 } from 'src/config'
import type { AxiosResponse } from 'src/types/axios'
import { logging } from 'src/utils/logging'
import { PrependShopCurrency } from '../types'
import { CurrencyCode } from 'src/utils/currencySymbolMap'
import { CampaignImage } from './getTopCampaigns'
import { extendCampaignImages } from './extendCampaignImages'

export type CampaignTicker = {
  campaignId: string
  campaignName: string
  campaignImages: CampaignImage[]
  revenue: number
  currency: CurrencyCode
  visitors: number
  isIncrease: boolean
}

export type GetCampaignTickers = (
  isBrand: boolean
) => Promise<AxiosResponse<CampaignTicker[]> | undefined>

export const getCampaignTickerApi: PrependShopCurrency<GetCampaignTickers> = async (
  activeShop, currencyCode, isBrand,
) => {
  try {
    const baseUrl = '/v1/analytics/campaign-tickers'
    const params = new URLSearchParams()
    params.append('currency', currencyCode)
    params.append('currentTime', new Date().getTime().toString())
    if(isBrand) params.append('isBrand', '1')
    const url = `${baseUrl}?${params.toString()}`

    const cfg = {
      baseURL: analyticsServiceBaseUrl2,
      headers: {shop: activeShop},
    }

    const response = await axiosInstance.get<CampaignTicker[]>(url, cfg)
    await extendCampaignImages(activeShop, response.data)
    return response
  }

  catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get performance analytics api' } })
  }
}

import { Box, Button, FormControlLabel, Link, MenuItem, Switch, TextField,Typography, useTheme } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import useGenericContext from 'src/hooks/useGenericContext'
import { useFlags } from 'launchdarkly-react-client-sdk'

export type PermalinkConfig = {
  // shared config items
  baseUrl: string
  itemsLocation: 'PATH' | 'QUERY'
  isBase64Encoded: boolean

  // possible path config items
  quantitySeparator?: string
  itemSeparator?: string

  // possible query config items
  queryParamName?: string
  variantFieldName?: string
  quantityFieldName?: string
  optionsFieldName?: string

}

// ConfigSettingsInfo objects are used to render the text fields for config settings.
type ConfigSettingsInfo = {
  fieldName: string
  displayName: string
  value: string | undefined
}

// getConfigSettings controlls which settings are available as text fields based on the config items location.
const getConfigSettings = (config: PermalinkConfig): ConfigSettingsInfo[] => {
  if (config.itemsLocation === 'QUERY') {
    return [
      {
        fieldName: 'queryParamName',
        displayName: 'Query Parameter Name',
        value: config.queryParamName,
      },
      {
        fieldName:'variantFieldName',
        displayName: 'Variant Field Name',
        value: config.variantFieldName,
      },
      {
        fieldName:'quantityFieldName',
        displayName: 'Quantity Field Name',
        value: config.quantityFieldName,
      },
      {
        fieldName: 'optionsFieldName',
        displayName: 'Options Field Name',
        value: config.optionsFieldName,
      },
    ]
  }
  //else if (config.itemsLocation === 'PATH')
  return [
    {
      fieldName: 'quantitySeparator',
      displayName: 'Quantity Separator',
      value: config.quantitySeparator,
    },
    {
      fieldName:'itemSeparator',
      displayName: 'Item Separator',
      value: config.itemSeparator,
    },
  ]
}

const getDefaultConfig = (): PermalinkConfig => (
  {
    baseUrl: '',
    itemsLocation: 'QUERY',
    queryParamName: '',
    variantFieldName: '',
    quantityFieldName: '',
    optionsFieldName: '',
    isBase64Encoded: false,
  }
)

const EditPermalinkConfigUI: FC = () => {
  const {getPermalinkConfig, postPermalinkConfig, getPermalinkPreview} = useGenericContext()
  const [permalinkConfig, setPermalinkConfig] = useState(getDefaultConfig())
  const [configSettings, setConfigSettings] = useState<ConfigSettingsInfo[]>([])
  const [permalinkPreview, setPermalinkPreview] = useState<string>('')
  const [formValid, setFormValid] = useState<boolean>(true)
  const [loading, setLoading] = useState(true)
  const [checkErrors, setCheckErrors] = useState(false)
  const theme = useTheme()
  const [baseUrlErrorString, setBaseUrlErrorString] = useState<string>('')

  // fetch the shop's initial permalink config.
  useEffect(() => {
    const fetchAndSetConfig = async () => {
      const fetchedConfig = await getPermalinkConfig()
      if (!fetchedConfig) return
      setPermalinkConfig(fetchedConfig)
      setLoading(false)
    }

    fetchAndSetConfig()
  }, [getPermalinkConfig])

  // fetch a preview permalink based on the config
  const fetchAndSetPermalinkPreview = useCallback(async() => {
    if (loading) return
    const fetchedPermalinkPreveiw = await getPermalinkPreview()
    if (!fetchedPermalinkPreveiw) return
    setPermalinkPreview(fetchedPermalinkPreveiw)
  },[loading,getPermalinkPreview])
  useEffect(() => {
    fetchAndSetPermalinkPreview()
  }, [fetchAndSetPermalinkPreview])

  // adjust the settings array when we change the permalink config.
  useEffect(() => {
    setConfigSettings(getConfigSettings(permalinkConfig))
  },[permalinkConfig])

  const handleInputChange = ((event, field) => {
    setBaseUrlErrorString('')
    // changing the items location should wipe the previous config settings
    let newConfig
    if (field === 'itemsLocation') {
      newConfig = {
        baseUrl: permalinkConfig.baseUrl,
        isBase64Encoded: permalinkConfig.isBase64Encoded,
        itemsLocation: event.target.value,
      }
    } else {
      newConfig = Object.assign({}, permalinkConfig)
    }
    newConfig[field] = event.target.value

    setPermalinkConfig({ ...newConfig })
    setFormValid(isFormValid(newConfig))
  })

  const isFormValid = ((config: PermalinkConfig) => {
    if (config.itemsLocation === 'PATH') {
      return !!config.quantitySeparator && config.quantitySeparator !== '' &&
      !!config.itemSeparator && config.itemSeparator !== ''
    }

    if (config.itemsLocation === 'QUERY') {
      return !!config.queryParamName && config.queryParamName !== '' &&
      !!config.variantFieldName && config.variantFieldName !== '' &&
      !!config.quantityFieldName && config.quantityFieldName !== '' &&
      !!config.optionsFieldName && config.optionsFieldName !== ''
    }

    return false
  })


  const handleSubmit = async () => {
    if (!permalinkConfig.baseUrl) {
      setBaseUrlErrorString('Base Url is required')
      return
    }
    else if (!permalinkConfig.baseUrl.startsWith('https://')) {
      setBaseUrlErrorString('Base Url needs to start with https://')
      return
    }
    else if (permalinkConfig.baseUrl.indexOf(' ') !== -1) {
      setBaseUrlErrorString('Base Url cannot contain spaces')
      return
    }

    if (!formValid) {
      setCheckErrors(true)
      return
    } else {
      setCheckErrors(false)
    }
    try {
      setLoading(true)
      // update the config on the repo.
      const response = await postPermalinkConfig(permalinkConfig)

      if (response) {
        setLoading(false)
        setPermalinkConfig(response)
        // fetch a new permalink preview
        fetchAndSetPermalinkPreview()
      } else {
        window.alert('Error submitting permalink config')
        console.error('Error submitting permalink config')
        setLoading(false)
      }
    }
    catch (error) {
      console.error('Error creating/updating component', error)
      window.alert('Error creating/updating component')
      setLoading(false)
    }
  }

  return (
    <Box>
      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '18px',
          marginBottom: '12px',
          marginTop: '12px',
        }}
      >
      Cart Permalink Settings
      </Box>
      {'Permalink Preview: '}
      <Link href={permalinkPreview} target="_blank" rel="noopener noreferrer"
        sx={{ color: 'blue', textDecoration: 'underline',wordBreak: 'break-word',whiteSpace: 'normal'  }}>
        {permalinkPreview}
      </Link>
      <Box>
        <Box sx={{ marginTop: '16px' }}
          component="form"
          noValidate
          autoComplete="off"
        >
          {/* shared settings*/}
          <Box sx={{
            marginBottom: '16px',
          }}>
            Base Url
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <TextField
                sx={{
                  width: '100%',
                  marginBottom: '8px',
                }}
                onChange={(ev) => handleInputChange(ev, 'baseUrl')}
                size='small' type='string' variant='outlined'
                value={permalinkConfig.baseUrl}
                helperText={baseUrlErrorString}
                error={!!baseUrlErrorString}
              />
            </Box>

            Parameter Location
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <TextField
                id='parameter_location'
                style={{
                  width: '100%',
                  marginRight: '12px',
                  marginBottom: '8px',
                }}
                select
                value={permalinkConfig.itemsLocation}
                size='small' margin='dense' type='string' variant='outlined'
                onChange={(ev) => handleInputChange(ev, 'itemsLocation')}
              >
                <MenuItem key={'PATH'} value={'PATH'}>Path</MenuItem>
                <MenuItem key={'QUERY'} value={'QUERY'}>Query</MenuItem>
              </TextField>
            </Box>

            Base 64 Encoded?
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <FormControlLabel
                sx={{
                  marginLeft: '0px',
                  marginRight: '10px',
                }}
                label={<Typography sx={{
                  color: !permalinkConfig.isBase64Encoded ? theme.palette.brand.urIndigo : 'inherit',
                }}>
              No
                </Typography>}
                labelPlacement='start'
                control={
                  <Switch
                    style={{ color: !permalinkConfig.isBase64Encoded ? theme.palette.brand.urIndigo : '' }}
                    checked={permalinkConfig.isBase64Encoded}
                    onChange={() => {
                      const event = {
                        target: {
                          value: !permalinkConfig.isBase64Encoded,
                        },
                      }
                      handleInputChange(event, 'isBase64Encoded')
                    }
                    }
                    color='primary'
                  />
                }
              />

              <Box
                sx={{
                  marginTop: '8px',
                }}
              >
            Yes
              </Box>
            </Box>
          </Box>

          {/* actual config settings */}
          <Box>
          Config Settings

            <Box
              sx={{
                marginTop: '8px',
              }}
            >
              {configSettings.map(setting => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                    key={setting.fieldName}
                  >
                    <TextField
                      sx={{
                        width: '100%',
                        marginBottom: '8px',
                      }}
                      onChange={(ev) => handleInputChange(ev, setting.fieldName)}
                      size='small' type='string' variant='outlined'
                      value={setting.value}
                      label={setting.displayName}
                      helperText={checkErrors ? 'This field is required' : ''}
                      error={checkErrors}
                    />
                  </Box>
                )
              })}
            </Box>
          </Box>

          {/* Submit and cancel buttons*/}
          <Box sx={{
            paddingTop: '8px',
            display: 'flex',
            gap: '5px',
            justifyContent: 'space-between',
            alignSelf: 'end',
          }}>
            <Box>
              <Button
                disabled={loading}
                variant='contained' color='primary'
                onClick={() => {
                  handleSubmit()
                }}
              >
                {loading ? 'Updating...' : 'Update'}
              </Button>
            </Box>
          </Box>

        </Box>
      </Box>
    </Box>
  )
}

export default EditPermalinkConfigUI

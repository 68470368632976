import moment from 'moment'

import type { TimeRange } from 'src/types/redux'
import { loadTimeRange } from './sessionStorage'

export type TimeRangeOption = 'last hour' | 'last 4 hours' | 'today' | 'last 7 days' | 'last 14 days' | 'last 30 days'

export const TIME_OPTIONS: Record<TimeRangeOption, number> = {
  'last hour': 3600000,
  'last 4 hours': 14400000,
  'today': 86400000,
  'last 7 days': 604800000,
  'last 14 days': 1209600000,
  'last 30 days': 2592000000,
}

export const DEFAULT_SELECTION = 'today'

export const getDateRange = (value: number, getFullDays?: boolean): Required<Pick<TimeRange, 'start'|'end'|'delim'>> => {
  if (getFullDays) {
    // Redshift performs better with fill day queries. Subtract 1 day to get full day which assumes the current day has ended.
    const start = moment(new Date()).subtract((value/86400000)-1, 'days').startOf('day').valueOf()
    const now = moment(new Date()).endOf('day').valueOf()
    const delim  = getTimeDelim(now, now - value)
    return {
      start: start,
      end: now,
      delim,
    }
  }
  const now = new Date().getTime()
  const delim  = getTimeDelim(now, now - value)
  return {
    start: now - value,
    end: now,
    delim,
  }
}

export const getDate = (): Required<TimeRange> => {
  const storageDate = loadTimeRange()
  const time: Required<TimeRange> = { start: 0, end: 0, selection: 'today', delim: '' }
  if (storageDate) {
    if (storageDate.selection !== 'custom') {
      const { start, end, delim } = getDateRange(TIME_OPTIONS[storageDate.selection], true)
      time.start = start
      time.end = end
      time.selection = storageDate.selection
      time.delim = delim || ''
    } else {
      return storageDate
    }
  } else {
    const { start, end, delim } = getDateRange(TIME_OPTIONS[DEFAULT_SELECTION], true)
    time.start = start
    time.end = end
    time.selection = DEFAULT_SELECTION
    time.delim = delim || ''
  }
  return time
}


export const getTimeDelim = (endTime: number, startTime: number ): string => {
  let delim = 'hour'
  const diffInMinutes = moment(endTime).diff(moment(startTime), 'minutes', true )

  const sixHoursInMinutes = 60*6
  const sixDaysInMinutes = 1440*6
  const sixWeeksInMinutes = 10080*6
  const sixMonthsInMinutes = 43800*6
  const sixYearsInMinutes = 525600*6

  // we want to be able to get at least 6 data points in backend response
  if (diffInMinutes < sixHoursInMinutes) {
    delim = 'minute'
  } else if (diffInMinutes > sixHoursInMinutes && diffInMinutes < sixDaysInMinutes) {
    delim = 'hour'
  } else if (diffInMinutes > sixDaysInMinutes && diffInMinutes < sixWeeksInMinutes){
    delim = 'day'
  } else if (diffInMinutes > sixWeeksInMinutes && diffInMinutes < sixMonthsInMinutes) {
    delim = 'week'
  } else if (diffInMinutes > sixMonthsInMinutes && diffInMinutes < sixYearsInMinutes) {
    delim = 'month'
  } else if (diffInMinutes > sixYearsInMinutes) {
    delim = 'year'
  }

  return delim
}

import React from 'react'
import { useTheme, Skeleton } from '@mui/material'


import type { FC } from 'react'

import Icon from 'src/components/Icon/Icon'
import UrBaseCard from 'src/components/UrBaseCard'
import MultiColumnRowCard from 'src/components/MultiColumnRowCard'
import { AnalyticsData } from 'src/types/components'

export interface MultiColumnRowProps {
  className?: string
  title: string
  subTitle?: string
  data: Array<AnalyticsData>
  isLoading: boolean
  error?: boolean
}

const MultiColumnRow: FC<MultiColumnRowProps> = ({ title, subTitle, data, isLoading, error }) => {
  const theme = useTheme()

  return (
    <UrBaseCard
      title={
        isLoading ?
          <Skeleton variant="text" width="20%" />
          : <div><div>{title}</div><div>{subTitle}</div></div>
      }
      subHeader={
        <MultiColumnRowCard
          data={data}
          loading={isLoading}
        />
      }
      mainIcon={
        <div style={{zoom: 1.5}}>
          <Icon
            name="RageClick"
            color={theme.palette.brand.blueGrey}
            loading={isLoading}
          />
        </div>
      }
      content={<span>&nbsp;</span>}
      error={error}
    />
  )
}

export default MultiColumnRow

